import React, { useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
const FormTab = (props) => {
  const [pbar, setPbar] = useState(10);
  window.scrollTo({
    top: 20,
    behavior: "smooth",
  });

  // if (window.location.href.indexOf("reload") == -1) {
  //   window.location.href = window.location.href + "?reload";
  // }
  function gototab(step) {
    console("new step", step, setPbar);
    if (step < parseInt(localStorage.getItem("activestep"))) {
      localStorage.setItem("activestep", step);

      // window.location.href = window.location.href + "?reload";
    }
    if (step === 2) {
      setPbar(20);
    }
    if (step === 3) {
      setPbar(30);
    }
    if (step === 4) {
      setPbar(40);
    }
    if (step === 5) {
      setPbar(50);
    }
  }

  return (
    <div className="tab-names tabbackground box arrow-bottommain">
      <div className=" ">
        <div className="row tab-align-center registrationform">
          <div
            className={
              1 === parseInt(localStorage.getItem("activestep"))
                ? " quiz_tab  d-flex activebtn"
                : " d-flex quiz_tab"
            }
          >
            <span>1</span>
            <div style={{ marginTop: "8px" }}>Basic Details</div>
          </div>
          <div
            className={
              2 === parseInt(localStorage.getItem("activestep"))
                ? " quiz_tab d-flex activebtn"
                : " d-flex quiz_tab"
            }
          >
            <span>2</span>
            <div style={{ lineHeight: "16px", marginTop: "3px" }}>
              Registration & <br /> Payments
            </div>
          </div>
          <div
            className={
              3 === parseInt(localStorage.getItem("activestep"))
                ? " quiz_tab  d-flex activebtn"
                : "quiz_tab  d-flex"
            }
          >
            <span>3</span>
            <div style={{ marginTop: "7px" }}>Rounds</div>
          </div>
          <div
            className={
              4 === parseInt(localStorage.getItem("activestep"))
                ? " quiz_tab  d-flex activebtn"
                : "quiz_tab  d-flex"
            }
          >
            <span>4</span>
            <div style={{ lineHeight: "16px", marginTop: "3px" }}>
              About
              <br /> Opportunity
            </div>
          </div>
          <div
            className={
              5 === parseInt(localStorage.getItem("activestep"))
                ? " quiz_tab  d-flex activebtn"
                : "quiz_tab  d-flex"
            }
          >
            <span>5</span>
            <div style={{ marginTop: "7px" }}>Prizes</div>
          </div>
          <div
            className={
              6 === parseInt(localStorage.getItem("activestep"))
                ? " quiz_tab  d-flex activebtn"
                : "quiz_tab  d-flex"
            }
          >
            <span>6</span>
            <div style={{ lineHeight: "16px", marginTop: "3px" }}>
              Additional
              <br /> Details
            </div>
          </div>
          <div
            className={
              7 === parseInt(localStorage.getItem("activestep"))
                ? " quiz_tab  d-flex activebtn"
                : "quiz_tab  d-flex"
            }
          >
            <span>7</span>
            <div style={{ marginTop: "7px" }}>Media/Gallery</div>
          </div>
          <div
            className={
              8 === parseInt(localStorage.getItem("activestep"))
                ? " quiz_tab  d-flex activebtn"
                : "quiz_tab  d-flex"
            }
          >
            <span>8</span>
            <div style={{ lineHeight: "16px", marginTop: "3px" }}>
              Registration
              <br /> form
            </div>
          </div>
          {/* {1 === parseInt(localStorage.getItem("activestep")) &&
          
            <div className="hosting_center">
              <p>Step 1 of 7</p>
              <p>Basic Details</p>
            </div>
          }
          {2 === parseInt(localStorage.getItem("activestep")) &&
             <div className="hosting_center">
              <p>Step 2 of 7</p>
               <p>Registration & Payments</p>
             </div>
          } */}
          {/* { 3 === parseInt(localStorage.getItem("activestep")) && 
          <div className="hosting_center">
              <p>Step 3 of 7</p>
            <p>Rounds</p>
          </div>
        } */}
          {/* {4 === parseInt(localStorage.getItem("activestep")) && 
             <div className="hosting_center">
              <p>Step 4 of 7</p>
               <p>About Opportunity</p>
             </div>
          } */}
          {/* {5 === parseInt(localStorage.getItem("activestep")) &&
        
           <div className="hosting_center">
              <p>Step 5 of 7</p>
             <p>Prizes</p>
           </div>
         } */}
          {/* {6 === parseInt(localStorage.getItem("activestep")) &&
             <div className="hosting_center">
                <p>Step 6 of 7</p>
               <p>Additional Details</p>
             </div>
          
          }
         {7 === parseInt(localStorage.getItem("activestep")) &&
            <div className="hosting_center">
               <p>Step 7 of 7</p>
              <p>Media / Gallery</p>
            </div>
         } */}
        </div>
      </div>
      <span className="hostingProgress">
        <ProgressBar
          now={
            parseInt(localStorage.getItem("activestep")) === 1
              ? 12.5
              : parseInt(localStorage.getItem("activestep")) === 2
                ? 25
                : parseInt(localStorage.getItem("activestep")) === 3
                  ? 37.5
                  : parseInt(localStorage.getItem("activestep")) === 4
                    ? 50
                    : parseInt(localStorage.getItem("activestep")) === 5
                      ? 62.5
                      : parseInt(localStorage.getItem("activestep")) === 6
                        ? 75
                        : parseInt(localStorage.getItem("activestep")) === 7
                          ? 87.5
                          : parseInt(localStorage.getItem("activestep")) ===
                              8 && 100
          }
          visuallyHidden
        />
      </span>
    </div>
  );
};

export default FormTab;
