import { Button, Form, Switch } from "antd";
import React, { useEffect, useState } from "react";
import CKEditor from "react-ckeditor-component";
import DynamicSubmissionForm from "./DynamicSubmissionForm";
import "./submissionSettings.css";
import SubmissionSettingsCaseStudy from "./SubmissionSettingsCaseStudy";

const AllowedFileTypes = [
  { label: "PDF Document", value: "application/pdf" },
  {
    label: "Presentation Files",
    value:
      "application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  {
    label: "Video Files",
    value:
      "video/mp4,video/webm,video/ogg,video/avi,video/mov,video/mkv,video/x-msvideo",
  },
  { label: "Audio Files", value: "audio/mp3,audio/wav" },
  { label: "Any", value: "any" },
];

const SubmissionSettings = (props) => {
  const [caseStudyForm] = Form.useForm();
  const [editorContent, setEditorContent] = useState(""); // State for CKEditor content
  const [uploading, setUploading] = useState(false);
  const [caseStudyData, setCaseStudyData] = useState([]);
  const [submissionFormSettings, setSubmissionFormSettings] = useState([]);

  useEffect(() => {
    if (props.submissionSettings) {
      caseStudyForm.setFieldsValue({
        showCaseStudy: props.submissionSettings.showCaseStudy,
      });
      setCaseStudyData(props.submissionSettings.caseStudyData);
      setEditorContent(props.submissionSettings.guidelines);
      setSubmissionFormSettings(props.submissionSettings.submissionFormSettings ?? []);
    } else {
      setEditorContent("");
      setCaseStudyData([]);
      setSubmissionFormSettings([]);
      caseStudyForm.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.submissionSettings]);

  const onFinish = async (values) => {
    try {
      setUploading(true);

      const formData = {
        ...values,
        guidelines: editorContent,
        caseStudyData,
        submissionFormSettings,
      };

      // Log the JSON output
      console.log("Form JSON Output:", JSON.stringify(formData, null, 2));
      props.onSubSettingSave(JSON.stringify(formData));
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setUploading(false);
    }
  };

  const handleCaseStudyDataChange = (csData) => {
    const updatedCaseStudyData = [...caseStudyData];
    updatedCaseStudyData[csData.index] = csData.data;
    setCaseStudyData(updatedCaseStudyData);
  };

  return (
    <Form
      name="caseStudyForm"
      form={caseStudyForm}
      onFinish={onFinish}
      layout="vertical"
    >
      {/* Case Study Upload Form */}
      {caseStudyData.length > 0 &&
        caseStudyData.map((cs, index) => {
          return (
            <React.Fragment key={index}>
              <SubmissionSettingsCaseStudy
                key={index}
                caseStudy={cs}
                index={index}
                onCaseStudyDataChange={handleCaseStudyDataChange}
                onDeleteCaseStudy={(index) => {
                  const updatedCaseStudyData = [...caseStudyData];
                  updatedCaseStudyData.splice(index, 1);
                  setCaseStudyData(updatedCaseStudyData);
                }}
              />
            </React.Fragment>
          );
        })}

      {caseStudyData.length === 0 && (
        <React.Fragment>
          <SubmissionSettingsCaseStudy
            caseStudy={null}
            index={0}
            onCaseStudyDataChange={handleCaseStudyDataChange}
          />
        </React.Fragment>
      )}

      {/* Switch Toggle */}
      <Form.Item
        className="show-case-study"
        label="Show the case study file for this Round"
        name="showCaseStudy"
        valuePropName="checked"
        initialValue={false}
      >
        <Switch />
      </Form.Item>

      {/* CKEditor for Guidelines */}
      <Form.Item label="Submission Guidelines" name="guidelines">
        <CKEditor
          activeClass="p10"
          content={editorContent}
          events={{
            change: (evt) => setEditorContent(evt.editor.getData()),
          }}
        />
      </Form.Item>

      <DynamicSubmissionForm
        allowedFileTypes={AllowedFileTypes}
        onSubFormSettingsChange={(newSettings) =>
          setSubmissionFormSettings(newSettings)
        }
        submissionFormSettings={submissionFormSettings}
      />

      {/* Add Case Study Button */}
      {/* <Form.Item>
        <Button
          type="primary"
          onClick={() => {
            setCaseStudyData([...caseStudyData, {}]);
          }}
        >
          Add Case Study
        </Button>
      </Form.Item> */}

      {/* Submit Button */}
      <Form.Item>
        <Button
          type="danger"
          size="large"
          htmlType="submit"
          loading={uploading}
        >
          <b>Submit</b>
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SubmissionSettings;
