import React, { useState, useEffect } from "react";
import "./Contactus.css";

function Contactus() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function submitMessage() {
    let contactItem = { name, email, message };
    console.log(contactItem);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-us">
      <div className="container mb-5">
        <div className="row">
          <div className="col text-center">
            <div className="contact-us-top">
              <h1>
                <span>Contact</span> Us
              </h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col sm-4">
            <div className="contact-us-left">
              <a href="#" className="location">
                <img
                  src={require("../../images/footer-location-icon.png").default}
                  alt="footer-location-icon"
                />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </a>
              <br />
              <a href="#" className="contact-number">
                <img
                  src={
                    require("../../images/footer-telephone-icon.png").default
                  }
                  alt="footer-telephone-icon"
                />
                <p>(91)123-456-5210</p>
              </a>
              <br />
              <a href="#" className="mail">
                <img
                  src={require("../../images/footer-email-icon.png").default}
                  alt="footer-email-icon"
                />
                <p>support@gradpartners.in</p>
              </a>
            </div>
          </div>

          <div className="col sm-8">
            <div className="contact-us-right">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                placeholder="Enter your name"
              />
              <br />
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Enter your email address"
              />
              <br />
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="form-control"
                placeholder="Enter your message"
              />
              <br />
              <button onClick={submitMessage} className="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
