import $ from "jquery";
import moment from "moment";
import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./OpportunitiesRightSideCard.css";

function OpportunitiesRightSideCard(props) {
  const getDaysLeft = (id, endDate, endTime) => {
    $(".timeshow" + id).text("Loading...");

    const endDateTime = moment(endDate);
    const endTimeMoment = moment(endTime, "HH:mm");

    endDateTime.set({
      hour: endTimeMoment.hour(),
      minute: endTimeMoment.minute(),
      second: endTimeMoment.second(),
    });

    const timeDifference = endDateTime.diff(moment());
    const duration = moment.duration(timeDifference);

    // When no time left
    if (
      Math.floor(duration.asDays()) <= 0 &&
      duration.hours() <= 0 &&
      duration.minutes() <= 0 &&
      duration.seconds() <= 0
    ) {
      $(".timeshow" + id).text("Closed");
    }

    // When more than 24 hours left
    if (Math.floor(duration.asDays()) >= 1) {
      const daysLeft = Math.floor(duration.asDays());
      $(".timeshow" + id).text(
        `${daysLeft} day${daysLeft > 1 ? "s" : ""} left`
      );
    }

    // When less than 24 hours left, Update the timer every second
    if (Math.floor(duration.asDays()) === 0 && duration.seconds() > 0) {
      setInterval(function () {
        // Recalculate the time difference on each interval
        const timeDifference = endDateTime.diff(moment());

        // Convert the time difference into a human-readable format
        const duration = moment.duration(timeDifference);

        // Display the time left
        const hours = duration.hours().toString().padStart(2, "0");
        const minutes = duration.minutes().toString().padStart(2, "0");
        const seconds = duration.seconds().toString().padStart(2, "0");

        $(".timeshow" + id).text(`${hours}:${minutes}:${seconds} Left`);
      }, 1000); // Update every second
    }
  };

  return (
    <div className="opportunities-rightside-cards">
      <Link to={`/e/${props.item.slug}`}>
        <Card
          className={
            props.status === 1
              ? "workshop-card-dash"
              : "workshop-card detailevent"
          }
        >
          {props.item.banner_image ? (
            <Card.Img
              variant="top"
              className="listingimg"
              src={props.item.banner_image}
            />
          ) : (
            <div className="no-banner">Banner Not Found</div>
          )}
          <div className="opportunity-box">
            <div className="workshop-date">
              {moment(props.item.start_date).format("Do MMM'YY")} -{" "}
              {moment(props.item.end_date).format("Do MMM'YY")}
            </div>
            <div className="workshop-title">
              <p>{props.item.title}</p>
              <span>Hosted By: {props.item.organization}</span>
              <br />
              <span>Impressions : {props.item.views}</span>
              <br />
              {!props.item.external_registration && (
                <span>Registrations : {props.item.register_count}</span>
              )}
            </div>

            <div className="cardBottom">
              <Link to={`/e/${props.item.slug}`}>
                <Button variant="primary" className="opportunities-card-btn">
                  Explore <i className="fa fa-arrow-right"></i>
                </Button>
              </Link>
              <span className={`days-left timeshow${props.item.id}`}>
                {getDaysLeft(
                  props.item.id,
                  props.item.register_end_date,
                  props.item.register_end_time
                )}
              </span>
            </div>
          </div>
        </Card>
      </Link>
    </div>
  );
}

export default OpportunitiesRightSideCard;
