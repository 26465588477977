import moment from "moment";
import React from "react";
import Card from "react-bootstrap/Card";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "./Opportunitiescard.css";

function Workshopcard(props) {
  return (
    <div className="opportunities-card row">
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        navigation={{
          nextEl: ".swiper-btn-next",
          prevEl: ".swiper-btn-prev",
        }}
        loop={true}
        pagination={{
          el: ".swiper-paginations",
          clickable: true,
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
        className="workshops pb-5"
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
        }}
      >
        {props.allworkshops &&
          props.allworkshops.map((oppr, index) => {
            const daysLeft = moment(oppr.register_end_date).diff(
              moment(new Date()),
              "days"
            );

            return (
              <div className="col-md-3" key={index}>
                <SwiperSlide>
                  <Card>
                    <Link to={`/e/${oppr.slug}`}>
                      {oppr.banner_image ? (
                        <Card.Img variant="top" src={oppr.banner_image} />
                      ) : (
                        <div className="no-banner">Banner Not Found</div>
                      )}
                    </Link>

                    <Card.Body>
                      <Link to={`/e/${oppr.slug}`}>
                        <p className="date-show">
                          <Moment format="DD" date={oppr.start_date} />-
                          <Moment
                            format="Do MMMM YYYY"
                            date={oppr.start_date}
                          />
                        </p>
                        <Card.Title>{oppr.title}</Card.Title>
                        <Card.Text>Hosted By: {oppr.organization}</Card.Text>
                      </Link>

                      <div className="cardBottom">
                        <Link to={`/e/${oppr.slug}`}>
                          <p
                            variant="primary"
                            className="opportunities-card-btn explore"
                          >
                            Explore <i className="fa fa-arrow-right"></i>
                          </p>
                        </Link>
                        <span className="days-left">
                          {daysLeft < 0
                            ? "Closed"
                            : daysLeft === 0
                              ? "Closing Today"
                              : `${daysLeft} day${daysLeft > 1 ? "s" : ""} left`}
                        </span>
                      </div>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              </div>
            );
          })}
        <div className="oppcardswipe swiper-buttons text-center">
          <div className="swiper-btn-prev">
            <FaArrowLeft />
          </div>
          <div className="swiper-paginations"></div>
          <div className="swiper-btn-next">
            <FaArrowRight />
          </div>
        </div>
      </Swiper>
    </div>
  );
}

export default Workshopcard;
