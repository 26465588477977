import axios from "axios";
import config from '../components/CommonFiles/config.json'
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const apiEndpoint = `${mainURL}/v1/s3/create-signed-url`;

/**
 * Function to get signed URLs from the API
 * @param {Array} files - Array of file objects with fileName and contentType
 * @returns {Array} - Array of objects containing fileName and signedUploadURL
 */
export const getSignedUrls = async (files) => {
  try {
    const response = await axios.post(apiEndpoint, { files });
    if (response.data.status === 1) {
      return response.data.data; // Return the array of {fileName, signedUploadURL}
    } else {
      throw new Error(response.data.message || "Failed to get signed URLs");
    }
  } catch (error) {
    console.error("Error getting signed URLs:", error);
    throw error;
  }
};

/**
 * Function to upload files to S3 using signed URLs
 * @param {Array} files - Array of File objects from input
 * @param {Array} signedUrls - Array of signed URL objects with fileName and signedUploadURL
 */
export const uploadFilesToS3 = async (files, signedUrls) => {
  try {
    const uploadPromises = files.map((file) => {
      const signedUrlObject = signedUrls.find(
        (urlObj) => urlObj.fileName === file.name
      );
      if (!signedUrlObject) {
        throw new Error(`Signed URL not found for file: ${file.name}`);
      }

      return axios.put(signedUrlObject.signedUploadURL, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
    });

    // Wait for all files to be uploaded
    await Promise.all(uploadPromises);
    console.log("All files uploaded successfully");
  } catch (error) {
    console.error("Error uploading files to S3:", error);
    throw error;
  }
};

/**
 * Uploads a file to the given signed URL with progress tracking.
 * @param {string} signedUrl - The pre-signed URL to upload the file to S3.
 * @param {File} file - The file object to be uploaded.
 * @param {function} onProgress - Callback function to track the progress. Receives the percentage of upload completed.
 * @returns {Promise<void>} - A promise that resolves when the upload is complete.
 */
export const uploadFileWithProgress = async (signedUrl, file, onProgress) => {
    try {
      const config = {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (onProgress) {
            onProgress(percentCompleted);
          }
        },
      };
  
      await axios.put(signedUrl, file, config);
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };
