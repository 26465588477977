import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import config from "../../CommonFiles/config.json";
import { CountryData } from "../../CommonFiles/CountryData";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "./Header.css";
import { NavLink, Link, useLocation } from "react-router-dom";
import logo from "../../../images/main_logo.png";
import { FaSearch, FaUserAlt } from "react-icons/fa";
import {
  Button as Antbtn,
  Form as AntForm,
  Input,
  Radio,
} from "antd";
import { sendOTPForEmailVerification } from "../../../services/emailVerificationService";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const STDCodes = CountryData.map((country) => {
  return {
    value: `+${country.CountryCode}`,
    label: `+${country.CountryCode} (${country.Country})`,
  };
});

const Header = (props) => {
  // localStorage.setItem('activestep' , 1)
  let [popupstatus, setPopupstatus] = useState(false);
  let [signupmodal, setSignupmodal] = useState(false);
  let [organizationModal, setOrganizationModal] = useState(false);

  let [forgotPassword, setForgotPassword] = useState(false);
  const [collagename, setCollageshow] = useState("");
  const [loginError, setLoginError] = useState("");
  const [verifymailerror, setVerifyError] = useState("");
  const [verifymailSuccess, setVerifySucess] = useState("");
  const [matched, setAllmatched] = useState();
  const [collages, setAllcollages] = useState();
  const [verifyemail, setVerifyemail] = useState("");
  let [filterstatus, setFilterstatus] = useState(0);
  const [showeye, setShoweye] = useState();

  const [organisationtext, setOrganisationtext] = useState();
  const [error, setError] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errormsgsignup, setErrormsgsignup] = useState("");
  const [selectedSTDCode, setSelectedSTDCode] = useState("+91");
  const [signUpPhnNum, setSignUpPhnNum] = useState("");
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  const splitLocation = pathname.split("/");

  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    confrmpassword: "",
    organization: "",
    gender: "",
  });
  const userdata = JSON.parse(localStorage.getItem("alldata"));

  useEffect(() => {
    setPopupstatus(props.openlogin);
  }, [localStorage.getItem("loginpopup"), props.openlogin]);

  // Show login popup after 30 seconds
  useEffect(() => {
    if (!userdata && !popupstatus) {
      const timeoutId = setTimeout(() => {
        setPopupstatus(true);
      }, 30000);
      // Store the timeout ID in a class variable
      Header.timeoutId = timeoutId;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (userdata) {
      // check of email is verified
      if (!userdata.is_email_verified) {
        window.location.replace(window.location.origin + "/email-verification");
      }
    }

    return () => {
      // Clear the timeout when the component unmounts
      clearTimeout(Header?.timeoutId);
    }
  }, []);

  useEffect(async () => {
    setPopupstatus(props.openlogin);
    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/allcollages",
      });
      setAllcollages(result.data.data);
      props.onCollegeDataLoad && props.onCollegeDataLoad(result.data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);


  function gotomanageform(params) {
    console.log("window.location.pathname", window.location.pathname);
    const tokencheck = localStorage.getItem("token");
    if (tokencheck === null) {
      // props.history.push(`/login`);
      setPopupstatus(true);
    } else {
      const firsttab = {
        category: "",
        title: "",
        website: "",
        organization: "",
        festival: "",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        location: "",
        region: "",
      };
      localStorage.removeItem("FormFive");
      localStorage.removeItem("FormSix");
      localStorage.removeItem("FormFour");
      localStorage.setItem("FormOne", JSON.stringify(firsttab));
      localStorage.removeItem("FormTwo");
      localStorage.removeItem("FormThree");
      localStorage.removeItem("FormSeven");
      localStorage.removeItem("opportunityfrom");
      localStorage.setItem("activestep", 1);
      localStorage.removeItem("editopportunityid");
      if (window.location.pathname === "/manage/opportunity") {
        window.location.reload();
      } else {
        props.history.push(`/manage/opportunity`);
      }
    }
  }

  function getorganization(val, name) {
    setInputValue({ ...inputValue, organization: val });
    setAllmatched([]);
    setCollageshow(val);

    setFilterstatus(0);
  }

  const loginPopup = () => {
    setPopupstatus(true);
    setSignupmodal(false);
    setForgotPassword(false);
    setLoginError("");
  };

  const closeModal = () => {
    setPopupstatus(false);
    if (props.loginPopup === "") {
      props.registernowclic(false);
    }
    localStorage.setItem("loginpopup", false);
    props.closeLoginModal && props.closeLoginModal();
  };

  const closeSignupModal = () => {
    setSignupmodal(false);
    if (props.loginPopup === "") {
      props.registernowclic(false);
    }
    localStorage.setItem("loginpopup", false);
  };

  const signupPupup = () => {
    setPopupstatus(false);
    setSignupmodal(true);
    clearTimeout(Header.timeoutId);
  };

  function selectorganisation(e, index) {
    setOrganisationtext(e);
    if (e === "") {
      setAllmatched([]);
      setFilterstatus(0);
    } else {
      const matches = collages.filter((s) =>
        s.label.toLowerCase().includes(e.toLowerCase())
      );
      setAllmatched(matches);
      setFilterstatus(1);
    }
  }
  // login function
  function login() {
    if (email == "" && password == "") {
      setLoginError("Email and Password both are required");
    } else {
      axios({
        method: "post",
        url: mainURL + "/login",
        data: {
          password: password,
          email: email,
        },
      }).then((response) => {
        if (response.data.status === 0) {
          setLoginError(response.data.reason);
        } else {
          localStorage.setItem("token", response.data.accessToken);
          localStorage.setItem("alldata", JSON.stringify(response.data.user));

          sessionStorage.setItem("userId", response.data.user.id);
          sessionStorage.setItem("email", response.data.user.email);

          if (!response.data.user.is_email_verified) {
            sendOTPForEmailVerification({ userId: response.data.user.id, email: response.data.user.email }).then((response) => {
              if (response.status) {
                window.location.replace(window.location.origin + "/email-verification");
              }
            })
            return;
          }

          setPopupstatus(false);
          setSignupmodal(false);
          if (window.location.href.includes("password-reset")) {
            window.location.replace(window.location.origin);
          } else {
            window.location.reload();
          }
        }
      })
        .catch((err) => {
          setLoginError("Incorrect Email Id");
        });
    }
  }

  const forgotpassModal = () => {
    setPopupstatus(false);
    setSignupmodal(false);
    setForgotPassword(true);
    setLoginError("");
    setVerifyError("");
    setVerifySucess("");
  };

  const closeforgotpassModal = () => {
    setForgotPassword(false);
    setPopupstatus(false);
    setSignupmodal(false);
    loginError("");
    if (props.loginPopup === "") {
      props.registernowclic(false);
    }
  };

  // forgot password link
  function sendMail(value) {
    axios({
      method: "post",
      url: mainURL + "/forgot-password",
      data: {
        email: value.email,
      },
    })
      .then(() => {
        setVerifySucess("Password reset link has been mailed");
      })
      .catch(function (response) {
        console.log("Error while calling api", response);
      });
  }

  const onFinish = (values) => {
    values.mobile = selectedSTDCode + values.mobile;
    axios({
      method: "post",
      url: mainURL + "/register",
      data: values,
    })
      .then((response) => {
        const alldata = response.data.data;
        localStorage.setItem("token", response.data.accessToken);
        if (response.data.status === 0) {
          setErrormsgsignup(response.data.message);
        } else {
          setSignupmodal(false);
          localStorage.setItem("token", response.data.accessToken);
          localStorage.setItem("alldata", JSON.stringify(response.data.user));

          sessionStorage.setItem("userId", response.data.user.id);
          sessionStorage.setItem("email", response.data.user.email);

          if (!response.data.user.is_email_verified) {
            sendOTPForEmailVerification({ userId: response.data.user.id, email: response.data.user.email }).then((response) => {
              if (response.status) {
                window.location.replace(window.location.origin + "/email-verification");
              }
            })
            return;
          }
        }
        props.history.push(`/`);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  var passwordField = document.querySelector(".password-field");
  const showPassword = () => {
    if (password == "") {
      return false;
    }
    if (passwordField.type === "password") {
      passwordField.type = "text";
      // showPw.classList.add("fas fa-eye")
      setShoweye(1);
    } else {
      passwordField.type = "password";
      setShoweye(0);
      // showPw.classList.add("fas fa-eye-slash")
    }
  };

  const createSchoot = () => {
    console.log("collagename", collagename);

    if (collagename === "") {
      setLoginError(`Please Enter Organisation Name`);
      return;
    }
    setCollageshow(collagename);
    setOrganisationtext(collagename);
    axios({
      method: "post",
      url: mainURL + "/collages",
      data: {
        name: collagename,
      },
    })
      .then(() => {
        setFilterstatus(0);
        setOrganizationModal(false);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  function createorg() {
    setOrganizationModal(true);
  }

  const closeCollagemodal = () => {
    setCollageshow("");
    setOrganisationtext("");
    setOrganizationModal(false);
  };
  const modalSchooltext = (e) => {
    setCollageshow(e.target.value);
    setOrganisationtext(e.target.value);
  };

  const handleSignUpPhnNumberChange = (e) => {
    if (!isNaN(e.target.value)) {
      setSignUpPhnNum(e.target.value);
    }
  };

  return (
    <div className="header">
      <div className="progress">
        <div
          className="firstcolor"
          role="progressbar"
          aria-valuenow="15"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <div
          className="  secondcolor"
          role="progressbar"
          aria-valuenow="30"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <div
          className=" thirdcolor"
          role="progressbar"
          aria-valuenow="20"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>

      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/">
            <a href="#">
              <img src={logo} alt="logo" className="logo" />
            </a>
          </NavLink>
          {userdata != null ? (
            <NavLink to="/user/setting">
              <div className="header__sortname formobileicon">
                {userdata.profileimage ? (
                  <img
                    id="file-image"
                    src={userdata.profileimage}
                    alt="Preview"
                  />
                ) : userdata.first_name ? (
                  <h3 className="usernamelatter">
                    {" "}
                    {userdata.first_name && userdata.first_name.charAt(0)}{" "}
                    {userdata.last_name && userdata.last_name.charAt(0)}{" "}
                  </h3>
                ) : (
                  <h3 className="emaillatter">
                    {" "}
                    {userdata.email && userdata.email.charAt(0)}{" "}
                  </h3>
                )}
              </div>
            </NavLink>
          ) : (
            <div
              className="centercls usericon header__sortname formobileicon"
              onClick={() => loginPopup()}
            >
              <h3>
                <FaUserAlt className="usericoncls" />
              </h3>
            </div>
          )}
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto menu-right">
              <li
                className={
                  splitLocation[1] === "" ? "nav-item active" : "nav-item"
                }
              >
                <Link className="nav-link" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <div className="dropdown">
                  <span
                    className={
                      splitLocation[1] === "opportunities"
                        ? "nav-link active"
                        : "nav-link"
                    }
                  >
                    Events
                  </span>
                  <div className="dropdown-content">
                    <Link
                      className="home nav-link"
                      activeClassName="menu-active"
                      to="/opportunities?type=opportunity"
                    >
                      Opportunities
                    </Link>

                    <Link
                      className="home nav-link"
                      activeClassName="menu-active"
                      to="/opportunities?type=workshop"
                    >
                      Workshops
                    </Link>
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <a
                  className="career nav-link"
                  href="https://forms.gle/mzPTvza1Ux9cy7vT7"
                  target="_blank"
                >
                  Careers
                </a>
              </li>
              <li
                className={
                  splitLocation[1] === "about" ? "nav-item active" : "nav-item"
                }
              >
                <Link
                  className="about-us nav-link"
                  activeClassName="menu-active"
                  to="/about"
                >
                  About Us
                </Link>
              </li>

              <li className="nav-item">
                <button
                  className="btn menubtn hostingbtnformobile"
                  onClick={() => gotomanageform(1)}
                >
                  Host Opportunities
                </button>
              </li>
            </ul>

            <div className="form-inline my-2 my-lg-0 nav-right-social">
              <NavLink to="/opportunities?type=opportunity">
                <input
                  type="text"
                  placeholder="Search Events"
                  className="topsearch"
                />
                <FaSearch className="topimg iconcls" />
              </NavLink>

              {userdata != null ? (
                <div className="header__sortname fordesktopicon">
                  <ul className="navbar-nav mr-auto menu-right">
                    <li className="nav-item">
                      <div className="dropdown">
                        <span className="">
                          <span className="profilename">
                            <div className="header__sortname">
                              {userdata.profileimage ? (
                                <img
                                  id="file-image"
                                  src={userdata.profileimage}
                                  alt="Preview"
                                />
                              ) : userdata.first_name ? (
                                <h3 className="usernamelatter">
                                  {" "}
                                  {userdata.first_name &&
                                    userdata.first_name.charAt(0)}{" "}
                                  {userdata.last_name &&
                                    userdata.last_name.charAt(0)}{" "}
                                </h3>
                              ) : (
                                <h3 className="emaillatter">
                                  {" "}
                                  {userdata.email &&
                                    userdata.email.charAt(0)}{" "}
                                </h3>
                              )}
                            </div>
                          </span>
                        </span>
                        <div className="dropdown-content">
                          <NavLink
                            className="home nav-link"
                            activeClassName="menu-active"
                            to="/user/setting"
                          >
                            My Profile
                          </NavLink>
                          <NavLink
                            className="home nav-link"
                            activeClassName="menu-active"
                            to="/user"
                          >
                            My Registrations
                          </NavLink>

                          <NavLink
                            className="home nav-link"
                            activeClassName="menu-active"
                            to="/user/hosting/"
                          >
                            My Hostings
                          </NavLink>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              ) : (

                <div
                  className="centercls usericon header__sortname fordesktopicon"
                  onClick={() => loginPopup()}
                >
                  <h3>
                    <FaUserAlt className="usericoncls" />
                  </h3>
                </div>

              )}

              <button
                className="btn menubtn hostingbtnfordesktop"
                onClick={() => gotomanageform(1)}
              >
                Host Opportunities
              </button>

            </div>
          </div>

          <Modal
            show={popupstatus}
            className="loginpopup"
            // onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <div>
              <span
                aria-hidden="true"
                className="popupclose"
                onClick={() => closeModal()}
              >
                &times;
              </span>
              <h1 className="mb-0">Welcome back</h1>
              <p>
                New to Grad Partners?{" "}
                <span onClick={() => signupPupup()}>Sign up</span>
              </p>
              <hr className="customhr" />
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="you@email.com"
                />
              </div>
              <div>
                <label>Password</label>
                <input
                  type="password"
                  className="form-control password-field"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="************"
                />
                {showeye === 1 ? (
                  <i className="fas fa-eye show-pw" onClick={showPassword}></i>
                ) : (
                  <i
                    className="fas fa-eye-slash show-pw"
                    onClick={showPassword}
                  ></i>
                )}
              </div>
              {loginError && (
                <div className="text-center mt-3 errormsg">{loginError}</div>
              )}
              <button className="btn loginbtn" onClick={login}>
                {" "}
                Login
              </button>
              <div className="remindmeandforgotpass">
                <span>
                  <input type="checkbox" />{" "}
                  <span className="rememberme">Remember Me</span>
                </span>
                <span className="forgot_pass" onClick={() => forgotpassModal()}>
                  Trouble logging in?
                </span>
              </div>
            </div>
          </Modal>
          <Modal
            show={signupmodal}
            className="signuppopup"
            backdrop="static"
            keyboard={false}
          >
            <div>
              <span
                aria-hidden="true"
                className="popupclose"
                onClick={() => closeSignupModal()}
              >
                &times;
              </span>
              <h1 className="mb-0">Create Account </h1>
              <p>
                Already have an account?{" "}
                <span onClick={() => loginPopup()}>Login</span>
              </p>
              <hr className="customhr" />
              <AntForm
                name="basic"
                className="form-group-col col-md-12"
                onFinish={onFinish}
                layout="vertical"
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="formGridOpportunityTitle"
                  >
                    <AntForm.Item
                      label="First Name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First name",
                        },
                      ]}
                    >
                      <Input className="form-control register-body-form-edit" />
                    </AntForm.Item>
                  </Col>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="formGridOpportunityTitle"
                  >
                    <AntForm.Item
                      label="Last Name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name",
                        },
                      ]}
                    >
                      <Input className="form-control register-body-form-edit" />
                    </AntForm.Item>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="formGridOpportunityTitle"
                  >
                    <AntForm.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please enter your E-mail!",
                        },
                      ]}
                    >
                      <Input className="form-control" />
                    </AntForm.Item>
                  </Col>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="formGridOpportunityTitle"
                  >
                    <AntForm.Item
                      label="Phone Number"
                      name="mobile"
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone number",
                        },
                        {
                          len: 10,
                          message: "Please enter valid phone number",
                        },
                      ]}
                    >
                      <div className="phone-number-block">
                        <select
                          className="form-control select-code"
                          name="mobile-code"
                          onChange={(e) => {
                            setSelectedSTDCode(e.target.value);
                          }}
                          value={selectedSTDCode}
                        >
                          {STDCodes.map((option, index) => {
                            return (
                              <option value={option.value} key={index}>
                                {option.label}
                              </option>
                            );
                          })}
                        </select>
                        <Input
                          name="mobile"
                          className="form-control phone-number"
                          maxLength={10}
                          onChange={handleSignUpPhnNumberChange}
                          value={signUpPhnNum}
                        />
                      </div>
                    </AntForm.Item>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="formGridOpportunityTitle"
                  >
                    <AntForm.Item
                      label="Password"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                        // {
                        //   pattern: /^[\d]{8,20}$/,
                        //   message: "Value should be less than 8 character",
                        // },
                      ]}
                    >
                      <Input.Password className="form-control " />
                    </AntForm.Item>
                  </Col>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="formGridOpportunityTitle"
                  >
                    <AntForm.Item
                      label="Confirm Password"
                      name="confirmpass"
                      rules={[
                        {
                          required: true,
                          message: "Please Confirm password!",
                        },
                        // {
                        //   pattern: /^[\d]{8,20}$/,
                        //   message: "Value should be less than 8 character",
                        // },
                      ]}
                    >
                      <Input.Password className="form-control" />
                    </AntForm.Item>
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="formGridOpportunityTitle"
                  >
                    <label>Institution/Organization Name</label>
                    {collagename === "" ? (
                      <input
                        type="text"
                        name="organization"
                        key={collagename}
                        defaultValue=""
                        onChange={(e) =>
                          selectorganisation(e.target.value, e.target.name)
                        }
                        className={
                          error.mobile
                            ? "form-control errormsgborder register-body-form"
                            : "form-control register-body-form"
                        }
                        placeholder="Institution / Organization (Enter Full Name)"
                      />
                    ) : (
                      <input
                        type="text"
                        name="organization"
                        key={collagename}
                        defaultValue={collagename != "" ? collagename : ""}
                        onChange={(e) =>
                          selectorganisation(e.target.value, e.target.name)
                        }
                        className={
                          error.mobile
                            ? "form-control errormsgborder register-body-form"
                            : "form-control register-body-form"
                        }
                        placeholder="Institution / Organization (Enter Full Name)"
                      />
                    )}

                    <div>
                      {filterstatus === 1 && organisationtext && (
                        <ul
                          className={
                            Object.keys(matched).length < 4
                              ? "autoheight"
                              : "uldatacollage"
                          }
                        >
                          {matched.map((item) => (
                            <li
                              onClick={() =>
                                getorganization(item.label, "organization")
                              }
                            >
                              {item.label}
                            </li>
                          ))}
                          {organisationtext && (
                            <li>
                              {organisationtext} ..{" "}
                              <span
                                className="createcolor"
                                onClick={() => createorg()}
                              >
                                Add Institute
                              </span>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                  </Col>

                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="formGridOpportunityTitle"
                  >
                    <AntForm.Item
                      name="gender"
                      label="Gender"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Gender",
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value="male">Male</Radio>
                        <Radio value="female">Female</Radio>
                        <Radio value="others">Others</Radio>
                      </Radio.Group>
                    </AntForm.Item>
                  </Col>
                </Form.Row>
                {errormsgsignup && (
                  <div className="text-center errormsg">{errormsgsignup}</div>
                )}
                <Antbtn
                  type="primary"
                  htmlType="submit"
                  className="btn signupbtn"
                >
                  Create Account
                </Antbtn>
              </AntForm>

              {/* <button className="btn loginbtn"> Create Account</button> */}
              <div className="text-center  mt-3" style={{ color: "#fff" }}>
                By signing up, you agree to our Privacy Policy and Terms of
                Service.
              </div>
            </div>
          </Modal>
          {/* organisation modal */}
          <Modal
            show={organizationModal}
            className="loginpopup createorg"
            // onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <div>
              <span
                aria-hidden="true"
                className="popupclose"
                onClick={() => closeCollagemodal()}
              >
                &times;
              </span>
              <h3
                className="mb-0 registrationmodalcss"
                style={{ color: "#fff !important" }}
              >
                Institution / Organization (Enter Full Name)
              </h3>

              <hr className="customhr" />
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={organisationtext}
                  // onChange={(e) => setEmail(e.target.value)}
                  onChange={(e) => {
                    modalSchooltext(e);
                  }}
                  placeholder="you@email.com"
                />
              </div>

              {loginError && (
                <div className="text-center mt-3 errormsg">{loginError}</div>
              )}
              <span className="btn loginbtn" onClick={() => createSchoot()}>
                {" "}
                Submit
              </span>
            </div>
          </Modal>
          {/* forgot pass word modal */}
          <Modal
            show={forgotPassword}
            className="loginpopup"
            // onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <div>
              <span
                aria-hidden="true"
                className="popupclose"
                onClick={() => closeforgotpassModal()}
              >
                &times;
              </span>
              <h1 className="mb-0">Forgot password?</h1>
              <p>
                New to Grad Partners?{" "}
                <span onClick={() => signupPupup()}>Sign up</span>
              </p>
              <hr className="customhr" />
              {verifymailSuccess ? (
                <div className="text-center mt-3 " style={{ color: "#fff" }}>
                  {verifymailSuccess}
                </div>
              ) : (
                <div className="mb-3">
                  <AntForm
                    name="register"
                    onFinish={sendMail}
                    className="form-group-col col-md-12"
                    layout="vertical"
                  >
                    <AntForm.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please enter your E-mail!",
                        },
                      ]}
                    >
                      <Input className="form-control" />
                    </AntForm.Item>
                    <AntForm.Item>
                      <Antbtn
                        type="primary"
                        htmlType="submit"
                        className="antdformbtn"
                      >
                        Send Verification Email
                      </Antbtn>
                    </AntForm.Item>
                  </AntForm>
                </div>
              )}

              <div className="backtologin">
                <span
                  className="text-center cursorPointer"
                  onClick={() => loginPopup()}
                >
                  Back to log in
                </span>
              </div>
            </div>
          </Modal>
        </div>
      </nav>
    </div>
  );
};

export default withRouter(Header);
