import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Core from "./Core";
import { defaultLocale } from "./Locale";
import "./styles.css";
import Footer from "../../CommonFiles/Footer";
import config from "../../CommonFiles/config.json";
import Header from "../../CommonFiles/Header";
import Swal from "sweetalert2";
import axios from "axios";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from "moment";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const USER_TOKEN = localStorage.getItem("token");
const AuthStr = "Bearer ".concat(USER_TOKEN);
const QuizDetail = (props) => {
  const handle = useFullScreenHandle();
  console.log("id pass", props);
  const [basicDetail, setBasicdetail] = useState([]);
  const [oppDetail, setOpportunityDetail] = useState(
    JSON.parse(localStorage.getItem("opportunitydata"))
  );
  const [roundDetail, setRoundDetail] = useState([]);
  useEffect(async () => {
    // if (localStorage.getItem("finish-quiz")) {
    //   window.location = `/e/HCCB-GET-Electrical-45`;
    // }
    window.scrollTo(0, 0);
    // window.open("www.google.com", "window" + id, 'toolbar=0,location=0,scrollbars=1,statusbar=1,menubar=0,resizable=1,width=' + width + ',height=800,left=100,top=50');

    try {
      axios({
        method: "post",
        url: mainURL + "/opportunity/round",
        data: { round_id: localStorage.getItem("selectedRound") },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldata = response.data.data;
          setRoundDetail(alldata);
          if (alldata.quiz_setting) {
            // setOpportunityDetail(alldata)

            let quiz_basic = JSON.parse(alldata.quiz_setting);
            setBasicdetail(quiz_basic);

            if (quiz_basic[0].full_screen === "yes") {
              toggleFullScreen();
            }
          }
          console.log("Error alldata.quiz_basic", alldata.quiz_setting);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });

      //   const result = await axios({
      //     method: "post",
      //     url: mainURL + "/opportunity/round",
      //   });

      //   console.log("result", result.data.data);

      //   if (result.data.data.quiz_basic) {
      //     setOpportunityDetail(result.data.data)
      // toggleFullScreen()

      //     let quiz_basic = JSON.parse(result.data.data.quiz_basic);
      //     setBasicdetail(quiz_basic);
      //   console.log("result basi", quiz_basic);

      //   }
    } catch (error) {
      console.log(error);
    }
  }, []);

  function gotoquiz() {
    Swal.fire({
      title: "Do you want to start the quiz?",
      // text: "You Want Start the quiz !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        props.history.push(`/e/Quiz-Testing-102/round-1/quiz`);
        // props.history.push(props.location.pathname+'/quiz', { id: props.history.location.state.id })
      }
    });
  }
  function toggleFullScreen() {
    if (!document.fullscreenElement) {
      document?.documentElement?.requestFullscreen();
    } else if (document.exitFullscreen) {
      // document.exitFullscreen();
    }
  }
  window.addEventListener("keydown", (e) => {
    console.log("key press", e.key);
  });

  return (
    <>
      {/* <Header /> */}
      <section className="quiz-banner-section1 cstm-slick-slider">
        <div className="pr-4 mt-3 pt-1 pl-4">
          <div className="col-md-12">
            <div className="row react-quiz-container">
              <div className="col-md-4 quizleftdetail ">
                <div className="sectionone">
                  <div className="row">
                    <div className="col-md-4">
                      <span>
                        <img src={oppDetail?.logo_image} />
                      </span>
                    </div>
                    <div className="col-md-8">
                      <span>
                        <h3>{oppDetail?.title}</h3>
                        {oppDetail?.organization}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="sectiontwo">
                  <h5>Online Quiz</h5>
                  <p>
                    <strong>Start Date:</strong>{" "}
                    {`${moment(roundDetail?.start_date).format("Do MMM YYYY")}, ${roundDetail?.start_time} hrs`}
                  </p>
                  <p>
                    <strong>End Date:</strong>{" "}
                    {`${moment(roundDetail?.end_date).format("Do MMM YYYY")}, ${roundDetail?.end_time} hrs`}
                  </p>
                  <p>
                    <strong>Quiz Duration:</strong> {basicDetail[0]?.duration}{" "}
                    Minutes
                  </p>
                  <p>
                    <strong>Total Questions:</strong>{" "}
                    {basicDetail[0]?.showquestion}
                  </p>
                </div>
              </div>
              <div className="col-md-8 p-0">
                <div className="quizrightdetail">
                  <h5>{basicDetail[0]?.title}</h5>
                  <div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: basicDetail[0]?.description,
                      }}
                    />
                  </div>
                  <button className="strtquiz" onClick={() => gotoquiz()}>
                    Start Quiz
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
};

export default QuizDetail;
