import React, { useState, useEffect } from "react";
import {
  OpportunitiesRightSide,
  filterShowClass,
} from "../CommonFiles/OpportunitiesRightSide";
import OpportunitiesLeftSide from "../CommonFiles/OpportunitiesLeftSide";
import axios from "axios";
import "./Opportunities.css";
import config from "../CommonFiles/config.json";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

function Opportunities(props) {
  const query = new URLSearchParams(props.location.search);

  const [opportunities, setOpportunities] = useState(null);
  const [tempData, setTempData] = useState(null);
  const [tabname, setTabbname] = useState(query.get("type"));

  const maintitile = query.get("type");

  useEffect(() => {
    window.scrollTo({
      top: 5,
      behavior: "smooth",
    });
    var element = document.getElementById("footershow");
    element.classList.add("footershow");
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios({
      method: "get",
      url: mainURL + "/opportunity?type=" + maintitile,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("Error while calling api", response);
        setOpportunities(response.data.data.rows);
        setTempData(response.data.data.rows);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
    setTabbname(maintitile);
  }, [maintitile]);

  const filterdata = (e) => {
    let value = e.target.value;

    if (value === "all") {
      setOpportunities(tempData);
    } else {
      let allopp = tempData;
      const filtered = allopp.filter(
        (item) => item.is_opportunity_paid === parseInt(value),
      );
      setOpportunities(filtered);
      console.log("filterdat", e.target.value, filtered, allopp);
    }
  };

  const filterbyeligible = (e) => {
    let value = e.target.value;
    let allopp = tempData;
    if (value === "all") {
      setOpportunities(tempData);
    } else {
      const filtered = allopp.filter((item) =>
        item.opportunity_open_for.toLowerCase().includes(value.toLowerCase()),
      );
      setOpportunities(filtered);
    }
  };

  const filterbydate = (e) => {
    const today = new Date().toISOString().slice(0, 10);
    let value = e.target.value;
    let allopp = tempData;
    if (value === "all") {
      setOpportunities(tempData);
    } else {
      if (value === "expired") {
        const filteredData = allopp.filter((item) => item.end_date < today);
        setOpportunities(filteredData);
      }
      if (value === "live") {
        const filteredData = allopp.filter((item) => item.end_date > today);
        setOpportunities(filteredData);
      }
    }
  };

  const searchData = (e) => {
    let value = e.target.value;

    if (value === "") {
      setOpportunities(tempData);
    } else {
      let allopp = opportunities;
      const filtered = allopp.filter((item) =>
        item.title.toLowerCase().includes(value.toLowerCase()),
      );
      setOpportunities(filtered);
      console.log(filtered, "filtered", value);
    }
  };

  const tabChange = (tabname) => {
    setTabbname(tabname);
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    let config = {
      header: {
        "Content-Type": "multipart/form-data",
      },
    };
    axios({
      method: "get",
      url: mainURL + "/opportunity?type=" + tabname,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("Error while calling api", response);
        setOpportunities(response.data.data.rows);
        setTempData(response.data.data.rows);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };
  return (
    <React.Fragment>
      <Header />
      <div className="work work-head">
        {/* <div className="work-head">
          <div className="workshop-btn">
            <h3>
              {maintitile === "opportunity" ? "Opportunities" : "Workshops"}
            </h3>
          </div>
        </div> */}
        {/* <div className="container ">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <div className="workshop-text">
                <p>
                  Learning opportunities from subject matter experts through
                  workshops, webinars, and conferences.
                </p>
              </div>
            </div>

            <div className="col-md-3"></div>
          </div>
        </div> */}
        <span className="filter-menu">
          {" "}
          <i className="fas fa-bars"></i>{" "}
        </span>{" "}
        <div className="opportunities-rightside-search">
          <span className="searchIcon">
            <i className="fa fa-search"></i>
          </span>
          <input
            className="search-input-workshop"
            type={Text}
            onChange={(e) => searchData(e)}
            placeholder="Search Events"
          ></input>
        </div>
        <div className="container-fluids">
          <div className="workshop-body ">
            <div className="row">
              <div className="col-md-3">
                <div className={`wb-leftside`}>
                  <OpportunitiesLeftSide
                    filterdata={(e) => filterdata(e)}
                    filterbyeligible={(e) => filterbyeligible(e)}
                    filterbydate={(e) => filterbydate(e)}
                  />
                </div>
              </div>

              <div className="col-md-9 ">
                <div className="totalEvent">
                  {opportunities && opportunities.length} Events
                </div>
                <div className="rightcard-final">
                  <button
                    className={
                      tabname === "opportunity"
                        ? "btn tabchangbtn active"
                        : "btn tabchangbtn"
                    }
                    onClick={() => tabChange("opportunity")}
                  >
                    Opportunities
                  </button>
                  <button
                    className={
                      tabname === "workshop"
                        ? "btn tabchangbtn active"
                        : "btn tabchangbtn"
                    }
                    onClick={() => tabChange("workshop")}
                  >
                    Workshops
                  </button>
                </div>

                <div className=" ">
                  <p className="descriptionshow">
                    {tabname === "opportunity" ? (
                      <>
                        <strong>Opportunities:</strong> Opportunities to test
                        your skills by participating in competitions and
                        hackathons.
                      </>
                    ) : (
                      <>
                        <strong>Workshops:</strong> Learning opportunities from
                        subject matter experts through workshops, webinars, and
                        conferences.
                      </>
                    )}
                  </p>
                  <OpportunitiesRightSide
                    type={maintitile}
                    opportunities={opportunities}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Opportunities;
