import $ from "jquery";
import moment from "moment";
import React from "react";
import Card from "react-bootstrap/Card";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { A11y, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "./Opportunitiescard.css";

function Opportunitiescard(props) {
  const getDaysLeft = (index, endDate, endTime) => {
    $(".timeshow" + index).text("Loading...");

    const endDateTime = moment(endDate);
    const endTimeMoment = moment(endTime, "HH:mm");

    endDateTime.set({
      hour: endTimeMoment.hour(),
      minute: endTimeMoment.minute(),
      second: endTimeMoment.second(),
    });

    const timeDifference = endDateTime.diff(moment());
    const duration = moment.duration(timeDifference);

    // When no time left
    if (
      Math.floor(duration.asDays()) <= 0 &&
      duration.hours() <= 0 &&
      duration.minutes() <= 0 &&
      duration.seconds() <= 0
    ) {
      $(".timeshow" + index).text("Closed");
    }

    // When more than 24 hours left
    if (Math.floor(duration.asDays()) >= 1) {
      const daysLeft = Math.floor(duration.asDays());
      $(".timeshow" + index).text(
        `${daysLeft} day${daysLeft > 1 ? "s" : ""} left`
      );
    }

    // When less than 24 hours left, Update the timer every second
    if (Math.floor(duration.asDays()) === 0 && duration.seconds() > 0) {
      setInterval(function () {
        // Recalculate the time difference on each interval
        const timeDifference = endDateTime.diff(moment());

        // Convert the time difference into a human-readable format
        const duration = moment.duration(timeDifference);

        // Display the time left
        const hours = duration.hours().toString().padStart(2, '0');
        const minutes = duration.minutes().toString().padStart(2, '0');
        const seconds = duration.seconds().toString().padStart(2, '0');

        $(".timeshow" + index).text(`${hours}:${minutes}:${seconds} Left`);
      }, 1000); // Update every second
    }
  };

  return (
    <div className="opportunities-card row">
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        navigation={{
          nextEl: ".swiper-btn-next",
          prevEl: ".swiper-btn-prev",
        }}
        loop={true}
        pagination={{
          el: ".swiper-paginations",
          clickable: true,
        }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
        className="workshops pb-5"
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 25,
          },
        }}
      >
        {props.allopportunities &&
          props.allopportunities.map((oppr, index) => {
            return (
              <div className="col-md-3" key={index}>
                <SwiperSlide>
                  <Card>
                    <Link to={`/e/${oppr.slug}`}>
                      {oppr.banner_image ? (
                        <Card.Img variant="top" src={oppr.banner_image} />
                      ) : (
                        <div className="no-banner">Banner Not Found</div>
                      )}
                    </Link>

                    <Card.Body>
                      <Link to={`/e/${oppr.slug}`}>
                        <p className="date-show">
                          <Moment format="Do MMM'YY" date={oppr.start_date} /> -{" "}
                          <Moment format="Do MMM'YY" date={oppr.end_date} />{" "}
                        </p>
                        <Card.Title>{oppr.title}</Card.Title>
                        <Card.Text>Hosted By: {oppr.organization}</Card.Text>
                      </Link>

                      <div className="cardBottom">
                        <Link to={`/e/${oppr.slug}`}>
                          <p
                            variant="primary"
                            className="opportunities-card-btn explore"
                          >
                            Explore <i className="fa fa-arrow-right"></i>
                          </p>
                        </Link>
                        <span className={`days-left timeshow${index}`}>
                          {getDaysLeft(
                            index,
                            oppr.register_end_date,
                            oppr.register_end_time
                          )}
                        </span>
                      </div>
                    </Card.Body>
                  </Card>
                </SwiperSlide>
              </div>
            );
          })}
        <div className="oppcardswipe swiper-buttons text-center">
          <div className="swiper-btn-prev">
            <FaArrowLeft />
          </div>
          <div className="swiper-paginations"></div>
          <div className="swiper-btn-next">
            <FaArrowRight />
          </div>
        </div>
      </Swiper>
    </div>
  );
}

export default Opportunitiescard;

// supporting documents
