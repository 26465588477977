import React, { useEffect, useState } from "react";
import Opportunities from "../StaticPages/Opportunities";
import axios from "axios";
import config from "../CommonFiles/config.json";
import OpportunitiesRightSide from "../CommonFiles/OpportunitiesRightSide";
import { Col, Row, Container, Card, Button } from "react-bootstrap";
import OpportunitiesRightSideCard from "../CommonFiles/OpportunitiesRightSideCard.js";
import Toggle from "react-toggle";
import Moment from "react-moment";

import "./dashboard.css";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const Event = (props) => {
  const [opportunities, setOpportunities] = useState();
  const [workshops, setWorkshops] = useState(null);
  const [attachmentstatus, setAttachmentstatus] = useState();

  useEffect(async () => {
    axios({
      method: "get",
      url: mainURL + "/opportunity?page=1&type=workshop&from=admin",
      // headers: { Authorization: AuthStr },
    })
      .then((response) => {
        setOpportunities(response.data.data.rows);
        console.log(response.data.data.rows);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api event", response);
      });
  }, []);

  const onMenuClick = () => {
    var leftSidebar = document.querySelector(".left-sidebar");
    leftSidebar.classList.toggle("collapsed-in");
  };

  function changestatus(event, id) {
    console.log("event", event.target.checked);
    setAttachmentstatus(event.target.checked);
    let status;
    if (event.target.checked === true) {
      status = 1;
    } else {
      status = 0;
    }
    console.log("status", status);

    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "put",
      url: mainURL + "/opportunity/" + id,
      data: {
        show_attachment: status,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {})
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  // featured slider
  function featuredslider(event, id) {
    console.log("event", event.target.checked);
    setAttachmentstatus(event.target.checked);
    let status;
    if (event.target.checked === true) {
      status = 1;
    } else {
      status = 0;
    }
    console.log("status", status);

    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "put",
      url: mainURL + "/opportunity/" + id,
      data: {
        is_featured_slider: status,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {})
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }
  function gottosubmition(params) {}
  function gotofeedback(params) {
    props.history.push(`/dashboard/oppportunity/feedback/${params}`);
  }

  return (
    <div id="main " className="topmargin">
      <main className="content">
        <div className="col-md-12 container mb-4">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <h5> All Workshop list </h5>
              </div>
              <div className="col-md-6 text-md-right">
                <p>
                  {" "}
                  <i className="fa fa-home" aria-hidden="true"></i> &nbsp;Home
                  &nbsp; | &nbsp;{" "}
                  <i className="fa fa-briefcase" aria-hidden="true"></i>{" "}
                  &nbsp;Workshop{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          {opportunities &&
            opportunities.map((oppr, index) => (
              <div className="row event_show">
                <div className="col-md-2">
                  {oppr.banner_image ? (
                    <img src={oppr.banner_image} className="imgsize" />
                  ) : (
                    <img
                      src="https://fasterthemes.com/demo/wix-wordpress-theme/wp-content/themes/wix/images/no-image.png"
                      className="imgsize"
                    />
                  )}
                </div>
                <div className="col-md-4">
                  <p>{oppr.title}</p>
                  {/* <p dangerouslySetInnerHTML={{ __html: oppr.about }} /> */}

                  <p>
                    {" "}
                    <Moment
                      format="D MMMM YYYY "
                      date={oppr.start_date}
                    /> - <Moment format="D MMMM YYYY " date={oppr.end_date} />{" "}
                  </p>

                  <p>{oppr.organization}</p>
                  <p>{oppr.country}</p>
                </div>
                <div className=" col-md-6 fr">
                  <label>
                    <span className="togglespan">
                      Do you want to public the attachment
                    </span>

                    <Toggle
                      defaultChecked={oppr.show_attachment === 1 ? true : false}
                      onChange={(e) => changestatus(e, oppr.id)}
                      className="custom-classname"
                      id={oppr.id}
                    />
                    <br />
                    <span className="togglespan">
                      Do you want to Make this featured slider
                    </span>

                    <Toggle
                      defaultChecked={oppr.show_attachment === 1 ? true : false}
                      onChange={(e) => featuredslider(e, oppr.id)}
                      className="custom-classname"
                      id={oppr.id}
                    />
                  </label>
                  <br />
                  <button className="btnshow">View</button>
                  <button
                    className="btnshow"
                    onClick={() => gotofeedback(oppr.id)}
                  >
                    Feedback
                  </button>

                  <button className="btnshow">Delete</button>
                  <button
                    className=" btnshow"
                    onClick={() => gottosubmition(oppr.id)}
                  >
                    {" "}
                    View Submition{" "}
                  </button>
                </div>
              </div>
            ))}
        </div>
      </main>
    </div>
  );
};

export default Event;
