import React, { Component } from "react";
import { Col, Button, Form } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight, FaSave } from "react-icons/fa";
import "react-quill/dist/quill.snow.css";
import { EditorState } from "draft-js";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateFromHTML } from "draft-js-import-html";
import CKEditor from "react-ckeditor-component";
import Swal from "sweetalert2";

import axios from "axios";
import { Link } from "react-router-dom";
import config from "../..//CommonFiles/config.json";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const USER_TOKEN = localStorage.getItem("token");
const AuthStr = "Bearer ".concat(USER_TOKEN);
class StepFive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutOpportunity: "",
      editorState: EditorState.createEmpty(),
      editorStatecp: EditorState.createEmpty(),
      companytittle: "",
      aboutOrganisation: "",
      errorshow: "",
      aboutOpportunityerrorshow: "",
      aboutOrganisationerrorshow: "",
      minaboutus: "",
      config: {
        toolbar: null,
      },
    };

    this.updateContent = this.updateContent.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangefororganisation = this.onChangefororganisation.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem("editopportunityid")) {
      axios({
        method: "get",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      })
        .then((response) => {
          const alldata = response.data.data.about;
          console.log(response.data.data.about, "get opprootunity step 2");
          this.setState({
            aboutOpportunity: alldata,
            aboutOrganisation: response.data.data.about_company,
            companytittle: response.data.data.company_title,
          });
          console.log("about step", this.state.aboutOpportunity);
        })
        .catch((err) => {
          console.log("jhghjghg", err);
        });
      // setEditorState(EditorState.createWithContent(contentState))
    }
  }

  updateContent(newContent) {
    this.setState({
      content: newContent,
    });
  }

  onChange(evt) {
    const MAX_LENGTH = 100;
    const lengthco = evt.editor.getData();
    console.log("word", lengthco);
    this.setState({
      aboutOpportunity: evt.editor.getData(),
    });
  }

  onChangefororganisation(evt) {
    const MAX_LENGTH = 100;
    const lengthco = evt.editor.getData();
    console.log("word", lengthco);
    this.setState({
      aboutOrganisation: evt.editor.getData(),
    });
  }

  onBlur(evt) {
    console.log("onBlur event called with event info: ", evt);
  }

  afterPaste(evt) {
    console.log("afterPaste event called with event info: ", evt);
  }

  nextStep = (status) => {
    console.log(
      "this.state.companytittle ",
      this.state.companytittle,
      this.state.aboutOpportunity,
      this.state.aboutOrganisation,
    );

    if (
      this.state.aboutOpportunity === null ||
      this.state.aboutOpportunity === ""
    ) {
      this.setState({
        aboutOpportunityerrorshow: "Please Enter about opportunity",
      });
    } else {
      this.setState({
        aboutOpportunityerrorshow: "",
      });
    }

    if (this.state.companytittle === null || this.state.companytittle === "") {
      this.setState({
        errorshow: "Please Enter Organisation Name",
      });
    } else {
      this.setState({
        errorshow: "",
      });
    }

    if (
      this.state.aboutOrganisation === null ||
      this.state.aboutOrganisation === ""
    ) {
      this.setState({
        aboutOrganisationerrorshow: "Please Enter About Organisation",
      });
    } else {
      this.setState({
        aboutOrganisationerrorshow: "",
      });
    }

    console.log(
      "this.state.companytittle error ",
      this.state.aboutOrganisationerrorshow,
      this.state.errorshow,
      this.state.aboutOpportunityerrorshow,
    );

    if (
      this.state.aboutOpportunity === null ||
      this.state.aboutOpportunity === "" ||
      this.state.companytittle === null ||
      this.state.companytittle === "" ||
      this.state.aboutOrganisation === null ||
      this.state.aboutOrganisation === ""
    ) {
      return;
    } else {
      const getlocal = localStorage.getItem("FormFive");
      // check if nothing update store previous locastorage value
      axios({
        method: "put",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
        data: {
          about: this.state.aboutOpportunity,
          about_company: this.state.aboutOrganisation,
          company_title: this.state.companytittle,
        },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldata = response.data.data;
          if (status === "save") {
            Swal.fire(
              "Good job!",
              "About opportunity details have been updated",
              "success",
            );
          } else {
            localStorage.setItem("activestep", 5);
            this.props.newActiveStepValue(5);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
      localStorage.setItem("FormFive", this.state.aboutOpportunity);

      localStorage.setItem("about_company", this.state.aboutOrganisation);
      localStorage.setItem("company_title", this.state.companytittle);
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    }
  };

  prevStep = () => {
    localStorage.setItem("activestep", 3);
    this.props.newActiveStepValue(3);
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };
  settitile = (e) => {
    this.setState({
      companytittle: e.target.value,
    });
  };

  render() {
    const { editorState, editorStatecp } = this.state;
    return (
      <div>
        <div className="form">
          <p className="notifyshow">
            {" "}
            <i className="fa fa-exclamation-circle"></i> This field helps you to
            mention the format of the competition you are listing, along with
            various rounds and specific needs of the competition.
          </p>

          <Form className="form-body editorbody">
            <Form.Row>
              <Col
                className="form-group-col"
                md={12}
                controlId="formGridOrganization"
              >
                <Form.Label className="input-titlle">
                  About Opportunity <span className="importantfield">*</span>
                </Form.Label>
                <br />

                <CKEditor
                  activeClass="p10"
                  content={this.state.aboutOpportunity}
                  // defaultValue={this.state.aboutOpportunity}
                  toolbar={[
                    "heading",
                    "|",
                    "bulletedList",
                    "numberedList",
                    "alignment",
                    "undo",
                    "redo",
                  ]}
                  events={{
                    blur: this.onBlur,
                    afterPaste: this.afterPaste,
                    change: this.onChange,
                  }}
                />
              </Col>
            </Form.Row>
            <Form.Text className=" checkerror">
              {this.state.minaboutus}
            </Form.Text>
            {this.state.aboutOpportunityerrorshow && (
              <Form.Text className=" checkerror">
                {this.state.aboutOpportunityerrorshow}
              </Form.Text>
            )}

            <hr />
            <Form.Row>
              <Col
                className="form-group-col"
                md={12}
                controlId="formGridOrganization"
              >
                <Form.Label className="input-titlle">
                  Organisation Name <span className="importantfield">*</span>
                </Form.Label>
                <br />

                <Form.Control
                  type="text"
                  placeholder="Organisation Name"
                  name="company_title"
                  required
                  onChange={(e) => this.settitile(e)}
                  defaultValue={this.state.companytittle}
                />
                <Form.Text className=" checkerror">
                  {this.state.errorshow}
                </Form.Text>
              </Col>
            </Form.Row>

            <Form.Label className="input-titlle">
              About Organisation <span className="importantfield">*</span>
            </Form.Label>
            <br />

            <CKEditor
              activeClass="p10"
              content={this.state.aboutOrganisation}
              events={{
                blur: this.onBlur,
                afterPaste: this.afterPaste,
                change: this.onChangefororganisation,
              }}
            />
          </Form>
          {this.state.aboutOrganisationerrorshow && (
            <Form.Text className=" checkerror">
              {this.state.aboutOrganisationerrorshow}
            </Form.Text>
          )}
        </div>
        <div className="hosting_bottom">
          <Button className="help-btn" type="button" onClick={this.prevStep}>
            <FaArrowLeft />
            Go Back
          </Button>
          <Button
            className="save-hosting-btn"
            type="button"
            onClick={() => this.nextStep("continue")}
          >
            Next <FaArrowRight />
          </Button>
          {localStorage.getItem("opportunityfrom") && (
            <Button
              className="save-hosting-first-btn editsavebtn"
              type="button"
              onClick={() => this.nextStep("save")}
            >
              save <FaSave />
            </Button>
          )}
          <Link to={`/user/hosting`}>
            {" "}
            <Button
              className="transparentbtn float-right mt-10 mr-2"
              type="button"
              // onClick={(e) => cancel(e)}
            >
              Cancel
            </Button>
          </Link>
        </div>
      </div>
    );
  }
}

export default StepFive;
