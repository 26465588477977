import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Core from "./Core";
import { defaultLocale } from "./Locale";
import "./styles.css";

const Quiz = ({
  quiz,
  quizseting,
  editableAns,
  shuffle,
  shuffleans,
  questionIndex,
  showDefaultResult,
  onComplete,
  customResultPage,
  showInstantFeedback,
  continueTillCorrect,
  onUpdateQuestionIndex,
  showonly,
  parentCallback,
}) => {
  console.log("21", shuffle);
  const [start, setStart] = useState(true);
  const [questions, setQuestions] = useState(quiz.questions);
  function closedWin() {
    alert("close ?");
    return false; /* which will not allow to close the window */
  }
  if (window.addEventListener) {
    window.addEventListener("close", closedWin, false);
  }

  useEffect(() => {
    console.log("step 2", shuffle, shuffleans);

    if (shuffle === true && !localStorage.getItem("suffle")) {
      let sQuestion = shuffleQuestions(quiz.questions);
      if (quiz.questions.length != showonly) {
        sQuestion.splice(-(quiz.questions.length - showonly));
      }
      console.log("quiz me quetions", sQuestion);
      setQuestions(sQuestion);
      localStorage.setItem("suffle", JSON.stringify(sQuestion));
      // if (window.location.href.indexOf("reload") == -1) {
      //   window.location.href = window.location.href + "?reload";
      // }
      parentCallback(sQuestion);
      document.documentElement.addEventListener("click", function (event) {
        if (event.ctrlKey) {
          event.preventDefault();
        }
      });
    } else if (localStorage.getItem("suffle")) {
      let localQuestions = JSON.parse(localStorage.getItem("suffle"));
      parentCallback(localQuestions);

      setQuestions(localQuestions);
      console.log("local storage", localQuestions);
    } else {
      setQuestions(quiz.questions);
      if (quiz.questions.length != showonly) {
        questions.splice(-(questions.length - showonly));
      }
      // questions.splice(-(questions.length-showonly));
      localStorage.setItem("suffle", JSON.stringify(questions));
      parentCallback(questions);

      console.log("local storage no shuffle", quiz.questions);
    }

    if (shuffleans === "yes") {
      let finalquestionList = JSON.parse(localStorage.getItem("suffle"));

      for (let i = 0; i < finalquestionList.length; i++) {
        shuffleAnswers(finalquestionList[i].answers);
      }
      localStorage.setItem("suffle", JSON.stringify(finalquestionList));
    }

    // console.log("local storage", JSON.parse(localStorage.getItem("suffle")));

    // console.log("local storage", questions)

    // setQuestions(questions.map((question, index) => ({
    //   ...question,
    //   questionIndex : index + 1
    // })))

    // console.log("questions", questions[0])
  }, [quiz.questions]);

  const shuffleQuestions = useCallback(
    (questions) => {
      for (let i = questions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [questions[i], questions[j]] = [questions[j], questions[i]];
      }
      console.log("quiz me quetions", questions);

      return questions;
    },
    [JSON.parse(localStorage.getItem("suffle"))],
  );

  const shuffleAnswers = (answers) => {
    for (let i = answers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [answers[i], answers[j]] = [answers[j], answers[i]];
    }
    return answers;
  };

  const updateQuestionIndex = (index) => {
    questionIndex = index;
    onUpdateQuestionIndex(index);
    console.log("ttttttt", questionIndex);
  };

  const validateQuiz = (quiz) => {
    if (!quiz) {
      console.error("Quiz object is required.");
      return false;
    }

    const { questions } = quiz;
    if (!questions) {
      console.error("Field 'questions' is required.");
      return false;
    }

    for (let i = 0; i < questions.length; i++) {
      const {
        question,
        type,
        questionPic,
        answerSelectionType,
        answers,
        correctAnswer,
      } = questions[i];
      if (!question) {
        console.error("Field 'question' is required.");
        return false;
      }

      // if(!type) {
      //   console.error("Field 'type' is required.");
      //   return false;
      // } else {
      //   if(type != 'text' && type != 'photo') {
      //     console.error("The value of 'type' is either 'text' or 'photo'.");
      //     return false;
      //   }
      // }

      if (!answers) {
        console.error("Field 'answers' is required.");
        return false;
      } else {
        if (!Array.isArray(answers)) {
          console.error("Field 'answers' has to be an Array");
          return false;
        }
      }

      // new question type check

      // if(!correctAnswer) {
      //   console.error("Field 'correctAnswer' is required.");
      //   return false;
      // }

      // if(!answerSelectionType) {
      //   // Default single to avoid code breaking due to automatic version upgrade
      //   console.warn("Field answerSelectionType should be defined since v0.3.0. Use single by default.")
      //   answerSelectionType = answerSelectionType || 'single';
      // }

      // if(answerSelectionType === 'single' && !(typeof answerSelectionType === 'string' || answerSelectionType   instanceof String) ) {
      //   console.error("answerSelectionType is single but expecting String in the field correctAnswer");
      //   return false;
      // }

      // if(answerSelectionType === 'multiple' && !Array.isArray(correctAnswer)) {
      //   console.error("answerSelectionType is multiple but expecting Array in the field correctAnswer");
      //   return false;
      // }
    }

    return true;
  };

  if (!validateQuiz(quiz)) {
    return null;
  }

  const appLocale = {
    ...defaultLocale,
    ...quiz.appLocale,
  };

  return (
    <div className="react-quiz-container">
      {!start && (
        <div>
          <h2>{quiz.quizTitle}</h2>
          {/* <div>{appLocale.landingHeaderText.replace("<questionLength>" , quiz.questions.length)}</div>
            {quiz.quizSynopsis &&
              <div className="quiz-synopsis">
                  {quiz.quizSynopsis}
              </div>
            } */}
          <div className="startQuizWrapper">
            <button onClick={() => setStart(true)} className="startQuizBtn btn">
              {appLocale.startQuizBtn}
            </button>
          </div>
        </div>
      )}
      <p>{/* {JSON.stringify(questions)} */}</p>

      <Core
        questions={questions}
        quizseting={quizseting}
        editableAns={editableAns}
        setQuestionIndex={questionIndex}
        onUpdateQuestionIndex={updateQuestionIndex}
        suffleAnswer={shuffleans === "yes" ? true : false}
        showDefaultResult={showDefaultResult}
        onComplete={onComplete}
        customResultPage={customResultPage}
        showInstantFeedback={showInstantFeedback}
        continueTillCorrect={continueTillCorrect}
        appLocale={appLocale}
      />
    </div>
  );
};

Quiz.propTypes = {
  quiz: PropTypes.object,
  shuffle: PropTypes.bool,
  showDefaultResult: PropTypes.bool,
  onComplete: PropTypes.func,
  customResultPage: PropTypes.func,
  showInstantFeedback: PropTypes.bool,
  continueTillCorrect: PropTypes.bool,
};

export default Quiz;
