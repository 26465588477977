import { Button, Form, Input, Select, notification } from "antd";
import { MailOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../CommonFiles/config.json";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const buttonLayout = {
  wrapperCol: {
    span: 12,
    offset: 12,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const BroadcastEmailForm = (props) => {
  const [formLayout, setFormLayout] = useState("vertical");
  const [uploadedEmails, setUploadedEmails] = useState([]);
  const [emailItems, setEmailItems] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const items = [];
    uploadedEmails.map((item) => {
      if (typeof item == "string") {
        items.push({
          key: item,
          value: item,
          label: item,
          name: item,
        });
      } else {
        if (item.hasOwnProperty("text"))
          items.push({
            key: item.text,
            value: item.text,
            label: item.text,
            name: item.text,
          });
      }
    });
    setEmailItems([...emailItems, ...items])
  }, [uploadedEmails]);

  const onFinish = (values) => {};

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: "bottomRight",
    });
  };

  const showUploadModal = async () => {
    const { value: file } = await Swal.fire({
      title: "Upload Email List",
      input: "file",
      inputAttributes: {
        accept: ".xlsx, .xls, .csv",
        "aria-label": "Upload Email List",
      },
    });
    if (file) {
      const formData = new FormData();
      formData.append("excelfile", file);
      const USER_TOKEN = localStorage.getItem("token");
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      axios({
        method: "post",
        url: mainURL + "/excel/",
        data: formData,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          setUploadedEmails([...uploadedEmails, ...response.data.emails]);
          Swal.fire({
            title: "File Uploaded Successfully",
            text: `Found ${response.data.emails.length} email ids.`,
            icon: "success",
          });
        })
        .catch(function () {
          //handle error
        });
    }
  };

  return (
    <Form
      form={form}
      layout={formLayout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      {/* <Form.Item
        name={"email"}
        label="Enter an email"
        rules={[
          {
            required: true,
            type: "email",
          },
        ]}
      >
        <Input type="email" />
      </Form.Item> */}
      <Form.Item
        name={"emails"}
        label="Enter an email"
        rules={[
          {
            required: true
          },
        ]}
      >
        <Select
          mode="tags"
          size="middle"
          placeholder="Add emails for sending mail"
          // onChange={(e) => handleEditClicked(e)}
          name="emails"
          options={emailItems}
        />
      </Form.Item>
      <Form.Item
        name={"templateName"}
        label="Select Email Template"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select>
          {props.templates.map((template, index) => (
            <Select.Option key={index} value={template["Name"]}>
              {template["Name"]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item {...buttonLayout}>
        <div className="d-flex justify-content-between">
          <Button
            icon={<UploadOutlined />}
            type="primary"
            size={"large"}
            danger
            htmlType="button"
            onClick={showUploadModal}
          >
            Upload Email Ids
          </Button>
          <Button
            icon={<MailOutlined />}
            type="primary"
            size={"large"}
            danger
            htmlType="submit"
          >
            Send Email
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default BroadcastEmailForm;
