import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Button, Form, Tab, Tabs, TabContent } from "react-bootstrap";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Swal from "sweetalert2";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const Discount = (props) => {
  const [name, setname] = useState();
  const [coupontype, setcoupontype] = useState();
  const [couponamount, setcouponamount] = useState();
  const [inputFields, setInputFields] = useState([
    {
      opportunity_id: props.match.params.id,
      name: "",
      coupon_type: "",
      coupon_value: "",
    },
  ]);
  const onInputChange = (val, name) => {
    // setInputValue({ ...inputFields, [name]: val });

    const rows = [...inputFields];
    console.log("getting", name, "getting val", val);
    if (name == "name") {
      rows.name = val;
    }
    if (name == "coupon_type") {
      rows.coupon_type = val;
    }
    if (name == "coupon_value") {
      rows.coupon_value = val;
    }

    console.log("data", inputFields);
    setInputFields(inputFields);
  };
  function submitonlymemberform() {
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: mainURL + "/coupons",
      data: {
        opportunity_id: props.match.params.id,
        name: name,
        coupon_type: parseInt(coupontype),
        coupon_value: parseInt(couponamount),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        // localStorage.setItem("allteam", JSON.stringify(inputFields));
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Coupon has been submitted",
          showConfirmButton: false,
          timer: 2500,
        });
        props.history.push(
          `/user/profile/event/dicount-list/` + props.match.params.id,
        );
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }
  return (
    <div className="col-md-12 topmargin">
      <div id="user-wrapper" className="dashboard">
        {/* <h5>Opportunity Lists</h5>   */}

        <div className="row">
          <div className="col-md-9">
            <h5>Add Discount</h5>
            <hr />
            <Form.Row className="">
              <Col
                className="form-group-col"
                md={12}
                controlId="oppotunityStartDate"
              >
                <Form.Label className="input-titlle">
                  Discount Name <span className="importantfield">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Your name"
                  name="name"
                  defaultValue={inputFields.name}
                  onChange={(e) => setname(e.target.value)}
                />
              </Col>
            </Form.Row>

            <Form.Row>
              <Col
                className="form-group-col"
                md={12}
                controlId="oppotunityEndTime"
              >
                <Form.Label className="input-titlle">
                  Type <span className="importantfield">*</span>
                </Form.Label>
                <div
                  className="form-control radiocss oppfor"
                  name="type"
                  onChange={(e) => setcoupontype(e.target.value)}
                >
                  <div className="form-check">
                    <input
                      className={`form-check-input `}
                      type="radio"
                      name="coupon_type"
                      value="1"
                    />
                    <label className="form-check-label" for="exampleRadios1">
                      Flat
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                      className={`form-check-input `}
                      type="radio"
                      name="coupon_type"
                      value="0"
                    />
                    <label className="form-check-label" for="exampleRadios1">
                      Percent
                    </label>
                  </div>
                </div>
              </Col>
            </Form.Row>
            <Form.Row className="">
              <Col
                className="form-group-col"
                md={12}
                controlId="formGridOrganization"
              >
                <Form.Label className="input-titlle">
                  Amount <span className="importantfield">*</span>
                </Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Amount"
                  name="coupon_value"
                  maxLength="10"
                  onChange={(e) => setcouponamount(e.target.value)}
                />
              </Col>
            </Form.Row>
            <div className="registerbtn">
              <button className="" onClick={() => submitonlymemberform()}>
                {" "}
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discount;
