import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Tab, Tabs, TabContent } from "react-bootstrap";
import axios from "axios";
import $ from "jquery";
import { FaArrowRight, FaSave } from "react-icons/fa";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import {
  Button as Antbtn,
  Checkbox,
  Form as AntForm,
  Select,
  Input,
  Radio,
} from "antd";
import config from "../..//CommonFiles/config.json";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const today = new Date().toISOString().split("T")[0];
const StepOne = (props) => {
  const [inputValue, setInputValue] = useState({
    // visibility: "",
    category: "",
    title: "",
    website: "",
    organization: "",
    festival: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    location: "",
    region: "",
  });

  const [error, setError] = useState([]);
  const [editData, setEditData] = useState([]);
  const [saveSucess, setSaveSucess] = useState("");

  useEffect(() => {
    if (localStorage.getItem("editopportunityid")) {
      axios({
        method: "get",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      })
        .then((response) => {
          const alldata = response.data.data;
          setEditData(alldata);
          var oldData = {
            category: alldata.category,
            title: alldata.title,
            website: alldata.website,
            organization: alldata.organization,
            festival: alldata.festival,
            start_date: alldata.start_date,
            start_time: alldata.start_time,
            end_date: alldata.end_date,
            end_time: alldata.end_time,
            location: alldata.location,
            region: alldata.region,
          };
          setInputValue(oldData);
          localStorage.setItem("FormOne", JSON.stringify(oldData));
        })
        .catch((err) => {
          console.log("jhghjghg", err);
        });
    }
  }, []);

  const onInputChange = (val, name) => {
    console.log("type", localStorage.getItem("FormOne"));

    const getlocal = localStorage.getItem("FormOne");
    if (getlocal === null) {
      localStorage.setItem("FormOne", JSON.stringify(inputValue));

      var existing = localStorage.getItem("FormOne");
      existing = existing ? JSON.parse(existing) : {};

      // Logic for adding https in website by default
      if (name === "website") {
        if (val.length > 0 && val !== "https://") {
          if (val.includes("http://")) {
            val = val.split("http://");
            val = "https://" + val[1];
          } else {
            if (!val.includes("https://")) {
              val = "https://" + val;
            }
          }
        } else {
          val = "";
        }
      }

      // Add new data to localStorage Array
      existing[name] = val;

      // Save back to localStorage
      localStorage.setItem("FormOne", JSON.stringify(existing));

      setInputValue(existing);
    } else {
      var existing = localStorage.getItem("FormOne");
      existing = existing ? JSON.parse(existing) : {};

      // Logic for adding https in website by default
      if (name === "website") {
        if (val.length > 0 && val !== "https://") {
          if (val.includes("http://")) {
            val = val.split("http://");
            val = "https://" + val[1];
          } else {
            if (!val.includes("https://")) {
              val = "https://" + val;
            }
          }
        } else {
          val = "";
        }
      }

      // Add new data to localStorage Array
      existing[name] = val;
      // Save back to localStorage
      localStorage.setItem("FormOne", JSON.stringify(existing));

      setInputValue(existing);
    }
  };

  const step1 = (e, status) => {
    console.log(inputValue.website);
    const error = [];

    // if (inputValue.visibility === "") {
    //   error["visibility"] = "Please select visibility";
    // }

    if (inputValue.category === "") {
      error["category"] = "Please select category";
    }

    if (inputValue.title === "") {
      error["title"] = "Opportunity Title is required";
    }

    if (inputValue.organization === "") {
      error["organization"] = "Organization is required";
    }

    // if (inputValue.festival === "") {
    //   error["festival"] = "Festival is required";
    // }
    if (inputValue.start_date === "") {
      error["start_date"] = "Please select Start date";
    }
    if (inputValue.start_time === "") {
      error["start_time"] = "Please select Start Time";
    }
    if (inputValue.end_date === "") {
      error["end_date"] = "Please select End Date";
    }
    if (inputValue.end_time === "") {
      error["end_time"] = "Please select End Time";
    }
    if (inputValue.location === "") {
      error["location"] = "Location is required";
    }
    // if (inputValue.region === "") {
    //   error["region"] = "Region is required";
    // }

    setError(error);
    console.log(error);
    if (Object.keys(error).length === 0) {
      const stepone = JSON.parse(localStorage.getItem("FormOne"));
      let method;
      let urlid;
      if (!localStorage.getItem("editopportunityid")) {
        method = "post";
        urlid = mainURL + "/opportunity";
      } else {
        method = "put";
        urlid =
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid");
      }

      console.log("form data", JSON.parse(localStorage.getItem("FormOne")));
      const USER_TOKEN = localStorage.getItem("token");
      console.log("user token", USER_TOKEN);
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      axios({
        method: method,
        url: urlid,
        data: stepone,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldata = response.data.data;
          console.log("Error sds calling api", response);
          if (response.data.status === 1) {
            if (alldata.id) {
              localStorage.setItem("editopportunityid", alldata.id);
            }
            // localStorage.setItem("activestep", 2);
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
      if (status === "contineu") {
        localStorage.setItem("activestep", 2);
        props.newActiveStepValue(2);
      } else {
        Swal.fire("Good job!", "Basic details have been updated", "success");
        // setSaveSucess("Basic Detail has been updated")
      }

      const getlocal = localStorage.getItem("FormOne");
      if (getlocal == null) {
        localStorage.setItem("FormOne", JSON.stringify(inputValue));
      }
    }

    console.log("error empty", Object.keys(error).length);

    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };
  const cancel = () => {
    props.history.push({
      pathname: "/user/hosting/",
    });
  };
  return (
    <div>
      <div className="form">
        <Form
          className="form-body"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Row className="twocolform">
            <Col className="form-group-col" md={6} controlId="formGridCategory">
              <Form.Label className="input-titlle">
                Category <span className="importantfield">*</span>
              </Form.Label>

              <Select
                bordered={false}
                className="select-category"
                dropdownMatchSelectWidth={true}
                onChange={(e) => onInputChange(e, "category")}
                options={[
                  {
                    label: "Select category",
                  },
                  {
                    value: "workshop",
                    label: "Workshop & Webinar",
                  },
                  {
                    value: "opportunity",
                    label: "Case Competition",
                  },
                ]}
                value={
                  inputValue.category ? inputValue.category : "Select category"
                }
              />
              <Form.Text className=" checkerror">{error.category}</Form.Text>
            </Col>

            <Col
              className="form-group-col"
              md={6}
              controlId="formGridOpportunityTitle"
            >
              <Form.Label className="input-titlle">
                Opportunity Title <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="title"
                defaultValue={inputValue.title}
                placeholder="Please enter opportunity name"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                required
              />
              <Form.Text className=" checkerror">{error.title}</Form.Text>
            </Col>
          </Form.Row>

          <Form.Row className="twocolform">
            <Col className="form-group-col" md={6} controlId="formGridWebsite">
              <Form.Label className="input-titlle">Website</Form.Label>
              <Form.Control
                type="text"
                name="website"
                placeholder="Please enter company website"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                value={inputValue.website}
                required
              />
              <Form.Text className=" checkerror">{error.website}</Form.Text>
            </Col>

            <Col
              className="form-group-col"
              md={6}
              controlId="formGridOrganization"
            >
              <Form.Label className="input-titlle">
                Organization <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="organization"
                placeholder="Please enter organization name"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                defaultValue={
                  inputValue.organization && inputValue.organization
                }
                required
              />
              <Form.Text className=" checkerror">
                {error.organization}
              </Form.Text>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col
              className="form-group-col "
              md={12}
              controlId="formGridFestivals"
            >
              <Form.Label className="input-titlle">Festivals </Form.Label>
              <Form.Control
                placeholder="Enter Fest. Name"
                name="festival"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                defaultValue={inputValue.festival}
                required
              />
              <Form.Text className=" checkerror">{error.festival}</Form.Text>
            </Col>
          </Form.Row>
          <Form.Row className="twocolform">
            <Col
              controlId="oppotunityEndDate"
              className="form-group-col"
              md={6}
            >
              <Form.Label className="input-titlle">
                Opportunity Start Date <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                min={today}
                max={inputValue.end_date && inputValue.end_date}
                placeholder="21-04-2021d"
                name="start_date"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                value={inputValue?.start_date}
                required
              />
              <Form.Text className=" checkerror">{error.start_date}</Form.Text>
            </Col>

            <Col
              className="form-group-col"
              md={6}
              controlId="oppotunityEndTime"
            >
              <Form.Label className="input-titlle">
                Opportunity Start Time <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                placeholder="00:00:00"
                name="start_time"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                defaultValue={inputValue?.start_time}
                required
              />
              <Form.Text className=" checkerror">{error.start_time}</Form.Text>
            </Col>
          </Form.Row>

          <Form.Row className="twocolform">
            <Col
              controlId="oppotunityEndDate"
              className="form-group-col"
              md={6}
            >
              <Form.Label className="input-titlle">
                Opportunity End Date <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                min={inputValue.start_date}
                placeholder="21-04-2021"
                name="end_date"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                value={inputValue?.end_date}
                required
              />
              <Form.Text className=" checkerror">{error.end_date}</Form.Text>
            </Col>

            <Col
              className="form-group-col"
              md={6}
              controlId="oppotunityEndTime"
            >
              <Form.Label className="input-titlle">
                Opportunity End Time <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                placeholder="00:00:00"
                name="end_time"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                defaultValue={inputValue.end_time}
                required
              />
              <Form.Text className=" checkerror">{error.end_time}</Form.Text>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col className="form-group-col" md={12} controlId="location">
              <Form.Label className="input-titlle">
                Location <span className="importantfield">*</span>
              </Form.Label>
              <div
                className="form-control radiocss"
                name="location"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
              >
                <Form.Check
                  type="radio"
                  value="online"
                  label="Online"
                  name="location"
                  checked={
                    inputValue.location === null
                      ? null
                      : inputValue.location === "online"
                  }
                />
                <Form.Check
                  type="radio"
                  value="offline"
                  label="Offline"
                  name="location"
                  checked={
                    inputValue.location === null
                      ? null
                      : inputValue.location === "offline"
                  }
                />
              </div>

              <Form.Text className=" checkerror">{error.location}</Form.Text>
            </Col>
          </Form.Row>

          {/* <Form.Row>
          <Col className="form-group-col" md={12} controlId="oppotunityEndTime">
            <Form.Label className="input-titlle">Region <span className="importantfield">*</span></Form.Label>

            <div
              className="form-control radiocss"
              onChange={(e) => onInputChange(e.target.value, e.target.name)}
            >
              <Form.Check
                type="radio"
                value="india"
                name="region"
                label="India"
                checked={inputValue.region === "india"}
              />
              <Form.Check
                type="radio"
                value="global"
                name="region"
                label="Global"
                checked={inputValue.region === "global"}
              />
            </div>
            <Form.Text className=" checkerror">{error.location}</Form.Text>
          </Col>
        </Form.Row> */}
          {saveSucess && <p className="success-save">{saveSucess}</p>}
        </Form>
      </div>
      <div className="hosting_bottom">
        <Button
          className="save-hosting-first-btn"
          type="button"
          onClick={(e) => step1(e, "contineu")}
        >
          Next <FaArrowRight />
        </Button>
        {localStorage.getItem("opportunityfrom") && (
          <Button
            className="save-hosting-first-btn editsavebtn"
            type="button"
            onClick={(e) => step1(e, "save")}
          >
            save <FaSave />
          </Button>
        )}
        <Link to={`/user/hosting`}>
          {" "}
          <Button
            className="transparentbtn float-right mt-10 mr-2"
            type="button"
            // onClick={(e) => cancel(e)}
          >
            Cancel
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default StepOne;
