import React, { useEffect } from "react";
import "./Aboutus.css";
import { Tabs, Card, Button } from "antd";
import { BsArrowRight } from "react-icons/bs";
import { FaFacebookSquare } from "react-icons/fa";
import Footer from "../CommonFiles/Footer";
import Header from "../CommonFiles/Header";
const { TabPane } = Tabs;

function Aboutus() {
  useEffect(() => {
    var element = document.getElementById("footershow");
    element.classList.add("footershow");
  }, []);

  window.scrollTo(0, 0);

  return (
    <React.Fragment>
      <Header />
      <div className="about-us-content">
        <div className="aboutuscontainer mt-md-5 about-us-section pt-4">
          <div className="row">
            <div className="col-md-6 about-lower ipad-full-width mt-4">
              <div className="about-us-heading">
                <h4 className="mb-1 hr-lines">About Us</h4>
              </div>
              <Card className="aboutsec">
                <p className="mb-0">
                  <p className="about-title">
                    Transforming education and careers, connecting students,
                    professionals, and <br />
                    organizations for success.
                  </p>
                  Grad Partners is a one stop platform for students,
                  organizations & professionals to connect and engage through
                  workshops, competitive events, mentorship etc. We help
                  students to accelerate their career growth in different
                  aspects of campus life & become corporate ready while
                  organization can benefit with branding among student community
                  and in identifying exceptional talents.
                </p>
              </Card>
            </div>
            <div className="col-md-6 ipad-full-width text-center">
              <img
                className="img-fluid roundedimg"
                src={require("../../images/about-head.png").default}
              />
            </div>
          </div>
        </div>

        <div className="aboutussecondcontainer">
          <div className="row">
            <div className="col-md-6 about-lower ipad-full-width">
              <div className="about-us-heading">
                <h4 className="mb-1">WHO WE ARE</h4>
              </div>
              <Card className="aboutsec">
                <p className="mb-0">
                  <p className="about-title">
                    Trailblazers of <br /> Connection & Growth
                  </p>
                </p>
              </Card>
            </div>

            <div className="col-md-6 about-lower ipad-full-width">
              <Card className="aboutsec">
                <p className="mb-0">
                  Empowering students with comprehensive resources for campus
                  life, including case competitions. IIM Bangalore alum launched
                  the platform to democratize learning opportunities and
                  streamline knowledge transfer. Our mission: empower students
                  and facilitate growth through accessible case competition
                  resources.
                </p>
              </Card>
            </div>
          </div>
        </div>

        <div className="aboutussecondcontainer countersec">
          <div className="row">
            <div className="col-md-4 about-lower ipad-full-width">
              <div className="about-us-heading">
                <h4 className="mb-1">founded</h4>
              </div>
              <Card className="aboutsec">
                <p className="mb-0">
                  <p className="about-title">2020 </p> When we founded{" "}
                  <span className="karpediemspan">Grad Partners</span>
                </p>
              </Card>
            </div>
            <div className="col-md-4 about-lower ipad-full-width">
              <div className="about-us-heading">
                <h4 className="mb-1">community</h4>
              </div>
              <Card className="aboutsec">
                <p className="mb-0">
                  <p className="about-title">100k+ </p> Members on{" "}
                  <span className="karpediemspan">Grad Partners</span>
                </p>
              </Card>
            </div>
            <div className="col-md-4 about-lower ipad-full-width">
              <div className="about-us-heading">
                <h4 className="mb-1">Mentees</h4>
              </div>
              <Card className="aboutsec">
                <p className="mb-0">
                  <p className="about-title">10k+ </p> Listed on{" "}
                  <span className="karpediemspan">Grad Partners</span>
                </p>
              </Card>
            </div>
            <div className="col-md-4 about-lower ipad-full-width">
              <div className="about-us-heading">
                <h4 className="mb-1">CASE COMPETITIONS</h4>
              </div>
              <Card className="aboutsec">
                <p className="mb-0">
                  <p className="about-title">10+ </p> From 2020 on{" "}
                  <span className="karpediemspan">Grad Partners</span>
                </p>
              </Card>
            </div>
            <div className="col-md-4 about-lower ipad-full-width">
              <div className="about-us-heading">
                <h4 className="mb-1">Institutes</h4>
              </div>
              <Card className="aboutsec">
                <p className="mb-0">
                  <p className="about-title">100+ </p> Listed on{" "}
                  <span className="karpediemspan">Grad Partners</span>
                </p>
              </Card>
            </div>
            <div className="col-md-4 about-lower ipad-full-width">
              <div className="about-us-heading">
                <h4 className="mb-1">Workshops</h4>
              </div>
              <Card className="aboutsec">
                <p className="mb-0">
                  <p className="about-title">25+ </p> Organized at{" "}
                  <span className="karpediemspan">Grad Partners</span>
                </p>
              </Card>
            </div>
          </div>
        </div>

        <div className="aboutussecondcontainer countersec subscribersecabout">
          <div className="secthird">
            <p>
              We are proud of the fact that many of our subscribers have aced
              multiple corporate & b-school case competitions, and have
              immensely benefitted with our personalized mentoring. We love to
              script your story and see you succeed.
            </p>
          </div>
        </div>
        <div className="aboutussecondcontainer lastsection ">
          <div className="about-us-heading ">
            <h4 className="mb-1">WHAT WE DO</h4>
          </div>
          <p className="about-title">
            Revolutionizing Campus Life <br />
            with Comprehensive Solutions{" "}
          </p>
        </div>

        <div className="container whatwedobox">
          <div className="row mb-4">
            <div className="col-md-4 ipad-full-width">
              <Card className="we-do-content">
                <img
                  className="img-fluid"
                  src={require("../../images/aboutusicon1.png").default}
                />
                <h5>Competition Marketplace</h5>
                <p>
                  Grad Partners platform is a marketplace where anyone be it
                  Corporate entities, Universities, Student bodies etc. can list
                  their events related to case competitions, workshops, webinars
                  etc. and seek participation from the eligible group. Our
                  network has grown upto 10000+ members from 100+ institutes and
                  are continuing to grow with even more pace. We urge you to
                  host your events on Grad Partners to increase its reach among
                  the target users. Let’s grow together!
                </p>
              </Card>
            </div>
            <div className="col-md-4 ipad-full-width">
              <Card className="we-do-content">
                <img src={require("../../images/aboutusicon2.png").default} />
                <h5>AceTheCase</h5>
                <p>
                  Series of 12-15 workshops taken by previous winners of
                  prestigious competitions and case experts. We discuss the
                  problem statement (case) with its winning solution focusing on
                  how (approach) instead of just what (solution), helping users
                  to formulate the thought process. We also present the winning
                  decks to help students understand the art of making
                  presentations and ace case competitions. We mentor our
                  subscribers providing personalized feedback to help improve on
                  their solutions. For the team reaching the finals, we also
                  provide mentoring from Industry experts and Professionals!
                </p>
              </Card>
            </div>
            <div className="col-md-4 ipad-full-width">
              <Card className="we-do-content">
                <img src={require("../../images/aboutusicon3.png").default} />
                <h5>AceTheCase @ Campus</h5>
                <p>
                  Workshop taken by the co-founders themselves at individual
                  institutions highlighting the importance of case competitions
                  and sharing their experiences. We present the preliminary
                  ground work required for any case, ways to conduct research,
                  preparation of one slider, key frameworks, and overall
                  structure with various integral parts of a case solution. We
                  also display multiple winning presentations prepared by
                  co-founders for various case competitions and discuss solution
                  of an actual case.
                </p>
              </Card>
            </div>

            <div className="col-md-4 ipad-full-width desktop-height-505">
              <Card className="we-do-content">
                <img src={require("../../images/aboutusicon4.png").default} />
                <h5>Uncase The Case</h5>
                <p>
                  2 day workshop on What, Why & How of Case Solving for
                  interviews. We discuss the importance of solving business
                  cases, various types of cases, approach to solve them
                  effectively, Guesstimate as a variant of solving cases and a
                  sample guesstimate. Moving into finer details, we also discuss
                  a business case end to end in interview set up (role play).
                </p>
              </Card>
            </div>
            <div className="col-md-4 ipad-full-width desktop-height-505">
              <Card className="we-do-content">
                <img src={require("../../images/aboutusicon5.png").default} />
                <h5>Consulting Bootcamp</h5>
                <p>
                  2 day action packed intensive bootcamp with 8 workshops to
                  support your consulting interview preparation which includes
                  introduction to the consulting world, various types of cases
                  and their individual approach, Personal interview experiences,
                  Tips for networking /buddy calls/ D-Day etc. These sessions
                  are taken by experts from MBB with rich experience of
                  interviews. Learn firsthand from the best and become a Pro!
                </p>
              </Card>
            </div>
            <div className="col-md-4 ipad-full-width desktop-height-505">
              <Card className="we-do-content">
                <img src={require("../../images/aboutusicon6.png").default} />
                <h5>Campus Case Cracker</h5>
                <p>
                  Series of 4 workshops with the most competitive leaders
                  focused on cracking B-school case competitions. Individual
                  sessions are focused on different types of competitions
                  ranging from Marketing, Strategy, Product Management, Business
                  plans, Simulations etc. along with tips on cracking the
                  eliminator quizzes with their individual experts. We will also
                  exhibit the winning solutions of top notch B school
                  competitions.
                </p>
              </Card>
            </div>
          </div>
          <div className="row"></div>
        </div>

        {/* <div className="about-founders pt-4">
          <div className="founders-heading">
            <h3>Leaders</h3>
          </div>
        
          <div className="container">
          <div className="col-md-10 offset-1 founder-section">
          
            <div className="row  ">
          
              <div className="col-md-6 text-center ameer-iqbal">
              <div className=" founder-sec">
                 <div className="mb-3"> <img src={require("../../images/amar-iqbal.png").default} /> </div>
                 <div className=" ">
                    <div className=" ">
                   <h4 className="mt-3">  Ameer Iqubal </h4>
                   <p>  Ameer Iqubal, an alumnus of IIM Bangalore, IIT Kharagpur and AMU, is a strong proponent of solving business cases in academics. He is cross-functional business.. </p> </div>
                   <div className="read-more-founder ">
                      <a  data-toggle="modal" data-target="#exampleModalCenter"> <i className="fa fa-plus" aria-hidden="true"></i> </a> <a href="https://www.linkedin.com/in/ameeri/"> <i className="fab fa-linkedin-in"></i>   </a>
                     </div>
                    
                 </div>
                   </div>
              </div>


              <div className="col-md-6 text-center ayshman-sikha">
              <div className=" founder-sec">
                 <div className="mb-3"> <img src={require("../../images/ayshmn.png").default} /> </div>
                 <div className=" ">
                    <div className=" ">
                   <h4  className="mt-3">  Ayushmn Sikka </h4>
                   <p> Ayushmn Sikka, an alumnus of NMIMS and Panjab University, strongly believes in formalizing knowledge transfer. Being a fresher in his MBA journey, he focused..  </p> </div>
                   <div className="read-more-founder ">
                      <a  data-toggle="modal" data-target="#ayshman"> <i className="fa fa-plus" aria-hidden="true"></i> </a> <a href="https://www.linkedin.com/in/ayushmn-sikka-b744aa164/"> <i className="fab fa-linkedin-in"></i>   </a>
                     </div>
                 </div>
                   </div>
              </div>


              </div>



             



            </div>
          </div>


        </div> */}

        {/* <div className="about-team">
          <div className="about-team-title mt-5 pt-4">
            <h3>Our Team</h3>
          </div>
          <div className="container ">
  
            <div className="row  contentcntr">
                       <div className="col-md-4">
                           <a href="">
                         <div className="members-box">                
                           <img  src={require("../../images/vaidya2.jpeg").default} className="img-fluid" alt="" /> 
                            <div className="member-box-top-txt">
                            <h4 className="mt-3"> Abhishek Gaurav </h4>
                            <h5>Head, Strategy </h5>
                           <p className="member-social"> <a href="https://www.linkedin.com/in/abhishek-gaurav-02071261"> <i className="fab fa-linkedin"></i> </a></p>
                           </div>
                                      
                         </div>
                         </a>
                   </div>



                   <div className="col-md-4">
                           <a href="">
                         <div className="members-box">                
                           <img  src={require("../../images/vaidya.png").default} className="img-fluid" alt="" /> 
                            <div className="member-box-top-txt">
                            <h4 className="mt-3"> Rashmi Singh </h4>
                            <h5>Case Advisor</h5>
                           <p className="member-social"> <a href="https://www.linkedin.com/in/rashmi-singh-449851103"> <i className="fab fa-linkedin"></i> </a></p>
                           </div>
                                      
                         </div>
                         </a>
                   </div>
                   </div>

                   <div className="row">
                   <div className="col-md-4">
                           <a href="">
                         <div className="members-box">                
                           <img  src={require("../../images/ajay.jpeg").default} className="img-fluid" alt="" /> 
                            <div className="member-box-top-txt">
                            <h4 className="mt-3"> Ajay Yadav </h4>
                            <h5>Program Manager</h5>
                           <p className="member-social"> <a href="https://www.linkedin.com/in/ajayyadav7"> <i className="fab fa-linkedin"></i> </a></p>
                           </div>
                                      
                         </div>
                         </a>
                   </div>

                   <div className="col-md-4">
                           <a href="">
                         <div className="members-box">                
                           <img  src={require("../../images/mansi.jpeg").default} className="img-fluid" alt="" /> 
                            <div className="member-box-top-txt">
                            <h4 className="mt-3"> Mansi Bhardwaj</h4>
                            <h5>Program Manager</h5>
                           <p className="member-social"> <a href="https://www.linkedin.com/in/mansibhardwaj/"> <i className="fab fa-linkedin"></i> </a></p>
                           </div>
                                      
                         </div>
                         </a>
                   </div>

                   <div className="col-md-4">
                           <a href="">
                         <div className="members-box">                
                           <img  src={require("../../images/vaidya4.png").default} className="img-fluid" alt="" /> 
                            <div className="member-box-top-txt">
                            <h4 className="mt-3"> Vaibhav Goel</h4>
                            <h5>Program Manager</h5>
                           <p className="member-social"> <a href="https://www.linkedin.com/in/vaibhav-goel-53557111b/"> <i className="fab fa-linkedin"></i> </a></p>
                           </div>
                                      
                         </div>
                         </a>
                   </div>
                  

            </div>
          </div>
        </div> */}
      </div>

      {/*   <!-- Modal start--> */}
      <div
        className="modal fade founder-modal"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered  modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                <img
                  style={{ width: "30px" }}
                  src={require("../../images/amar-iqbal.png").default}
                />{" "}
                Ameer Iqubal{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Ameer Iqubal, an alumnus of IIM Bangalore, IIT Kharagpur and AMU,
              is a strong proponent of solving business cases in academics. He
              is cross-functional business leader with more than 10 years of
              experience in Supply chain across e-commerce and energy industry
              During his 2 year MBA stint, he participated in 100+ case
              competitions and aced many of them e.g. Cisco, Mahindra, Flipkart,
              HCCB, Tavaga, Bajaj Finserv, Colgate, TUP etc for which he was
              awarded Most Competitive B-School Leader 2020 by Dare2Compete .
              InsideIIM also recognized him amongst India’s best 50 employable
              managers (batch of 2020) for his grit, leadership & problem
              solving skills.
            </div>
            {/*  <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div> */}
          </div>
        </div>
      </div>

      {/*   <!-- Modal end--> */}

      {/*   <!-- Modal start--> */}
      <div
        className="modal fade founder-modal"
        id="ayshman"
        tabIndex="1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered  modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                <img
                  style={{ width: "30px" }}
                  src={require("../../images/ayshmn.png").default}
                />{" "}
                Ayushmn Sikka{" "}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              Ayushmn Sikka, an alumnus of NMIMS and Panjab University, strongly
              believes in formalizing knowledge transfer. Being a fresher in his
              MBA journey, he focused strongly on Case Competitions to maximize
              his career opportunities and participated in many of them. He was
              adjudged as one among Top 10 D2C College Champions (2020) for
              National Runner Up in Asian Paints CANVAS and National 2nd Runner
              Up in HUL LIME XI.
            </div>
            {/*  <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div> */}
          </div>
        </div>
      </div>

      {/*   <!-- Modal end--> */}

      <Footer />
    </React.Fragment>
  );
}

export default Aboutus;
