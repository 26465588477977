import React, { useState, useEffect } from "react";
import "./Managequiz.css";
import QuizTab from "./QuizTab";
import QuizSettingPage from "./QuizSettingPage";
import Header from "../../CommonFiles/Header";
import Footer from "../../CommonFiles/Footer";
import config from "../../CommonFiles/config.json";
import AddQuestions from "./AddQuestions";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

function Managequiz(props) {
  const [activeStep, setActiveStep] = useState();
  useEffect(() => {
    console.log("quizStep");
    let activestatus = localStorage.getItem("quizStep");

    if (activestatus === null) {
      localStorage.setItem("quizStep", 1);
      setActiveStep("1");
    } else {
      localStorage.setItem("quizStep", activestatus);
      setActiveStep(activestatus);
    }
  }, [activeStep]);

  const activeSteps = (num) => {
    setActiveStep(num);
  };

  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        <QuizTab />

        {activeStep === "1" && (
          <QuizSettingPage
            // editStatus={props.location.state}
            quizIndexval={props.quizIndexval}
            newActiveStepValue={activeSteps}
          />
        )}

        {activeStep === "2" && (
          <AddQuestions
            // editStatus={props.location.state}
            quizIndexval={props.quizIndexval}
            newActiveStepValue={activeSteps}
            closeBtn={props.closeBtn}
          />
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Managequiz;
