import React from "react";
import "./OpportunitiesLeftSide.css";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Form, Card, Button } from "react-bootstrap";

const filterMenuClose = (props) => {
  var wbLeftSide = document.querySelector(".wb-leftside");
  wbLeftSide.classList.remove("showingFilter");
};

const filterbysearch = (e) => {
  console.log("filter value", e.target.value);
};

function OpportunitiesLeftSide(props) {
  return (
    <div className="opportunities-Leftside cstm-checkbox">
      <div>
        <div className="ol-filter">
          <div className="category-heading">
            <span>
              Filters{" "}
              <span
                className="filterCloseBtn"
                onClick={() => filterMenuClose()}
              >
                {" "}
                <i className="fas fa-times-circle"></i>{" "}
              </span>{" "}
            </span>
          </div>

        </div>

        <div className="ol-status">
          <div className="category-heading">
            <div className="filterHeader">
              <p>Status</p>
              {/* <span className='rightborder'></span> */}
            </div>
            <div className="form-check form-check-block pl-0">
              <input
                className="form-check-input"
                name="filter"
                type="radio"
                onChange={(e) => props.filterbydate(e)}
                id="all"
                value="all"
              />
              <label className="form-check-label" for="all">
                All
              </label>
            </div>
            <div className="form-check form-check-block pl-0">
              <input
                className="form-check-input"
                name="filter"
                type="radio"
                onChange={(e) => props.filterbydate(e)}
                id="live"
                value="live"
              />
              <label className="form-check-label" for="live">
                Live
              </label>
            </div>
            <div className="form-check form-check-block pl-0">
              <input
                className="form-check-input"
                name="filter"
                type="radio"
                onChange={(e) => props.filterbydate(e)}
                id="expired"
                value="expired"
              />
              <label className="form-check-label" for="expired">
                Expired
              </label>
            </div>
          </div>

          {/*  <Form>
              <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" inline label="All" />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" inline label="Live" />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" inline label="Expired" />
              </Form.Group>
            </Form> */}
        </div>

        <div className="ol-status">
          <div className="category-heading">
            <div className="filterHeader">
              <p>Payments</p>
              {/* <span className='rightborder'></span> */}
            </div>

            <div className="form-check form-check-block pl-0">
              <input
                className="form-check-input"
                type="radio"
                name="filter"
                onChange={(e) => props.filterdata(e)}
                id="allPayment"
                value="all"
              />
              <label className="form-check-label" for="allPayment">
                All
              </label>
            </div>
            <div className="form-check form-check-block pl-0">
              <input
                className="form-check-input"
                type="radio"
                name="filter"
                onChange={(e) => props.filterdata(e)}
                id="paid"
                value="1"
              />
              <label className="form-check-label" for="paid">
                Paid
              </label>
            </div>
            <div className="form-check form-check-block pl-0">
              <input
                className="form-check-input"
                type="radio"
                name="filter"
                onChange={(e) => props.filterdata(e)}
                id="free"
                value="0"
              />
              <label className="form-check-label" for="free">
                Free
              </label>
            </div>
          </div>
          {/*  <Form>
              <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" inline label="All" />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" inline label="Paid" />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" inline label="Free" />
              </Form.Group>
            </Form> */}
        </div>

        <div className="ol-status eligibility">
          <div className="category-heading">
            <div className="filterHeader">
              <p>Eligible</p>
              {/* <span className='rightborder'></span> */}
            </div>
            <div className="form-check pl-0">
              <input
                className="form-check-input"
                value="all"
                type="radio"
                name="filter"
                onChange={(e) => props.filterbyeligible(e)}
                id="eligibleAll"
              />
              <label className="form-check-label" for="eligibleAll">
                All
              </label>
            </div>
            <div className="form-check pl-0">
              <input
                className="form-check-input"
                type="radio"
                name="filter"
                onChange={(e) => props.filterbyeligible(e)}
                value="Working Professionals"
                id="workingProfessionals"
              />
              <label className="form-check-label" for="workingProfessionals">
                Working Professionals
              </label>
            </div>
            <div className="form-check pl-0">
              <input
                className="form-check-input"
                type="radio"
                name="filter"
                onChange={(e) => props.filterbyeligible(e)}
                value="Startups"
                id="startups"
              />
              <label className="form-check-label" for="startups">
                Startups
              </label>
            </div>
            <div className="form-check pl-0">
              <input
                className="form-check-input"
                type="radio"
                name="filter"
                onChange={(e) => props.filterbyeligible(e)}
                value="School Students"
                id="schoolStudents"
              />
              <label className="form-check-label" for="schoolStudents">
                School Students
              </label>
            </div>
            <div className="form-check pl-0">
              <input
                className="form-check-input"
                type="radio"
                name="filter"
                onChange={(e) => props.filterbyeligible(e)}
                value="student"
                id="collageStudents"
              />
              <label className="form-check-label" for="collageStudents">
                College Students
              </label>
            </div>
          </div>
        </div>

        {/* <div className="ol-domain">
            <div className="category-heading">
              <p>Domains</p>
            </div>
            <Form>
              <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" label="Arts and Science" />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" label="Business" />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" label="Engineering" />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
              <Form.Check type="checkbox" label="Others" />
              </Form.Group>
            </Form>
         </div> */}
      </div>
    </div>
  );
}

export default OpportunitiesLeftSide;
