import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Opportunitieslogo from "../../../images/opportunities.png";
import { Col, Row, Container } from "react-bootstrap";
import "./Cards.css";
import CountUp from "react-countup";

const Cards = (props) => {
  let [cart, setCart] = useState(props);

  console.log("props.cart}", props.boxlists);
  return (
    <Container className=" my-md-5 pb-mb-4 counter-boxes">
      <Row className="desktop-card">
        <Col sm="12" xs="12" md="2"></Col>
        <Col md="8">
          <div className="row justify-content-center formobilecardcls">
            {props.boxlists.map((item, index) => {
              return (
                <div className={`cstm-boxes col-4 `}>
                  <div className={`Opportunities boxcss box${index + 1}`}>
                    <div>
                      <img className="card-icon" src={item.image} />
                    </div>
                    <p className="card-subtitle">
                      <CountUp delay={1} end={parseInt(item.subtitle)} />+
                    </p>
                    <h5 className="card-title mb-0">{item.title}</h5>
                  </div>
                </div>
              );
            })}
          </div>
        </Col>
        <Col md="2"></Col>
      </Row>

      {/* <Row className="mobile-card">
            {props.boxlists.map((item, index) => {
              return (
                <div className={`cstm-boxes col-md-4 col-4`}>
                  <div className={`Opportunities boxcss box${index+1}`}>
                    <div>
                      <img className="card-icon" src={item.image} />
                    </div>
                    <p className="card-subtitle">
                      <CountUp delay={1} end={parseInt(item.subtitle)} />+
                    </p>
                    <h5 className="card-title mb-0">{item.title}</h5>
                  </div>
                </div>
              );
            })} */}
      {/* </Row> */}
    </Container>
  );
};
export default Cards;
