import React from "react";
import successimg from "../../images/Success.png";
import Footer from "../CommonFiles/Footer";
import Header from "../CommonFiles/Header";
import "./static.css";

function Success(props) {
  const [memberData, setMemberData] = React.useState(
    JSON.parse(localStorage.getItem("memberData")) || []
  );

  React.useEffect(() => {
    setMemberData(JSON.parse(localStorage.getItem("memberData")));
  }, []);

  window.scrollTo({
    top: 20,
    behavior: "smooth",
  });

  const gotoOpportunity = () => {
    props.history.push("/e/" + localStorage.getItem("eventslug"), {
      id: 7,
      color: "green",
    });
  };

  return (
    <React.Fragment>
      <Header />
      <div className="success-page">
        <div className="">
          <div className="successimg">
            <img src={successimg} alt="logo" className="logo" />
          </div>
          <span className="successpagemsg">
            You have registered successfully <br /> for the opportunity!{" "}
          </span>
          <table id="customers">
            <thead>
              <th>Team Name</th>
              <th>Member Name</th>
              {memberData && memberData.length > 1 && <th>Member Type</th>}
              <th>Email</th>
              <th>Gender</th>
              <th>Number</th>
            </thead>
            {memberData &&
              memberData.map((item, index) => (
                <tr key={index}>
                  <td>{item.team_name}</td>
                  <td>{item.members_name}</td>
                  {memberData.length > 1 && (
                    <td>{index === 0 ? "Leader" : "Member"}</td>
                  )}
                  <td>{item.members_email}</td>
                  <td>{item.gender}</td>
                  <td>{item.members_mobile_no}</td>
                </tr>
              ))}
          </table>
          <button
            className="btn menubtn fr mt-5"
            onClick={() => gotoOpportunity()}
          >
            View opportunity
          </button>
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Success;
