import React, { useEffect, useRef, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";

const LeftSideBar = (props) => {
  const { menuItems = [] } = props;
  console.log("all menu links", menuItems);
  // state
  console.log(props);
  const [selected, setSelectMenuItem] = useState(menuItems[0].name);
  const menuItemsJSX = menuItems.map((item, index) => {
    let active = "";

    const isItemSelected = selected === item.name;
    console.log("menu check 1", item.to);
    let urlElements = window.location.href.split("/");
    if (window.location.pathname === item.to) {
      active = "active";
    }

    console.log("menu check", window.location.pathname);
    return (
      <li className={active} key={index}>
        {" "}
        <Link to={item.to} className="nav-link">
          {" "}
          <i className={item.icon} aria-hidden="true"></i> &nbsp; {item.name}{" "}
        </Link>{" "}
      </li>
    );
  });
  return (
    <nav className="left-sidebar">
      <div className="sticky-sidebar">
        <h4 className="color-white mb-3"> GRAD PARTNER </h4>
        <ul className="">
          <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDarkDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Dropdown
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-dark"
                  aria-labelledby="navbarDarkDropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default LeftSideBar;
