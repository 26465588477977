import React from "react";
import { Switch, Route } from "react-router-dom";
import Userprofile from "./Userprofile";
import Settings from "./Settings";
import Useropportunity from "./Useroppportunity";
import Submitionlist from "./Submitionlist";
import { Editprofile } from "./Editprofile";
import Allregistereduser from "./Allregistereduser";
import Editevent from "./Editevent";
import Addquiz from "./Addquiz";
import discount from "./discount";
import Discountlist from "./discountlist";
import Userhosting from "./UserHosting";
import opportunityDashboard from "../Dashboard/opprtunityDashboard";
import Round from "../Dashboard/Rounds";
import Allregistrationlist from "../Dashboard/Registrationlist";
import NotificationManagement from "./NotificationManagement";
import EditRegistrationform from "./EditRegistration";
import Managequiz from "./quizManagement/ManageQuiz";
const UserRoutes = () => {
  return (
    <Switch>
      <Route exact path="/user" component={Userprofile} />
      <Route
        exact
        path="/user/myregistration/:id"
        component={EditRegistrationform}
      />
      <Route exact path="/user/quizsetting" component={Managequiz} />
      <Route exact path="/user/hosting" component={Userhosting} />
      <Route
        exact
        path="/user/notification-anagemant/:id"
        component={NotificationManagement}
      />
      <Route
        exact
        path="/user/opportunity/dashboard/:id"
        component={opportunityDashboard}
      />
      <Route
        exact
        path="/user/opportunity/dashboard/rounds/:id"
        component={Round}
      />
      <Route
        exact
        path="/user/opportunity/dashboard/regstration/list/:id"
        component={Allregistrationlist}
      />
      <Route exact path="/user/opportunity" component={Useropportunity} />
      <Route exact path="/user/setting" component={Settings} />
      <Route
        exact
        path="/user/event/submissions/:id"
        component={Submitionlist}
      />
      <Route exact path="/user/profile/edit/:id" component={Editprofile} />
      <Route
        exact
        path="/user/profile/event/allteam/:id"
        component={Allregistereduser}
      />
      <Route exact path="/user/profile/event/edit/:id" component={Editevent} />
      <Route exact path="/user/profile/event/quiz/:id" component={Addquiz} />
      <Route
        exact
        path="/user/profile/event/dicount/:id"
        component={discount}
      />
      <Route
        exact
        path="/user/profile/event/dicount-list/:id"
        component={Discountlist}
      />
    </Switch>
  );
};

export default UserRoutes;
