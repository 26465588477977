import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import config from "../CommonFiles/config.json";
import "../DynamicPages/LogIn.css";

const mainURL = Object.values(config["URL"]).join("");

function ResetPasswordModal(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfimPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [showPasswordIcon, setShowPasswordIcon] = useState(false);
  const [showConfirmPassIcon, setShowConfirmPassIcon] = useState(false);
  const [isResetBtnDisabled, setIsResetBtnDisabled] = useState(true);
  const urlParams = useParams();

  useEffect(() => {
    if (
      ((urlParams.id === undefined || urlParams.id === null) &&
        (urlParams.token === undefined || urlParams.token === null)) ||
      window.localStorage.getItem("alldata") !== null
    ) {
      props.onClose();
    }
  }, [urlParams, props]);

  useEffect(() => {
    if (password === "" || confirmPassword === "") {
      setIsResetBtnDisabled(true);
    }
  }, [password, confirmPassword]);

  function handleResetPassword() {
    if (password.length === 0 || confirmPassword.length === 0) {
      setErrorMsg("Both fields are required");
    } else {
      setIsResetBtnDisabled(true);
      axios({
        method: "post",
        url: mainURL + "/" + urlParams.id + "/" + urlParams.token,
        data: {
          password: password,
        },
      })
        .then((response) => {
          if (response.data.status === 0) {
            setErrorMsg(response.data.reason);
          } else {
            setSuccessMsg("Your password has been updated.");
          }
        })
        .catch(function () {
          setErrorMsg("Sorry, there was an error resetting your password");
        })
        .finally(() => {
          setPassword("");
          setConfimPassword("");
          setIsResetBtnDisabled(false);
        });
    }
  }

  const handlePasswordEdit = (e) => {
    setPassword(e.target.value);
    if (e.target.value !== confirmPassword) {
      setIsResetBtnDisabled(true);
      confirmPassword.length &&
        setPasswordMatchError(
          "'Confirm Password' field should match with the 'Password' field."
        );
    } else {
      setPasswordMatchError("");
      setIsResetBtnDisabled(false);
    }
  };

  const handleConfirmPasswordEdit = (e) => {
    setConfimPassword(e.target.value);
    if (e.target.value !== password) {
      setIsResetBtnDisabled(true);
      setPasswordMatchError(
        "'Confirm Password' field should match with the 'Password' field."
      );
    } else {
      setPasswordMatchError("");
      setIsResetBtnDisabled(false);
    }
  };

  const showPassword = (fieldName) => {
    const passwordField = document.querySelector(fieldName);
    if (passwordField.type === "password") {
      passwordField.type = "text";
      fieldName === ".password-field"
        ? setShowPasswordIcon(true)
        : setShowConfirmPassIcon(true);
    } else {
      passwordField.type = "password";
      fieldName === ".password-field"
        ? setShowPasswordIcon(false)
        : setShowConfirmPassIcon(false);
    }
  };

  return (
    <Modal
      show={props.isOpen}
      className="loginpopup"
      backdrop="static"
      keyboard={false}
    >
      <div>
        <span aria-hidden="true" className="popupclose" onClick={props.onClose}>
          &times;
        </span>
        <h1>Reset password </h1>
        <p className="reset-new-pass">
          Your new password must be different from previous password
        </p>
        <hr className="customhr" />
        <br />
        {errorMsg && <p className="reset-error-msg">{errorMsg}</p>}
        {successMsg && (
          <p className="reset-success-msg">
            {`${successMsg} Please `}
            <span
              className="login-link"
              onClick={() => {
                props.onLoginClick();
                props.onClose();
              }}
            >
              login
            </span>
            {` to continue!`}
          </p>
        )}
        <label>Password</label>
        <input
          type="password"
          onChange={handlePasswordEdit}
          className="form-control password-field register-body-form"
          placeholder="Create New password"
        />
        {showPasswordIcon ? (
          <i
            className="fas fa-eye newpass-show-pw"
            onClick={() => showPassword(".password-field")}
          ></i>
        ) : (
          <i
            className="fas fa-eye-slash newpass-show-pw"
            onClick={() => showPassword(".password-field")}
          ></i>
        )}
        <br />
        <label>Confirm Password</label>
        <input
          type="password"
          onChange={handleConfirmPasswordEdit}
          className="form-control confirm-password-field register-body-form"
          placeholder="Confirm Password"
        />
        {showConfirmPassIcon ? (
          <i
            className="fas fa-eye newpass-show-pw"
            onClick={() => showPassword(".confirm-password-field")}
          ></i>
        ) : (
          <i
            className="fas fa-eye-slash newpass-show-pw"
            onClick={() => showPassword(".confirm-password-field")}
          ></i>
        )}
        {passwordMatchError && (
          <p className="reset-password-match-error">{passwordMatchError}</p>
        )}
        <button
          onClick={handleResetPassword}
          className="resetBtn"
          disabled={isResetBtnDisabled}
        >
          Reset Password
        </button>
        <br />
        <br />
      </div>
    </Modal>
  );
}

export default ResetPasswordModal;
