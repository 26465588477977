import React, { useEffect, useState } from "react";
import TabItem from "react-bootstrap/Tab";
import TabsContainer from "react-bootstrap/Tabs";

const Tab = (props) => {
  const [key, setKey] = useState(props.tabData[0].title);

  useEffect(() => {
    console.log(props, "user");
    setKey(props.ActiveTab);
  });

  return (
    <>
      <TabsContainer
        activeKey={key}
        onSelect={(k) => props.setKey(k)}
        className="mb-5 cstm__tab"
      >
        {props.tabData.map((val) => {
          return (
            <TabItem key={val.id} eventKey={val.title} title={val.title}>
              {props.userdata}
            </TabItem>
          );
        })}
      </TabsContainer>
    </>
  );
};

export default Tab;
