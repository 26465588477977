import React from "react";
import Header from "../../CommonFiles/Header";
import Footer from "../../CommonFiles/Footer";
import successimg from "../../../images/opportunitySuccess.png";

// import '../../static.css';
let allteam = JSON.parse(localStorage.getItem("opportunitydata"));
function quizSuccess(props) {
  window.scrollTo({
    top: 20,
    behavior: "smooth",
  });

  console.log("allteam", allteam);

  const gotoOpportunity = () => {
    props.history.push("/e/" + allteam.slug);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="qiz-success-page">
        <div className="msgtitle">
          <img src={successimg} className="mt-5" /> <br />
          <h3>
            <br />
            Thank you for participating in the {allteam.title} competition.
            <br />
            All the best for results!
          </h3>
          <div>
            <button
              onClick={() => {
                gotoOpportunity();
              }}
              className="btn menubtn hostingbtnfordesktop"
            >
              View Opportunity Page
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default quizSuccess;
