import React, { useEffect, useState } from "react";
import "./Usercss.css";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Footer from "../CommonFiles/Footer";
import Header from "../CommonFiles/Header";
import Modal from "react-bootstrap/Modal";

import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import "./profile.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "./Components/Utils/Button";
import Tab from "./Components/Utils/tabs";
import Select from "react-select";
import {
  Button as Antbtn,
  Checkbox,
  Form as AntForm,
  Input,
  Radio,
} from "antd";
const mainurl = Object.values(config["URL"]);
const URLs = mainurl.join("");
const mainURL = URLs;
var Successmsg;

const Editprofile = (props) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState({
    passowrd: "",
    confirmpass: "userdata?.github_profile",
  });
  const [passwordmsg, setPasswordmsg] = useState("");
  const [msg, setMsg] = useState("");
  const [username, setUserName] = useState("");
  const [confirmpass, setConfirmpass] = useState("");
  const [deletmodal, setDeletmoda] = useState(false);
  const [errormsg, setErrorpass] = useState("");
  const [validation, setValidation] = useState(0);
  const [activeTab, setActiveTab] = useState("General");
  const [tabContent, setTabContent] = useState();
  const [userdata, setUserdata] = useState(
    JSON.parse(localStorage.getItem("alldata")),
  );
  const [imageview, setPreviewlogo] = useState("");

  const totalProgress = 60;
  const USER_TOKEN = localStorage.getItem("token");
  const AuthStr = "Bearer ".concat(USER_TOKEN);
  const tabName = [
    { id: 1, title: "General", desciption: "General Data" },
    { id: 2, title: "Password", desciption: "Password Data" },
    {
      id: 3,
      title: "College Information",
      desciption: "College Information Data",
    },
    { id: 4, title: "Social Profiles", desciption: "Social Profiles Data" },
  ];
  const [organisationData, setOrganisationData] = useState({
    user_type: userdata?.user_type,
    college: userdata?.college,
    domain: userdata?.domain,
    course: userdata?.course,
    organization: userdata?.organization,
    specialization: userdata?.specialization,
    college_id: userdata?.college_id,
    year_of_study: userdata?.year_of_study,
    graduation_year: userdata?.graduation_year,
  });

  const [inputValue, setInputValue] = useState({
    first_name: userdata?.first_name,
    last_name: userdata?.last_name,
    email: userdata?.email,
    mobile: userdata?.mobile,
    organization: userdata?.organization,
    gender: userdata?.gender,
  });
  const [socialLinks, setSocialLinks] = useState({
    linkedin_profile: userdata?.linkedin_profile,
    github_profile: userdata?.github_profile,
  });
  const [error, setError] = useState([]);

  useEffect(() => {
    setTabContent(profileTabContent);
  }, []);

  function updateProfile(value) {
    console.log("inputValue d", value);
    // return false;

    axios({
      method: "post",
      url: mainURL + "/profile",
      headers: { Authorization: AuthStr },
      data: value,
    })
      .then((response) => {
        // setMsg("Profile has been updated.");
        // Successmsg = "Profile has been updated.";
        if (response.data.status === 1) {
          Swal.fire("Good job!", "Profile has been updated", "success");
          userApi();
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  const userApi = () => {
    axios({
      method: "get",
      url: mainURL + "/profile",
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldatas = response.data.data[0];
        localStorage.setItem("alldata", JSON.stringify(alldatas));
        setUserdata(JSON.parse(localStorage.getItem("alldata")));
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  function gotoedit(params) {
    props.history.push(`/user/profile/edit/` + params);
  }
  const specializationOptions = [
    { value: "workshop", label: "workshop" },
    { value: "opportunity", label: "opportunity" },
  ];

  const profileTabContent = (
    <AntForm
      name="basic"
      className="form-group-col col-md-12"
      onFinish={updateProfile}
      layout="vertical"
      autoComplete="off"
    >
      <div className="bg-white collage_info">
        <h5 className="errormsg">{msg && msg}</h5>
        <div className="row">
          <div className="col-md-6">
            <AntForm.Item
              label="First Name"
              name="first_name"
              initialValue={inputValue.first_name}
              rules={[
                {
                  required: true,
                  message: "Please input your First Name",
                },
              ]}
            >
              <Input className="form-control register-body-form-edit" />
            </AntForm.Item>
          </div>

          <div className="col-md-6">
            <AntForm.Item
              label="Last Name"
              name="last_name"
              initialValue={inputValue.last_name}
              rules={[
                {
                  required: true,
                  message: "Please input your Last Name",
                },
              ]}
            >
              <Input className="form-control register-body-form-edit" />
            </AntForm.Item>
          </div>
        </div>

        <br />
        <AntForm.Item
          name="email"
          label="E-mail"
          initialValue={inputValue.email}
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input className="form-control disabled" disabled/>
        </AntForm.Item>

        <br />
        <AntForm.Item
          label="Mobile"
          name="mobile"
          initialValue={inputValue.mobile}
          rules={[
            {
              required: true,
              message: "Please input your Mobile number",
            },
          ]}
        >
          <Input className="form-control register-body-form-edit" />
        </AntForm.Item>

        <br />
        <div className={error.gender && "  errormsgborder"}>
          <Form.Row className="noneborder">
            <Col
              className="form-group-col"
              md={12}
              controlId="oppotunityEndTime"
            >
              <AntForm.Item
                name="gender"
                label="Gender"
                initialValue={inputValue.gender}
                rules={[
                  {
                    required: true,
                    message: "Please Select Gender",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="male">Male</Radio>
                  <Radio value="female">Female</Radio>
                  <Radio value="others">Others</Radio>
                </Radio.Group>
              </AntForm.Item>
            </Col>
          </Form.Row>
        </div>

        <Antbtn type="primary" htmlType="submit" className="btn signupbtn">
          Save Changes
        </Antbtn>
      </div>
    </AntForm>
  );
  const changepass = (updatePass) => {
    if (updatePass.password != updatePass.confirmpass) {
      // setPasswordmsg("The password and confirmation password do not match.");
      Swal.fire(
        "Opps!",
        "The password and confirmation password do not match.",
        "error",
      );
      return;
    }

    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: mainURL + "/change-password",
      data: {
        password: updatePass.password,
        oldpassword: updatePass.oldpass,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("password res", response.data);
        setPasswordmsg("");
        if (response.data.status === 0) {
          Swal.fire("Oops!", response.data.reason, "error");
        } else {
          Swal.fire("Good job!", "Password updated successfully!", "success");
        }
      })
      .catch(function (response) {
        //handle error
      });
  };
  const onFinish = (values) => {
    console.log("Success:", values);
    changepass(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const passwordTabContent = (
    <div className="bg-white passwordChange">
      <div className="row">
        <h5 className="errormsg">{passwordmsg && passwordmsg}</h5>

        <AntForm
          name="basic"
          className="form-group-col col-md-12 editprofilepass"
          onFinish={onFinish}
          layout="vertical"
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Row>
            <Col
              className="form-group-col"
              md={12}
              controlId="formGridOpportunityTitle"
            >
              <AntForm.Item
                label="Old Password"
                name="oldpass"
                rules={[
                  {
                    required: true,
                    message: "Please input your old password!",
                  },
                ]}
              >
                <Input.Password className="form-control register-body-form-edit" />
              </AntForm.Item>
            </Col>
          </Form.Row>

          <AntForm.Item
            label="New Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              // {
              //   pattern: /^[\d]{8,20}$/,
              //   message: "Value should be less than 8 character",
              // },
            ]}
          >
            <Input.Password className="form-control register-body-form-edit" />
          </AntForm.Item>
          <AntForm.Item
            label="Confirm Password"
            name="confirmpass"
            rules={[
              {
                required: true,
                message: "Please Confirm password!",
              },
              // {
              //   pattern: /^[\d]{8,20}$/,
              //   message: "Value should be less than 8 character",
              // },
            ]}
          >
            <Input.Password className="form-control register-body-form-edit" />
          </AntForm.Item>
          <AntForm.Item>
            <Antbtn type="primary" htmlType="submit">
              Submit
            </Antbtn>
          </AntForm.Item>
        </AntForm>
      </div>
    </div>
  );

  const sociallinksupdate = (val, name) => {
    console.log("onchange", val, name);
    // setInputValue({...inputValue, [name]: val });
    const value = socialLinks;
    if (name == "linkedin_profile") {
      value.linkedin_profile = val;
    }
    if (name == "github_profile") {
      value.github_profile = val;
    }

    setSocialLinks(value);
  };
  const updateSociallinks = () => {
    console.log(
      "socialLinks.github_profile",
      socialLinks.github_profile,
      socialLinks.linkedin_profile,
    );
    if (
      socialLinks.github_profile === null ||
      socialLinks.linkedin_profile === null
    ) {
      return false;
    }
    axios({
      method: "post",
      url: mainURL + "/profile",
      headers: { Authorization: AuthStr },
      data: socialLinks,
    })
      .then((response) => {
        setMsg("Profile has been updated.");
        Successmsg = "Profile has been updated.";
        if (response.data.status === 1) {
          Swal.fire("Good job!", "Social links has been updated", "success");
          userApi();
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  const socialTabContent = (
    <div className="bg-white collage_info">
      {console.log("all data")}

      {/* <h5 className="errormsg">{msg && msg}</h5> */}
      <div className="row">
        <div className="col-md-12">
          <label>LinkedIn Profile Link</label>
          <input type="hidden" name="linkedin_profile" />
          <input
            type="text"
            name="linkedin_profile"
            defaultValue={
              socialLinks.linkedin_profile && socialLinks.linkedin_profile
            }
            onChange={(e) => sociallinksupdate(e.target.value, e.target.name)}
            className={
              error.first_name
                ? "form-control errormsgborder register-body-form-edit"
                : "form-control register-body-form-edit"
            }
          />
          {/* <i className="fa fa-user material-icons"></i> */}
        </div>

        <div className="col-md-12">
          <br />
          <br />
          <label>GitHub Profile Link</label>

          <input
            type="text"
            name="github_profile"
            value={socialLinks.github_profile}
            onChange={(e) => sociallinksupdate(e.target.value, e.target.name)}
            className={
              error.last_name
                ? "form-control errormsgborder register-body-form-edit"
                : "form-control register-body-form-edit"
            }
          />
        </div>
      </div>
      <br />
      <br />
      <button
        disabled={
          socialLinks.github_profile === "" ||
          socialLinks.linkedin_profile === ""
            ? "disabled"
            : ""
        }
        onClick={() => updateSociallinks()}
        className="btn btn-primary"
      >
        Save Changes
      </button>
    </div>
  );

  const organizationOnInputChange = (val, name) => {
    console.log("onchange", val, name);
    // setInputValue({...inputValue, [name]: val });
    const value = organisationData;
    if (name == "user_type") {
      value.user_type = val;
    }
    if (name == "college") {
      value.college = val;
    }
    if (name == "domain") {
      value.domain = val;
    }
    if (name == "course") {
      value.course = val;
    }
    if (name == "organization") {
      value.organization = val;
    }
    if (name == "specialization") {
      value.specialization = val;
    }
    if (name == "college_id") {
      value.college_id = val;
    }
    if (name == "year_of_study") {
      value.year_of_study = val;
    }
    if (name == "graduation_year") {
      value.graduation_year = val;
    }
    setOrganisationData(value);
  };
  //
  const updateOrganizationDetail = (value) => {
    axios({
      method: "post",
      url: mainURL + "/profile",
      headers: { Authorization: AuthStr },
      data: value,
    })
      .then((response) => {
        setMsg("Profile has been updated.");
        Successmsg = "Profile has been updated.";
        if (response.data.status === 1) {
          Swal.fire(
            "Good job!",
            "College information has been updated",
            "success",
          );
          userApi();
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  const organizationTab = (
    <AntForm
      className="collage_info"
      onFinish={updateOrganizationDetail}
      layout="vertical"
    >
      <Form.Row className={error.gender ? "  errormsgborder" : "noneborder"}>
        <Col className="form-group-col" md={12} controlId="oppotunityEndTime">
          <div
            name="user_type"
            onChange={(e) =>
              organizationOnInputChange(e.target.value, e.target.name)
            }
            className="checktitle"
          >
            <AntForm.Item
              name="user_type"
              label="User Type"
              initialValue={organisationData.user_type}
              rules={[
                {
                  required: true,
                  message: "Please Select User Type",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="student">College Student</Radio>
                <Radio value="professional">Working Professional</Radio>
                <Radio value="others">Others</Radio>
              </Radio.Group>
            </AntForm.Item>
          </div>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col
          className="form-group-col"
          md={12}
          controlId="formGridOpportunityTitle"
        >
          <AntForm.Item
            label="College/Organization"
            name="organization"
            initialValue={organisationData.organization}
            rules={[
              {
                required: true,
                message: "Please input your College/Organization Name",
              },
            ]}
          >
            <Input className="form-control register-body-form-edit" />
          </AntForm.Item>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col className="form-group-col" md={6} controlId="formGridCategory">
          <AntForm.Item
            label="Domain"
            name="domain"
            initialValue={organisationData.domain}
            placeholder="Engineering / Management / Others"
            rules={[
              {
                required: true,
                message: "Please input your Domain",
              },
            ]}
          >
            <Input className="form-control register-body-form-edit" />
          </AntForm.Item>
        </Col>

        <Col className="form-group-col" md={6} controlId="formGridCategory">
          <AntForm.Item
            label="Course"
            name="course"
            initialValue={organisationData.course}
            placeholder="Engineering / Management / Others"
            rules={[
              {
                required: true,
                message: "Please input your Course",
              },
            ]}
          >
            <Input className="form-control register-body-form-edit" />
          </AntForm.Item>
        </Col>
      </Form.Row>

      <Form.Row>
        <Col className="form-group-col" md={6} controlId="formGridCategory">
          <AntForm.Item
            label="Specialization"
            name="specialization"
            initialValue={organisationData.specialization}
            placeholder="Supply Chain / HR / Finance / Marketing / Others"
            rules={[
              {
                required: true,
                message: "Please input your Specialization",
              },
            ]}
          >
            <Input className="form-control register-body-form-edit" />
          </AntForm.Item>
        </Col>

        <Col
          className="form-group-col"
          md={6}
          controlId="formGridOpportunityTitle"
        >
          <AntForm.Item
            label="College Registration no/Employee ID:"
            name="college_id"
            initialValue={organisationData.college_id}
            placeholder="College Registration no/Employee ID"
          >
            <Input className="form-control register-body-form-edit" />
          </AntForm.Item>
        </Col>
      </Form.Row>

      <Form.Row className={error.gender ? "  errormsgborder" : "noneborder"}>
        <Col className="form-group-col" md={12} controlId="oppotunityEndTime">
          <AntForm.Item
            name="year_of_study"
            label="Year of Study"
            initialValue={organisationData.year_of_study}
            rules={[
              {
                required: true,
                message: "Please Select Year of Study",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="1st year">1st year</Radio>
              <Radio value="2nd year">2nd year</Radio>
              <Radio value="3rd year">3rd year</Radio>
              <Radio value="4th year">4th year</Radio>
              <Radio value="5th year">5th year</Radio>
            </Radio.Group>
          </AntForm.Item>
        </Col>
      </Form.Row>

      <Form.Row className={error.gender ? "  errormsgborder" : "noneborder"}>
        <Col className="form-group-col" md={12} controlId="oppotunityEndTime">
          <AntForm.Item
            name="graduation_year"
            label="Graduation Year"
            initialValue={organisationData.graduation_year}
            rules={[
              {
                required: true,
                message: "Please Select Year of Study",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="2024">2024</Radio>
              <Radio value="2025">2025</Radio>
              <Radio value="2026">2026</Radio>
              <Radio value="2027">2027</Radio>
              <Radio value="2028">2028</Radio>
            </Radio.Group>
          </AntForm.Item>
        </Col>
      </Form.Row>
      <Antbtn type="primary" htmlType="submit" className="btn signupbtn">
        Save Changes
      </Antbtn>
    </AntForm>
  );
  const setKey = (title) => {
    setActiveTab(title);
    if (title === "General") {
      setTabContent(profileTabContent);
    }
    if (title === "Password") {
      setTabContent(passwordTabContent);
    }
    if (title === "Social Profiles") {
      setTabContent(socialTabContent);
    }
    if (title === "College Information") {
      setTabContent(organizationTab);
    }
  };
  const formData = new FormData();

  const fileObj = [];
  const fileArray = [];
  const profilimageupload = (val, name, newval) => {
    console.log("data", name);
    const file = newval;
    let apidata;

    let imgRef = URL.createObjectURL(file);
    setPreviewlogo(imgRef);
    formData.append("profile_image", newval, name);
    apidata = {
      profile_image: formData,
    };
    axios({
      method: "post",
      url: mainURL + "/profile/image",
      data: formData,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;

        console.info("all analytics", alldata);
        userApi();
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  const deleteProfileimage = () => {
    console.log("under deelte");
    axios({
      method: "post",
      url: mainURL + "/profile",
      data: {
        profileimage: "",
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        // const alldata = response.data.data;

        console.info("all analytics", response);
        userApi();
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };
  const deleteprofile = () => {
    axios({
      method: "post",
      url: mainURL + "/profile",
      data: {
        is_deleted: 1,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        // const alldata = response.data.data;

        console.info("all analytixxcs", response);
        localStorage.removeItem("token");
        localStorage.removeItem("alldata");
        localStorage.clear();

        props.history.push(`/`);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };
  return (
    <Container className="pt-5 mt-5 pb-5 mb-5">
      <Row>
        <Col></Col>
        <Modal
          show={deletmodal}
          className="deletstatusmodal collage_info"
          // onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <div>
            <span
              aria-hidden="true"
              className="popupclose"
              // onClick={() => closeModal()}
            >
              &times;
            </span>
            <h1 className="mb-0">Delete My Account</h1>
            <p>We’re sorry to see you go.</p>

            <hr className="customhr" />
            <p>Deleting account will do the following:</p>
            <hr />
            <ul className="deletinstuction">
              <li>Log you out on all devices.</li>
              <li>Delete all of your account information.</li>
              <li>
                Account deletion is final. There will be no way to restore your
                account.
              </li>
            </ul>
            <div className="d-flex">
              <button
                type="primary"
                onClick={() => deleteprofile(userdata?.id)}
                htmlType="submit"
                className="btn signupbtn"
              >
                Delete Account
              </button>
              <button
                type="primary"
                htmlType="submit"
                onClick={() => setDeletmoda(false)}
                className="btn removemodal"
              >
                No, I’ve changed my mind
              </button>
            </div>
          </div>
        </Modal>
        <Col md={8} className="profile__detail__section">
          {/* Profile Detail Start */}
          <div className="flexwithcontent">
            <div>
              <h3>
                {userdata && userdata?.first_name + " " + userdata?.last_name} /
                Edit Profile
              </h3>
              <h6>Set up your personal & other information here</h6>
            </div>
            <div
              // onClick={() => deleteprofile(userdata?.id)}
              onClick={() => setDeletmoda(true)}
              className="cursorPointer"
            >
              <i className="fa fa-trash deleteicon" aria-hidden="true"></i>
              Delete My Profile
            </div>
          </div>
          <div className="profile__detail">
            <div className="profile__detail__sortname">
              {imageview ? (
                <img id="file-image" src={imageview} alt="Preview" />
              ) : userdata?.profileimage ? (
                <img
                  id="file-image"
                  src={userdata?.profileimage}
                  alt="Preview"
                />
              ) : (
                <h3>
                  {" "}
                  {userdata.first_name && userdata?.first_name.charAt(0)}{" "}
                  {userdata.first_name && userdata?.last_name.charAt(0)}{" "}
                </h3>
              )}
            </div>
            <div className="edit__profile">
              <Form.Row>
                <Col
                  className="form-group-col "
                  md={12}
                  controlId="oppotunityEndTime"
                >
                  <span className="uploader profileupdate">
                    <input
                      type="file"
                      id="file-upload"
                      accept="image/*"
                      name="logo_image"
                      value=""
                      onChange={(e) =>
                        profilimageupload(
                          e.target.files[0].name,
                          e.target.name,
                          e.target.files[0],
                        )
                      }
                    />
                    <label for="file-upload" id="file-drag">
                      <p>Upload New Picture</p>
                    </label>
                  </span>
                </Col>
                {/* <img src={imageview} /> */}
              </Form.Row>
            </div>
            <div
              className="edit__profile"
              onClick={() => deleteProfileimage(userdata?.id)}
            >
              <Button clsName="pink__btn" name="Delete" />
            </div>
          </div>
          {/* Profile Detail Start */}

          {/* Profile Complete Start */}
          <div className="progress__bar">
            <p>{userdata && userdata?.profile_completed}% Profile Completed </p>
            <ProgressBar
              variant="success"
              now={userdata && userdata?.profile_completed}
            />
          </div>
          <hr className="cstm__hrcolor" />
          <Tab
            tabData={tabName}
            userdata={tabContent}
            ActiveTab={activeTab}
            setKey={(k) => setKey(k)}
          />

          {/* Tab End */}
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
};

export { Editprofile, Successmsg };
