import React, { useEffect, useState } from "react";
import LeftSideBar from "./UserLeftSideBar";
import RightSidebar from "./UserRightSidebar";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import Moment from "react-moment";
import Toggle from "react-toggle";
import Swal from "sweetalert2";

import { Link } from "react-router-dom";
import "./Usercss.css";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const Useropportunity = (props) => {
  const [alldata, setAlldata] = useState([]);
  const [attachmentstatus, setAttachmentstatus] = useState();

  useEffect(async () => {
    // const tokencheck = localStorage.getItem("token")
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      axios({
        method: "get",
        url: mainURL + "/profile/opportunities",
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data.rows;
          setAlldata(alldatas);
          console.info("all opportunities", alldata);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  }, []);

  function changestatus(event, id) {
    console.log("event", event.target.checked);
    setAttachmentstatus(event.target.checked);
    let status;
    if (event.target.checked === true) {
      status = 1;
    } else {
      status = 0;
    }
    console.log("status", status);

    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "put",
      url: mainURL + "/opportunity/" + id,
      data: {
        show_attachment: status,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        // props.history.push(
        //   `/opportunities/detail/`+props.match.params.id
        // );
      })
      .catch(function (error) {
        //handle error
        // if(error.response.data.auth ===  false)
        // {
        //   Swal.fire({
        //     title: "Session has been expired  you will redirect to home page",
        //     icon: "warning",
        //     showCancelButton: false,
        //     confirmButtonColor: "#3085d6",
        //     allowOutsideClick: false,
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: "Continue",
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       let slugName = localStorage.getItem("eventslug")
        //       localStorage.clear();

        //       window.location.href='/'
        //     }
        //   });
        // }
        console.log("Error while calling api", error.response);
      });
  }

  function gottosubmition(params) {
    props.history.push(`/user/event/submissions/` + params);
  }
  function gottoteamlist(params) {
    props.history.push(`/user/profile/event/allteam/` + params);
  }
  function gotodetail(params) {
    props.history.push(`/user/profile/event/edit/` + params);
  }

  function gotodiscount(id) {
    props.history.push(`/user/profile/event/dicount-list/` + id);
  }
  function gotoaddquiz(params) {
    props.history.push(`/user/profile/event/quiz/` + params);
  }

  async function exportproducts(id) {
    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/quiz/result/excel?opportunity_id=" + id,
        // data: {
        //   opportunity_id : props.match.params.id
        // }
      });
      console.log("product re", result.data.data);
      let excelurl = mainURL + result.data.path;
      window.open(excelurl, "_blank");
    } catch (error) {
      console.log(error);
    }
    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/quiz/score/excel?opportunity_id=" + id,
        // data: {
        //   opportunity_id : props.match.params.id
        // }
      });
      console.log("product re", result.data.data);
      let excelurl = mainURL + result.data.path;
      window.open(excelurl, "_blank");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="col-md-12 topmargin">
      <div id="user-wrapper" className="dashboard">
        {/* <h5>Opportunity Lists</h5>   */}

        <div className="row">
          <div className="col-md-9">
            <h5>Opportunity Lists</h5>

            {/* <input type="text" value="" name="" placeholder="" className="searchbox"></input> */}
          </div>
          <div className="col-md-3">
            <button className="topbtnview">Live</button>
            <button className="topbtnview">Closed</button>
          </div>
        </div>
      </div>
      {alldata &&
        alldata.map((item, index) => (
          <div className="dashboard registerbox tp20">
            <div className="row">
              <div className="col-md-2" key={index}>
                <img src={item.banner_image} className="imgsize" />
              </div>
              <div className="col-md-5">
                <p>
                  <Link to={`/e/${item.slug}`}>{item.title}</Link>
                </p>
                <p>{item.organization}</p>
                <p>
                  {" "}
                  <Moment format="D MMMM YYYY " date={item.start_date} /> -{" "}
                  <Moment format="D MMMM YYYY " date={item.end_date} />{" "}
                </p>
              </div>
              <div className="col-md-5 talignright">
                {item.is_active === 1 ? (
                  <p className="approved">Approved</p>
                ) : (
                  <p className="pending">Pending</p>
                )}
                <label>
                  <span className="togglespan">
                    Do you want to public the attachment
                  </span>

                  <Toggle
                    defaultChecked={item.show_attachment === 1 ? true : false}
                    onChange={(e) => changestatus(e, item.id)}
                    className="custom-classname"
                    id={item.id}
                  />
                </label>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-md-12 text-right">
                <button
                  className="btn viewsubmition"
                  onClick={() => exportproducts(item.id)}
                >
                  Download Quiz Result
                </button>

                <button
                  className="btn viewsubmition"
                  onClick={() => gottosubmition(item.id)}
                >
                  View Submission
                </button>

                <button
                  className="btn viewsubmition"
                  onClick={() => gotoaddquiz(item.id)}
                >
                  Add quiz
                </button>

                <button
                  className="btn viewsubmition"
                  onClick={() => gottoteamlist(item.id)}
                >
                  Registrations
                </button>
                <button
                  className="btn viewsubmition"
                  onClick={() => gotodetail(item.id)}
                >
                  Edit
                </button>

                {item.is_opportunity_paid != null && (
                  <button
                    className="btn viewsubmition"
                    onClick={() => gotodiscount(item.id)}
                  >
                    Discounts
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Useropportunity;
