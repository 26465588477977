import React, { useEffect, useState } from "react";

import axios from "axios";
import config from "../CommonFiles/config.json";
import { Table, Switch, Space } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toggle/style.css";
import "react-toastify/dist/ReactToastify.css";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const Feedbacks = (props) => {
  const [alldata, setAlldata] = useState();
  const [opportunity, setOpportunity] = useState();
  const [feedbacklist, setFeedback] = useState();

  const [forfilter, setAForfilter] = useState();
  const [status, setStatus] = useState(1);
  const [attachmentstatus, setAttachmentstatus] = useState();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      sorter: {
        compare: (a, b) => a.chinese - b.chinese,
        multiple: 3,
      },
    },
    {
      title: "Member Rank",
      dataIndex: "is_teamlead",
      render: (text, record) =>
        record.is_teamlead === 1 ? "Leader" : "Member",
    },
    {
      title: "Active/Inactive",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {record.status === 1 ? (
            <Switch defaultChecked onChange={(e) => onChange(record.id, e)} />
          ) : (
            <Switch onChange={(e) => onChange(record.id, e)} />
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    // const tokencheck = localStorage.getItem("token")
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      axios({
        method: "get",
        url: mainURL + "/ratings?opportunity_id=" + props.match.params.id,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          // console.log("response", response)
          // const alldatas = response.data.data.rows;
          setFeedback(response.data.data);
          // console.info("alldata", alldata);
          // console.info("alldata", opportunity);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  }, []);

  function filter(filterstatus, statuscheck) {
    console.log(filterstatus);
    const closedata = forfilter.filter(
      (workshop) => workshop.is_active === statuscheck,
    );
    if (filterstatus === 1) {
      setAlldata(forfilter);
    } else {
      setAlldata(closedata);
    }
    setStatus(statuscheck);

    console.log("close data", alldata);
  }

  function changestatus(event) {}

  //   function onChange(pagination, filters, sorter, extra) {
  //     console.log('params', pagination, filters, sorter, extra);
  //   }

  function onChange(id, e) {
    console.log("params", id);
    console.log(`switch to `, e);
    let status;
    if (e === true) {
      status = 1;
    } else {
      status = 0;
    }
    console.log("status", status);

    // const USER_TOKEN = localStorage.getItem("token");
    // console.log("user token", USER_TOKEN);
    // const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "put",
      url: mainURL + "/ratings/" + id,
      data: {
        status: status,
      },
      // headers: { Authorization: AuthStr },
    })
      .then((response) => {
        let statusmsg;
        if (status === 1) {
          statusmsg = "Feedback has been active";
        } else {
          statusmsg = "Feedback has been inactive";
        }
        toast(statusmsg);

        // props.history.push(
        //   `/opportunities/detail/`+props.match.params.id
        // );
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  return (
    <div className="container topmargin">
      <ToastContainer />

      <div className="  mb-4">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <h5> Registered Teams</h5>
            </div>
          </div>
          <hr />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-6">
          <input
            type="text"
            value=""
            name=""
            placeholder=""
            className="searchbox"
          ></input>
        </div>
      </div> */}
      <div id="user-wrapper" className="dashboard row registerbox tp20">
        <Table
          columns={columns}
          dataSource={feedbacklist}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Feedbacks;
