import React, {  } from "react";
import RightSidebar from "./components/Dashboard/RightSidebar";
import LeftSideBar from "./components/Dashboard/LeftSideBar";

function Backendrouter() {
  const menuItems = [
    { name: "Home", to: "/dashboard", icon: "fas fa-home" },
    { name: "All workshops", to: "/dashboard/workshops", icon: "fas fa-users" },
    {
      name: "All oppportunity",
      to: "/dashboard/oppportunity",
      icon: "fas fa-users",
    },
  ];
  return (
    <div id="wrapper" className="dashboard">
      <LeftSideBar menuItems={menuItems} />
      <RightSidebar />
    </div>
  );
}
export default Backendrouter;
