import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form, Tab, Tabs, TabContent } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight, FaSave } from "react-icons/fa";
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import config from "../..//CommonFiles/config.json";
import CKEditor from "react-ckeditor-component";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { createHashHistory } from "history";
import "react-tooltip/dist/react-tooltip.css";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const USER_TOKEN = localStorage.getItem("token");
const AuthStr = "Bearer ".concat(USER_TOKEN);
const StepSix = (props) => {
  const [inputValue, setInputValue] = useState({});
  const [priceHeading, setPriceHeading] = useState("");
  const history = createHashHistory();
  const [inputFields, setInputFields] = useState([
    {
      rank: "",
      currency: "",
      prize_money: "",
      certificate_provided: "0",
      description: "",
    },
  ]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({
      rank: "",
      currency: "",
      prize_money: "",
      certificate_provided: "0",
      description: "",
    });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };
  useEffect(() => {
    // window.location.reload();

    if (localStorage.getItem("editopportunityid")) {
      axios({
        method: "get",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      })
        .then((response) => {
          const alldata = response.data.prizes;
          console.log(alldata, "get opprootunity step 2");
          if (alldata) {
            setPriceHeading(response.data.data.prize_heading);
            localStorage.setItem("FormSix", JSON.stringify(alldata));
            var formdatssa = JSON.parse(localStorage.getItem("FormSix"));
            setInputFields(formdatssa);
          }
        })
        .catch((err) => {
          console.log("jhghjghg", err);
        });
    }
    const formdata = JSON.parse(localStorage.getItem("FormSix"));

    if (formdata) {
      var formdatssa = JSON.parse(localStorage.getItem("FormSix"));
      setInputFields(formdatssa);
    }
  }, []);
  const onInputChange = (val, name, idx) => {
    // setInputValue({ ...inputValue, [name]: val });

    const rows = [...inputFields];
    console.log("get val val, name, idx", val, name, idx, rows);

    if (name === "rank") {
      rows[idx].rank = val;
    }
    if (name === "currency") {
      rows[idx].currency = val;
    }
    if (name === "prize_money") {
      rows[idx].prize_money = val;
    }
    if (name === "certificate_provided") {
      if (val.target.checked === true) {
        rows[idx].certificate_provided = "1";
      } else {
        rows[idx].certificate_provided = "0";
      }
    }
    if (name === "description") {
      rows[idx].description = val;
    }
    localStorage.setItem("FormSix", JSON.stringify(rows));

    // setInputValue(rows);
    setInputFields(rows);

    console.log("get val", rows);
  };

  const nextStep = (val, status) => {
    const stepData = JSON.parse(localStorage.getItem("FormSix"));

    axios({
      method: "put",
      url:
        mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      data: { prizes: stepData, prize_heading: priceHeading },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
    if (status === "save") {
      Swal.fire("Good job!", "Prize details have been updated", "success");
    } else {
      localStorage.setItem("activestep", 6);
      props.newActiveStepValue(6);
    }

    localStorage.setItem("FormSix", JSON.stringify(stepData));
    window.scrollTo({
      top: 300,

      behavior: "smooth",
    });
  };

  const prevStep = () => {
    localStorage.setItem("activestep", 4);
    props.newActiveStepValue(4);
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "ST";
    }
    if (j == 2 && k != 12) {
      return i + "ND";
    }
    if (j == 3 && k != 13) {
      return i + "RD";
    }
    return i + "TH";
  }

  const cancel = () => {
    history.push("/");
  };
  return (
    <div>
      <div className="form">
        <p className="notifyshow">
          {" "}
          <i className="fa fa-exclamation-circle"></i> Please mention the
          confirmed prize amount or minimum prize money since you won't be able
          to decrease the prize money once the event is live.
        </p>

        <form className="form-body">
          <div className="form-row">
            <label htmlFor="firstName">
              Prize Informations &nbsp;
              <i
                className="fa fa-info-circle"
                id="app-title"
                aria-hidden="true"
              ></i>
              <ReactTooltip
                anchorId="app-title"
                className="tooltipcls"
                place="right"
                content="Please mention the confirmed prize amount or minimum prize money since you won't be able to decrease the prize money once the event is live."
              />
            </label>
            <input
              type="text"
              className="form-control"
              id="description"
              name="heading"
              placeholder="One liner description about prizes"
              defaultValue={priceHeading && priceHeading}
              onChange={(e) => setPriceHeading(e.target.value)}
            />
            {inputFields.map((inputField, index) => (
              <>
                <h5 className="prizetittle">
                  {ordinal_suffix_of(index + 1)} PRIZE
                </h5>

                <div
                  key={`${inputField}~${index}`}
                  className=" col-md-12 row  mainrow"
                >
                  <div className="form-group col-sm-3 col-md-4">
                    <label htmlFor="firstName">Rank</label>
                    <input
                      type="text"
                      className="form-control"
                      id="rank"
                      defaultValue={inputField?.rank}
                      name="rank"
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                    />
                  </div>
                  <div className="form-group col-sm-3 col-md-4">
                    <label htmlFor="currency">Currency</label>

                    <select
                      className="form-control"
                      name="currency"
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                    >
                      <option>Select Currency</option>
                      <option
                        value="inr"
                        selected={
                          inputField.currency
                            ? inputField?.currency === "inr"
                              ? true
                              : false
                            : false
                        }
                      >
                        Rs. (INR)
                      </option>
                      <option
                        value="doller"
                        selected={
                          inputField.currency
                            ? inputField?.currency === "doller"
                              ? true
                              : false
                            : false
                        }
                      >
                        $ (USD)
                      </option>
                      <option></option>
                    </select>
                  </div>
                  <div className="form-group col-sm-3 col-md-4">
                    <label htmlFor="cash">Cash Amount</label>
                    <input
                      type="number"
                      className="form-control"
                      id="cash"
                      defaultValue={inputField?.prize_money}
                      name="prize_money"
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                    />
                  </div>

                  <div className="form-group col-sm-3 col-md-12">
                    <label htmlFor="other_details">Other Details</label>

                    <input
                      type="text"
                      className="form-control"
                      id="other_details"
                      name="description"
                      defaultValue={inputField.description}
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                    />
                  </div>
                  <div className="form-group col-sm-2 col-md-12">
                    <label htmlFor="certificate" className="">
                      <span className="certificateTag">Certificate</span>
                      <input
                        type="checkbox"
                        className="checkbox-control"
                        id="certificate"
                        value={inputField.certificate_provided}
                        name="certificate_provided"
                        // checked={inputField.certificate_provided === 1 ? true : null }
                        checked={
                          inputField.certificate_provided === null
                            ? null
                            : inputField.certificate_provided === "1"
                        }
                        onChange={(e) => onInputChange(e, e.target.name, index)}
                      />
                    </label>
                  </div>

                  {index === 0 ? (
                    ""
                  ) : (
                    <div className="col-md-12 fr">
                      <div className="form-group  remove-field">
                        <button
                          className="btn btn-link minus-btn"
                          type="button"
                          onClick={() => handleRemoveFields(index)}
                        >
                          <h2 className="remove-round fr">
                            Remove <i className="fa fa-trash"></i>
                          </h2>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
          <div className="form-group  addminus">
            <button
              className="btn btn-link plus-btn"
              type="button"
              onClick={() => handleAddFields()}
            >
              <h2 className="add-round fr">
                <i className="fa fa-plus"></i>Add Prize{" "}
              </h2>
            </button>
          </div>
        </form>
      </div>

      <div className="hosting_bottom">
        <Button className="help-btn" type="button" onClick={() => prevStep(4)}>
          <FaArrowLeft />
          Go Back
        </Button>
        <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => nextStep(6, "continue")}
        >
          Next <FaArrowRight />
        </Button>
        {localStorage.getItem("opportunityfrom") && (
          <Button
            className="save-hosting-first-btn editsavebtn"
            type="button"
            onClick={() => nextStep(6, "save")}
          >
            save <FaSave />
          </Button>
        )}
        <Link to={`/user/hosting`}>
          {" "}
          <Button
            className="transparentbtn float-right mt-10 mr-2"
            type="button"
            // onClick={(e) => cancel(e)}
          >
            Cancel
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default StepSix;
