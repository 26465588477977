import React from "react";
import { IoMdInformationCircle } from "react-icons/io";
import "./Tooltip.css";

const Tooltip = ({ text }) => {
  return (
    <div className="tooltip-container">
      <IoMdInformationCircle className="tooltip-icon" />
      <div className="tooltip-text">{text}</div>
    </div>
  );
};

export default Tooltip;
