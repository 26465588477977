import React from "react";
import BootstapBtn from "react-bootstrap/Button";

const Button = (props) => {
  return (
    <>
      <BootstapBtn className={props.clsName} variant="outline">
        {props.name}{" "}
      </BootstapBtn>
    </>
  );
};

export default Button;
