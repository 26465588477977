import React from "react";
import $ from "jquery";

// const Stopwatch = (props) => {
//     const [initialState, setInitialState] = useState(60);

//     useEffect(() => {
//         handleStartClick();
//     })
//     function getSeconds () {
//         return(
//             '0' + initialState % 60
//         ).slice(-2);
//     }
//     function getMinutes () {
//         return Math.floor(initialState/60);
//     }
//     function handleStartClick () {
//         setInterval(function () {
//             setInitialState(
//                 initialState + 1000
//             );
//         }, 1000)
//     }
//     return(
//         <div>
//             <h1>{getMinutes()}{getSeconds()}</h1>
//         </div>
//     );
// }

// export default Stopwatch;

const leftPad = (width, n) => {
  if ((n + "").length > width) {
    return n;
  }
  const padding = new Array(width).join("0");
  return (padding + n).slice(-width);
};

var minutes;
var seconds;

class Stopwatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeElapsed: 0,
      message: "",
      dates: new Date(),
      gettime: this.props.limits,
      minutes: "",
      seconds: "",
    };
  }
  componentDidMount() {
    // var timer2 = "40:00";
    // console.log("timing checks a", this.props)

    if (this.props.minutscheck === 0) {
      var startTime = parseInt(this.props.duration) - 1;
      console.log(
        "this.props.minutscheck sechk",
        typeof parseInt(this.props.duration),
      );
    } else {
      // alert(this.props.minutscheck)

      var startTime =
        parseInt(this.props.duration) - 1 - this.props.minutscheck;
      console.log("this.props.minutscheck", this.props.minutscheck);
    }

    var endtime = 60 - this.props.secondcheck;
    console.log("this.props.minutscheck second", endtime);
    console.log("this.props.minutscheck second", this.props.secondcheck);

    // var endtime = 60 - this.props.secondcheck
    var timer2 = startTime + ":" + endtime;
    console.log("this.props.minutscheck timing checks add", timer2);

    var interval = setInterval(function () {
      var timer = timer2.split(":");
      // console.log("timing check", timer2)
      // console.log("timing checks", timer2)
      //by parsing integer, I avoid all extra string processing
      minutes = parseInt(timer[0], 10);
      seconds = parseInt(timer[1], 10);
      --seconds;
      minutes = seconds < 0 ? --minutes : minutes;
      if (minutes < 0) clearInterval(interval);
      seconds = seconds < 0 ? 59 : seconds;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      //minutes = (minutes < 10) ?  minutes : minutes;
      $("#demo").html(minutes + ":" + seconds + " Min");
      timer2 = minutes + ":" + seconds;
    }, 1000);
    this.startTimer();
  }
  startTimer = () => {
    this.startTime = Date.now();
    this.timer = setInterval(this.update, 10);
  };
  update = () => {
    const delta = Date.now() - this.startTime;
    this.setState({ timeElapsed: this.state.timeElapsed + delta });
    this.startTime = Date.now();
    // console.log("delta", delta)
  };
  getUnits = () => {
    const seconds = this.state.timeElapsed / 60;
    return {
      hr: Math.floor(seconds / (60 * 60)).toString(),
      min: Math.floor(seconds / 60).toString(),
      sec: Math.floor(seconds % 60).toString(),
    };
  };

  render() {
    const units = this.getUnits();
    // console.log("getting minuts: " , JSON.stringify(minutes) , "secton : " ,JSON.stringify(seconds))
    // console.log("from props minuts: " , this.props.min1 , "secton : " ,this.props.sec1)
    // localStorage.setItem("secondsget" , leftPad(2, units.sec))
    // localStorage.setItem("secondsget" , leftPad(2, units.sec))
    if (minutes === 3 && seconds === "00") {
      // console.log("hey nice to see you!");
      localStorage.setItem("msg", "Meeting will end in 5 minutes");
      this.props.handleNotification();
    }
    if (minutes === 0 && seconds === "00") {
      this.props.handleLeaveMeeting();
      // alert("new enter")
    }
    return (
      <div id="timer" className="d-flex justify-content-center">
        <span className="stopwatchText">Time Left</span>
        <div className="text-center">
          <p className={`${this.props.timeEnd} timeandsec mb-0`}>00 </p>
          <p className="statictext mb-1">hours</p>
        </div>
        <div className="mx-1">
          <p className=" dotcolor mb-0">: </p>
        </div>
        <div className="text-center">
          <p className={`${this.props.timeEnd} timeandsec mb-0`}>
            {minutes < 10 ? "0" + minutes : minutes}{" "}
          </p>
          <p className="statictext mb-1">minutes</p>
        </div>
        <div className="mx-1">
          <p className="dotcolor mb-0">: </p>
        </div>
        <div className="text-center">
          <p className={`${this.props.timeEnd} timeandsec mb-0`}>{seconds} </p>
          <p className="statictext mb-1">seconds</p>
        </div>
        {/* <span></span> */}
        {/* hewn */}
      </div>
    );
  }
}

export default Stopwatch;

// [
//     {
//       "question": "With an international footfall of almost 90 million annually, which country is the most popular tourism destination in the world?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "checkradio" : "1",
//       "answers": [
//         "France",
//         "India",
//         "The USA",
//         "Germany"
//       ],
//       "correctAnswer": "France",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Which place in India has been referred to as Gomanchala, Gomantak, and Gopakapattana among other names in ancient Indian texts?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Goa",
//         "Gokarna",
//         "Gwalior",
//         "Govindpura"
//       ],
//       "correctAnswer": "Goa",
//       "checkradio" : "2",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "In London, if you are standing below the Elizabeth Tower, which historical landmark are you actually visiting?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Big Ben",
//         "London Bridge",
//         "Buckingham Palace",
//         "Madame Tussauds"
//       ],
//       "correctAnswer": "Big Ben",
//       "checkradio" : "3",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "The look of the protagonist in the 1928 silent film - The Man Who Laughs, inspired the design of which comic book character?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "The Joker",
//         "The Riddler",
//         "Thanos",
//         "Loki"
//       ],
//       "correctAnswer": "The Joker",
//       "checkradio" : "4",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Which literary work, whose author is yet unknown, is the most translated book in the world with over 3,300 translations?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "The Bible",
//         "Panchatantra",
//         "Aesop's Fables",
//         "Arabian Nights"
//       ],
//       "correctAnswer": "The Bible",
//       "checkradio" : "5",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     // this is new question
//     {
//       "question": "Who is this athlete who has been in the news all over in August 2021?",
//       "questionPic": "https://www.thenewsminute.com/sites/default/files/BajrangPunia_TokyoOlympics_060821_1200x800_TwitterTeamIndia.jpeg",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Bajrang Punia",
//         "Neeraj Chopra",
//         "Ravi Kumar Dahiya",
//         "Mahavir Singh Phogat"
//       ],
//       "correctAnswer": "Bajrang Punia",
//       "checkradio" : "6",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "The name of this Netflix series is a reference to the effect of a TV or computer screen which, when switched off, gives a dark reflection of the onlooker. Which series?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Black Mirror",
//         "Dark Desire",
//         "Stranger Things",
//         "Dim The Lights"
//       ],
//       "correctAnswer": "Black Mirror",
//       "checkradio" : "7",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "The name of which food item is the plural for an Italian word meaning thin string?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Spaghetti",
//         "Pasta",
//         "Noodles",
//         "Chowmein"
//       ],
//       "correctAnswer": "Spaghetti",
//       "checkradio" : "8",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "The name of the city is a corrupted version of “the town of Mahishasura” in local language. According to a mythological story, the city was ruled by Mahishasura. In response to prayers, Parvati took birth as Chamundeshwari and killed the monster on top of the Chamundi Hill near this city. Which city?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Mysore/Mysuru",
//         "Mumbai",
//         "Madurai",
//         "Mahabaleshwar"
//       ],
//       "correctAnswer": "Mysore/Mysuru",
//       "checkradio" : "9",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Which beer brand continues to be the most valuable beer brand in the world, according to August '20 data, even though there was significant industry speculation that the global pandemic might end up causing severe damage to the brand’s reputation?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Corona",
//         "Budweiser",
//         "Heineken",
//         "Kingfisher"
//       ],
//       "correctAnswer": "Corona",
//       "checkradio" : "10",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "This is the idol from a very popular Durga Puja in South Kolkata [2014]. The idol is currently placed at the Netaji Subhas Chandra Bose International Airport in Kolkata. The idol was the first one of its kind in the world because of the way it was created. What is special about it?",
//       "questionPic":"https://pbs.twimg.com/media/CuVAwBbVIAIxH5a?format=jpg",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "It is the world's first 3D printed idol",
//         "It is the world's first completely recyclable idol",
//         "It is the world's first idol made completely by carving a single large rock",
//         "It is the world's first idol made of painted glass"
//       ],
//       "correctAnswer": "It is the world's first 3D printed idol",
//       "checkradio" : "11",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Splashdata announced the Worst Password List in the year 2018. It compiled from more than 2 million leaked passwords and came up with the 25 most leaked passwords. The worst password according to the list is “123456”. Which is the second worst?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "password",
//         "abcdef",
//         "User's name",
//         "User's birthday"
//       ],
//       "correctAnswer": "password",
//       "checkradio" : "12",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Which nine-letter English word, which is often associated with planning stage before going on a trip, has been derived from the Latin for 'record of a journey'?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Itinerary",
//         "Departure",
//         "Excursion",
//         "Honeymoon"
//       ],
//       "correctAnswer": "Itinerary",
//       "checkradio" : "13",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "First: Nourishment and Food, Second: Health and Prosperity, Third: Spirituality and Wealth, Fourth: Love and Trust, Fifth: Beautiful and Brave Offsprings, Sixth: Peace across Seasons of Life, Seventh: Companionship and Loyalty. What are these seven things?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Saat Phere [Seven circles around the fire during Hindu marriages]",
//         "The seven ladders on the Snakes & Ladders board",
//         "The seven greatest virtues",
//         "The meaning behind the seven continents according to Buddhism"
//       ],
//       "correctAnswer": "Saat Phere [Seven circles around the fire during Hindu marriages]",
//       "checkradio" : "14",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "With over 850 million downloads across platforms, which was the most popular app of 2020?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "TikTok",
//         "Facebook",
//         "Clubhouse",
//         "Instagram"
//       ],
//       "correctAnswer": "TikTok",
//       "checkradio" : "15",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Which company, that has been in news in India since 2020, is he the CEO of?",
//       "questionPic":"https://www.biospectrumindia.com/uploads/articles/1-16093.jpg",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Serum Institute of India",
//         "Bharat Biotech",
//         "AstraZeneca",
//         "Pfizer"
//       ],
//       "correctAnswer": "Serum Institute of India",
//       "checkradio" : "16",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "With over 175 million views, 'Me at the Zoo' is a very popular and very simple 18-seconds YouTube video where a man stands at a zoo and just talks about the elephants in the background. Why is this video so popular?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "First ever Youtube video.",
//         "Most disliked YouTube video.",
//         "The only YouTube video with actual ghosts in the background.",
//         "Most shared YouTube video."
//       ],
//       "correctAnswer": "First ever Youtube video.",
//       "checkradio" : "17",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "These small and usually gray or green parrots are noted for their pretty colors and their affection towards their mate. These characteristics led to these parrots being called by a certain colloquial name in English. In the 19th-century, people familiar with these parrots and their habits began to use their name to refer to partners in a romantic relationship. What is the common name for these parrots?",
//       "questionPic": "https://cf.ltkcdn.net/small-pets/images/std-xs/240971-340x191-lovebirds.jpg",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Lovebirds",
//         "Soulmates",
//         "Twosomes",
//         "Couplets"
//       ],
//       "correctAnswer": "Lovebirds",
//       "checkradio" : "18",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "The Great Mosque of Samarra is a 1200-year old religious site in Iraq that is revered by the Islamic community. Though the mosque has been destroyed by invading armies, the outer walls and the spiral minaret still stand tall. The minaret's unique structure has inspired the architecture of a large number of similar minarets and mosques around the world and even a chapel in Texas. The most famous landmark that has been inspired by the minaret might remind some of you of Tom Cruise or Akshay Kumar. Which landmark am I talking about?",
//       "questionPic": "https://i1.wp.com/archeyes.com/wp-content/uploads/2020/10/Abu-Dulaf-Mosque-Minaret-Samarra-ArchEyes-sideview.jpg?resize=850%2C567&ssl=1%20850w,%20https://i1.wp.com/archeyes.com/wp-content/uploads/2020/10/Abu-Dulaf-Mosque-Minaret-Samarra-ArchEyes-sideview.jpg?resize=300%2C200&ssl=1%20300w,%20https://i1.wp.com/archeyes.com/wp-content/uploads/2020/10/Abu-Dulaf-Mosque-Minaret-Samarra-ArchEyes-sideview.jpg?resize=1536%2C1024&ssl=1%201536w,%20https://i1.wp.com/archeyes.com/wp-content/uploads/2020/10/Abu-Dulaf-Mosque-Minaret-Samarra-ArchEyes-sideview.jpg",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Burj Khalifa",
//         "Sears Tower",
//         "Qutb-Minar",
//         "Empire State Building"
//       ],
//       "correctAnswer": "Burj Khalifa",
//       "checkradio" : "19",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "This is probably the most famous and the most iconic peak in Switzerland. But it is famous globally due to its association with a brand. Which brand?",
//       "questionPic": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Matterhorn_from_Domh%C3%BCtte_-_2.jpg/1200px-Matterhorn_from_Domh%C3%BCtte_-_2.jpg",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Toblerone",
//         "Paramount Pictures",
//         "Patagonia",
//         "Universal Pictures"
//       ],
//       "correctAnswer": "Toblerone",
//       "checkradio" : "20",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Dilwale Dulhania Le Jayengey is a Bollywood classic and it turned Shah Rukh Khan into a national heartthrob. However, Shah Rukh Khan was not the first choice for the role and Yash Chopra had even considered casting a Hollywood superstar for the role instead. Who was the first choice to play SRK's role in DDLJ?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Tom Cruise",
//         "Brad Pitt",
//         "George Clooney",
//         "Denzel Washington"
//       ],
//       "correctAnswer": "Tom Cruise",
//       "checkradio" : "21",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Who is this braveheart who has been the subject of a biopic in 2021?",
//       "questionPic": "https://images.newindianexpress.com/uploads/user/imagelibrary/2021/7/7/w900X450/Captain_Vikram_Batra.jpg?w=640&dpr=1.0",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Captain Vikram Batra",
//         "Captain Shershah Singh",
//         "Lieutenant Balwan Singh",
//         "Lieutenant Manoj Kumar Pandey"
//       ],
//       "correctAnswer": "Captain Vikram Batra",
//       "checkradio" : "22",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Which app is this logo associated with?",
//       "questionPic": "https://i.gadgets360cdn.com/large/snapchat_logo_image_1621517744338.jpg?downsize=950:*",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Snapchat",
//         "Vimeo",
//         "Airbnb",
//         "Tumblr"
//       ],
//       "correctAnswer": "Snapchat",
//       "checkradio" : "23",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Founded by four friends and business collaborators, this was a fashion and lifestyle e-commerce portal that sold apparels, footwear, accessories, beauty products, fragrances, home accessories, and other fashion and lifestyle products. The site started operations in January, 2012 and by December 2012, it was already the 46th most popular website in India. Which website is this that is now shut down after being acquired by another company?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Jabong",
//         "Myntra",
//         "Shein",
//         "Limeroad"
//       ],
//       "correctAnswer": "Jabong",
//       "checkradio" : "24",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "This is a Colombian dance program created by dancer and choreographer Alberto Perez during the 1990s. It involves dance and aerobic elements. Which dance form is this?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Zumba",
//         "Tango",
//         "Salsa",
//         "Disco"
//       ],
//       "correctAnswer": "Zumba",
//       "checkradio" : "25",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Identify this actor who received national fame after his lead role in a 2020 webseries.",
//       "questionPic": "https://img.huffingtonpost.com/asset/5f9c3f0f240000142961ec57.jpeg?ops=1778_1000",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Pratik Gandhi",
//         "Prateik Babbar",
//         "Pratik Sen",
//         "Pratik Sehajpal"
//       ],
//       "correctAnswer": "Pratik Gandhi",
//       "checkradio" : "26",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Identify this luxurious brand from the logo shown here.",
//       "questionPic": "https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/1960s_Gucci_Logo.svg/640px-1960s_Gucci_Logo.svg.png",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Gucci",
//         "Chanel",
//         "Versace",
//         "Dior"
//       ],
//       "correctAnswer": "Gucci",
//       "checkradio" : "27",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "People always speculate if she is based on an actual person. However, the company have always said she is just an illustration that was created back in the 1960s by a company called Everest Creatives. Which iconic girl of Indian advertisement am I talking about?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Parle-G Girl",
//         "Amul Girl",
//         "Lijjat Papad Girl",
//         "Nirma Girl"
//       ],
//       "correctAnswer": "Parle-G Girl",
//       "checkradio" : "28",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Which famous series of fantasy novels was written by this author?",
//       "questionPic": "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ed/Portrait_photoshoot_at_Worldcon_75%2C_Helsinki%2C_before_the_Hugo_Awards_%E2%80%93_George_R._R._Martin.jpg/1200px-Portrait_photoshoot_at_Worldcon_75%2C_Helsinki%2C_before_the_Hugo_Awards_%E2%80%93_George_R._R._Martin.jpg",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Game of Thrones/A Song of Ice and Fire",
//         "The Lord of the Rings",
//         "The Chronicles of Narnia",
//         "The Witcher"
//       ],
//       "correctAnswer": "Game of Thrones/A Song of Ice and Fire",
//       "checkradio" : "29",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "What is the name of this animal which is also the name of a Marvel superhero?",
//       "questionPic": "https://www.konnecthq.com/wp-content/uploads/2021/05/Wolverine-facts-3-5-1.jpg",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Wolverine",
//         "Rocket Raccoon",
//         "Sabretooth",
//         "Wildcat"
//       ],
//       "correctAnswer": "Wolverine",
//       "checkradio" : "30",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "The term was originally coined by the press to refer to a bomb which had enough explosive power to destroy an entire street or large buildings. Later the term began to be used in a completely different context and as a measure of success. What is the term?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Blockbusters",
//         "Chartbusters",
//         "Superhits",
//         "Knockouts"
//       ],
//       "correctAnswer": "Blockbusters",
//       "checkradio" : "31",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Which was Pixar's first feature-length movie?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Toy Story",
//         "A Bug's Life",
//         "Finding Nemo",
//         "The Incredibles"
//       ],
//       "correctAnswer": "Toy Story",
//       "checkradio" : "32",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "X gets its name from the fact that it used to be shown at the end of feature films. According to a governing authority, the current versions of X either come with a green or red band depending on the content, cannot exceed more than 150 seconds and usually have rating cards. What is X?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Trailers",
//         "Teasers",
//         "Posters",
//         "Motion Pictures"
//       ],
//       "correctAnswer": "Trailers",
//       "checkradio" : "33",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Which of these is both an IPL team name as well as a very famous private equity fund? [Though, of course, their full forms are different!]",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "KKR",
//         "CSK",
//         "KXIP",
//         "SRH"
//       ],
//       "correctAnswer": "KKR",
//       "checkradio" : "34",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//     {
//       "question": "Tata’s coffee houses in India are Starbucks, but what are Tata’s tea houses in India called?",
//       "questionType": "text",
//       "answerSelectionType": "single",
//       "answers": [
//         "Tata Cha",
//         "Tata Tea",
//         "Tata Garam",
//         "Moonbucks"
//       ],
//       "correctAnswer": "Tata Cha",
//       "checkradio" : "35",
//       "messageForCorrectAnswer": "Correct answer. Good job.",
//       "messageForIncorrectAnswer": "Incorrect answer. Please try again.",
//       "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       "point": "5"
//     },
//   ]
