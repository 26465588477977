import React, { useState, useEffect } from "react";
import LeftSideBar from "../LeftSideBar/LeftSideBar";
import { Table, Dropdown, Space } from "antd";
import "./registraionlist.css";
import {
  Col,
  Row,
  Form,
  Button,
  Tab,
  Tabs,
  TabContent,
  Container,
} from "react-bootstrap";
import { DownOutlined, VerticalAlignBottomOutlined } from "@ant-design/icons";
import axios from "axios";
import config from "../../CommonFiles/config.json";
const mainurl = Object.values(config["URL"]);
const APIBASEURL = Object.values(config["API_BASEPATH"]).join("");
const URL = mainurl.join("");
const mainURL = URL;

export default function Allregistrationlist(props) {
  const [selectionType, setSelectionType] = useState("checkbox");
  const [activeToptab, setActiveToptab] = useState("all");
  const [registerList, setRegisterList] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [tableParams, setTableParams] = useState({
    current: 1,
    // pageSize:10,
    pageSize: 0,
  });

  useEffect(() => {
    // const tokencheck = localStorage.getItem("token")
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/dashboard/registered/opportunities/" +
          localStorage.getItem("hostingId"),
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          // console.log("response", response)
          // const alldatas = response.data.data.rows;
          setRegisterList(response.data.data);
          setTeamData(response.data.teamsData);

          console.info("alldata", response.data.data);
          // console.info("alldata", opportunity);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  }, []);
  const items = [
    {
      label: (
        <span onClick={() => exportproducts()}>
          {/* <img
        className=""
        src={require("../../assets/images/Export.png").default}
      /> */}
          <i className="fa fa-download" aria-hidden="true"></i> Download
          Registration List
        </span>
      ),
      key: "0",
    },
    // {
    //   label: <a href="https://www.aliyun.com">2nd menu item</a>,
    //   key: '1',
    // },
    // {
    //   type: 'divider',
    // },
    // {
    //   label: '3rd menu item',
    //   key: '3',
    // },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const data = [];

  const columns = [
    {
      title: "#",
      dataIndex: "idx",
      key: "idx",
      render: (text, record, index) => {
        const { current, pageSize } = tableParams;
        const serialNumber = (current - 1) * pageSize + index + 1;
        return <span>{serialNumber}</span>;
      },
    },

    {
      title: "PARTICIPANTS & COLLEGE",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <div className="d-flex">
          <div className="imgeshow w-40 mr-3">
            <img
              className="list-icon-img"
              src={require("../../../images/total_reg.png").default}
              alt="od-banner"
            />
          </div>
          <div className="teamname pt-1">
            {record.name}{" "}
            <span>
              {record.teamCount} {record.teamCount > 1 ? "Players" : "Player"}
            </span>
            <br />
            <div className="">{record.school}</div>
          </div>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Incomplete",
          value: 0,
        },
        {
          text: "Complete",
          value: 1,
        },
      ],
      onFilter: (value, record) => record.status === value,
      render: (_, record) => (
        <>
          {record.status === 0 && record.teamCount > 1 ? (
            <p className="incomplete">Incomplete</p>
          ) : (
            <p className="complete">Complete</p>
          )}
        </>
      ),
    },
    {
      title: "CURRENT STATUS",
      dataIndex: "status",
      key: "status",
      render: (record) => (
        <p className="roundbtn">
          {record.current_status ? (
            record.current_status
          ) : (
            <span>
              <span className="roundDot"></span>Round 1
            </span>
          )}
        </p>
      ),
    },
  ];

  async function exportproducts(params) {
    try {
      const result = await axios({
        method: "get",
        url:
          mainURL + "/registers/excel?opportunity_id=" + props.match.params.id,
        data: {
          opportunity_id: props.match.params.id,
        },
      });
      console.log("product re", result.data.data);
      let excelurl = APIBASEURL + "/" + result.data.path;
      window.open(excelurl, "_blank");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <div className="wrapper pt-5">
      {/* START LEFTSIDEBAR */}
      <LeftSideBar />
      {/* END LEFTSIDEBAR */}
      {/* START PAGE CONTENT START */}
      <div className="contents opportunityDashboard">
        <div className="page_content pt-5">
          <Container>
            <Row>
              <Col className="col-md-6">
                <h3 className="pb-3">All Registrations</h3>
              </Col>
              <Col className="col-md-6 text-md-right">
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      <span className="dotcss">...</span>

                      {/* <DownOutlined /> */}
                    </Space>
                  </a>
                </Dropdown>
              </Col>
            </Row>
            {/* <Col>
              <div className="  mb-4">
          <div className="row">
           
            <div className="col-md-3">
            <button className="btn menubtn" onClick={() => exportproducts()}>
              {/* <img
                className=""
                src={require("../../assets/images/Export.png").default}
              /> 
              Download Registration List
            </button>
          </div>
          <hr/>
        </div>
        
      </div></Col> */}
            <Row className="card_box_sec pb-3 registerationheader">
              <Col className="col-md-6">
                <h5 className="pb-0 pt-3 topheading">
                  {teamData.totalStudents} Registrations
                  <br />
                  {/* {teamData.totalTeams} Teams ({teamData.totalStudents} Students) Registrations <br/>
             {teamData.confirmedTeams} Students Confirmed<br/>
             {teamData.pendingTeams} Students Pending */}
                </h5>
                <div>
                  <span className="registerSuccess">
                    Completed: {teamData.confirmedTeams}
                  </span>
                  <span className="registerPending">
                    Incompleted:{teamData.pendingTeams}
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
          <div>
            <Table bordered dataSource={registerList} columns={columns} />;
          </div>
        </div>
      </div>
    </div>
  );
}
