const ImagePath = {
  DASHBOARDICON: require("../images/Icon-Dashboard.png").default,
  REGISTERICON: require("../images/Icon-Manage_Reg.png").default,
  NOTIFICATIONICON: require("../images/Icon-Comm_Mgmt.png").default,
  HOSTINGICON: require("../images/Icon-Hosting.png").default,
  // HEMBURGER_MENU : require("../assets/images/hemburger_menu.png"),
  // INFORMATION : require("../assets/images/information.png"),
  // PRODUCTS : require("../assets/images/products.png"),
  // SALES : require("../assets/images/sales.png"),
  // QUOTATIONS : require("../assets/images/quotations.png"),
  // PURCHASES : require("../assets/images/purchases.png"),
  // EXPENSES : require("../assets/images/expenses.png"),
  // STOCK_TRANSFER : require("../assets/images/stock transfer.png"),
  // STOCK_ADJUSTMENT : require("../assets/images/stock_adjustment.png"),
  // USER_MANAGEMENT : require("../assets/images/user_management.png"),
  // CALENDER : require("../assets/images/calender.png"),
  // CONTACTS : require("../assets/images/contacts.png"),
  // REPORTS : require("../assets/images/reports.png"),
  // SETTINGS : require("../assets/images/settings.png"),
  // SUBSCRIPTIONS : require("../assets/images/subscriptions.png"),
  // LOGOUT : require("../assets/images/logout.png"),
  // PROFILE_IMG : require("../assets/images/profile.png"),
};

export default ImagePath;
