import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import DashboardHome  from './Backend/DashboardHome';
import Dashboard from "./Layout";
import Opprtunitydetailpage from "../Backend/Opprtunitydetailpage";
import Event from "./Event";
import Opprtunitydash from "./Opprtunitydash";
import MainDashboard from "./MainDashboard";
import Submitionlist from "../User/Submitionlist";
import Feedbacks from "./Feedbacks";
const Routes = () => {
  return (
    <Switch>
      <Route exact path="/dashboard" component={MainDashboard} />
      <Route exact path="/dashboard/workshops" component={Event} />
      <Route exact path="/dashboard/oppportunity" component={Opprtunitydash} />
      <Route
        exact
        path="/dashboard/oppportunity/feedback/:id"
        component={Feedbacks}
      />
      <Route
        exact
        path="/dashboard/event/:id"
        component={Opprtunitydetailpage}
      />
    </Switch>
  );
};

export default Routes;
