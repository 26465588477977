import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import OpportunitiesRightSideCard from "../CommonFiles/OpportunitiesRightSideCard.js";
import "./OpportunitiesRightSide.css";

function OpportunitiesRightSide(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.opportunities) {
      setIsLoading(false);
    }
  }, [props]);

  return (
    <div className="opportunities-rightside">
      <div className="opportunities-rightside-cards">
        <Row>
          {props.opportunities &&
            props.opportunities.map((oppr, index) => (
              <Col md={4}>
                <OpportunitiesRightSideCard key={index} item={oppr} />
              </Col>
            ))}
        </Row>
      </div>

      {isLoading && (
        <div className="reg-form-loader-container">
          <div className="reg-form-loader"></div>
        </div>
      )}
    </div>
  );
}

export { OpportunitiesRightSide };
