import React, { useEffect, useRef, useState } from "react";
import Nav from "react-bootstrap/Nav";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";

const UserLeftSideBar = (props) => {
  const { menuItems = [] } = props;
  console.log("all menu links", menuItems);
  // state
  console.log(props);
  const [selected, setSelectMenuItem] = useState(menuItems[0].name);
  const menuItemsJSX = menuItems.map((item, index) => {
    let active = "";

    const isItemSelected = selected === item.name;
    console.log("menu check 1", item.to);
    let urlElements = window.location.href.split("/");
    if (window.location.pathname === item.to) {
      active = "active";
    }

    console.log("menu check", window.location.pathname);
    return (
      <li className={active} key={index}>
        {" "}
        <Link to={item.to} className="nav-link">
          {" "}
          <i className={item.icon} aria-hidden="true"></i> &nbsp; {item.name}{" "}
        </Link>{" "}
      </li>
    );
  });
  return (
    <>
      <nav className="left-sidebar col-md-3 topmargin">
        <div className="sticky-sidebar">
          <br />
          <ul className="">
            <Nav variant="pills" className="flex-column">
              {menuItemsJSX}{" "}
            </Nav>
          </ul>
        </div>
      </nav>
      {/* <div>
                <ul>
                    <li><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/2048px-User_icon_2.svg.png" /></li>
                    <li><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/2048px-User_icon_2.svg.png" /></li>
                    <li><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/2048px-User_icon_2.svg.png" /></li>
                    <li><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/2048px-User_icon_2.svg.png" /></li>
                </ul>
            </div> */}
    </>
  );
};

export default UserLeftSideBar;
