import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Moment from "react-moment";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Popconfirm, message, Radio, Tabs } from "antd";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { FaRegCalendarAlt } from "react-icons/fa";
import Swal from "sweetalert2";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const Userprofile = (props) => {
  const { TabPane } = Tabs;
  const [alldata, setAlldata] = useState();
  const [tempData, setTempData] = useState();
  const [filterdata, setFilterData] = useState();
  const [registrationDeadline, setRegistrationDeadline] = useState();
  const [eventdeadline, setEventdeadline] = useState();
  const [teamdetail, setTeamdetail] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [selectedtab, setSelctedtab] = useState(1);
  const [TeamModalShow, setTeamModalShow] = useState(false);
  const [allmembers, setAllmembers] = useState([]);
  const [finalTime, setFinalTime] = useState();
  const [userdata, setUserdata] = useState(
    JSON.parse(localStorage.getItem("alldata")),
  );
  const USER_TOKEN = localStorage.getItem("token");
  const AuthStr = "Bearer ".concat(USER_TOKEN);
  const data = [
    {
      value: 0,
      text: "All",
      icon: <i className="fa fa-bars"></i>,
    },
    {
      value: 1,
      text: "Live",
      icon: <i className="fa fa-circle"></i>,
    },
    {
      value: 2,
      text: "Closed",
      icon: <i className="fa fa-lock"></i>,
    },
  ];
  useEffect(async () => {
    // const tokencheck = localStorage.getItem("token")
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      axios({
        method: "get",
        url: mainURL + "/profile/registered/opportunities",
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data;
          setTeamdetail(response.data.teamlead);
          setAlldata(alldatas);
          setTempData(alldatas);
        })
        .catch(function (error) {
          if (error.response.data.auth === false) {
            Swal.fire({
              title: "Your session has expired, please login again!",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              allowOutsideClick: false,
              cancelButtonColor: "#d33",
              confirmButtonText: "Continue",
            }).then((result) => {
              if (result.isConfirmed) {
                let slugName = localStorage.getItem("eventslug");
                localStorage.clear();
                window.location.href = "/";
              }
            });
          }
          //handle error
          console.log("Error while calling api", error);
        });
    }
  }, []);

  // Date interval

  const dateFunction = (item) => {
    const getDatefrom = moment(item.register_end_date)
      .tz("Asia/Kolkata")
      .format("YYYY-MM-DD");
    const finaltime = moment
      .utc(getDatefrom + " " + item.register_end_time)
      .format("YYYY-MM-DD HH:mm:ss");
    // const endtime =  moment(getDatefrom).format("hh:mm")

    // get hours and minuts

    var duration = moment.duration(moment(finaltime).diff(moment(new Date())));
    var days = duration.days();
    var hours = duration.hours();
    var mins = duration.minutes();
    var sec = duration.seconds();

    let leftTime;
    // var duration2 = moment.duration(duration._milliseconds*1000, 'milliseconds');
    var interval = 1000;
    if (sec > 0) {
      setInterval(function () {
        var duration = moment.duration(
          moment(finaltime).diff(moment(new Date())),
        );
        var days = duration.days();
        var hours = duration.hours();
        var mins = duration.minutes();
        var sec = duration.seconds();
        // console.log("day" , days , "hours" , hours ,"minuts" , mins , "sec", sec)

        if (sec < 0) {
          setFinalTime("end");
        }
      }, interval);

      return (
        <div className="d-flex pt-3 justify-content-end">
          <button
            className="btn  menubtn actionBtn transparentbtn"
            onClick={() => gotodashboard(item.id, item.title, item.slug)}
          >
            <img
              src={require("../../images/view_dashboard.png").default}
              alt="od-banner"
              className="dashboardicon"
            />{" "}
            &nbsp; Edit Registration
          </button>
        </div>
      );
    }

    // if(days > 0)
    // {
    //   return leftTime = days

    // }
    // else{
    //   if (hours >= 0 && mins >= 0) {
    //     return leftTime = `${hours} : ${mins}`;
    //   } else {
    //     return leftTime = "timeout";
    //   }
    // }
    // setFinalTime(leftTime)
  };

  function confirm(id) {
    console.log(id);
    // message.success('Click on Yes');

    axios({
      method: "post",
      url: mainURL + "/profile/cancel/registered/opportunities",
      data: {
        user_id: userdata.id,
        opportunity_id: id,
      },
    })
      .then((response) => {
        axios({
          method: "get",
          url: mainURL + "/profile/registered/opportunities",
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            const alldatas = response.data.data;
            setTeamdetail(response.data.teamlead);
            setAlldata(alldatas);
          })
          .catch(function (response) {
            //handle error
            console.log("Error while calling api", response);
          });
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  const searchRegistrations = (e) => {
    console.log("search", e.target.value);
    setSearchValue(e.target.value);
    let filterdata;
    if (e.target.value !== "") {
      if (registrationDeadline === 0) {
        filterdata = tempData;
      } else {
        filterdata = alldata;
      }
      const searchList = filterdata.filter((item) => {
        return (
          item.title.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
        );
      });
      setAlldata(searchList);
    } else {
      setAlldata(tempData);
    }
  };
  function cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }
  function selectetab(status) {
    setSelctedtab(status);
    setSearchValue("");
    console.log("status show", status, registrationDeadline, searchValue);
    if (registrationDeadline === 0) {
      setAlldata(tempData);
    }
  }
  const handleRegistrationDeadline = (e, forcheck) => {
    console.log(
      "change opetion",
      e,
      selectedtab,
      alldata,
      searchValue,
      forcheck,
    );
    setRegistrationDeadline(e.value);
    const filterdata = [];
    let searchArray;
    let filterfor;
    if (searchValue != undefined) {
      searchArray = alldata;
    } else {
      searchArray = tempData;
    }

    for (let index = 0; index < searchArray.length; index++) {
      const element = searchArray[index];
      if (forcheck === "registrationdeadline") {
        filterfor = element.register_end_date;
      } else {
        filterfor = element.end_date;
      }
      if (e.value === 2) {
        if (moment(filterfor).diff(moment(new Date()), "days") <= 0) {
          filterdata.push(element);
        }
      } else if (e.value === 1) {
        if (moment(filterfor).diff(moment(new Date()), "days") > 0) {
          filterdata.push(element);
        }
      }
    }
    if (e.value === 0) {
      setAlldata(tempData);
    } else {
      setAlldata(filterdata);
    }
  };

  const registrationManage = (id, status) => {
    console.log("status check", status);
    axios({
      method: "post",
      url: mainURL + "/opportunity/register/accepted",
      headers: { Authorization: AuthStr },
      data: {
        is_accepted: status === "accept" ? 1 : 0,
        opportunity_id: id,
      },
    })
      .then((response) => {
        let oppId = document.getElementById(id);
        oppId.innerHTML = status === "accept" ? "Accepted" : "Rejected";
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };
  const gotodashboard = (hosting_id, title, slug) => {
    localStorage.setItem("hostingId", hosting_id);

    props.history.push({
      pathname: "/user/myregistration/" + hosting_id,
      state: {
        title: title,
        slug: slug,
      },
      key: slug,
    });
  };

  const teamDetailmodal = (team, item) => {
    setTeamModalShow(true);
    setAllmembers(item);
  };
  const renderCard = (item, index) => {
    const regEndDateTime = moment(item.register_end_date).set({
      hour: parseInt(item.register_end_time.split(":")[0], 10),
      minute: parseInt(item.register_end_time.split(":")[1], 10),
    });

    return (
      <div className="row registerbox">
        <div className="col-md-2">
          <div>
            <img src={item.logo_image} className="imgsize" />
          </div>
        </div>
        <div className="col-md-7 centerbox">
          <p className="myhosting-titiles">
            <Link to={`/e/${item.slug}`}>{item.title}</Link>
            {moment(item.end_date).diff(moment(new Date()), "days") <= 0 ? (
              <button className="btn menubtn stautusBtn closed">
                <i className="fa fa-lock"></i> Closed
              </button>
            ) : (
              <button className="btn menubtn stautusBtn ">
                <i className="fa fa-circle"></i> Live
              </button>
            )}
            <i className="fa fa-share-alt shareicon"></i>
            {/* <WhatsappShareButton url="https://www.gradpartners.in/e/HCCB-campus-2023-60">
<WhatsappIcon size={32} round={true} />
</WhatsappShareButton> */}
          </p>
          {/* <p>
  {item.organization}, {item.location}
</p> */}
          <div className="">
            <p>
              {" "}
              Team Name: <span className="team_name">{item.team_name}</span>
            </p>
            <p>
              {" "}
              Team Status:{" "}
              <span className="team_name">
                {item.isAllRegistered === 1 ? "Confirmed" : "Pending"}
              </span>
              <i
                className="fa fa-info-circle infoicon"
                onClick={() => teamDetailmodal(item.allmembers, item)}
              ></i>
            </p>
          </div>
          {/* {item.rounds[0].start_date  + "d" + moment(item.rounds[0].start_date).diff(moment(new Date()), "days") } */}

          {moment(item.rounds[0].start_date).diff(moment(new Date()), "days") >=
          0 ? (
            <div className="">
              <div>
                <p>
                  {" "}
                  Ongoing Round: <span className="team_name">Registration</span>
                </p>
              </div>
              <div>
                <p>
                  {" "}
                  Ongoing Round Deadline:{" "}
                  <span className="team_name">
                    <Moment
                      format="Do MMM YYYY "
                      date={item.register_end_date}
                    />
                  </span>
                </p>
              </div>
            </div>
          ) : (
            <div className="">
              <div>
                <p>
                  {" "}
                  Ongoing Round:{" "}
                  <span className="team_name">{item.rounds[0]?.title}</span>
                </p>
              </div>
              <div>
                <p>
                  {" "}
                  Ongoing Round Deadline:{" "}
                  <span className="team_name">
                    <Moment
                      format="Do MMM YYYY "
                      date={item.rounds[0].end_date}
                    />
                  </span>
                </p>
              </div>
            </div>
          )}

          <p className="mt-10">
            <FaRegCalendarAlt className="roundcal" />
            <Moment format="DD MMM YYYY " date={item.start_date} /> -{" "}
            <Moment format="DD MMM YYYY " date={item.end_date} />{" "}
          </p>
        </div>
        <div className="col-md-3 talignright">
          <p>
            Registered By:{" "}
            {item.teamlead.email === "" ? (
              <>
                <span className="register_by">Self</span>

                {dateFunction(item)}
              </>
            ) : (
              item.teamlead.email
            )}
          </p>
          <div className="mt-15">
            {item.is_accepted === null && item.leader_id != null ? (
              <>
                {regEndDateTime.isAfter(moment()) && (
                  <div id={item.id}>
                    <Popconfirm
                      title="Are you sure to accept this registration?"
                      onConfirm={() => registrationManage(item.id, "accept")}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button className="btn menubtn actionBtn">Accept</button>
                    </Popconfirm>
                    <Popconfirm
                      title="Are you sure to reject this registration?"
                      onConfirm={() => registrationManage(item.id, "reject")}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <button className="btn menubtn actionBtn">Decline</button>
                    </Popconfirm>
                  </div>
                )}
              </>
            ) : item.is_accepted === 0 && item.leader_id != null ? (
              <button className="btn menubtn cancelbtn" disabled>
                Declined Registration
              </button>
            ) : (
              item.is_accepted === 1 &&
              item.leader_id != null && (
                <button className="btn menubtn cancelbtn" disabled>
                  Accepted
                </button>
              )
            )}
          </div>
          {/* {item.show_attachment === 1 && (
      <a href={item.attachment_img} download>
        {" "}
        <button className="btn menubtn ">Case Study </button>
      </a>
    )} */}
        </div>
      </div>
    );
  };

  const finalfilterdata = (e, status) => {
    let statusoflisting;
    let sortof;
    let searchExist;
    if (status === "searchData") {
      setSearchValue(e);
      statusoflisting =
        typeof registrationDeadline != "undefined"
          ? "&status=" + registrationDeadline
          : "";
      sortof =
        typeof eventdeadline !== "undefined"
          ? "&eventstatus=" + eventdeadline
          : "";
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/registered/opportunities?search=" +
          e +
          statusoflisting +
          sortof,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data;
          setTeamdetail(response.data.teamlead);
          setAlldata(alldatas);
          setTempData(alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }

    if (status === "registrationdeadline") {
      console.log("check search", searchValue);
      searchExist = searchValue != "" ? "search=" + searchValue : "";
      sortof =
        typeof eventdeadline !== "undefined"
          ? "&eventstatus=" + eventdeadline
          : "";
      setRegistrationDeadline(e);
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/registered/opportunities?" +
          searchExist +
          "&status=" +
          e +
          sortof,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data;
          setTeamdetail(response.data.teamlead);
          setAlldata(alldatas);
          setTempData(alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
    if (status === "eventdeadline") {
      searchExist = searchValue != "" ? "&search=" + searchValue : "";
      statusoflisting =
        typeof registrationDeadline != "undefined"
          ? "&status=" + registrationDeadline
          : "";

      setEventdeadline(e);
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/registered/opportunities?" +
          searchExist +
          statusoflisting +
          "&eventstatus=" +
          e,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data;
          setTeamdetail(response.data.teamlead);
          setAlldata(alldatas);
          setTempData(alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  };
  return (
    <>
      {alldata && (
        <div className="dashboard-BG">
          <div className="row searchandDropdown">
            <div className="col-md-6">
              <span className="searchIcon">
                <i className="fa fa-search"></i>
              </span>
              {searchValue ? (
                <input
                  type="text"
                  value={searchValue === "" ? "" : searchValue}
                  name=""
                  placeholder="Search for registrations"
                  className="searchbox"
                  // onChange={(e) => searchRegistrations(e)}
                  onChange={(e) =>
                    finalfilterdata(e.target.value, "searchData")
                  }
                ></input>
              ) : (
                <input
                  type="text"
                  value=""
                  name=""
                  placeholder="Search for registrations"
                  className="searchbox"
                  onChange={(e) => searchRegistrations(e)}
                ></input>
              )}
            </div>

            <Modal
              size="sm"
              show={TeamModalShow}
              onHide={() => setTeamModalShow(false)}
              aria-labelledby="example-modal-sizes-title-sm"
              className="plantable"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-sm">
                  Team Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <table className="table table-bordered">
                  <thead className="thead-light">
                    <th>Member Name</th>
                    <th>Member Type</th>
                    <th>Email</th>
                    <th>Institution / Organization</th>
                    <th>Registration Status</th>
                  </thead>
                  <tbody>
                    {allmembers.allmembers?.length > 0 ? (
                      allmembers.allmembers?.map((member, index) => {
                        const data = member?.form_data ? JSON.parse(member.form_data) : null;
                        const school = member?.school || data.college || data.institute_organization;
                        return (
                          <tr key={index}>
                            <td>{member?.name}</td>
                            <td>{member?.is_teamlead === 1 ? "Leader" : "Member"}</td>
                            <td>{member?.email}</td>
                            <td>{school}</td>
                            {allmembers.isAllRegistered === 1 ? (
                              <td>Registered</td>
                            ) : (
                              <td>
                                {member.is_accepted === 1
                                  ? "Accepted"
                                  : member.is_teamlead === 1
                                    ? "Registered"
                                    : member.is_accepted === null ||
                                        member.is_accepted === 2
                                      ? "Pending"
                                      : member.is_accepted === 0 && "Rejected"}
                              </td>
                            )}
                          </tr>
                      )
                      })
                    ) : (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          Pending Registration
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Modal.Body>
            </Modal>
            <div className="col-md-3">
              <div className="dropdownContainer">
                <span className="right-dropdown">Registration Deadline:</span>
                <Select
                  placeholder="Select"
                  // value={selectedOption}
                  options={data}
                  onChange={(e) =>
                    finalfilterdata(e.value, "registrationdeadline")
                  }
                  // onChange={(e) =>
                  //   handleRegistrationDeadline(e, "registrationdeadline")
                  // }
                  getOptionLabel={(e) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {e.icon}
                      <span style={{ marginLeft: 5 }}>{e.text}</span>
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="dropdownContainer">
                <span className="right-dropdown">Event Status: </span>
                <Select
                  placeholder="Select"
                  // value={selectedOption}
                  options={data}
                  onChange={(e) => finalfilterdata(e.value, "eventdeadline")}
                  // onChange={(e) =>
                  //   handleRegistrationDeadline(e, "eventdeadline")
                  // }
                  // onChange={handleChange}
                  getOptionLabel={(e) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {e.icon}
                      <span style={{ marginLeft: 5 }}>{e.text}</span>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div
            id="user-wrapper"
            className="dashboard  content regsisterationpage hostingpage"
          >
            <h3>{tempData && tempData.length} Registrations</h3>
            <Tabs
              style={{
                marginBottom: 32,
              }}
              className="insideTab"
              onChange={(e) => selectetab(e)}
            >
              <TabPane
                tab={
                  <span>
                    <img
                      className="list-icon-img"
                      src={require("../../images/all.png").default}
                      alt="od-banner"
                    />{" "}
                    All ({alldata && alldata.length}){" "}
                  </span>
                }
                key="1"
              >
                {alldata.length ? (
                  alldata.map((item, index) => renderCard(item, index))
                ) : (
                  <div className="text-center">
                    <img class="img-fluid" src="https://gradimages.s3.ap-south-1.amazonaws.com/not-found.png" />
                  </div>
                )}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    {" "}
                    <img
                      className="list-icon-img"
                      src={require("../../images/Icon-Competition.png").default}
                      alt="od-banner"
                    />{" "}
                    Competitions (
                    {alldata &&
                      alldata.reduce(function (n, person) {
                        return n + (person.category == "opportunity");
                      }, 0)}
                    ){" "}
                  </span>
                }
                key="2"
              >
                {alldata.length ? (
                  alldata.map(
                    (item, index) =>
                      item.category === "opportunity" &&
                      renderCard(item, index),
                  )
                ) : (
                  <div className="text-center">
                    <img class="img-fluid" src="https://gradimages.s3.ap-south-1.amazonaws.com/not-found.png" />
                  </div>
                )}
              </TabPane>
              <TabPane
                tab={
                  <span>
                    {" "}
                    <img
                      className="list-icon-img"
                      src={require("../../images/Icon-Workshop.png").default}
                      alt="od-banner"
                    />{" "}
                    Workshops (
                    {alldata &&
                      alldata.reduce(function (n, person) {
                        return n + (person.category == "workshop");
                      }, 0)}
                    ){" "}
                  </span>
                }
                key="3"
              >
                {alldata.length > 0 ? (
                  alldata.map(
                    (item, index) =>
                      item.category === "workshop" && renderCard(item, index),
                  )
                ) : (
                  <div className="text-center">
                    <img class="img-fluid" src="https://gradimages.s3.ap-south-1.amazonaws.com/not-found.png" />
                  </div>
                )}
              </TabPane>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};

export default Userprofile;
