import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./LogIn.css";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Footer from "../CommonFiles/Footer";
import Header from "../CommonFiles/Header";

import { Successmsg } from "./Register";
// import "./paytm.js"
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
var paytmconfig;

const Thankyou = (props) => {
  // alert("fdf")

  return (
    <React.Fragment>
      <Header />

      <div className="bg">
        <div className="col-sm-4 offset-sm-4 main-div">
          <div className="login-body bg-white">
            <h1>
              Thank you for registering for the opportunity. We will share
              further details on your registered email.{" "}
            </h1>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Thankyou;
