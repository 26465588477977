import React, { useEffect, useState } from "react";
import Opportunities from "../StaticPages/Opportunities";
import axios from "axios";
import config from "../CommonFiles/config.json";
import OpportunitiesRightSide from "../CommonFiles/OpportunitiesRightSide";
import { Col, Row, Container, Card, Button } from "react-bootstrap";
import OpportunitiesRightSideCard from "../CommonFiles/OpportunitiesRightSideCard.js";
import { FaCalendarAlt, FaRegClock } from "react-icons/fa";
import { Link } from "react-router-dom";
import moment from "moment";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const RightSidebar = () => {
  const [opportunities, setOpportunities] = useState();
  const [workshops, setWorkshops] = useState(null);
  const [success, setSuccess] = useState("");
  const [opprovestatus, setOpprovestatus] = useState("");

  useEffect(async () => {
    axios({
      method: "get",
      url: mainURL + "/opportunity?from=admin",
      // headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("Error while calling api", response.data.data.rows);

        setOpportunities(response.data.data.rows);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }, []);

  const onMenuClick = () => {
    var leftSidebar = document.querySelector(".left-sidebar");
    leftSidebar.classList.toggle("collapsed-in");
  };

  function statusmanage(id, status) {
    if (status === 1) {
      setOpprovestatus(1);
    }
    if (status === 0) {
      setOpprovestatus(0);
    }
    axios({
      method: "put",
      url: mainURL + "/opportunity/status/" + id,
      data: {
        is_active: status,
      },
    })
      .then((response) => {
        const alldata = response.data.data;
        console.log("alltdata", alldata);
        setSuccess("Event has been approved");
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  return (
    <main className="content">
      <div className="col-md-12 container mb-4">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              <h5> Dashboard </h5>
            </div>
            <div className="col-md-6 text-md-right">
              <p>
                <i className="fa fa-home" aria-hidden="true"></i> &nbsp;Home &nbsp;
                | &nbsp; <i className="fa fa-briefcase" aria-hidden="true"></i>
                &nbsp;Workshop
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="row">
          {opportunities &&
            opportunities.map((oppr, index) => (
              <Col md={4}>
                <div className="opportunities-rightside-cards">
                  <Card className="workshop-card-dash">
                    {oppr.banner_image ? (
                      <Card.Img
                        variant="top"
                        className="listingimg"
                        src={oppr.banner_image}
                      />
                    ) : (
                      <Card.Img
                        variant="top"
                        className="listingimg"
                        src=" https://fasterthemes.com/demo/wix-wordpress-theme/wp-content/themes/wix/images/no-image.png"
                      />
                    )}

                    <p className="bookmark">
                      <FaRegClock />
                      {moment(oppr.end_date).diff(
                        moment(oppr.start_date),
                        "days",
                      )}
                      Days Left
                    </p>
                    <div className="workshop-date">
                      <p>
                        <FaCalendarAlt />
                        {moment(oppr.start_date).format("DD/MM/YYYY")} -
                        {moment(oppr.end_date).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className="workshop-title">
                      <p>{oppr.title}</p>
                      <span>{oppr.organization}</span>
                    </div>
                    <div className="fordashboardview">
                      <Link to={`/dashboard/event/${oppr.slug}`}>
                        <button className="read-more-btn fl"> View</button>
                      </Link>
                      <button
                        className="read-more-btn fr"
                        onClick={() => statusmanage(oppr.id, oppr.is_active)}
                      >
                        {oppr.is_active === 1 ? "Approved" : "Approve"}
                      </button>
                    </div>

                    <div className="cardbottom">
                      <p>
                        <i className="fa fa-user"></i> {oppr.register_count}
                        <br />
                        Registered
                      </p>
                      <p>
                        <i className="fa fa-clock">
                          {moment(oppr.end_date).diff(
                            moment(oppr.start_date),
                            "days",
                          )}
                        </i>
                        <br />
                        days left
                      </p>
                      <p>
                        <i className="fa fa-eye"> {oppr.views} </i> <br />
                        Views
                      </p>
                    </div>
                  </Card>
                </div>
              </Col>
            ))}
        </div>
      </div>
    </main>
  );
};

export default RightSidebar;
