import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Frontendroutes from "./Frontendroutes";
import Swal from "sweetalert2";

function App() {
  // useEffect(() => {
  //  setTimeout(() => {
  //   if (localStorage.getItem("token") != null) {
  //   Swal.fire({
  //     icon: "error",
  //     title: "Oops...",
  //     text: "Please attempt Aptitude assessment first!",
  //   });
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("alldata");
  //   localStorage.clear();
  //   window.location = `/`
  // }

  //  },  300000);
  // }, [])

  return (
    <Router>
      <div className="app">
        <Frontendroutes />
      </div>
    </Router>
  );
}

export default App;
