import React, { useEffect } from "react";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import "./static.css";

function Privacypolicy() {
  window.scrollTo(0, 0);
  useEffect(() => {
    var element = document.getElementById("footershow");
    element.classList.add("footershow");
  }, []);
  return (
    <React.Fragment>
      <Header />
      <div className="privacypolicy"></div>
      <div className="container termandcondition">
        <p>Privacy Policy – We strongly believe in your rights to privacy.</p>

        <p>1. Introduction</p>

        <p>
          Privacy Policy applies to the use of website, contents and all of the
          services offered by Grad Partners (also referred to as ‘we’, ‘our’,
          ‘us’, ‘Company’). This policy mechanism has been designed to identify
          personal information of users that may be collected and to describe
          how we will use your data upon your usage of Glad Partners platform.
        </p>

        <p>
          Grad Partners platform has been designed to democratize the upskilling
          process and list various opportunities using our technology. This
          enables institutes and organizations, limited &amp;amp; non-exclusive
          rights to organize events (quizzes, case submissions, workshops,
          seminars etc.) for relevant target group. We enable clients to reach
          out to target consumer base through promotions, advertisement and
          marketing of their opportunities through a dedicated promotional page
          of their event. In turn, this also allows users to register, attend
          and participate in opportunities conducted by our clients through Grad
          Partners Platform.
        </p>

        <p>
          We continuously review our system consisting your data to protect your
          privacy and to secure any kind of your personal / business
          information. We will investigate in case there is any complaint or
          such actions in order to take legal proceedings to recover damages
          against those responsible. Any User, if not agreed with any
          provision(s) of this Privacy Policy and the Terms of Service, will be
          supposed to leave the website with immediate effect. However, if you
          disagree with this Privacy Policy but want to continue accessing the
          platform website and provide your personal information, Grad Partners
          won’t take any liabilities if arising therefrom. Rather it shall be
          assumed by your use of the Platform to any extent that you already
          agreed with terms of the policy.
        </p>

        <p>2. Scope of Policy</p>

        <p>
          This privacy policy applies to all the users who access our platform
          and are therefore required to go through the privacy policy before
          sharing any personal information. If you already submitted any
          personal information to Grad Partners before going through this policy
          mentioned below, and you do not accept the way of collecting, storing,
          using personal information; then you may access, modify and delete all
          the information stored by reaching out to us at support
          @gradpartners.in.{" "}
        </p>

        <p>
          This Privacy Policy covers only the services offered by Grad Partners
          and platform owned by Grad Partners. This does not applies to the
          bodies, organization, and institutions etc. which are not under our
          direct control. Any third party websites may place their own cookies
          or other files on the users' devices, collect data or solicit personal
          information from the users, for which Grad Partners is not responsible
          or liable. Grad Partners does not make any representation(s)
          concerning the privacy practices or policies of such third parties or
          terms of use of such websites, nor does Grad Partners guarantee the
          accuracy and authenticity of the information, data, text, software,
          sound, photographs, graphics, videos, messages or other materials
          available on such websites. The inclusion or exclusion does not depict
          any endorsement by Grad Partners of that website, the website's
          provider, or the information on the website. Grad Partners recommends
          the users to read the privacy policies of that such website.
        </p>

        <p>
          Grad Partners has taken all reasonable precautions to treat Personal
          Information as confidential with industry standards that it has
          implemented to protect from unauthorized access, improper use or
          disclosure, modification and unlawful destruction or accidental loss
          of the Personal Information.
        </p>

        <p>3. Information collection</p>

        <p>
          Grad Partners offers plethora of services and to access them, a User
          is required to register with us and create an account. For
          registration, we may collect information such as – full name, profile
          picture, e-mail address, residential / official address, contact
          number, login details, date of birth, gender, occupation, name of the
          organization, designation and any other information required for
          various services. Grad Partners may, in future, include other optional
          requests for information from the User to help itself to customize the
          platform to deliver personalized offers to the user.{" "}
        </p>

        <p>
          Other information requested on the registration form, including the
          ability to receive newsletters, promotional offers etc from Grad
          Partners, is optional and can be subscribed / unsubscribed any time.
          In some cases, we are able to send you direct marketing without your
          consent, where we rely on our legitimate interests. However, you have
          an absolute right to opt-out of direct mailers, at any point of time
          by way of links provided at the bottom of each mailer or reaching us
          at{" "}
          <a href="mailto:support@gradpartners.in">support@gradpartners.in</a>.
          In case of its violation of the terms and policies, please write to us
          at <a href="mailto:admin@gradpartners.in">admin@gradpartners.in</a>{" "}
          and report such incident.
        </p>

        <p>
          Grad Partners collects information that is sent over the internet such
          as, but not limited to the Internet protocol (IP) address, operating
          system, login, email, password; browser information- type / version,
          time zone setting, location, internet service provider, type of
          camera, microphone or speakers, browsing behavior &amp;amp; other
          information associated with your interaction with the Platform. We use
          data collection devices such as “cookies” which are files located on
          your hard drive that assist us in providing customized services.
          Cookies enable us to learn about what ads you see, what ads you click,
          and other actions you take on our sites and other sites. Cookies can
          also help us provide information which is targeted to your interests.
          Our Platform uses session cookies to ensure that you have a good
          experience. You may choose to accept or decline cookies by modifying
          your browser settings as per your preference. We may also use various
          third-party cookies to understand behavior and preferences.
        </p>

        <p>
          Grad Partners shall also collect your responses to the various
          opportunities, but not limited to quizzes, submissions, payment
          details, subscription plans, calls received/ made for enquires,
          feedback etc. Your communication for reaching out to us through links
          on platform may be shared with company representatives and used for
          interacting with you further. Grad Partners may keep records of
          telephone calls received and made for making enquiries, registered
          courses, feedback or other requirements for the purpose of providing
          services effectively and efficiently.
        </p>

        <p>4. Secured Personal Information </p>

        <p>
          To protect the security of user’s personal information during
          transmission, we encrypt your inputted information. To prevent
          unauthorized access to sensitive information, it is important for you
          to monitor those who have access to your password and to your
          computer, laptop or any other device through which your account can be
          accessed. Ensure to sign out when finished accessing our platform
          while using a shared device.
        </p>

        <p>5. Use of information</p>

        <p>
          While enrolling for our services you will provide your personal
          information to Grad Partners. If that information is wrong, you can
          request to modify or delete the same. Grad Partners will take all
          reasonable measures to ensure that the personal information is
          modified and used for providing services to you and as otherwise in
          compliance with laws. When modifying or deleting personal information,
          we may ask you to verify your identity before we can act on your
          request. Grad Partners may at its discretion reject any request that
          is contrary to law, requires unreasonable technical efforts. We do not
          assure that we will delete all residual copies and archives made by
          any third party without our knowledge and consent.
        </p>

        <p>
          Grad Partners shall use your personal information to communicate with
          you. It can be done by multiple channels including, but not limited to
          LinkedIn, calls, texts, emails, whatsapp, any other social media
          platforms etc. If at any time you wish to not receive any
          communication from us, you can opt-out of the same by reaching out to
          us on{" "}
          <a href="mailto:support@gradpartners.in">support@gradpartners.in</a>{" "}
        </p>

        <p>6. Information sharing:</p>

        <p>
          Without user's agreement, Grad Partners will not share, rent or sell
          any Personal Information with third parties in any way other than what
          is disclosed in this Policy. We may need to share the relevant data
          when – we have your consent to share your information, provide the
          service/product that you have requested, might need to your
          information to the institutions conducting events.
        </p>

        <p>
          Wherever consent has been taken, you will always be able to withdraw
          that consent, although we may have other legal grounds for processing
          your data for other purposes, such as those set out above.{" "}
        </p>

        <p>
          Event organizers may request for certain additional information of the
          user during an opportunity apart from the data collected by Grad
          Partners as mentioned above. Such information is shared by the user
          directly with the organizers and Grad Partners Platform is in no
          manner involved in such transactions. We shall in no manner be
          responsible and/or liable for the use, misuse, or treatment of such
          information and this Privacy Notice does not govern any such
          information .
        </p>

        <p>7. Deviation cases:</p>

        <p>
          Your Personal Information may be shared with third parties who have a
          need or authority to receive such information, if we have a trust and
          confidence that access, use, preservation or disclosure of the
          information is reasonably necessary to comply with (i) in response to
          any authority having to receive such information under law (ii) any
          order of court (iii) detect, prevent, or otherwise address fraud,
          security or technical issues (iv) protect against harm to the rights,
          property or safety of Grad Partners, our users or the public as
          required or permitted by law. Grad Partners does not make any
          unsolicited calls or otherwise market any products or services, except
          for in relation to the purpose for which such information has been
          provided or taking any feedback, addressing any complaints, informing
          you about new features or free/paid courses. Users must not disclose
          their personal information to any third parties that are not
          authorized by us and verify the identity of such person who seek
          information. Grad Partners will communicate with the users through
          call, message, email or notices posted on the platform etc.{" "}
        </p>

        <p>
          Grad Partners may have to use personal information without reference
          to any persona or identity for research, statistical analysis and
          business intelligence purpose and may transfer such research,
          statistical or intelligence data in an aggregated or non-personally
          identifiable form to third parties and affiliates. All Grad Partners
          employees and data processors, who have access to, and are associated
          with the processing of sensitive personal data or information, are
          obliged to respect the confidentiality of every user's sensitive
          personal data and information. Grad Partners may also disclose or
          transfer User's personal and other information which a User provides,
          to another third party as part of reorganization or a sale of the
          assets of Grad Partners corporation division or company. Any third
          party to which Grad Partners transfers or sells its assets will have
          the right to continue to use the personal and other information that a
          User provide to us. To the extent necessary to provide Users with the
          services on the Website, Grad Partners may provide User’s personal
          information to third party contractors who work on behalf of or with
          Grad Partners to provide Users with such services, to help Grad
          Partners communicate with users or to maintain the platform.
          Generally, these contractors do not have any independent right to
          share this information, however certain contractors who provide
          services on the platform, including the providers of online
          communications services, online payment gateway services, will have
          rights to use and disclose the Personal Information collected in
          connection with the provision of these services in accordance with
          their own privacy policies. Interest-based ads, also sometimes
          referred to as personalised or targeted ads, are displayed to you
          based on information from activities such as subscription on our
          website, visiting sites that contain Grad Partners content or ads
        </p>

        <p>8. Amendments to the Privacy Policy</p>

        <p>
          Grad Partners may update or amend this policy from time to time, with
          or without advance notice. In case of significant changes in the way
          Grad Partners treats users’ personally identifiable information, Grad
          Partners will notify you by communication through website or email.{" "}
        </p>

        <p>9. Data Integrity</p>

        <p>
          Grad Partners processes personal information only for the purposes for
          which it was collected and in accordance with this Privacy Policy or
          any applicable service- specific privacy notice. We review our data
          collection, storage and processing practices to ensure that we only
          collect, store and process the personal information needed to provide
          or improve our services. We take reasonable steps to ensure that the
          personal information we process is accurate, complete, and current,
          but we depend on our users to update or correct their personal
          information whenever necessary.
        </p>

        <p>
          If you have any questions, grievances etc. with respect to website or
          Privacy Policy, please feel free to reach out to us at{" "}
          <a href="mailto:support@gradpartners.in">support@gradpartners.in</a>{" "}
        </p>

        <br />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Privacypolicy;
