import React from "react";
import Footer from "../CommonFiles/Footer";
import Header from "../CommonFiles/Header";
// import Testimonials from "../DynamicPages/Testimonials.js";
import "./AceTheCase.css";
import Testimonials from "../../utils/Testimonials";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "./swiperstyle/style.css";
const AceTheCase = () => {
  const videoPlay = (item) => {
    var videoFiles = "https://www.youtube.com/embed/";
    var videoParam = "autoplay=1&rel=0&showinfo=0&autohide=1";
    document.getElementById("player").src =
      videoFiles + item + "?" + videoParam;
    // Hide an element

    document.getElementById("videoThumb").style.display = "none";
  };

  return (
    <React.Fragment>
      <Header />
      {/* AceTheCase Banner section Start */}
      <section
        id="aceTheCase"
        className="py-4 mt-5 flag-bg"
        style={{
          backgroundImage: `url(require("../img/Ill-Backdrop_Line.svg"))`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 my-5 pe-5 banner-info-box">
              <h1 className="styledFont  mb-4">
                #<span className="colorOrange">A</span>ce
                <span className="colorOrange">T</span>he
                <span className="colorOrange">C</span>ase
              </h1>

              <p className="mb-4">
                An initative to create a learning platform for students who seek
                to ace different aspects of a B-Schooler’s journey
              </p>
              <a href="#workshops" className="buttonMain text-light mt-2">
                Explore <FaArrowRight />
              </a>
            </div>
            <div className="col-lg-7">
              <div className="row  align-items-center">
                <div className="col-md-6 mb-5">
                  <div className="flag mb-4">
                    <img
                      src={require("../../images/img/flag.png").default}
                      className="img-fluid"
                      width="84"
                      height="94"
                    />
                  </div>
                  <div className="elm-card color7 mb-4">
                    <div className="elm-card-content pt-4 px-4">
                      <h4>Real Mentors</h4>
                      <p>
                        We have mentored hundreds of students with acing case
                        competitions.
                      </p>
                    </div>
                    <div className="elm-card-img">
                      <img
                        src={require("../../images/img/elm-card3.png").default}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="elm-card color5 mb-4">
                    <div className="elm-card-content  pt-4 px-4">
                      <h4>Flagship Workshops</h4>
                      <p>
                        Workshop series of Grad Partners mobilizing the thoughts
                        and experiences of domain experts to maximize the
                        learning of students.
                      </p>
                    </div>
                    <div className="elm-card-img px-3 pb-4">
                      <img
                        src={require("../../images/img/elm-card1.png").default}
                        className="img-fluid"
                      />
                      <img
                        src="https://uploads-ssl.webflow.com/60f0a1afc7be292413e48176/6143aa43202ad63a1d409343_hand-wave.gif"
                        className="wave-hand"
                      />
                    </div>
                  </div>
                  <div className="elm-card color6 mb-4">
                    <div className="elm-card-content pt-4 px-4">
                      <h4>Developed Community</h4>
                      <p>
                        We are an ever growing community of 15,000+ students
                        participating from 100+ Institutes.
                      </p>
                    </div>
                    <div className="elm-card-img text-center px-3 pb-4">
                      <img
                        src={require("../../images/img/elm-card2.jpg").default}
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row arrow-row">
            <div className="col-md-5">
              <img
                src={require("../../images/img/Ill-Backdrop_Plane.png").default}
                className="img-fluid"
                width="160px"
                height="100px"
              />
            </div>
          </div>
        </div>
      </section>
      {/*  AceTheCase Banner section End */}
      <section id="workshops">
        <div className="container-fluid px-0 mx-0">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto text-center">
              <h2>
                AceTheCase{" "}
                <span className="color2">
                  <em>2022</em>
                </span>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-11 offset-md-1">
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                // spaceBetween={20}
                // slidesPerView={2.5}
                navigation={{
                  nextEl: ".swiper-btn-next",
                  prevEl: ".swiper-btn-prev",
                }}
                loop={true}
                pagination={{
                  el: ".swiper-pagination",
                  clickable: true,
                }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                className="workshops pb-5"
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 2.7,
                    spaceBetween: 25,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="workshop-info-box">
                      <div className="workshop-image">
                        <img
                          className="img-fluid"
                          src={
                            require("./img/acethecase_2022/asianpaints.png")
                              .default
                          }
                          alt="HUL LIME Season 13"
                        />
                      </div>
                      <div className="workshop-content mt-4 ">
                        <h3>Asian Paints CANVAS</h3>
                        {/* <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut.
                        </p> */}
                      </div>
                      <div className="workshop-meta mb-4">
                        <p>
                          <i className="fa fa-calendar me-2"></i>
                          <span className="me-2">21st August,</span>{" "}
                          <span className="me-2">12:00-01:30 PM</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="workshop-info-box">
                      <div className="workshop-image">
                        <img
                          className="img-fluid"
                          src={require("./img/acethecase_2022/5.png").default}
                          alt="HUL LIME Season 13"
                        />
                      </div>
                      <div className="workshop-content mt-4 ">
                        <h3>HUL LIME Season 13</h3>
                        {/* <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut.
                        </p> */}
                      </div>
                      <div className="workshop-meta mb-4">
                        <p>
                          <i className="fa fa-calendar me-2"></i>
                          <span className="me-2">30th July,</span>{" "}
                          <span className="me-2">10:00-11:30 PM</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="workshop-info-box">
                      <div className="workshop-image">
                        <img
                          className="img-fluid"
                          src={require("./img/acethecase_2022/1.png").default}
                          alt="Flipkart Wired"
                        />
                      </div>
                      <div className="workshop-content mt-4 ">
                        <h3>Google Case Challenge</h3>
                        {/* <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut.
                        </p> */}
                      </div>
                      <div className="workshop-meta mb-4">
                        <p>
                          <i className="fa fa-calendar me-2"></i>
                          <span className="me-2">16th July,</span>{" "}
                          <span className="me-2">5:00-6:30 PM</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="workshop-info-box">
                      <div className="workshop-image">
                        <img
                          className="img-fluid"
                          src={require("./img/acethecase_2022/2.png").default}
                          alt="Flipkart Wired"
                        />
                      </div>
                      <div className="workshop-content mt-4 ">
                        <h3>Flipkart Wired Challenge</h3>
                        {/* <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut.
                        </p> */}
                      </div>
                      <div className="workshop-meta mb-4">
                        <p>
                          <i className="fa fa-calendar me-2"></i>
                          <span className="me-2">10th July,</span>{" "}
                          <span className="me-2">5:00-6:30 PM</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide">
                    <div className="workshop-info-box">
                      <div className="workshop-image">
                        <img
                          className="img-fluid"
                          src={require("./img/acethecase_2022/3.png").default}
                          alt="Flipkart Wired"
                        />
                      </div>
                      <div className="workshop-content mt-4 ">
                        <h3>Hccb India Case Challenge</h3>
                        {/* <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut.
                        </p> */}
                      </div>
                      <div className="workshop-meta mb-4">
                        <p>
                          <i className="fa fa-calendar me-2"></i>
                          <span className="me-2">3rd July,</span>{" "}
                          <span className="me-2">4:00-6:00 PM</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="workshop-info-box">
                      <div className="workshop-image">
                        <img
                          className="img-fluid"
                          src={require("./img/acethecase_2022/4.jpg").default}
                          alt="Flipkart Wired"
                        />
                      </div>
                      <div className="workshop-content mt-4 ">
                        <h3>Amazon Ace Challenge</h3>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut.
                        </p>
                      </div>
                      <div className="workshop-meta mb-4">
                        <p>
                          <i className="fa fa-calendar me-2"></i>
                          <span className="me-2">2nd, July</span>{" "}
                          <span className="me-2">5:00-6:30 PM</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide> */}

                <div className="swiper-buttons text-center">
                  <div className="swiper-btn-prev">
                    <FaArrowLeft />
                  </div>
                  <div className="swiper-pagination"></div>
                  <div className="swiper-btn-next">
                    <FaArrowRight />
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
          {/* <div className="row"><div className="col-lg-12"><p className="text-center"><a href="https://www.gradpartners.in/e/AceTheCase-2022-34" className="buttonMain text-light mt-2">Register Now <FaArrowRight/></a></p>
        </div></div> */}
        </div>
      </section>
      <section id="past-workshops">
        <div className="container">
          <div className="past-workshop-wrapper pt-3">
            <h2 className="text-light text-center py-5">
              Our Past{" "}
              <span className="color3">
                <em>Workshops</em>
              </span>
            </h2>
            {/* <!-- Slider main container --> */}
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              spaceBetween={20}
              slidesPerView={2.5}
              navigation={{
                nextEl: ".swiper-btn-next",
                prevEl: ".swiper-btn-prev",
              }}
              loop={true}
              pagination={{
                el: ".swiper-pagination",
                clickable: true,
              }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
              className="past-workshops  mb-4 pb-5"
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 2.4,
                  spaceBetween: 20,
                },
              }}
            >
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    className="img-fluid"
                    src={require("./img/past_workshop/1.jpg").default}
                    alt="Flipkart Wired"
                  />
                  {/* <div className="past-workshop-info-box">
                    <a href="#" className="buttonMain">
                      View Details
                    </a>
                  </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    className="img-fluid"
                    src={require("./img/past_workshop/2.jpg").default}
                    alt="Flipkart Wired"
                  />
                  {/* <div className="past-workshop-info-box">
                    <a href="#" className="buttonMain">
                      View Details
                    </a>
                  </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    className="img-fluid"
                    src={require("./img/past_workshop/3.jpg").default}
                    alt="Flipkart Wired"
                  />
                  {/* <div className="past-workshop-info-box">
                    <a href="#" className="buttonMain">
                      View Details
                    </a>
                  </div> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    className="img-fluid"
                    src={require("./img/past_workshop/4.jpg").default}
                    alt="Flipkart Wired"
                  />
                  {/* <div className="past-workshop-info-box">
                    <a href="#" className="buttonMain">
                      View Details
                    </a>
                  </div> */}
                </div>
              </SwiperSlide>
              <div className="swiper-buttons text-center text-light my-4">
                <div className="swiper-btn-prev">
                  <FaArrowLeft />
                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-btn-next">
                  <FaArrowRight />
                </div>
              </div>
            </Swiper>
          </div>
        </div>
      </section>
      <section id="campus">
        <div className="container">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto text-center campus-title">
              <h2 className="mb-4">
                AceTheCase{" "}
                <span className="color4">
                  <em>@ Campus</em>
                </span>
              </h2>
              <p>
                Workshop taken by the co-founders themselves at institutions
                sharing their experiences and strategies for acing case
                competitions
              </p>
            </div>
          </div>
          <div className="campus-wrapper px-lg-5">
            <Swiper
              modules={[Navigation, Pagination, A11y]}
              spaceBetween={40}
              slidesPerView={3}
              navigation={{
                nextEl: ".swiper-btn-next",
                prevEl: ".swiper-btn-prev",
              }}
              loop={true}
              pagination={{
                el: ".swiper-pagination",
                clickable: true,
              }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
              className="campus  px-4 pb-4"
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 1,
                  spaceBetween: 50,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
              }}
            >
              <div className="swiper-wrapper mb-5">
                {/* <!-- Slides --> */}

                <SwiperSlide>
                  <div className="swiper-slide">
                    <img
                      className="img-fluid"
                      src={require("./img/campus/1.jpg").default}
                      alt="Flipkart Wired"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <img
                      className="img-fluid"
                      src={require("./img/campus/2.png").default}
                      alt="Flipkart Wired"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <img
                      className="img-fluid"
                      src={require("./img/campus/3.jpg").default}
                      alt="Flipkart Wired"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <img
                      className="img-fluid"
                      src={require("./img/campus/4.jpg").default}
                      alt="Flipkart Wired"
                    />
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide">
                    <img
                      className="img-fluid"
                      src={require("./img/campus/5.jpg").default}
                      alt="Flipkart Wired"
                    />
                  </div>
                </SwiperSlide>
              </div>
              <div className="swiper-buttons text-center mt-4">
                <div className="swiper-btn-prev">
                  <FaArrowLeft />
                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-btn-next">
                  <FaArrowRight />
                </div>
              </div>
            </Swiper>

            {/* <!-- Slider main container --> */}
          </div>
        </div>
      </section>
      <section id="ourStory" className="py-5 text-center">
        <div className="container-fluid">
          <h2>
            Our{" "}
            <span className="color2">
              <em>Story</em>
            </span>
          </h2>
          <div className="row py-lg-5 align-items-md-baseline">
            <div className="col-lg-3">
              <img
                className="img-fluid w-100"
                src={require("./img/Ill-Our_Story_Left.svg").default}
              />
            </div>
            <div className="col-lg-6 col-md-6 mx-auto">
              <div className="mb-4 player">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    id="player"
                    width="100%"
                    height="306"
                    src="https://www.youtube.com/embed/M4mR5sIWUBE"
                    title="YouTube
                  video player"
                    frameborder="0"
                    allow="accelerometer; autoplay;
                  clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <a
                    id="videoThumb"
                    className="video-thumbnail"
                    onClick={() => videoPlay("M4mR5sIWUBE")}
                    href="javascript:void(0)"
                  >
                    <img
                      className="img-fluid w-100"
                      src={require("./img/video-thumbnail.png").default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <img
                className="img-fluid w-100"
                src={require("./img/Ill-Our_Story_Right.svg").default}
              />
            </div>
          </div>
        </div>
      </section>
      <Testimonials />s
      <section
        id="achievements"
        className="py-5 text-center subscriber-achievement"
      >
        <div className="container-fluid px-0">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto">
              <h2>
                Subscribers <span className="color2">Achievements</span>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="pb-md-4 pb-sm-3">
                <Swiper
                  modules={[Navigation, Pagination, A11y, Autoplay]}
                  //     wrapperClass= 'marquee-right__wrapper'
                  // slideClass= 'marquee-right__slide'
                  // touchEventsTarget= 'marquee-right__wrapper'
                  direction="horizontal"
                  slidesPerView={4.5}
                  spaceBetween={30}
                  freeMode={true}
                  freeModeMomentum={false}
                  freeModeMomentumBounce={false}
                  speed={5000}
                  autoplay={{
                    delay: 0,
                    disableOnInteraction: true,
                    //waitForTransition: false,
                    reverseDirection: false,
                  }}
                  loop={true}
                  grabCursor={true}
                  className="marquee-left__rev__container   pb-5"
                >
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/tata-imagination.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/google-challange.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/lime-season.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/ace-challange.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/lorel-brand.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/quest.jpg").default}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="pb-md-4 pb-sm-3">
                <Swiper
                  modules={[Navigation, Pagination, A11y, Autoplay]}
                  //     wrapperClass= 'marquee-right__wrapper'
                  // slideClass= 'marquee-right__slide'
                  // touchEventsTarget= 'marquee-right__wrapper'
                  direction="horizontal"
                  slidesPerView={4.5}
                  spaceBetween={30}
                  freeMode={true}
                  freeModeMomentum={false}
                  freeModeMomentumBounce={false}
                  speed={5000}
                  autoplay={{
                    delay: 0,
                    disableOnInteraction: true,
                    //waitForTransition: false,
                    reverseDirection: true,
                  }}
                  loop={true}
                  grabCursor={true}
                  className="marquee-right__rev__container   pb-5"
                >
                  <SwiperSlide className="marquee-right__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      style={{ width: "180px" }}
                      src={require("../../images/flipkart_wired.jpeg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-right__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/interoberg.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-right__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/tata-cru.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-right__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/hero-campus.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-right__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/loreal-sus.jpg").default}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="pb-md-4 pb-sm-3">
                <Swiper
                  modules={[Navigation, Pagination, A11y, Autoplay]}
                  //     wrapperClass= 'marquee-right__wrapper'
                  // slideClass= 'marquee-right__slide'
                  // touchEventsTarget= 'marquee-right__wrapper'
                  direction="horizontal"
                  slidesPerView={4.5}
                  spaceBetween={30}
                  freeMode={true}
                  freeModeMomentum={false}
                  freeModeMomentumBounce={false}
                  speed={5000}
                  autoplay={{
                    delay: 0,
                    disableOnInteraction: true,
                    //waitForTransition: false,
                    reverseDirection: false,
                  }}
                  loop={true}
                  grabCursor={true}
                  className="marquee-left__rev__container   pb-5"
                >
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/desh-agri.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/oneplus.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/steel.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/over-the-wall.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/offroad.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/microsoft-pm.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/capgemini.jpg").default}
                    />
                  </SwiperSlide>
                  <SwiperSlide className="marquee-left__slide">
                    <img
                      className="img-fluid px-4 py-2"
                      src={require("../../images/pidlite.jpg").default}
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="awards" className="py-5 awards-recognisations">
        <div className="container-fluid">
          <div className="row py-lg-5">
            <div className="col-lg-6 col-md-8 mx-auto text-center">
              <h2>
                Grad <span className="color2">Awards & Recognisations</span>
              </h2>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-2">
              <img className="img-fulid w-100" src={require("./img/Ill-Awards_Left.svg").default} />
            </div>
            <div className="col-lg-8 mx-auto">
              <div className="awards-wrapper">
                <Swiper
                  modules={[Navigation, Pagination, A11y]}
                  spaceBetween={5}
                  slidesPerView={3}
                  navigation={{
                    nextEl: ".swiper-btn-next",
                    prevEl: ".swiper-btn-prev",
                  }}
                  loop={true}
                  pagination={{
                    el: ".swiper-pagination",
                    clickable: true,
                  }}
                  centeredSlides= {true}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                  className="awards py-5"
                  breakpoints= {{
                    // when window width is >= 320px
                    320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                    },
                    // when window width is >= 480px
                    480: {
                    slidesPerView: 1,
                    spaceBetween: 10
                    },
                    // when window width is >= 640px
                    640: {
                    slidesPerView: 3,
                    spaceBetween: 5
                    }
                }}
                >
                  <div className="swiper-wrapper">
                    
                    <SwiperSlide>
                      <div className="awards-inner-wrapper mb-5">
                        <div className="award-thumb px-3">
                          <img
                            className="img-fluid"
                            src={require("./img/awards2.png").default}
                          />
                        </div>
                        <div className="awards-title">
                          <h4 className="t-title mb-1">Kartik Singhal</h4>
                          <p className="t-subtitle mb-0">
                            National Runner Up, Asian Paints CANVAS IIM Calcutta
                          </p>
                          <p className="awards-meta">
                            <i className="fa fa-trophy"></i>
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="awards-inner-wrapper mb-5">
                        <div className="award-thumb px-3">
                          <img
                            className="img-fluid"
                            src={require("./img/awards2.png").default}
                          />
                        </div>
                        <div className="awards-title">
                          <h4 className="t-title mb-1">Kartik Singhal</h4>
                          <p className="t-subtitle mb-0">
                            National Runner Up, Asian Paints CANVAS IIM Calcutta
                          </p>
                          <p className="awards-meta">
                            <i className="fa fa-trophy"></i>
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="awards-inner-wrapper mb-5">
                        <div className="award-thumb px-3">
                          <img
                            className="img-fluid"
                            src={require("./img/awards2.png").default}
                          />
                        </div>
                        <div className="awards-title">
                          <h4 className="t-title mb-1">Kartik Singhal</h4>
                          <p className="t-subtitle mb-0">
                            National Runner Up, Asian Paints CANVAS IIM Calcutta
                          </p>
                          <p className="awards-meta">
                            <i className="fa fa-trophy"></i>
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                     <SwiperSlide>
                      <div className="awards-inner-wrapper mb-5">
                        <div className="award-thumb px-3">
                          <img
                            className="img-fluid"
                            src={require("./img/awards2.png").default}
                          />
                        </div>
                        <div className="awards-title">
                          <h4 className="t-title mb-1">Kartik Singhal</h4>
                          <p className="t-subtitle mb-0">
                            National Runner Up, Asian Paints CANVAS IIM Calcutta
                          </p>
                          <p className="awards-meta">
                            <i className="fa fa-trophy"></i>
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="awards-inner-wrapper mb-5">
                        <div className="award-thumb px-3">
                          <img
                            className="img-fluid"
                            src={require("./img/awards2.png").default}
                          />
                        </div>
                        <div className="awards-title">
                          <h4 className="t-title mb-1">Kartik Singhal</h4>
                          <p className="t-subtitle mb-0">
                            National Runner Up, Asian Paints CANVAS IIM Calcutta
                          </p>
                          <p className="awards-meta">
                            <i className="fa fa-trophy"></i>
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="awards-inner-wrapper mb-5">
                        <div className="award-thumb px-3">
                          <img
                            className="img-fluid"
                            src={require("./img/awards2.png").default}
                          />
                        </div>
                        <div className="awards-title">
                          <h4 className="t-title mb-1">Kartik Singhal</h4>
                          <p className="t-subtitle mb-0">
                            National Runner Up, Asian Paints CANVAS IIM Calcutta
                          </p>
                          <p className="awards-meta">
                            <i className="fa fa-trophy"></i>
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  </div>
                  <div className="swiper-buttons text-center mt-4">
                    <div className="swiper-btn-prev">
                      <FaArrowLeft />
                    </div>
                    <div className="swiper-pagination"></div>
                    <div className="swiper-btn-next">
                      <FaArrowRight />
                    </div>
                  </div>
                </Swiper>
              </div>
            </div>
            <div className="col-lg-2">
            <img className="img-fulid w-100" src={require("./img/Ill-Awards_Right.svg").default} />
          </div>
          </div>
        </div>
      </section> */}
      {/* Your Campus College collaboration Section End*/}
      {/* Subscribers achievements Section Start*/}
      {/* Subscribers achievements Section End*/}
      <div
        className="modal fade"
        id="eventVideo"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
        >
          <div className="modal-content ">
            <button
              type="button"
              className="close upcoming-event-close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-body upcoming-event-modal-video">
              <iframe
                width="100%"
                height="450px"
                src="https://www.youtube.com/embed/M4mR5sIWUBE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Video modal end--> */}
      <Footer />
    </React.Fragment>
  );
};

export default AceTheCase;
