import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
// import "./swiperstyle/style.css";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";

export default function Testimonials() {
  return (
    <div>
      {" "}
      <section id="testimonials" className="py-5 text-center">
        <div className="container">
          <h1>Testimonials</h1>
          <p className="subtitle">
            Read what our talented community members are saying about us{" "}
          </p>
          <div className="row py-lg-5">
            <div className="col-lg-12 mx-auto">
              <div className="container">
                <div className="testimonial-wrapper">
                  <Swiper
                    modules={[Navigation, Pagination, A11y]}
                    spaceBetween={20}
                    slidesPerView={1}
                    navigation={{
                      nextEl: ".swiper-btn-next",
                      prevEl: ".swiper-btn-prev",
                    }}
                    loop={true}
                    pagination={{
                      el: ".swiper-pagination",
                      clickable: true,
                    }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log("slide change")}
                    className="testimonial pb-5"
                  >
                    <div className="swiper-wrapper">
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="row align-items-center">
                            <div className="col-lg-3 px-4">
                              <div className="testimonial-thumb px-3">
                                <img
                                  className="testimonial-thumbnail img-fluid"
                                  src={
                                    require("../../images/karan_gauba.jpeg")
                                      .default
                                  }
                                  alt="Karan Gauba"
                                />
                              </div>
                              <div className="testimonial-title">
                                <h4 className="t-title mb-0">Karan Gauba</h4>
                                <p className="t-subtitle mb-0">
                                  International Runner Up, L'Oreal Brandstorm
                                  2021 ,NMIMS Mumbai
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-9 testimonial-bg py-4">
                              <div className="testimonial-content-box pe-md-5">
                                <p>
                                  The sessions from Grad Partners across last
                                  four months have enabled exponential growth in
                                  my Skillset. The team has been immensely
                                  helpful, has served as a guiding light for
                                  acing at competitions, and has opened my mind
                                  to the out of the box approach. The guidance
                                  provided by the team is easy to understand -
                                  offered by top alumnus from different
                                  B-Schools. AceTheCase team is highly proactive
                                  and works round the clock to help clear each
                                  and everyone’s doubts. I look forward to the
                                  upcoming sessions filled with peer-to-peer
                                  learning and the opportunity to expand my
                                  horizons. Lastly, I would like to mention that
                                  with the help, support, and guidance of the
                                  GRAD Team, my team could bag the International
                                  Runner’s up position in the coveted L'Oreal
                                  Brandstorm 2021
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="row align-items-center">
                            <div className="col-lg-3 px-4">
                              <div className="testimonial-thumb px-3">
                                <img
                                  className="testimonial-thumbnail img-fluid"
                                  src={
                                    require("../../images/sohan-bougle.png")
                                      .default
                                  }
                                  alt="Sohan Bulge"
                                />
                              </div>
                              <div className="testimonial-title">
                                <h4 className="t-title mb-0">Sohan Bulge</h4>
                                <p className="t-subtitle mb-0">
                                  National Finalist, HUL LIME XII , IIM
                                  Bangalore
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-9 testimonial-bg py-4">
                              <div className="testimonial-content-box pe-md-5">
                                <p>
                                  If you are looking for a formula to ace Case
                                  Competitions, look no further! Ace The Case is
                                  the One Stop Shop for all things Case
                                  competitions. Personally, I feel that their
                                  weekly workshops with past case competition
                                  winners helped shape me for case competitions
                                  & imbibe in me the elements that help your
                                  submissions stand out. Special thanks to
                                  Ayushmn and Ameer for always showing genuine
                                  interest in our development! Looking back,
                                  joining AceTheCase was a fruitful decision,
                                  helping me a great deal with my personal
                                  development
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="row align-items-center">
                            <div className="col-lg-3 px-4">
                              <div className="testimonial-thumb px-3">
                                <img
                                  className="testimonial-thumbnail img-fluid"
                                  src={
                                    require("../../images/kartik_singhal.jpeg")
                                      .default
                                  }
                                  alt="Kartik Singhal"
                                />
                              </div>
                              <div className="testimonial-title">
                                <h4 className="t-title mb-0">Kartik Singhal</h4>
                                <p className="t-subtitle mb-0">
                                  National Runner Up, Asian Paints CANVAS IIM
                                  Calcutta
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-9 testimonial-bg py-4">
                              <div className="testimonial-content-box pe-md-5">
                                <p>
                                  AceTheCase is a great initiative towards
                                  helping students become better at case
                                  competitions. Workshops organised by Grad
                                  Partners helped me understand business cases
                                  better and it also helped me structure my
                                  solutions better. Speakers walk us though
                                  their presentations step-by-step and deep dive
                                  into the though process behind it. Also the
                                  workshops organised by the team cover all
                                  types of case including HR, Marketing,
                                  Finance, Technology which helps in overall
                                  development of case solving skills.I
                                  especially thank Ayushmn and Ameer for
                                  starting such a platform for helping B-school
                                  students",
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="swiper-slide">
                          <div className="row align-items-center">
                            <div className="col-lg-3 px-4">
                              <div className="testimonial-thumb px-3">
                                <img
                                  className="testimonial-thumbnail img-fluid"
                                  src={
                                    require("../../images/Pushkar_Bhattacharya.jpeg")
                                      .default
                                  }
                                  alt="Kartik Singhal"
                                />
                              </div>
                              <div className="testimonial-title">
                                <h4 className="t-title mb-0">
                                  Pushkar Bhattacharya
                                </h4>
                                <p className="t-subtitle mb-0">
                                  National Runner Up, Asian Paints CANVAS IIM
                                  Calcutta
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-9 testimonial-bg py-4">
                              <div className="testimonial-content-box pe-md-5">
                                <p>
                                  If you are looking for a formula to ace Case
                                  Competitions, look no further! Ace The Case is
                                  the One Stop Shop for all things Case
                                  competitions. Personally, I feel that their
                                  weekly workshops with past case competition
                                  winners helped shape me for case competitions
                                  & imbibe in me the elements that help your
                                  submissions stand out. Special thanks to
                                  Ayushmn and Ameer for always showing genuine
                                  interest in our development! Looking back,
                                  joining AceTheCase was a fruitful decision,
                                  helping me a great deal with my personal
                                  development.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </div>

                    <div className="swiper-buttons text-center mt-4">
                      <div className="swiper-btn-prev">
                        <FaArrowLeft />
                      </div>
                      <div className="swiper-pagination"></div>
                      <div className="swiper-btn-next">
                        <FaArrowRight />
                      </div>
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
