import React, { useState, useEffect } from "react";
import "./Register.css";
import { Link } from "react-router-dom";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Footer from "../CommonFiles/Footer";
import Header from "../CommonFiles/Header";
import Form from "react-bootstrap/Form";
// import CreatableSelect from 'react-select/creatable';
import Swal from "sweetalert2";

import { Select } from "antd";
const Option = Select.Option;
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
var Successmsg;

const Register = (props) => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [collagename, setCollageshow] = useState("");
  const [confirmpass, setConfirmpass] = useState("");
  const [errormsg, setErrorpass] = useState("");
  const [validation, setValidation] = useState();
  const [showeye, setShoweye] = useState();
  const [showconfirmeye, setconfirmShoweye] = useState();
  const [collages, setAllcollages] = useState();
  const [matched, setAllmatched] = useState();
  let [filterstatus, setFilterstatus] = useState(0);

  const [organisationtext, setOrganisationtext] = useState();

  // const [collages, setfilterAllcollages] = useState();

  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    confrmpassword: "",
    organization: "",
    gender: "",
  });
  const [error, setError] = useState([]);

  useEffect(async () => {
    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/allcollages",
      });
      setAllcollages(result.data.data);
      console.log(collages.lenght);
    } catch (error) {
      console.log(error);
    }
  }, []);

  function signUp(params) {
    setValidation(0);

    // console.log("inputValue" , inputValue)
    // return false;

    console.log(
      "inputValue.password",
      inputValue.name,
      inputValue.confrmpassword,
    );

    const error = [];

    if (inputValue.password != inputValue.confrmpassword) {
      setErrorpass("password is not matched");
      // return false;
    }

    if (!inputValue.first_name) {
      error["first_name"] = "Please Enter your First Name";
      setValidation(1);
    }
    if (!inputValue.last_name) {
      error["last_name"] = "Please Enter your Last Name";
      setValidation(1);
    }
    const filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!inputValue.email) {
      error["email"] = "";
      setValidation(1);
    }

    if (filter.test(inputValue.email)) {
      // Yay! valid
    } else {
      error["email"] = "Please Enter Valid email address";
    }
    if (!inputValue.mobile) {
      error["mobile"] = "Please Enter your mobile";
      setValidation(1);
    }
    if (!inputValue.password) {
      error["password"] = "Please Enter your password";
      setValidation(1);
    }
    if (!inputValue.confrmpassword) {
      error["confrmpassword"] = "Please Enter your confrmpassword";
      setValidation(1);
    }
    // if (!inputValue.organization) {
    //   error["organization"] = "Please Enter your organization";
    //   setValidation(1);
    // }
    if (!inputValue.gender) {
      error["gender"] = "Please Select gender";
      setValidation(1);
    }

    setError(error);
    console.log("error", error);

    console.log("inputValue", validation);
    if (validation === 0) {
      axios({
        method: "post",
        url: mainURL + "/register",
        data: inputValue,
      })
        .then((response) => {
          const alldata = response.data.data;
          localStorage.setItem("token", response.data.accessToken);

          setMsg("You have successfully registered");
          Successmsg = "You have successfully registered";
          if (response.data.status === 1) {
            props.history.push(`/login`);
            setInputValue({
              name: "",
              email: "",
              mobile: "",
              password: "",
              confrmpassword: "",
              organization: "",
              gender: "",
            });
          }
          if (response.data.status === 0) {
            setMsg("User Already Registered!");
          }

          console.info("all analytics", alldata);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  }
  const handleChangesearch = (newValue, actionMeta) => {
    console.group("Value Changed");
    console.log("get val", newValue.value);
    axios({
      method: "post",
      url: mainURL + "/collages",
      data: {
        name: newValue.value,
      },
    })
      .then((response) => {})
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };
  const handleInputChange = (inputValue, actionMeta) => {
    console.group("Input Changed");
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };
  function handleChange(value) {
    console.log(`selected ${value}`);
    setInputValue({ ...inputValue, organization: value });
  }

  function handleBlur() {
    console.log("blur");
  }

  function handleFocus() {
    console.log("focus");
  }

  const onInputChange = (val, name) => {
    setInputValue({ ...inputValue, [name]: val });
  };

  // function selectorganisation() {

  // }

  function alphaOnly(event) {
    var key = event.keyCode;
    return (key >= 65 && key <= 90) || key == 8;
  }

  var passwordField = document.querySelector(".password-field");
  const showPassword = (e) => {
    if (passwordField.type === "password") {
      passwordField.type = "text";
      // showPw.classList.add("fas fa-eye")
      setShoweye(1);
    } else {
      passwordField.type = "password";
      setShoweye(0);
      // showPw.classList.add("fas fa-eye-slash")
    }
  };
  // confirm pass
  var fonfrmpasswordField = document.querySelector(".confirm-password-field");
  const showConfirmPassword = (e) => {
    if (fonfrmpasswordField.type === "password") {
      fonfrmpasswordField.type = "text";
      // showPw.classList.add("fas fa-eye")
      setconfirmShoweye(1);
    } else {
      fonfrmpasswordField.type = "password";
      setconfirmShoweye(0);
      // showPw.classList.add("fas fa-eye-slash")
    }
  };

  function selectorganisation(e, index) {
    let filet = [];
    //   var filteredNames = allproject.filter(function(word) {
    //     return word.name.charAt(index) === e.target.value;
    //  });
    console.log("search data", e);
    setOrganisationtext(e);
    if (e === "") {
      setAllmatched([]);
      setFilterstatus(0);
    } else {
      const matches = collages.filter((s) =>
        s.label.toLowerCase().includes(e.toLowerCase()),
      );
      setAllmatched(matches);
      console.log("allproject fdfd", collages);
      setFilterstatus(1);
    }
  }
  function getorganization(val, name) {
    setInputValue({ ...inputValue, organization: val });
    setAllmatched([]);
    setCollageshow(val);

    setFilterstatus(0);
  }
  function createorg(params) {
    Swal.fire({
      title: "Organisation Name",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      inputValue: organisationtext,
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        console.log("login", login);
        if (login === "") {
          Swal.showValidationMessage(`Please Enter Organisation Name`);
        }
        setCollageshow(login);
        setOrganisationtext(login);
        axios({
          method: "post",
          url: mainURL + "/collages",
          data: {
            name: login,
          },
        })
          .then((response) => {
            setFilterstatus(0);
          })
          .catch(function (response) {
            //handle error
            console.log("Error while calling api", response);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Your Institute has been listed",
          imageUrl: result.value.avatar_url,
        });
        setAllmatched([]);
      }
    });
  }

  return (
    <React.Fragment>
      <Header />
      <div className="signup-bg">
        <div className=" ">
          {/* <div className="col-md-4 offset-sm-1">
                <img src="https://hrmcdn-13a1c.kxcdn.com/images/hr-sg/content-images/jerene-aug-2020-jobs-recruitment-concept-123rf.jpg" className="regimg" />
            </div> */}
          <div className="col-md-5 offset-sm-4 main-div">
            <div className="register-body">
              <h1>User Sign Up</h1>
              <h5 className="errormsg">{msg && msg}</h5>
              <input
                type="text"
                name="first_name"
                value={inputValue.first_name}
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                className={
                  error.first_name
                    ? "form-control errormsgborder register-body-form"
                    : "form-control register-body-form"
                }
                placeholder="First Name"
              />
              <i className="fa fa-user material-icons"></i>
              {/* <p className="text-muted errormsg">{error.first_name}</p> */}
              <br />
              <input
                type="text"
                name="last_name"
                value={inputValue.last_name}
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                className={
                  error.last_name
                    ? "form-control errormsgborder register-body-form"
                    : "form-control register-body-form"
                }
                placeholder="Last Name"
              />
              <i className="fa fa-user material-icons"></i>
              {/* <p className="text-muted errormsg">{error.last_name}</p> */}
              <br />
              <input
                type="email"
                name="email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                value={inputValue.email}
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                className={
                  error.email
                    ? "form-control errormsgborder register-body-form"
                    : "form-control register-body-form"
                }
                placeholder="Email-ID"
              />
              <i className="fa fa-envelope  material-icons"></i>
              {error.email && (
                <span className="text-muted errormsg">{error.email}</span>
              )}
              <br />
              <input
                type="tel"
                name="mobile"
                value={inputValue.mobile}
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                className={
                  error.mobile
                    ? "form-control errormsgborder register-body-form"
                    : "form-control register-body-form"
                }
                placeholder="Phone no."
                maxLength="10"
              />
              <i className="fa fa-phone  material-icons"></i>
              {/* {error.mobile && (
              <p className="text-muted errormsg">{error.mobile}</p>
            )} */}
              <br />
              <input
                type="password"
                name="password"
                value={inputValue.password}
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                className={
                  error.password
                    ? "form-control errormsgborder password-field register-body-form"
                    : "password-field form-control register-body-form"
                }
                placeholder="Password"
              />
              <i className="fa fa-lock  material-icons"></i>{" "}
              {showeye === 1 ? (
                <i className="fas fa-eye show-pw" onClick={showPassword}></i>
              ) : (
                <i
                  className="fas fa-eye-slash show-pw"
                  onClick={showPassword}
                ></i>
              )}
              {/* <p className="text-muted errormsg">{error.password}</p> */}
              <br />
              <input
                type="password"
                name="confrmpassword"
                value={inputValue.confrmpassword}
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                className={
                  error.confrmpassword
                    ? "form-control confirm-password-field errormsgborder register-body-form"
                    : "form-control confirm-password-field register-body-form"
                }
                placeholder="Confirm password"
              />
              <i className="fa fa-lock  material-icons"></i>{" "}
              {showconfirmeye === 1 ? (
                <i
                  className="fas fa-eye show-pw"
                  onClick={showConfirmPassword}
                ></i>
              ) : (
                <i
                  className="fas fa-eye-slash show-pw"
                  onClick={showConfirmPassword}
                ></i>
              )}
              {/* <p className="text-muted errormsg">{error.confrmpassword}</p> */}
              <br />
              <input
                type="text"
                name="organization"
                key={collagename}
                defaultValue={collagename}
                onChange={(e) =>
                  selectorganisation(e.target.value, e.target.name)
                }
                className={
                  error.mobile
                    ? "form-control errormsgborder register-body-form"
                    : "form-control register-body-form"
                }
                placeholder="Institution / Organization (Enter Full Name)"
              />
              <i className="fa fa-university material-icons"></i>
              <br />
              <div>
                {filterstatus === 1 && (
                  <ul
                    className={
                      Object.keys(matched).length < 4
                        ? "autoheight"
                        : "uldatacollage"
                    }
                  >
                    {matched.map((item) => (
                      <li
                        onClick={() =>
                          getorganization(item.label, "organization")
                        }
                      >
                        {item.label}
                      </li>
                    ))}
                    <li>
                      {organisationtext} ..{" "}
                      <span className="createcolor" onClick={() => createorg()}>
                        List Institute
                      </span>
                    </li>
                  </ul>
                )}
              </div>
              <div
                className={
                  error.gender
                    ? "radiobtnregister  errormsgborder form-control register-body-form"
                    : "radiobtnregister form-control register-body-form"
                }
              >
                Gender
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  onChange={(e) => onInputChange(e.target.value, e.target.name)}
                  className="form-control"
                />
                Male
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  onChange={(e) => onInputChange(e.target.value, e.target.name)}
                  className="form-control"
                />
                Female
                <input
                  type="radio"
                  name="gender"
                  value="others"
                  onChange={(e) => onInputChange(e.target.value, e.target.name)}
                  className="form-control"
                />
                Others
              </div>
              {errormsg && <p className="errormsg">{errormsg}</p>}
              <br />
              <button onClick={() => signUp()} className="btn btn-primary">
                Sign Up
              </button>
              {window.lintrk("track", { conversion_id: 5201505 })}
              <p>
                Already have an account ? &nbsp;&nbsp;
                <Link to="/LogIn">
                  <a className="sign-up" href="#">
                    Log In
                  </a>
                </Link>
              </p>
            </div>
          </div>
          {/* <div className="col-md-5 offset-sm-4 main-div">
          <img src="https://d8it4huxumps7.cloudfront.net/uploads/images/d2c-images/login-banner.svg)" />
        </div> */}
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export { Register, Successmsg };
