import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import PropTypes from "prop-types";
import QuizResultFilter from "./core-components/QuizResultFilter";
import { checkAnswer, rawMarkup } from "./core-components/helpers";
import InstantFeedback from "./core-components/InstantFeedback";
import Explanation from "./core-components/Explanation";
import $ from "jquery";
import axios from "axios";
import config from "../../CommonFiles/config.json";
import Swal from "sweetalert2";
import moment from "moment";
import Stopwatch from "../../DynamicPages/stopWatch";

// import {FaLongArrowAltLeft} from "@react"
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

function closedWin() {
  alert("close ?");
  return false; /* which will not allow to close the window */
}
if (window.addEventListener) {
  window.addEventListener("close", closedWin, false);
}

const Core = (
  {
    questions,
    quizseting,
    editableAns,
    setQuestionIndex,
    suffleAnswer,
    appLocale,
    showDefaultResult,
    onComplete,
    customResultPage,
    showInstantFeedback,
    continueTillCorrect,
    onUpdateQuestionIndex,
    notificationmsg,
    timestart,
    handleNotification,
    handleLeaveMeeting,
  },
  props,
) => {
  // let qq = JSON.parse(localStorage.getItem("suffle"))
  // console.log("setQuestionIndex on core", qq)
  // console.log("questions on core", questions)
  let quizStartTimesecond = moment();
  let ssminutes = Math.floor(quizStartTimesecond / 60);
  let ssseconds = quizStartTimesecond - ssminutes * 60;

  const [incorrectAnswer, setIncorrectAnswer] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [showNextQuestionButton, setShowNextQuestionButton] = useState(false);
  const [endQuiz, setEndQuiz] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] =
    useState(setQuestionIndex);
  const [buttons, setButtons] = useState({});
  const [buttonClasses, setButtonClasses] = useState({});
  const [correct, setCorrect] = useState([]);
  const [incorrect, setIncorrect] = useState([]);
  const [userInput, setUserInput] = useState([]);
  const [filteredValue, setFilteredValue] = useState("all");
  const [userAttempt, setUserAttempt] = useState(1);
  const [showDefaultResultState, setShowDefaultResult] = useState(true);
  const [answerSelectionTypeState, setAnswerSelectionType] =
    useState(undefined);
  const [answers, setAnswers] = useState([]);
  const [lastque, setLastque] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [correctPoints, setCorrectPoints] = useState(0);
  // console.log("111111", questions[currentQuestionIndex])
  // console.log("111111", questions[0])
  const [getAns, setGetAns] = useState([]);
  const [question, setQuestion] = useState(questions[0]);
  const [questionSummary, setQuestionSummary] = useState(undefined);
  const [myQuestions, setMyQuestions] = useState([]);
  const [userAns, setUserAns] = useState();
  // if(setQuestionIndex + 1 === questions.length)
  //   {
  //     setLastque(true)
  //   }

  document.addEventListener("visibilitychange", (event) => {
    console.log("full screen", quizseting[0].full_screen);
    if (document.visibilityState == "visible") {
      console.log("tab is active");
      window.focus();
    } else if (quizseting[0].full_screen === "yes") {
      console.log("tab is inactive");

      Swal.fire({
        title:
          "Please refrain from changing this window or violating the full-screen mode. Such repeated instances will lead to the termination of the assessment. For now, we are redirecting you to the main page.",
        // text: "You Want Start the quiz !",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        allowOutsideClick: false,
        cancelButtonColor: "#d33",
        confirmButtonText: "Continue",
      }).then((result) => {
        if (result.isConfirmed) {
          let slugName = localStorage.getItem("eventslug");
          window.open("/e/" + slugName, "_self");
          // props.history.push('/e/'+slugName)

          // setEndQuiz(true)
        }
      });
    }
  });
  useEffect(() => {
    // document.oncontextmenu = document.body.oncontextmenu = function() {return false;}

    console.log("qe list", question);

    setShowDefaultResult(
      showDefaultResult !== undefined ? showDefaultResult : true,
    );

    // localStorage.setItem("userquestions" , question.question)
  }, [showDefaultResult]);

  $(window).blur(function () {
    // alert('You are not allowed to leave page blah blah');
    // return false
    // do something else
  });

  // useEffect(() => {
  //   console.log("currentQuestionIndex1", questions)
  //   // console.log("currentQuestionIndex1", currentQuestionIndex)
  //   // console.log("currentQuestionIndex1", questions[2])
  //   // console.log("currentQuestionIndex1", questions[0])
  //   let question = questions[0]
  //   console.log("currentQuestionIndex1", question)
  //   setQuestion(question);
  //   setAnswers([...questions[currentQuestionIndex].answers])

  // }, []);

  // useEffect(() => {
  //   console.log("questions[0] : ", questions[0])
  //   console.log("questions core", questions)

  //   setQuestion(questions[0])
  // }, [questions])

  useEffect(() => {
    // console.log("questions index 12", setQuestionIndex)
    // console.log("currentQuestionIndex12", questions[currentQuestionIndex])
    // console.log("questions core", questions)
    // let question = questions[0]
    // console.log("questions core", question)

    setCurrentQuestionIndex(setQuestionIndex);
    setQuestion(questions[setQuestionIndex]);
    console.log("checking", questions);

    setAnswers([...questions[setQuestionIndex].answers]);
    console.log("checking", questions);
  }, [setQuestionIndex, questions]);

  useEffect(() => {
    let { answerSelectionType } = question;
    // Default single to avoid code breaking due to automatic version upgrade
    setAnswerSelectionType(answerSelectionType || "single");
  }, [JSON.parse(localStorage.getItem("suffle")), currentQuestionIndex]);

  useEffect(() => {
    if (endQuiz) {
      let totalPointsTemp = 0;
      let correctPointsTemp = 0;
      for (let i = 0; i < questions.length; i++) {
        let point = questions[i].point || 0;
        if (typeof point === "string" || point instanceof String) {
          point = parseInt(point);
        }

        totalPointsTemp = totalPointsTemp + point;

        if (correct.includes(i)) {
          correctPointsTemp = correctPointsTemp + point;
        }
      }
      setTotalPoints(totalPointsTemp);
      setCorrectPoints(correctPointsTemp);
    }
  }, [endQuiz]);

  useEffect(() => {
    console.log("step2");
    localStorage.setItem("finishbtn", false);
    setQuestionSummary({
      numberOfQuestions: questions.length,
      numberOfCorrectAnswers: correct.length,
      numberOfIncorrectAnswers: incorrect.length,
      questions: questions,
      userInput: userInput,
      totalPoints: totalPoints,
      correctPoints: correctPoints,
    });
  }, [totalPoints, correctPoints]);

  useEffect(() => {
    console.log("step 3");
    if (endQuiz && onComplete !== undefined && questionSummary !== undefined) {
      onComplete(questionSummary);
    }
  }, [endQuiz, questionSummary, JSON.parse(localStorage.getItem("suffle"))]);

  const nextQuestion = (currentQuestionIndex, qlist) => {
    var retrievedObject = JSON.parse(localStorage.getItem("userQA"));

    const propertyValues = Object.entries(qlist);
    let finalAns = userAns;

    console.log("qlist outside", userAns);

    if (userAns === "") {
      if (
        retrievedObject.some(
          (elem) => (elem.question === qlist.question) === true,
        )
      ) {
        for (let i = 0; i < retrievedObject.length; i++) {
          const element = retrievedObject[i];
          if (element.question === qlist.question) {
            console.log(
              "qlist inside",
              element.userAnswer,
              qlist.question,
              retrievedObject,
              retrievedObject.some((elem) => elem.question === qlist.question),
            );

            finalAns = element.userAnswer;
          }
        }
      }
    }

    checkAnswer(finalAns, correctAnswer, "single", question, {
      userInput,
      userAttempt,
      currentQuestionIndex,
      continueTillCorrect,
      showNextQuestionButton,
      incorrect,
      correct,
      myQuestions,
      setButtons,
      setCorrectAnswer,
      setIncorrectAnswer,
      setCorrect,
      setIncorrect,
      setShowNextQuestionButton,
      setUserInput,
      setUserAttempt,
      setMyQuestions,
    });
    setGetAns(JSON.parse(localStorage.getItem("userQA")));

    setIncorrectAnswer(false);
    setCorrectAnswer(false);
    setUserAns("");
    setShowNextQuestionButton(false);
    setButtons({});
    onUpdateQuestionIndex(currentQuestionIndex + 1);
    // console.log("questions.length index" , currentQuestionIndex + 1)
    // console.log("questions.length" , questions.length)
    if (currentQuestionIndex + 2 === questions.length) {
      console.log(
        "last que",
        currentQuestionIndex + 2,
        "question length",
        questions.length,
      );
      localStorage.setItem("finishbtn", true);
      // setLastque(true)
    }

    if (currentQuestionIndex + 1 === questions.length) {
      console.log(localStorage.getItem("userQA"));
      const USER_TOKEN = localStorage.getItem("token");
      console.log("user token", USER_TOKEN);
      let date1 = moment();
      let date2 = moment(localStorage.getItem("quizStartTime"));
      let takeminuts = date1.diff(date2, "minutes");
      let takeseconds = date1.diff(date2, "seconds");
      let ssminutes = Math.floor(takeseconds / 60);
      let ssseconds = takeseconds - ssminutes * 60;
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      axios({
        method: "post",
        url: mainURL + "/quize",
        data: {
          all_data: localStorage.getItem("userQA"),
          questions_list: localStorage.getItem("suffle"),
          opportunity_id: localStorage.getItem("quiz_id"),
          is_completed: 1,
          start_time: moment(localStorage.getItem("quizStartTime")).format(
            "DD-MM-YYYY hh:mm:ss a",
          ),
          end_time: moment().format("DD-MM-YYYY hh:mm:ss a"),
          total_time: ssminutes + ":" + ssseconds,
          is_quiz: 1,
        },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          // setUploadmsg("Submission has been successfully uploaded");
          localStorage.removeItem("userQA");
          // localStorage.setItem("finish-quiz", true);
          localStorage.removeItem("quizStartTime");
          localStorage.removeItem("suffle");
          localStorage.removeItem("quiz_id");
          localStorage.setItem("finishbtn", true);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
      // setEndQuiz(true)
      // props.history.push(`/quiz-detail`)
      localStorage.setItem("finish-quiz", true);

      // window.location = `/quiz-finish`;
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const prevQuestion = (currentQuestionIndex) => {
    console.log("currentQuestionIndex", currentQuestionIndex);
    setIncorrectAnswer(false);
    setCorrectAnswer(false);
    // setShowNextQuestionButton(false);
    setButtons({});
    onUpdateQuestionIndex(currentQuestionIndex - 1);

    console.log("questions.length index", currentQuestionIndex + 1);
    console.log("questions.length", questions.length);
    if (currentQuestionIndex < questions.length) {
      // setLastque(false)
      localStorage.setItem("finishbtn", false);
    }

    if (currentQuestionIndex === 0) {
      return;
      setEndQuiz(true);
    } else {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const finishquiz = (currentQuestionIndex) => {
    console.log("step1 ");
    Swal.fire({
      title: "Do you want to submit your answers and finish quiz?",
      text: "Quiz will be submitted immediately. You can’t undo this action.",
      icon: "warning",
      className: "finish-alert",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const USER_TOKEN = localStorage.getItem("token");
        console.log("user token", USER_TOKEN);
        const AuthStr = "Bearer ".concat(USER_TOKEN);
        let date1 = moment();
        let date2 = moment(localStorage.getItem("quizStartTime"));
        let takeminuts = date1.diff(date2, "minutes");
        let takeseconds = date1.diff(date2, "seconds");
        let ssminutes = Math.floor(takeseconds / 60);
        let ssseconds = takeseconds - ssminutes * 60;
        if (ssseconds < 10) {
          ssseconds = "0" + ssseconds;
        }

        let finaldata;
        if (localStorage.getItem("userQA") === null) {
          finaldata = localStorage.getItem("suffle");
        } else {
          finaldata = localStorage.getItem("userQA");
        }
        axios({
          method: "post",
          url: mainURL + "/quize",
          data: {
            all_data: finaldata,
            questions_list: localStorage.getItem("suffle"),
            opportunity_id: localStorage.getItem("eventid"),
            round_id: localStorage.getItem("selectedRound"),
            is_completed: 1,
            start_time: moment(localStorage.getItem("quizStartTime")).format(
              "DD-MM-YYYY hh:mm:ss a",
            ),
            end_time: moment().format("DD-MM-YYYY hh:mm:ss a"),
            total_time: ssminutes + ":" + ssseconds,
            is_quiz: 1,
          },
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            // setUploadmsg("Submission has been successfully uploaded");
            localStorage.removeItem("userQA");
            // localStorage.setItem("finish-quiz", true);
            localStorage.removeItem("quizStartTime");
            localStorage.removeItem("suffle");
            localStorage.removeItem("quiz_id");
            localStorage.setItem("finishbtn", true);

            window.location = `/quiz-finish`;
          })
          .catch(function (response) {
            //handle error
            console.log("Error while calling api", response);
          });
      }
    });
  };

  // clear
  const clearQuestion = (currentQuestionIndex) => {
    console.log("currentQuestionIndex", currentQuestionIndex);

    let userQA = JSON.parse(localStorage.getItem("userQA"));
    if (userQA) {
      console.log("userQA", userQA[currentQuestionIndex]);
      // userQA.splice(currentQuestionIndex, 1);
      userQA.splice(
        userQA.findIndex(
          ({ question }) => question == currentQuestionIndex.question,
        ),
        1,
      );
      localStorage.setItem("userQA", JSON.stringify(userQA));
    }

    // console.log("currentQuestionIndex" , currentQuestionIndex.correctAnswer);
    // $("input:radio").attr("checked", false);

    $('input[name="' + currentQuestionIndex.checkradio + '"]').prop(
      "checked",
      false,
    );
    $("label:has(input:not(:checked))").removeClass("selectedcls");
    // console.log("this is check " , $('input[name="' + currentQuestionIndex.checkradio + '"]').prev('label').removeClass('selectedcls'))
  };

  const handleChange = (event) => {
    setFilteredValue(event.target.value);
  };

  const renderAnswerInResult = (question, userInputIndex) => {
    console.log("question check", question);
    const { answers, correctAnswer, questionType } = question;
    let { answerSelectionType } = question;
    let answerBtnCorrectClassName;
    let answerBtnIncorrectClassName;

    // Default single to avoid code breaking due to automatic version upgrade
    answerSelectionType = answerSelectionType || "single";

    return answers.map((answer, index) => {
      if (answerSelectionType === "single") {
        // correctAnswer - is string
        answerBtnCorrectClassName =
          `${index + 1}` === correctAnswer ? "correct" : "";
        answerBtnIncorrectClassName =
          `${userInputIndex}` !== correctAnswer &&
          `${index + 1}` === `${userInputIndex}`
            ? "incorrect"
            : "";
      } else {
        // correctAnswer - is array of numbers
        answerBtnCorrectClassName = correctAnswer.includes(index + 1)
          ? "correct"
          : "";
        answerBtnIncorrectClassName =
          !correctAnswer.includes(index + 1) &&
          userInputIndex.includes(index + 1)
            ? "incorrect"
            : "";
      }

      return (
        <div key={index}>
          <button
            disabled={true}
            className={
              "answerBtn btn " +
              answerBtnCorrectClassName +
              answerBtnIncorrectClassName
            }
          >
            {questionType === "text" && <span>{answer}</span>}
            {questionType === "photo" && <img src={answer} alt="image" />}
          </button>
        </div>
      );
    });
  };

  const renderQuizResultQuestions = useCallback(() => {
    let filteredQuestions;
    let filteredUserInput;

    if (filteredValue !== "all") {
      if (filteredValue === "correct") {
        filteredQuestions = questions.filter(
          (question, index) => correct.indexOf(index) !== -1,
        );
        filteredUserInput = userInput.filter(
          (input, index) => correct.indexOf(index) !== -1,
        );
      } else {
        filteredQuestions = questions.filter(
          (question, index) => incorrect.indexOf(index) !== -1,
        );
        filteredUserInput = userInput.filter(
          (input, index) => incorrect.indexOf(index) !== -1,
        );
      }
    }

    return (filteredQuestions ? filteredQuestions : questions).map(
      (question, index) => {
        const userInputIndex = filteredUserInput
          ? filteredUserInput[index]
          : userInput[index];

        // Default single to avoid code breaking due to automatic version upgrade
        let answerSelectionType = question.answerSelectionType || "single";

        return (
          <div className="result-answer-wrapper" key={index + 1}>
            {/* <h3 dangerouslySetInnerHTML={rawMarkup(`Q${question.questionIndex}: ${question.question}`)} /> */}
            <h3
              dangerouslySetInnerHTML={rawMarkup(
                `Q${question.questionIndex}: ${questions[0].question}`,
              )}
            />
            {question.questionPic && (
              <img src={question.questionPic} alt="image" />
            )}
            {renderTags(
              answerSelectionType,
              question.correctAnswer.length,
              question.segment,
            )}
            <div className="result-answer">
              {renderAnswerInResult(question, userInputIndex)}
            </div>
            <Explanation question={question} isResultPage={true} />
          </div>
        );
      },
    );
  }, [endQuiz, filteredValue]);

  const onClicktestAnswer = (index) => {
    console.log(index, "chck");
  };

  const RenderAnswers = ({ question, buttons, answerss }) => {
    console.log("click check");
    const { answers, correctAnswer, type, checkradio } = question;
    const currentque = question.question;
    let { answerSelectionType } = question;
    var retrievedObject = JSON.parse(localStorage.getItem("userQA"));
    console.log("retrievedObject", question.question);
    // if(setQuestionIndex + 1 === questions.length ||)
    // {
    //   setLastque(true)
    // }
    // else
    // {
    //   setLastque(false)
    // }

    const onClickAnswer = (index, name) => {
      setUserAns(index);
      if (retrievedObject) {
        retrievedObject = retrievedObject.filter(
          (item) => item.question !== currentque,
        );
        localStorage.setItem("userQA", JSON.stringify(retrievedObject));
      }
    };

    // Default single to avoid code breaking due to automatic version upgrade
    answerSelectionType = answerSelectionType || "single";
    return answerss.map((answer, index) => (
      <Fragment key={index}>
        {buttons[index] !== undefined ? (
          <>
            <label
              className={`${buttons[index].className} answerBtn btn`}
              onClick={() => onClickAnswer(index)}
            >
              <input type="radio" className="radioinput" name={checkradio} />
              {JSON.stringify(localStorage.getItem("userQA"))}
              {type === "text" && <span>{answer}</span>}
              {type === "photo" && <img src={answer} alt="image" />}
            </label>{" "}
          </>
        ) : (
          <>
            <label
              className={`answerBtn btn ${
                userAns === answer.choice
                  ? "selectedcls"
                  : retrievedObject &&
                      retrievedObject.some(
                        (item) => item.question === currentque,
                      ) === true &&
                      retrievedObject.some(
                        (item) =>
                          item.question === currentque &&
                          item.userAnswer === answer.choice,
                      ) === true
                    ? "answerBtn btn selectedcls"
                    : editableAns === "no" && "answerBtn btn disabledAns"
              }`}
            >
              {/* { JSON.stringify(retrievedObject)} */}
              {/* { index} */}
              {/* {retrievedObject.filter(function(vendor){ return vendor.question === answer })} */}
              {}
              <input
                type="radio"
                className="radioinput"
                checked={
                  userAns === answer.choice
                    ? "checked"
                    : retrievedObject &&
                        retrievedObject.some(
                          (item) =>
                            item.question === currentque &&
                            item.userAnswer === answer.choice,
                        ) === true
                      ? "checked"
                      : null
                }
                name={checkradio}
                disabled={
                  retrievedObject
                    ? retrievedObject.some(
                        (item) =>
                          item.question === currentque &&
                          item.userAnswer &&
                          editableAns === "no",
                      ) === true && true
                    : ""
                }
                onClick={(e) => onClickAnswer(answer.choice, e)}
              />
              {/* {answer.choice} */}
              {answer.type === "text" && answer.choice}
              {answer.type === "photo" && (
                <img src={answer.choice} alt="image" />
              )}
            </label>
          </>
        )}
      </Fragment>
    ));
  };

  const renderTags = (answerSelectionType, numberOfSelection, segment) => {
    const {
      singleSelectionTagText,
      multipleSelectionTagText,
      pickNumberOfSelection,
    } = appLocale;

    return (
      <div className="tag-container">
        {answerSelectionType === "single" && (
          <span className="single selection-tag">{singleSelectionTagText}</span>
        )}
        {answerSelectionType === "multiple" && (
          <span className="multiple selection-tag">
            {multipleSelectionTagText}
          </span>
        )}
        <span className="number-of-selection">
          {pickNumberOfSelection.replace(
            "<numberOfSelection>",
            numberOfSelection,
          )}
        </span>
        {segment && <span className="selection-tag segment">{segment}</span>}
      </div>
    );
  };

  const renderResult = () => {
    let count = 1;

    return (
      <div className="card-body">
        <h2>
          {appLocale.resultPageHeaderText
            .replace("<correctIndexLength>", correct.length)
            .replace("<questionLength>", questions.length)}
        </h2>
        {/* <h2>
          {appLocale.resultPagePoint.replace("<correctPoints>", correctPoints).replace("<totalPoints>", totalPoints)}
        </h2> */}
        <br />
        {/* <QuizResultFilter
            filteredValue={filteredValue}
            handleChange={handleChange}
            appLocale={appLocale}
        /> */}
        {/* {renderQuizResultQuestions()} */}
      </div>
    );
  };

  return (
    <div className="questionWrapper">
      {!endQuiz && (
        <div className="questionWrapperBody">
          <div className="questionModal">
            <InstantFeedback
              question={question}
              showInstantFeedback={showInstantFeedback}
              correctAnswer={correctAnswer}
              incorrectAnswer={incorrectAnswer}
            />
          </div>
          {/* {JSON.stringify(question)} */}
          <div className="quetionnumber">
            <span className="qnnumber">
              {appLocale.question} {currentQuestionIndex + 1} /{" "}
              {questions.length}{" "}
            </span>
            {/* {JSON.stringify(question)} */}
            <span className="showmarks">
              {question.points} {question.points === "1" ? "Mark" : "Marks"}{" "}
            </span>
            <br />
            <h3
              dangerouslySetInnerHTML={rawMarkup(question && question.question)}
            />
          </div>
          {/* <h3 dangerouslySetInnerHTML={rawMarkup(question && question.question)}/> */}

          {question && question.questionPic && (
            <div className="showimg">
              <img src={question.questionPic} alt="image" />
            </div>
          )}
          {/* {question && renderTags(answerSelectionTypeState, question.correctAnswer.length, question.segment)} */}
          <div className="optiosn-list">
            <div>Answers:</div>
            {/* {question && renderAnswers(question, buttons)} */}
            {/* {JSON.stringify(question)} */}
            {question && (
              <RenderAnswers
                question={question}
                buttons={buttons}
                answerss={answers}
              />
            )}
          </div>
          {/* {showNextQuestionButton && */}
          <div className="footer-btm">
            {currentQuestionIndex > 0 && (
              <div className="">
                <button
                  onClick={() => prevQuestion(currentQuestionIndex)}
                  className="nextQuestionBtn d-inline-block float-left btn"
                >
                  {/* <FaAngleLeft /> {appLocale.prevQuestionBtn} */}
                  <FaAngleLeft />
                </button>
              </div>
            )}

            <div className="">
              <button
                onClick={
                  localStorage.getItem("finishbtn") === true ||
                  currentQuestionIndex === questions.length - 1
                    ? () => finishquiz(currentQuestionIndex)
                    : () => nextQuestion(currentQuestionIndex, question)
                }
                className={
                  lastque === true ||
                  currentQuestionIndex === questions.length - 1
                    ? "finishquiz nextQuestionBtn d-inline-block  float-right btn"
                    : "nextQuestionBtn nextquiz d-inline-block  float-right btn"
                }
              >
                {/* {currentQuestionIndex} {questions.length -1 } */}
                {localStorage.getItem("finishbtn") === true ||
                currentQuestionIndex === questions.length - 1
                  ? "Finish"
                  : appLocale.nextQuestionBtn}{" "}
                {lastque === false && <FaAngleRight />}
              </button>{" "}
              &nbsp;
              {/* {JSON.stringify(quizseting) } */}
              {quizseting[0].iseditable === "yes" && (
                <button
                  onClick={() => clearQuestion(question)}
                  className="nextQuestionBtn d-inline-block mr-2 float-right btn"
                >
                  {appLocale.clearQuestionBtn}
                </button>
              )}
              {/* {timestart == true && ( */}
              {/* )} */}
            </div>
          </div>
          {/* } */}
        </div>
      )}
      {endQuiz &&
        showDefaultResultState &&
        customResultPage === undefined &&
        renderResult()}
      {endQuiz &&
        !showDefaultResultState &&
        customResultPage !== undefined &&
        customResultPage(questionSummary)}
      {/* <div className="prevbtn">
        <button className="btn menu ">Previous</button>
        </div>
        <div className="prevbtn">
        <button className=" btn menubtn nxtbtn">Next</button>
        </div> */}
    </div>
  );
};

Core.propTypes = {
  questions: PropTypes.array,
  showDefaultResult: PropTypes.bool,
  onComplete: PropTypes.func,
  customResultPage: PropTypes.func,
  showInstantFeedback: PropTypes.bool,
  continueTillCorrect: PropTypes.bool,
  appLocale: PropTypes.object,
};

export default Core;
