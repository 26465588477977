import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Toggle from "react-toggle";
import { Col, Row, Form, Button, Tab, Tabs, TabContent } from "react-bootstrap";
import "./Usercss.css";
import "react-toggle/style.css";
const mainurl = Object.values(config["URL"]);
const URLs = mainurl.join("");
const mainURL = URLs;

const Editevent = (props) => {
  const [alldata, setAlldata] = useState();
  const [forfilter, setAForfilter] = useState();
  const [status, setStatus] = useState(1);
  const [attachmentstatus, setAttachmentstatus] = useState();
  const [imageview, setPreviewlogo] = useState("");
  const [bannerview, setPreviewbanner] = useState("");
  const [success, setSuccess] = useState();
  const [errormsg, setError] = useState();
  const formData = new FormData();

  const onInputChange = (val, name, newval) => {
    console.log("data", name);
    const file = newval;
    let apidata;
    if (name === "logo_image") {
      let imgRef = URL.createObjectURL(file);
      setPreviewlogo(imgRef);
      formData.append("logo_image", newval, name);
      apidata = {
        logo_image: formData,
      };
    }
    if (name === "banner_image") {
      let imgRef = URL.createObjectURL(file);
      setPreviewbanner(imgRef);
      formData.append("banner_image", newval, name);

      apidata = {
        banner_image: formData,
      };
    }
    if (name === "rulebook") {
      formData.append("rulebook", newval, name);

      apidata = {
        rulebook: formData,
      };
    }

    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    const oppid = localStorage.getItem("oppid");
    axios({
      method: "post",
      url: mainURL + "/opportunity/images/" + props.match.params.id,
      data: formData,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;

        console.info("all analytics", alldata);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };
  function step1(params) {
    setSuccess("Event has beed updated");
  }
  return (
    <div className="container topmargin">
      <div className="form">
        <h3 className="successmsg">{success && success}</h3>
        {errormsg && <h3 className="errormsg">{errormsg}</h3>}
        <Form className="form-body">
          <Form.Row>
            <Col
              className="form-group-col "
              md={12}
              controlId="oppotunityEndTime"
            >
              <span className="uploader">
                <input
                  type="file"
                  id="file-upload"
                  accept="image/*"
                  name="logo_image"
                  value=""
                  onChange={(e) =>
                    onInputChange(
                      e.target.files[0].name,
                      e.target.name,
                      e.target.files[0],
                    )
                  }
                />

                <label for="file-upload" id="file-drag">
                  {imageview && (
                    <img id="file-image" src={imageview} alt="Preview" />
                  )}
                  <div id="start">
                    <i className="fa fa-download" aria-hidden="true"></i>
                    <div>Upload Your logo</div>
                    <div id="notimage" className="hidden">
                      Please select an image
                    </div>
                    <span id="file-upload-btn" className="btn btn-primary">
                      Select a file
                    </span>
                  </div>
                </label>
              </span>
            </Col>
            {/* <img src={imageview} /> */}
          </Form.Row>

          {/* for banner image */}
          <Form.Row>
            <Col
              className="form-group-col "
              md={12}
              controlId="oppotunityEndTime"
            >
              <span className="uploader">
                <input
                  type="file"
                  id="file-uploads"
                  accept="image/*"
                  name="banner_image"
                  value=""
                  onChange={(e) =>
                    onInputChange(
                      e.target.files[0].name,
                      e.target.name,
                      e.target.files[0],
                    )
                  }
                />

                <label for="file-uploads" id="file-drag">
                  {bannerview && (
                    <img id="file-image" src={bannerview} alt="Preview" />
                  )}
                  <div id="start">
                    <i className="fa fa-download" aria-hidden="true"></i>
                    <div>Upload Your banner</div>
                    <div id="notimage" className="hidden">
                      Please select an image
                    </div>
                    <span id="file-upload-btn" className="btn btn-primary">
                      Select a file
                    </span>
                  </div>
                </label>
              </span>
            </Col>
            {/* <img src={imageview} /> */}
          </Form.Row>
          {/* rule book upload */}
          <Form.Row>
            <Col
              className="form-group-col "
              md={12}
              controlId="oppotunityEndTime"
            >
              <span className="uploader">
                <input
                  type="file"
                  id="file-uploads-rule"
                  name="rulebook"
                  value=""
                  onChange={(e) =>
                    onInputChange(
                      e.target.files[0].name,
                      e.target.name,
                      e.target.files[0],
                    )
                  }
                />

                <label for="file-uploads-rule" id="file-drag">
                  <div id="start">
                    <i className="fa fa-download" aria-hidden="true"></i>
                    <div>Upload Your Rule Book</div>
                    <div id="notimage" className="hidden">
                      Please select an PDF
                    </div>
                    <span id="file-upload-btn" className="btn btn-primary">
                      Select a file
                    </span>
                  </div>
                </label>
              </span>
            </Col>
          </Form.Row>

          <Button className="save-btn" type="button" onClick={() => step1(1)}>
            Save and Finish
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Editevent;
