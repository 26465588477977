import React, { useEffect, useState } from "react";
import LeftSideBar from "./UserLeftSideBar";
import RightSidebar from "./UserRightSidebar";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import { Tabs, Radio } from "antd";
import { Col, Row, Button, Form, TabContent } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import { stateFromHTML } from "draft-js-import-html";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const { TabPane } = Tabs;
const Addquiz = (props) => {
  const [alldata, setAlldata] = useState();
  const [addQuizstatus, setAddQuizstatus] = useState();
  const [confirmpass, setConfirmpass] = useState();
  let [tabkey, setTabkey] = useState("");
  let [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [msg, setMsg] = useState();
  const userdata = JSON.parse(localStorage.getItem("alldata"));
  const [inputFields, setInputFields] = useState([
    { question: "", answer: "", points: "", answers: [] },
  ]);
  const [basicdetail, setBasicdetail] = useState([
    {
      title: "",
      discription: "",
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      duration: "",
      total_mark: "",
    },
  ]);
  const [contactFields, setContactFields] = useState([{ choice: "" }]);
  const [setting, setSetting] = useState([
    {
      question_type: "yes",
      who_can_play: "yes",
      shuffling_quetions: "",
      shuffling_options: "",
      showquestion: "",
    },
  ]);
  const [answers, setAnswers] = useState([]);

  useEffect(async () => {
    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/opportunity/" + props.match.params.id,
      });
      console.log("result", result.data.data);

      if (result.data.data.quiz_basic) {
        let quiz_basic = JSON.parse(result.data.data.quiz_basic);
        setBasicdetail(quiz_basic);
        console.log("quiz_basic.discription", quiz_basic[0].discription);
        let contentState = stateFromHTML(quiz_basic[0].discription);
        console.log(contentState);

        setEditorState(EditorState.createWithContent(contentState));
      }
      if (result.data.data.quiz_setting) {
        let quizsetting = JSON.parse(result.data.data.quiz_setting);
        setSetting(quizsetting);
      }
      if (result.data.data.quizes) {
        let allqueiz = JSON.parse(result.data.data.quizes);
        setInputFields(allqueiz);
      }

      // console.log("rounds", result.data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleAddFields = (index) => {
    const values = [...inputFields];
    values.push({ question: "", answer: "", points: "", answers: [] });
    setInputFields(values);
    console.log("contact val", inputFields);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  function onEditorStateChange(editorState) {
    const value = basicdetail;
    value[0].discription = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    setEditorState(editorState);
  }

  const handleAddcontactFields = (index) => {
    const values = [...contactFields];
    const fild = [...inputFields];
    console.log("add choice", fild[index]);
    console.log("add 2nd  choice", index);
    console.log(inputFields);

    if (fild[index].answers.length > 3) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Can not Add more then 4 choices",
      });
    } else {
      console.log("under if");
      fild[index].answers.push({
        choice: "",
      });
      // setContactFields(values);

      console.log("FDJFDF", fild);
      setInputFields(fild);
    }

    // setAnswers(ans)
  };

  const handleRemovecontactFields = (index) => {
    const values = [...contactFields];
    values.splice(index, 1);
    setContactFields(values);
  };

  function selectedtab(keyno) {
    // alert(keyno)

    console.log("totken check ", tabkey);
  }

  function savebasicdetail(keyno) {
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "put",
      url: mainURL + "/opportunity/" + props.match.params.id,
      data: {
        quiz_basic: JSON.stringify(basicdetail),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Basic Detail has been submitted",
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  // input entered
  const onInputChangecontact = (val, name, idx) => {
    // setInputValue({ ...inputValue, [name]: val });

    const rows = [...inputFields];

    if (name == "question") {
      rows[idx].question = val;
    }
    if (name == "correct_ans") {
      rows[idx].answer = val;
    }
    if (name == "points") {
      rows[idx].points = val;
    }

    console.log("ljghgjk", rows);
    setInputFields(rows);
  };
  function onInputsetans(val, name, idx, qidx) {
    console.log("ans choice", val, name, idx, qidx);
    const rows = [...inputFields];

    rows[qidx].answers[idx].choice = val;

    console.log("enter row", rows);
    setInputFields(rows);
  }

  function onInputremoveans(index, qidx) {
    const values = [...inputFields];
    values[qidx].answers.splice(index, 1);
    console.log("remove row", values);
    setInputFields(values);

    // setContactFields(values);
  }

  function addbasicdetail(val, name) {
    console.log("fdsf", val, name);

    const value = basicdetail;

    if (name == "title") {
      value[0].title = val;
    }
    if (name == "start_date") {
      value[0].start_date = val;
    }
    if (name == "end_date") {
      value[0].end_date = val;
    }
    if (name == "start_time") {
      value[0].start_time = val;
    }
    if (name == "end_time") {
      value[0].end_time = val;
    }
    if (name == "duration") {
      value[0].duration = val;
    }
    if (name == "total_mark") {
      value[0].total_mark = val;
    }
    console.log("basic value", value);
    setBasicdetail(value);
  }

  function settingadd(val, name) {
    // { question_type: "", who_can_play:"", shuffling_quetions: "", shuffling_answers:""},
    const value = setting;
    if (name == "quiztype") {
      value[0].question_type = val;
    }
    if (name == "whoplay") {
      value[0].who_can_play = val;
    }
    if (name == "shuffleque") {
      value[0].shuffling_quetions = val;
    }
    if (name == "shufflequeoption") {
      value[0].shuffling_options = val;
    }
    if (name == "showquestion") {
      value[0].showquestion = val;
    }

    console.log("setting options", value);
    setSetting(value);
  }

  function savesettings(keyno) {
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "put",
      url: mainURL + "/opportunity/" + props.match.params.id,
      data: {
        quiz_setting: JSON.stringify(setting),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Settings has been submitted",
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  function saveQuestions(params) {
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "put",
      url: mainURL + "/opportunity/" + props.match.params.id,
      data: {
        quizes: JSON.stringify(inputFields),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Questions has been submitted",
          showConfirmButton: false,
          timer: 2500,
        });
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  return (
    <section>
      <div id="user-wrapper" className="dashboard form addquizsection topmargin">
        <div className="row form-body">
          <Tabs type="card" defaultActiveKey={tabkey}>
            <TabPane tab="Basic Info" key="1">
              <Form className="quizform">
                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={12}
                    controlId="oppotunityStartDate"
                  >
                    <Form.Label className="input-titlle">
                      {tabkey}
                      Quiz Title <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Quiz Title"
                      name="title"
                      defaultValue={basicdetail[0].title}
                      onChange={(e) =>
                        addbasicdetail(e.target.value, e.target.name)
                      }
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={12}
                    controlId="oppotunityStartDate"
                  >
                    <Form.Label className="input-titlle">
                      Display Text <span className="importantfield">*</span>
                    </Form.Label>
                    <Editor
                      editorState={editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={onEditorStateChange}
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="oppotunityStartDate"
                  >
                    <Form.Label className="input-titlle">
                      Start Date <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Your name"
                      name="start_date"
                      defaultValue={basicdetail[0].start_date}
                      onChange={(e) =>
                        addbasicdetail(e.target.value, e.target.name)
                      }
                    />
                  </Col>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="oppotunityStartDate"
                  >
                    <Form.Label className="input-titlle">
                      End Date <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="00:00:00"
                      name="end_date"
                      // defaultValue={inputField.name}
                      defaultValue={basicdetail[0].end_date}
                      onChange={(e) =>
                        addbasicdetail(e.target.value, e.target.name)
                      }
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="oppotunityStartDate"
                  >
                    <Form.Label className="input-titlle">
                      Start time <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="time"
                      placeholder="Your name"
                      name="start_time"
                      defaultValue={basicdetail[0].start_time}
                      onChange={(e) =>
                        addbasicdetail(e.target.value, e.target.name)
                      }
                    />
                  </Col>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="oppotunityStartDate"
                  >
                    <Form.Label className="input-titlle">
                      End time <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="time"
                      placeholder="00:00:00"
                      name="end_time"
                      // defaultValue={inputField.name}
                      defaultValue={basicdetail[0].end_time}
                      onChange={(e) =>
                        addbasicdetail(e.target.value, e.target.name)
                      }
                    />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="oppotunityStartDate"
                  >
                    <Form.Label className="input-titlle">
                      Quiz Duration <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Quiz Duration"
                      name="duration"
                      // defaultValue={inputField.name}
                      defaultValue={basicdetail[0].duration}
                      onChange={(e) =>
                        addbasicdetail(e.target.value, e.target.name)
                      }
                    />
                  </Col>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="oppotunityStartDate"
                  >
                    <Form.Label className="input-titlle">
                      Total Questions <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Total Questions"
                      name="total_mark"
                      // defaultValue={inputField.name}
                      defaultValue={basicdetail[0].total_mark}
                      onChange={(e) =>
                        addbasicdetail(e.target.value, e.target.name)
                      }
                    />
                  </Col>
                </Form.Row>
              </Form>

              <div className="float-right">
                <button
                  className="btn menubtn"
                  onClick={() => savebasicdetail("2")}
                >
                  Save
                </button>
              </div>
            </TabPane>
            <TabPane tab="Quiz Settings" key="2" className="setting-row">
              <div className="row">
                <div className="quiz-settings">
                  <div className="col-md-5">
                    <label>Quiz type</label>
                  </div>
                  <div className="col-md-7">
                    <span className="mr-20">
                      <input
                        type="radio"
                        name="quiztype"
                        checked
                        onChange={() => settingadd("yes", "quiztype")}
                      />{" "}
                      <label>Standalone</label>
                    </span>
                    {/* <span>
         <input type="radio" name="quiztype"/> No
         </span> */}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="quiz-settings">
                  <div className="col-md-5">
                    <label>Who can play ?</label>
                  </div>
                  <div className="col-md-7">
                    <span className="mr-20">
                      <input
                        type="radio"
                        name="whoplay"
                        checked
                        onChange={() => settingadd("yes", "whoplay")}
                      />{" "}
                      <label>Leader</label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="quiz-settings">
                  <div className="col-md-5">
                    <label>Shuffling of Quetions</label>
                  </div>
                  <div className="col-md-7">
                    <span className="mr-20">
                      <input
                        type="radio"
                        name="shuffleque"
                        checked={
                          setting && setting[0].shuffling_quetions == "True"
                            ? setting[0].shuffling_options
                            : null
                        }
                        onChange={() => settingadd("True", "shuffleque")}
                      />{" "}
                      <label>True</label>
                    </span>
                    <span>
                      <input
                        type="radio"
                        name="shuffleque"
                        checked={
                          setting && setting[0].shuffling_quetions == "False"
                            ? setting[0].shuffling_options
                            : null
                        }
                        onChange={() => settingadd("False", "shuffleque")}
                      />{" "}
                      <label>False</label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="quiz-settings">
                  <div className="col-md-5">
                    <label> Shuffle Questions option</label>
                  </div>
                  <div className="col-md-7">
                    <span className="mr-20">
                      <input
                        type="radio"
                        name="shufflequeoption"
                        checked={
                          setting && setting[0].shuffling_options == "True"
                            ? setting[0].shuffling_options
                            : null
                        }
                        onChange={() => settingadd("True", "shufflequeoption")}
                      />{" "}
                      <label>True</label>
                    </span>
                    <span>
                      <input
                        type="radio"
                        name="shufflequeoption"
                        checked={
                          setting && setting[0].shuffling_options == "False"
                            ? setting[0].shuffling_options
                            : null
                        }
                        onChange={() => settingadd("False", "shufflequeoption")}
                      />{" "}
                      <label>False</label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="quiz-settings">
                  <div className="col-md-5">
                    <label>How many questions do you want to display</label>
                  </div>
                  <div className="col-md-7">
                    <span className="mr-20">
                      <input
                        type="text"
                        name="showquestion"
                        defaultValue={setting[0].showquestion}
                        onChange={(e) =>
                          settingadd(e.target.value, "showquestion")
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>

              <div className="float-right">
                <button className="btn menubtn" onClick={() => savesettings(3)}>
                  Save
                </button>
              </div>
            </TabPane>
            <TabPane tab="Questions" key="3">
              <>
                <div className="buttonstyle">
                  <span className="alert alert-primary mr-md-5 mt-md-5">
                    <input
                      type="radio"
                      name="addQuizstatus"
                      onChange={() => {
                        setAddQuizstatus("auto");
                      }}
                    />{" "}
                    Do you want to upload the excel
                  </span>
                  <span className="alert alert-primary mr-md-5 mt-md-5">
                    <input
                      type="radio"
                      name="addQuizstatus"
                      onChange={() => {
                        setAddQuizstatus("mannual");
                      }}
                    />{" "}
                    Do you want to add mannualy add the questions
                  </span>
                </div>
                {addQuizstatus === "mannual" &&
                  inputFields.map((inputField, qindex) => (
                    <div className="col-md-12 float-right pt-3">
                      <div>
                        <div className="row">
                          <div className="col-md-12  inputboxcss quizform  pb-2 mb-3">
                            {qindex > 0 && (
                              <div className="text-right removeicon removeiconq">
                                <button
                                  className="btn btn-link minus-btn"
                                  type="button"
                                  onClick={() => handleRemoveFields(qindex)}
                                >
                                  <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                              </div>
                            )}
                            <div className="form-group float-left col-md-12">
                              <label
                                className="input-titlle form-label"
                                htmlFor="firstName"
                              >
                                {qindex + 1}. Questions
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="question"
                                defaultValue={inputField.question}
                                onChange={(e) =>
                                  onInputChangecontact(
                                    e.target.value,
                                    e.target.name,
                                    qindex,
                                  )
                                }
                              />
                            </div>
                            <div className="form-group float-left col-md-12">
                              <label
                                className="input-titlle form-label"
                                htmlFor="firstName"
                              >
                                Correct Answer
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="correct_ans"
                                defaultValue={inputField.answer}
                                onChange={(e) =>
                                  onInputChangecontact(
                                    e.target.value,
                                    e.target.name,
                                    qindex,
                                  )
                                }
                              />
                            </div>
                            <div className="form-group float-left col-md-12">
                              <label
                                className="input-titlle form-label"
                                htmlFor="firstName"
                              >
                                Points
                              </label>
                              <input
                                type="numbers"
                                className="form-control"
                                name="points"
                                defaultValue={inputField.points}
                                onChange={(e) =>
                                  onInputChangecontact(
                                    e.target.value,
                                    e.target.name,
                                    qindex,
                                  )
                                }
                              />
                            </div>
                          </div>
                          {/* <div className="col-md-10">
                      <h5>Add Options</h5>
                      </div> */}
                          {inputField && (
                            <label htmlFor="firstName">Options</label>
                          )}
                          <div className="inputboxcss choicecss">
                            {inputField.answers.map((inputField, index) => (
                              <>
                                <div
                                  key={`${inputField}~${index}`}
                                  className=" col-md-12 quizform   pb-2 mb-3"
                                >
                                  <div className="">
                                    <div className="form-group float-left col-md-10">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        defaultValue={inputField.choice}
                                        onChange={(e) =>
                                          onInputsetans(
                                            e.target.value,
                                            e.target.name,
                                            index,
                                            qindex,
                                          )
                                        }
                                      />
                                    </div>

                                    <div className="form-group float-right col-md-1">
                                      <div className=" float-left">
                                        <div className="removeicon">
                                          {index > 0 && (
                                            <button
                                              className="btn btn-link minus-btn"
                                              type="button"
                                              onClick={() =>
                                                onInputremoveans(index, qindex)
                                              }
                                            >
                                              <i
                                                className="fa fa-trash"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group float-right col-md-1">
                                      <div className=" float-left">
                                        <div className="removeicon">
                                          <input
                                            type="radio"
                                            name="currectans"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                          <div className="col-md-12 text-right">
                            <button
                              className="btn menubtn"
                              type="button"
                              onClick={() => handleAddcontactFields(qindex)}
                            >
                              Add Choice
                            </button>
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  ))}
                {addQuizstatus === "auto" && (
                  <span className="uploader">
                    <input
                      type="file"
                      id="file-uploads"
                      accept="image/*"
                      name="banner_image"
                      value=""
                    />

                    <label for="file-uploads" id="file-drag">
                      {/* {bannerview && (
                <img id="file-image" src={bannerview} alt="Preview"  />

                  )} */}
                      <div id="start">
                        <i className="fa fa-download" aria-hidden="true"></i>
                        <div>
                          Upload File<span className="importantfield">*</span>
                        </div>
                        {/* <div id="notimage" className="hidden">Please select an image</div> */}
                        <span id="file-upload-btn" className="btn btn-primary">
                          Select a file
                        </span>
                      </div>
                    </label>
                  </span>
                )}

                {addQuizstatus === "mannual" && (
                  <>
                    <div className="text-right mainrow">
                      <button
                        className="btn menubtn"
                        type="button"
                        onClick={() => handleAddFields()}
                      >
                        Add More Quextions
                      </button>
                    </div>
                  </>
                )}
                <div className="text-center mainrow">
                  <button
                    className="btn transparentbtn "
                    type="button"
                    onClick={() => saveQuestions()}
                  >
                    Download Sample File
                  </button>
                </div>
              </>
            </TabPane>
          </Tabs>
        </div>
        <hr />
      </div>
    </section>
  );
};

export default Addquiz;
