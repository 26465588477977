import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../DynamicPages/LogIn.css";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Footer from "../CommonFiles/Footer";
import Header from "../CommonFiles/Header";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const Forgotpassword = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errormsg, setErrormsg] = useState("");

  function login() {
    if (email == "" && password == "") {
      setErrormsg("Email field is  required");
    } else {
      let item = { email, password };
      console.log(item);
      axios({
        method: "post",
        url: mainURL + "/forgot-password",
        data: {
          email: email,
        },
      })
        .then((response) => {
          setErrormsg("Password reset link has been mailed");
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
          //   setErrormsg(response)
        });
    }
  }

  return (
    <React.Fragment>
      <Header />

      <div className="bg">
        <div className="col-sm-4 offset-sm-4 main-div">
          <div className="login-body bg-white">
            <h1>Forgot Password ?</h1>
            <p>Enter your email address</p>
            {errormsg && <p className="errormsgdd">{errormsg}</p>}
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control register-body-form"
              placeholder="Email-ID"
            />
            <i className="fa fa-envelope  material-icons"></i>
            <br />
            <button onClick={login} className="btn btn-primary">
              Submit
            </button>
            <br />
            <br />

            <div className="loginbtnlogin">
              <Link to="/login" className="sign-up fr-login">
                Login
              </Link>
              <Link to="/Register" className="sign-up">
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Forgotpassword;
