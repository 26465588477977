export const defaultLocale = {
  landingHeaderText: "<questionLength> Questions",
  question: "Question",
  startQuizBtn: "Start Quiz",
  resultFilterAll: "All",
  resultFilterCorrect: "Correct",
  resultFilterIncorrect: "Incorrect",
  nextQuestionBtn: "Next Question",
  prevQuestionBtn: "Previous",
  clearQuestionBtn: "Clear",
  resultPageHeaderText: "You have completed the quiz.",
  resultPagePoint: "You scored <correctPoints> out of <totalPoints>.",
  singleSelectionTagText: "Single Selection",
  multipleSelectionTagText: "Multiple Selection",
  pickNumberOfSelection: "Pick <numberOfSelection>",
};
