import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./LogIn.css";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Footer from "../CommonFiles/Footer";
import Header from "../CommonFiles/Header";
import { Successmsg } from "./Register";
// import "./paytm.js"
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
var paytmconfig;

const LogIn = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errormsg, setErrormsg] = useState("");
  let [txntokenid, setTxntokenid] = useState();
  const [showeye, setShoweye] = useState();
  let tokenid;

  function login() {
    if (email == "" && password == "") {
      setErrormsg("Email and Password both are required");
    } else {
      let item = { email, password };
      console.log(item);
      axios({
        method: "post",
        url: mainURL + "/login",
        data: {
          password: password,
          email: email,
        },
      })
        .then((response) => {
          const alldata = response.data.data;
          if (response.data.status === 0) {
            setErrormsg("Invalid password");
          } else {
            localStorage.setItem("token", response.data.accessToken);
            localStorage.setItem("alldata", JSON.stringify(response.data.user));
            props.history.push(`/`);
            console.info("all analytics", response.data.user);
          }
        })
        .catch((err) => {
          console.log("jhghjghg", err);
          setErrormsg("Incorrect Email Id");
        });
    }
  }

  var passwordField = document.querySelector(".password-field");
  const showPassword = (e) => {
    if (password == "") {
      return false;
    }
    if (passwordField.type === "password") {
      passwordField.type = "text";
      // showPw.classList.add("fas fa-eye")
      setShoweye(1);
    } else {
      passwordField.type = "password";
      setShoweye(0);
      // showPw.classList.add("fas fa-eye-slash")
    }
  };

  return (
    <React.Fragment>
      <Header />

      <div className="">
        <div className="col-sm-8 offset-sm-2 main-div col-md-4 offset-md-4 loginpage resetpass-main-div">
          <div className="login-body ">
            <h1>User Log In</h1>
            {errormsg && <p className="errormsg">{errormsg}</p>}
            {Successmsg && <p className="successfullyreg">{Successmsg}</p>}
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control register-body-form"
              placeholder="Email-ID"
            />
            <i className="fa fa-envelope  material-icons"></i>
            <br />
            {/* <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control" placeholder="Password"/> */}
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control register-body-form password-field"
              placeholder="Password"
            />
            <i className="fa fa-lock  material-icons"></i>{" "}
            {showeye === 1 ? (
              <i className="fas fa-eye show-pw" onClick={showPassword}></i>
            ) : (
              <i className="fas fa-eye-slash show-pw" onClick={showPassword}></i>
            )}
            {/* <div className="forget-password">
                    <p><a href="#" className="sign-up">Forget password</a></p>
                </div> */}
            <Link to="/forgot-password" className="fp">
              Forgot Password ?
            </Link>
            <br />
            {/* <button onClick={login} className="btn btn-primary loginbtn">Log In</button> */}
            <button type="submit" onClick={login} className="btn btn-primary">
              Log In
            </button>
            {/* <button onClick={()=>onScriptLoad()}> paytm</button> */}
            <br />
            <p className="mb-0">
              Don't have an account?{" "}
              <Link to="/Register" className="sign-up">
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default LogIn;
