import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Menu } from "antd";
import ImagePath from "../../../Constants/imagePath";
import "./LeftSideBar.css";
import { NavLink } from "react-router-dom";
import axios from "axios";
import config from "../../CommonFiles/config.json";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const LeftSideBar = (props) => {
  const history = useHistory();
  const [allRounds, setAllrounds] = useState([]);
  const params = useParams();
  const [openKeys, setOpenKeys] = useState(["sub1"]);

  useEffect(() => {
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/dashboard/rounds/opportunities/" +
          localStorage.getItem("hostingId"),
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data;
          props.onAllRoundsDataLoaded && props.onAllRoundsDataLoaded(alldatas);
          setAllrounds(alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  }, []);

  const onOpenChange = (keys) => {
    console.log("get key", keys);
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const openround = (key) => {
    history.push("/user/opportunity/dashboard/rounds/" + key);
  };

  const openLink = (key, getID) => {
    if (key === "resgisterlist") {
      history.push(
        "/user/opportunity/dashboard/regstration/list/" +
          localStorage.getItem("hostingId")
      );
      //  props.history.push("/user/opportunity/dashboard/" +localStorage.getItem("hostingId"));
    }
    if (key === "dashboard") {
      history.push(
        "/user/opportunity/dashboard/" + localStorage.getItem("hostingId")
      );
      //  props.history.push("/user/opportunity/dashboard/" +localStorage.getItem("hostingId"));
    }
    if (key === "notification") {
      history.push(
        "/user/notification-anagemant/" + localStorage.getItem("hostingId")
      );
      //  props.history.push("/user/opportunity/dashboard/" +localStorage.getItem("hostingId"));
    }
    if (key === "round") {
      localStorage.setItem("selectedRound", getID);
      history.push(
        "/user/opportunity/dashboard/rounds/" +
          localStorage.getItem("hostingId")
      );
    }
  };

  const items = [
    getItem(
      <span onClick={() => openLink("dashboard")}>Dashboard</span>,
      "sub1",
      <img src={ImagePath.DASHBOARDICON} />
    ),
    getItem(
      "Manage Registrations",
      "sub2",
      <img src={ImagePath.REGISTERICON} />,
      [
        getItem(
          <span onClick={() => openLink("resgisterlist")}>
            All Registrations
          </span>,
          "sub3"
        ),
        ...allRounds.map((item, index) =>
          getItem(
            <span onClick={() => openround(item.id)}>{item.title}</span>,
            "sub5" + index
          )
        ),
      ]
    ),
    getItem(
      <span onClick={() => openLink("notification")}>
        Notification Management
      </span>,
      "sub4",
      <img src={ImagePath.NOTIFICATIONICON} />
    ),
  ];
  const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4"];

  return (
    <div className="left_sidebar pt-4">
      {/* START LOGO */}

      {/* START NAVITEMS */}
      <div className="nav_items">
        {/* <div className="collapse navbar-collapse" id="navbarNavDarkDropdown">
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDarkDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Dropdown
              </a>
              <ul
                className="dropdown-menu dropdown-menu-dark"
                aria-labelledby="navbarDarkDropdownMenuLink"
              >
                <li>
                  <a className="dropdown-item" href="#">
                    Action
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Another action
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Something else here
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div> */}
        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          style={{
            width: 256,
          }}
          items={items}
        />
      </div>
      {/* END NAVITEMS */}
    </div>
  );
};

export default LeftSideBar;
