import React, { useEffect, useState } from "react";
import "../DynamicPages/OpportunityDetail.css";
import { Link } from "react-router-dom";
import { Col, Row, Button, Card, FormControl, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaCheckCircle,
  FaFilePdf,
  FaEye,
  FaUsers,
  FaUserAlt,
  FaBookmark,
  FaRegFileAlt,
  FaSyncAlt,
  FaRegCalendarTimes,
  FaRegAddressBook,
  FaRegCommentAlt,
  FaTrophy,
  FaAward,
  FaRegCalendarAlt,
  FaCalendarAlt,
  FaRegComments,
} from "react-icons/fa";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import Moment from "react-moment";
import StarRatings from "react-star-ratings";
import { EditorState, convertToRaw } from "draft-js";
import moment from "moment";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

function Opprtunitydetailpage(props) {
  const [opportunities, setOpportunities] = useState(null);
  const [rounds, setRounds] = useState([]);
  const [prizes, setPrizes] = useState([]);
  const [alldata, setAlldata] = useState([]);
  const [imortantdates, setImortantdates] = useState();
  const [imortantcontact, setImortantcontact] = useState();
  const [rating, setRating] = useState();
  const [reviewdata, setRreviewdata] = useState({});
  useEffect(async () => {
    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/opportunity/" + props.match.params.id,
      });
      console.log("result", result.data);
      setAlldata(result.data.data);
      const rounds = result.data.rounds;
      setRounds(rounds);
      setPrizes(result.data.prizes);

      const dates = JSON.parse(result.data.data.important_dates);
      const datess = JSON.parse(result.data.data.important_dates);
      const contacts = JSON.parse(result.data.data.important_contacts);
      console.log("json data", result.data.data);
      setImortantdates(datess);
      setImortantcontact(contacts);

      console.log("rounds", result.data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  function changeRating(newRating, name) {
    setRating(newRating);
    console.log("rating", rating);
    setRreviewdata({ ...reviewdata, rating: rating });
  }
  const onInputChange = (val, name) => {
    setRreviewdata({ ...reviewdata, [name]: val });
  };
  function submit(params) {
    setRreviewdata({ ...reviewdata, opportunity_id: props.match.params.id });
    console.log("review data", reviewdata);

    // return false;
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: mainURL + "/opportunity/rating",
      data: reviewdata,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        // const alldata = response.data.data;
        // this.setState({
        //   alldata: alldata,
        // });
        console.info("all analytics", alldata);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  function statusmanage(id, status) {
    axios({
      method: "put",
      url: mainURL + "/opportunity/status/" + id,
      data: {
        is_active: status,
      },
    })
      .then((response) => {
        const alldata = response.data.data;
        console.log("alltdata", alldata);
        toast("Event has been Approved");
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  return (
    <React.Fragment>
      <div className="padding15 container pt-3 margingtop ">
        <button className="read-more-btn fl">Reject </button>
        <button
          className="read-more-btn fr"
          onClick={() => statusmanage(alldata.id, 1)}
        >
          {alldata.is_active === 1 ? "Approved" : "Approve"}
        </button>
      </div>
      <div className="opportunity-detail">
        <ToastContainer />

        <div className="od-banner">
          <img
            className="list-icon-img mainbanner"
            src={alldata.banner_image}
            alt="od-banner"
          />
          <div className="od-body">
            <Card className="od-card" style={{ width: "48rem" }}>
              <Row>
                <Col className="left">
                  <div className="od-card-leftside">
                    <div className="od-leftside-heading">
                      <h5>Free Registration</h5>
                    </div>
                    <div className="od-leftside-stats">
                      <Row className="stat-card-container">
                        <Col className="stat-card">
                          <FaCalendarAlt />
                          <p> </p>
                          <p>
                            {moment(alldata.end_date).diff(
                              moment(new Date()),
                              "days",
                            ) < 0
                              ? " Closed"
                              : moment(alldata.end_date).diff(
                                  moment(new Date()),
                                  "days",
                                ) + " Days Left"}
                          </p>
                          <p></p>
                        </Col>
                        <Col className="stat-card">
                          <FaEye />
                          <p>{alldata.views}</p>
                          <p>Views</p>
                        </Col>
                      </Row>
                      <Row className="stat-card-container">
                        <Col className="stat-card">
                          <FaUsers />
                          <p>{alldata.max_team_size}</p>
                          <p>Team Member</p>
                        </Col>
                        <Col className="stat-card">
                          <FaUserAlt />
                          <p>12</p>
                          <p>Registration</p>
                        </Col>
                      </Row>
                    </div>
                    <div className="leftside-icon-bt">
                      <Button type="primary">
                        <FaBookmark />
                      </Button>
                      <Button type="primary">
                        <FaCalendarAlt />
                      </Button>
                    </div>
                    <div className="leftside-register">
                      <Link
                        to={`/opportunities/detail/registration/${alldata.id}`}
                      >
                        <Button type="primary">
                          <FaUserAlt />
                          Register on GradPartners
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col className="right">
                  <div className="od-card-rightside">
                    <div className="od-rightside-heading">
                      <h5>{alldata.title}</h5>
                      <p className="rightside-sub-heading">
                        #{alldata.organization}
                      </p>
                    </div>
                    <div className="od-rightside-details">
                      <ul className="details-list">
                        <li>
                          <div className="list-icon">
                            <img
                              className="list-icon-img"
                              src={
                                require("../../detailspage/participant-icon.png")
                                  .default
                              }
                              alt="od-banner"
                            />
                          </div>
                          <div className="list-content">
                            <p className="list-content-title">Participant</p>
                            <p className="list-content-text">
                              Individual Participant
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="list-icon">
                            <img
                              className="list-icon-img"
                              src={
                                require("../../detailspage/date.png").default
                              }
                              alt="od-banner"
                            />
                          </div>
                          <div className="list-content">
                            <p className="list-content-title">Date</p>
                            <p className="list-content-text mb-0">
                              <Moment
                                format="D MMMM YYYY "
                                date={alldata.start_date}
                              />{" "}
                              {alldata.start_time}
                            </p>
                            <p className="list-content-text">
                              <Moment
                                format="D MMMM YYYY "
                                date={alldata.end_date}
                              />{" "}
                              {alldata.end_time}
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="list-icon">
                            <img
                              className="list-icon-img"
                              src={
                                require("../../detailspage/religious.png")
                                  .default
                              }
                              alt="od-banner"
                            />
                          </div>
                          <div className="list-content">
                            <p className="list-content-title">Region</p>
                            <p className="list-content-text">
                              {alldata.region}
                            </p>
                            <p></p>
                          </div>
                        </li>
                        <li>
                          <div className="list-icon">
                            <img
                              className="list-icon-img"
                              src={
                                require("../../detailspage/date.png").default
                              }
                              alt="od-banner"
                            />
                          </div>
                          <div className="list-content">
                            <p className="list-content-title">Updated On</p>
                            <p className="list-content-text">
                              <Moment
                                format="D MMMM YYYY "
                                date={alldata.updatedAt}
                              />
                            </p>
                          </div>
                        </li>
                        <li>
                          <div className="list-icon">
                            <img
                              className="list-icon-img"
                              src={
                                require("../../detailspage/eligibility.png")
                                  .default
                              }
                              alt="od-banner"
                            />
                          </div>
                          <div className="list-content">
                            <p className="list-content-title">Eligibility</p>
                            <div className="eligibility-area">
                              <Button type="primary">Undergrudate</Button>
                              <Button type="primary">MBA Students</Button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </div>
        </div>

        {/* <nav className="nav-body">
          <div className= "od-nav">
                <ul className= "od-nav-links" id="mainNav">
                <li className=""><a href="#brief" ><FaRegFileAlt />Briefs</a></li>
                {rounds && (
                <li><a href="#round" ><FaSyncAlt />Rounds</a></li>
                )}
                <li><a href="#detail" ><FaRegFileAlt />Details</a></li>
                <li><a href="#contact" ><FaRegAddressBook />Contacts</a></li>
                {prizes && (
                <li><a href="#prize" ><FaTrophy />Prizes</a></li>
                )}
                <li><a href="#review" ><FaRegCommentAlt />Reviews</a></li>
                </ul>
            </div>
      </nav> */}

        {/* <div className="od-rounds">
        <div className="od-rounds-heading">
          <h3><span>HUL LIME</span> Season 12: Stages and Timelines</h3>
        </div>
        <div className="container">
          <div className="row">
          <div className="col-md-3">
          <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingOne">
                      <h4 className="panel-title">
                  <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    Collapsible Group Item #1
                  </a>
                </h4>

                  </div>
                  <div id="collapseOne" className="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                      <div className="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.le VHS.</div>
                  </div>
              </div>
              <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingTwo">
                      <h4 className="panel-title">
                  <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    Collapsible Group Item #2
                  </a>
                </h4>

                  </div>
                  <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                      <div className="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid.</div>
                  </div>
              </div>
              <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id="headingThree">
                      <h4 className="panel-title">
                  <a className="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    Collapsible Group Item #3
                  </a>
                </h4>

                  </div>
                  <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                      <div className="panel-body">Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. </div>
                  </div>
              </div>
          </div>
          </div>
          </div>
          </div>

      </div> */}

        <div className="od-content" id="round">
          {/* {JSON.stringify(rounds)} */}

          {rounds &&
            rounds.map((item, index) => (
              <Card className="content-body">
                <h3 className="od-content-tilte">
                  <span>{item.title}</span>
                </h3>
                <p className="od-content-text">{item.description}</p>
                <p>
                  <span>
                    <FaRegCalendarAlt /> {item.start_date} {item.start_time} -{" "}
                    <FaRegCalendarAlt /> {item.end_date} {item.end_time}{" "}
                  </span>
                </p>
                {/* {alldata.is_registered === true && alldata.show_attachment === 1 && <button className="btn menubtn"><a href={alldata.attachment_img} download>Download Attachment</a></button>} */}
              </Card>
            ))}
        </div>
        <div id="detail">
          <div className="od-content">
            <div className="od-content-heading">
              <br />
              <h3>More Details about {alldata.title} </h3>
            </div>
            <Card className="rp-content-body">
              <h3 className="rp-content-tilte">
                {/* <span>Read</span> more about {alldata.organization} */}
              </h3>
              <p className="rp-content-text">
                {/* {alldata.about} */}
                <span dangerouslySetInnerHTML={{ __html: alldata.about }} />
                {/* {convertToRaw(alldata.about)} */}
              </p>
            </Card>
          </div>
        </div>

        <div className="dnd" id="contact">
          <Row>
            <Col md={6}>
              <div className="dnd-heading">
                <h3>
                  Important <span>Dates</span> and <span>Deadlines</span>
                </h3>
              </div>
              {imortantdates &&
                imortantdates.map((item, index) => (
                  <Col md={imortantdates.length == 1 ? 12 : 6} key={index}>
                    <div className="dnd-body">
                      <div className="dnd-icon">
                        <img
                          className="list-icon-img"
                          src={require("../../detailspage/date.png").default}
                          alt="od-banner"
                        />
                      </div>
                      <div className="dnd-text">
                        <p className="dnd-timeslot">
                          <Moment format="D MMMM YYYY " date={item.date} />
                        </p>
                        <p className="dnd-timeslot-type">{item.datetitle}</p>
                      </div>
                    </div>
                  </Col>
                ))}
            </Col>
            <Col md={6}>
              <div className="dnd-heading">
                <h3>
                  {" "}
                  Contact the <span>Dates</span>
                </h3>
              </div>
              {imortantcontact &&
                imortantcontact.map((item, index) => (
                  <Col md={imortantdates.length == 1 ? 12 : 6} key={index}>
                    <div className="dnd-body">
                      <div className="dnd-icon">
                        <img
                          className="list-icon-img"
                          src={
                            require("../../detailspage/review-profile.png")
                              .default
                          }
                          alt="od-banner"
                        />
                      </div>
                      <div className="dnd-text">
                        <p className="dnd-timeslot">{item.name}</p>
                        <p className="dnd-timeslot-type">{item.email}</p>
                        <p className="dnd-timeslot-type">{item.contact}</p>
                      </div>
                    </div>
                  </Col>
                ))}
            </Col>
          </Row>
        </div>

        {/* <div className="download-attachment">
        <div className="da-heading">
          <h3><span>Download</span> Attachment</h3>
        </div>
        <div className="da-body">
          <Row>
            <Col className="da-content"><a><FaFilePdf />HUL LIME S12 Rule Book</a></Col>
            <Col className="da-content"><a><FaFilePdf />HUL LIME S12 Rule Book</a></Col>
            <Col className="da-content"><a><FaFilePdf />HUL LIME S12 Rule Book</a></Col>
          </Row>
          <Row>
            <Col className="da-content"><a><FaFilePdf />HUL LIME S12 Rule Book</a></Col>
            <Col className="da-content"><a><FaFilePdf />HUL LIME S12 Rule Book</a></Col>
            <Col className="da-content"><a><FaFilePdf />HUL LIME S12 Rule Book</a></Col>
          </Row>
        </div>
      </div> */}

        <div className="rewards-prizes" id="prize">
          <div className="rp-heading">
            <h3>
              <span>What's</span> at stake - Rewards and Prizes?
            </h3>
          </div>
          <div className="rp-text">
            <p>Attractive prizes to be won along with PPIs.</p>
          </div>
          <div className="rp-card-body">
            <Row>
              {prizes &&
                prizes.map((item, index) => (
                  <Col md={4}>
                    <div className="text-left prize-sec">
                      <div className="d-inline-block float-left">
                        <h4 className="mb-1">
                          <b> {item.rank} </b>
                        </h4>
                        <h2 className="font-weight-bold prize-sec-price color-white">
                          {item.currency === "inr" ? (
                            <i className="fa fa-rupee iconclor"></i>
                          ) : (
                            "$"
                          )}
                          {item.prize_money.toLocaleString()} /{" "}
                          <span className="certificate">
                            {/* {certificate_provided && certificate_provided} */}
                          </span>
                        </h2>
                        <p>{item.description}</p>
                      </div>
                      <div className="text-right">
                        <FaAward className="awardicon" />
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>

          <div className="rp-content" id="detail">
            <Card className="rp-content-body">
              <h3 className="rp-content-tilte">
                <span>Read</span> more about {alldata.organization}
              </h3>
              <p className="rp-content-text">
                <span dangerouslySetInnerHTML={{ __html: alldata.about }} />
                {/* {convertToRaw(alldata.about)} */}
              </p>
            </Card>
          </div>
        </div>

        {/* <div className="read-articles">
          <div className="ra-heading">
            <h3><span>Read</span> Articles</h3>
          </div>
           <Row>

           <Col>
            <Card className="ra-card">
              <Card.Img variant="top" src={require('../../detailspage/aricle-img-1.png').default} />
              <div className="ra-card-content">
                <p className="ra-card-title"><span>Speakers</span> Connect Uday Upreti</p>
                <p className="ra-card-text">If you are looking to start your venture and not sure where to start, we are here to assist and support you in your....</p>
                <Button className="read-more-btn" type="primary">Explore More  </Button>
              </div>
             
            </Card>
            </Col>

            <Col>
            <Card className="ra-card">
              <Card.Img variant="top" src={require('../../detailspage/aricle-img-1.png').default} />
              <div className="ra-card-content">
                <p className="ra-card-title"><span>Speakers</span> Connect Uday Upreti</p>
                <p className="ra-card-text">If you are looking to start your venture and not sure where to start, we are here to assist and support you in your....</p>
                <Button className="read-more-btn" type="primary">Explore More  </Button>
              </div>
             
            </Card>
            </Col>

            <Col>
            <Card className="ra-card">
              <Card.Img variant="top" src={require('../../detailspage/aricle-img-1.png').default} />
              <div className="ra-card-content">
                <p className="ra-card-title"><span>Speakers</span> Connect Uday Upreti</p>
                <p className="ra-card-text">If you are looking to start your venture and not sure where to start, we are here to assist and support you in your....</p>
                <Button className="read-more-btn" type="primary">Explore More  </Button>
              </div>
              
            </Card>
            </Col>

            </Row>             
            </div>   */}

        {/*   <div className="complaint">
              <div className="complaint-text">
                <p>*This opportunity has been listed by Youth Action Hub - India (Delhi). Dare2Compete is not liable for any content mentioned includingthis opportunity 
                  or the process followed by the organizers for this opportunity. However, please raise a complaint if you Want Dare2Compete to look into the matter.</p>
              </div>
              <Button className="complaint-btn" id="contact">
                <img src={require('../../detailspage/raise-a-complaint.png').default} />
                Raise a Complaint
              </Button>
            </div> */}

        {/* <div className="faqs"  id="faq">
              <div className="faqs-heading">
                <h3><span>(FAQ)s</span>(FAQ)s and Discussions</h3>
              </div>

              <Card  className="comment-card">
                <div className="person-image">
                <img src={require('../../detailspage/review-profile.png').default} />
                </div>
                <div className="comment-details">
                  <p className="person-name">Manideepak Gandhi</p>
                  <FaCalendarAlt /><span>5 Months Ago</span>
                  <p className="review-comments">By when can we except the results for Round 2B?</p>
                    <div>
                      <div className="reply-image">
                      <img src={require('../../detailspage/review-profile.png').default} />
                      </div>
                      <div className="reply-details">
                        <p className="replyer-name">Manideepak Gandhi</p>
                        <FaCalendarAlt /><span>5 Months Ago</span>
                        <p className="reply-comments">By when can we except the results for Round 2B?</p>
                       </div> 
                    </div>
                </div>
              </Card>

              <Card className="comment-card">
                <div className="person-image">
                <img src={require('../../detailspage/review-profile.png').default} />
                </div>
                <div className="comment-details">
                  <p className="person-name">Manideepak Gandhi</p>
                  <FaCalendarAlt /><span>5 Months Ago</span>
                  <p className="review-comments">By when can we except the results for Round 2B?</p>
                    <div>
                      <div className="reply-image">
                      <img src={require('../../detailspage/review-profile.png').default} />
                      </div>
                      <div className="reply-details">
                        <p className="replyer-name">Manideepak Gandhi</p>
                        <FaCalendarAlt /><span>5 Months Ago</span>
                        <p className="reply-comments">By when can we except the results for Round 2B?</p>
                       </div> 
                    </div>
                </div>
              </Card>

              <div className="faqs-bottom">
                <Button><Link to="/Opportinities">View all Discussions</Link></Button>
              </div>
              </div> */}
      </div>
    </React.Fragment>
  );
}

export default Opprtunitydetailpage;
