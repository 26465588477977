import marked from "marked";
import dompurify from "dompurify";

export const rawMarkup = (data) => {
  const sanitizer = dompurify.sanitize;
  let rawMarkup = marked(sanitizer(data));
  return { __html: rawMarkup };
};

export const checkAnswer = (
  index,
  correctAnswer,
  answerSelectionType,
  question,
  {
    userInput,
    userAttempt,
    currentQuestionIndex,
    continueTillCorrect,
    showNextQuestionButton,
    incorrect,
    correct,
    myQuestions,
    setButtons,
    setCorrectAnswer,
    setIncorrectAnswer,
    setCorrect,
    setIncorrect,
    setShowNextQuestionButton,
    setUserInput,
    setUserAttempt,
    setMyQuestions,
  },
) => {
  const indexStr = `${index}`;
  const disabledAll = {
    0: { disabled: true },
    1: { disabled: true },
    2: { disabled: true },
    3: { disabled: true },
  };
  console.log("answerSelectionType", answerSelectionType);
  if (answerSelectionType === "single") {
    console.log("answerSelectionType", answerSelectionType);
    if (userInput[currentQuestionIndex] === undefined) {
      userInput.push(index);
    }
    console.log("question list", question);
    // console.log("indexStr", indexStr)
    // console.log("correctAnswer", correctAnswer)
    // console.log("correct", correct)
    // console.log("currentQuestionIndex", currentQuestionIndex)
    // console.log("correct", myQuestions)

    question.userAnswer = indexStr;
    let userQA = JSON.parse(localStorage.getItem("userQA"));
    if (userQA) {
      let questionExist = userQA.filter(
        (vendor) => vendor["question"] === question.question,
      );
      console.log("userQA", questionExist);

      if (questionExist.length > 0) {
        questionExist[0].userAnswer = indexStr;
      } else {
        userQA.push(question);
      }
    } else {
      userQA = [];
      userQA.push(question);
    }
    localStorage.setItem("userQA", JSON.stringify(userQA));

    // if (indexStr === correctAnswer) {
    //     if (incorrect.indexOf(currentQuestionIndex) < 0 && correct.indexOf(currentQuestionIndex) < 0) {
    //         correct.push(currentQuestionIndex);
    //     }
    //     console.log("correct", correct)
    //     // setButtons((prevState) => ({
    //     //         ...prevState,
    //     //         ...disabledAll,
    //     //         [index - 1]: {
    //     //             className: (indexStr === correctAnswer) ? "correct" : "incorrect"
    //     //         },
    //     //     })
    //     // );

    //     // setCorrectAnswer(true);
    //     // setIncorrectAnswer(false);
    //     // setCorrect(correct);
    //     setShowNextQuestionButton(true)

    // } else {
    //     if (correct.indexOf(currentQuestionIndex) < 0 && incorrect.indexOf(currentQuestionIndex) < 0) {
    //         incorrect.push(currentQuestionIndex)
    //     }
    //     console.log("incorrect", incorrect)

    //     if (continueTillCorrect) {
    //         // setButtons((prevState) => (
    //         //     Object.assign(
    //         //         {},
    //         //         prevState,
    //         //         {
    //         //             [index - 1]: {
    //         //                 disabled: !prevState[index - 1]
    //         //             }
    //         //         }
    //         //     )
    //         // ))
    //     } else {
    //         // setButtons((prevState) => (
    //         //     Object.assign(
    //         //         {},
    //         //         prevState,
    //         //         {
    //         //             ...disabledAll,
    //         //             [index - 1]: {
    //         //                 className: (indexStr === correctAnswer) ? "correct" : "incorrect"
    //         //             },
    //         //         }
    //         //     )
    //         // ));

    //         // setShowNextQuestionButton(true)
    //     }

    //     // setIncorrectAnswer(true);
    //     // setCorrectAnswer(false);
    //     // setIncorrect(incorrect)
    // }
  } else {
    let maxNumberOfMultipleSelection = correctAnswer.length;

    if (userInput[currentQuestionIndex] === undefined) {
      userInput[currentQuestionIndex] = [];
    }

    if (userInput[currentQuestionIndex].length < maxNumberOfMultipleSelection) {
      userInput[currentQuestionIndex].push(index);

      if (correctAnswer.includes(index)) {
        if (
          userInput[currentQuestionIndex].length <= maxNumberOfMultipleSelection
        ) {
          // setButtons((prevState) => ({
          //         ...prevState,
          //         [index - 1]: {
          //             disabled: !prevState[index - 1],
          //             className: (correctAnswer.includes(index)) ? "correct" : "incorrect"
          //         },
          //     })
          // )
        }
      } else {
        if (
          userInput[currentQuestionIndex].length <= maxNumberOfMultipleSelection
        ) {
          // setButtons((prevState) => ({
          //         ...prevState,
          //         [index - 1]: {
          //             className: (correctAnswer.includes(index)) ? "correct" : "incorrect"
          //         },
          //     })
          // )
        }
      }
    }

    if (maxNumberOfMultipleSelection === userAttempt) {
      let cnt = 0;
      for (let i = 0; i < correctAnswer.length; i++) {
        if (userInput[currentQuestionIndex].includes(correctAnswer[i])) {
          cnt++;
        }
      }

      if (cnt === maxNumberOfMultipleSelection) {
        correct.push(currentQuestionIndex);

        // setCorrectAnswer(true);
        // setIncorrectAnswer(false);
        // setCorrect(correct);
        setShowNextQuestionButton(true);
        setUserAttempt(1);
      } else {
        incorrect.push(currentQuestionIndex);

        // setIncorrectAnswer(true);
        // setCorrectAnswer(false);
        // setIncorrect(incorrect);
        setShowNextQuestionButton(true);
        setUserAttempt(1);
      }
    } else {
      if (!showNextQuestionButton) {
        setUserInput(userInput);
        setUserAttempt(userAttempt + 1);
      }
    }
  }
};
