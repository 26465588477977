import React from "react";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import successimg from "../../images/Success.png";

import "./static.css";
let allteam = JSON.parse(localStorage.getItem("allteam"));
function Success(props) {
  window.scrollTo({
    top: 20,
    behavior: "smooth",
  });

  console.log("allteam", allteam);
  if (window.location.href.indexOf("reload") == -1) {
    window.location.href = window.location.href + "?reload";
  }
  const gotoOpportunity = () => {
    props.history.push("/e/" + localStorage.getItem("eventslug"), {
      id: 7,
      color: "green",
    });
  };

  return (
    <React.Fragment>
      <Header />
      <div className="success-page">
        <div className="">
          <div className="successimg">
            <img src={successimg} alt="logo" className="logo" />
          </div>
          <span className="successpagemsg">
            You have registered successfully <br /> for the opportunity!{" "}
          </span>
          <table id="customers">
            <thead>
              <th>Team Name</th>
              <th>Member Name</th>
              <th>Member Type</th>
              <th>Email</th>
              <th>Gender</th>
              <th>Number</th>
              <th>Institution / Organization</th>
              <th>Type</th>
              <th>Domain</th>
              <th>Course</th>
              <th>Specialization</th>
              <th>Year of Study</th>
              <th>Graduation Year</th>
            </thead>
            {allteam &&
              allteam.map(
                (item, index) =>
                  item.name && (
                    <tr>
                      <td>{item.team_name}</td>
                      <td>{item.name}</td>
                      <td>{item.is_teamlead === 1 ? "Leader" : "Member"}</td>
                      <td>{item.email}</td>
                      <td>{item.gender}</td>
                      <td>{item.mobile}</td>
                      <td>{item.school}</td>
                      <td>{item.type}</td>
                      {/* <td>{item.pronoun}</td> */}
                      <td>{item.domain}</td>
                      <td>{item.course}</td>
                      <td>{item.specialization}</td>
                      <td>{item.year_of_study}</td>
                      <td>{item.graduation_year}</td>
                    </tr>
                  ),
              )}
          </table>
          <button
            className="btn menubtn fr mt-5"
            onClick={() => gotoOpportunity()}
          >
            View opportunity
          </button>
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Success;
