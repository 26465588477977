import React, {  } from "react";
import RightSidebar from "./UserRightSidebar";
import Footer from "../CommonFiles/Footer";
import UserHeader from "./UserHeader";

function Backendrouter() {
  const menuItems = [
    { name: "My Registrations", to: "/user", icon: "fas fa-home" },
    { name: "Workshop Hosted", to: "/workshops", icon: "fa fa-graduation-cap" },
    {
      name: "Opportunity Hosted",
      to: "/user/opportunity",
      icon: "fa fa-paper-plane",
    },
    { name: "Settings", to: "/user/setting", icon: "fas fa-cog" },
  ];
  return (
    <div id="user-wrapper" className="dashboard">
      <UserHeader />
      <div className="row">
        <RightSidebar />
      </div>
      <Footer />
    </div>
  );
}
export default Backendrouter;
