export const trimObjectValues = (obj) => {
  let cleanedObj = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key) && typeof obj[key] === "string") {
      cleanedObj[key] = obj[key].trim();
    } else {
      cleanedObj[key] = obj[key];
    }
  }

  return cleanedObj;
};
