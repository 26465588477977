import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Tab, Tabs, TabContent } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaArrowLeft, FaArrowRight, FaSave } from "react-icons/fa";
import config from "../../CommonFiles/config.json";
import Swal from "sweetalert2";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const StepSeven = ({ newActiveStepValue }) => {
  const [inputValue, setInputValue] = useState({});
  const [errormsg, setError] = useState();
  const [inputFields, setInputFields] = useState([{ datetitle: "", date: "" }]);
  const [contactFields, setContactFields] = useState([
    { name: "", email: "", contact: "" },
  ]);

  useEffect(() => {
    if (localStorage.getItem("editopportunityid")) {
      axios({
        method: "get",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      })
        .then((response) => {
          const alldata = response.data.data;
          console.log(
            JSON.parse(alldata.important_dates),
            "get opprootunity step 7"
          );
          localStorage.setItem("FormSeven", alldata.important_dates);
          // var formdatssa = inputFields.push(JSON.parse(alldata.important_dates));
          var formdatssa = JSON.parse(localStorage.getItem("FormSeven"));
          if (JSON.parse(alldata.important_dates).length > 0) {
            setInputFields(JSON.parse(alldata.important_dates));
          }
          if (JSON.parse(alldata.important_contacts).length > 0) {
            setContactFields(JSON.parse(alldata.important_contacts));
          }
        })
        .catch((err) => {
          console.log("jhghjghg", err);
        });
    }
    // const formdata = JSON.parse(localStorage.getItem("FormSeven"));

    // if (formdata) {
    //   var formdatssa = JSON.parse(localStorage.getItem("FormSeven"));
    //   setInputFields(formdatssa);
    // }
  }, []);

  const onInputChange = (val, name, idx) => {
    const rows = [...inputFields];
    console.log("getting", name);
    if (name == "datetitle") {
      rows[idx].datetitle = val;
    } else {
      rows[idx].date = val;
    }
    setInputValue(rows);
    console.log("get val", inputValue);
  };
  // contact detail
  const onInputChangecontact = (val, name, idx) => {
    const rows = [...contactFields];
    console.log("getting", name);
    if (name == "name") {
      rows[idx].name = val;
    }
    if (name == "email") {
      rows[idx].email = val;
    }
    if (name == "contact" && val.length < 11) {
      rows[idx].contact = val;
    }
    setContactFields(rows);
    console.log("contact val", contactFields);
    console.log("contact val", JSON.stringify(contactFields));
  };
  const handleAddFields = (index) => {
    if (inputFields[inputFields.length - 1].date === "") return;

    const values = [...inputFields];
    values.push({
      datetitle: "",
      date: "",
    });
    setInputFields(values);
    console.log("contact val", inputFields);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleAddcontactFields = (index) => {
    if (
      contactFields[contactFields.length - 1].contact === "" &&
      contactFields[contactFields.length - 1].email === ""
    )
      return;

    const values = [...contactFields];
    values.push({
      name: "",
      email: "",
      contact: "",
    });
    setContactFields(values);
  };

  const handleRemovecontactFields = (index) => {
    const values = [...contactFields];
    values.splice(index, 1);
    setContactFields(values);
  };

  const nextStep = (e) => {
    if (inputValue.length === 0) {
      setError("Both are required");
      return true;
    }
    if (contactFields.length === 0) {
      setError("Both are required");
      return true;
    }

    localStorage.setItem("FormSeven", JSON.stringify(inputValue));
    // const FormFive = JSON.parse(localStorage.getItem("FormFive"));
    const FormSeven = JSON.parse(localStorage.getItem("FormSeven"));

    const mergedObject = {
      important_dates: JSON.stringify(inputFields),
      important_contacts: JSON.stringify(contactFields),
    };
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });

    console.log("dta", mergedObject);

    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "put",
      url:
        mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      data: mergedObject,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;
        // this.setState({
        //   alldata: alldata,
        // });
        if (e === "save") {
          Swal.fire(
            "Good job!",
            "Additional details have been updated",
            "success"
          );
        } else {
          localStorage.setItem("activestep", 7);
          newActiveStepValue(8);
        }
        localStorage.setItem("oppid", alldata.id);
        console.info("all analytics", alldata);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  const prevStep = () => {
    localStorage.setItem("activestep", 5);
    newActiveStepValue(5);
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className="form">
        <Form className="form-body">
          {errormsg && <h5 className="errormsg">{errormsg}</h5>}
          <h5 className="prizetittle impdate">IMPORTANT DATE </h5>
          <div className="form-row">
            {inputFields.map((inputField, index) => (
              <div key={`${inputField}~${index}`} className="col-md-12 row">
                <div className="form-group float-left col-md-6">
                  <label htmlFor="firstName">Date Title</label>
                  <input
                    type="text"
                    className="form-control"
                    name="datetitle"
                    value={inputField.datetitle}
                    onChange={(e) =>
                      onInputChange(e.target.value, e.target.name, index)
                    }
                  />
                </div>

                <div className="form-group float-left col-md-6">
                  <label htmlFor="currency">Date</label>

                  <input
                    type="date"
                    className="form-control"
                    name="date"
                    value={inputField.date}
                    onChange={(e) =>
                      onInputChange(e.target.value, e.target.name, index)
                    }
                  />
                </div>

                {index === 0 ? (
                  ""
                ) : (
                  <div className="form-group col-md-12 remove-field">
                    <button
                      className="btn btn-link minus-btn"
                      type="button"
                      onClick={() => handleRemoveFields(index)}
                    >
                      <h2 className="remove-round fr">
                        Remove <i className="fa fa-trash"></i>
                      </h2>
                    </button>
                  </div>
                )}
              </div>
            ))}

            <div className="row col-md-12 pl-3 pr-3">
              <button
                className="btn btn-link plus-btn add-date-btn"
                type="button"
                onClick={() => handleAddFields()}
              >
                <h2 className="add-round fr">
                  <i className="fa fa-plus"></i>Add Important Date{" "}
                </h2>
              </button>
            </div>
          </div>
          <h5 className="prizetittle impcontact">ORGANIZATION CONTACTS </h5>
          <div className="form-row">
            {contactFields.map((inputField, index) => (
              <div key={`${inputField}~${index}`} className="col-md-12 row">
                <div className="col-md-12 pb-2 mb-3 pl-0 pr-0">
                  <div className="form-group float-left col-md-4">
                    <label htmlFor="firstName">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={inputField.name}
                      onChange={(e) =>
                        onInputChangecontact(
                          e.target.value,
                          e.target.name,
                          index
                        )
                      }
                    />
                  </div>

                  <div className="form-group float-left col-md-4">
                    <label htmlFor="currency">Email</label>

                    <input
                      type="email"
                      className="form-control"
                      Name="email"
                      value={inputField.email}
                      onChange={(e) =>
                        onInputChangecontact(
                          e.target.value,
                          e.target.name,
                          index
                        )
                      }
                    />
                  </div>

                  <div className="form-group float-left col-md-4">
                    <label htmlFor="currency">Contact No</label>

                    <input
                      type="tel"
                      className="form-control"
                      name="contact"
                      value={inputField.contact && inputField.contact}
                      minLength="9"
                      maxLength="10"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      onChange={(e) =>
                        onInputChangecontact(
                          e.target.value,
                          e.target.name,
                          index
                        )
                      }
                    />
                  </div>

                  {index > 0 && (
                    <div className="form-group col-md-12 remove-field">
                      <button
                        className="btn btn-link minus-btn"
                        type="button"
                        onClick={() => handleRemovecontactFields(index)}
                      >
                        <h2 className="remove-round fr">
                          Remove <i className="fa fa-trash"></i>
                        </h2>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="row col-md-12 pl-3 pr-3">
            <button
              className="btn btn-link plus-btn add-date-btn"
              type="button"
              onClick={() => handleAddcontactFields()}
            >
              <h2 className="add-round fr">
                <i className="fa fa-plus"></i>Add Organization Contacts{" "}
              </h2>
            </button>
          </div>
        </Form>
      </div>
      <div className="hosting_bottom">
        <Button className="help-btn" type="button" onClick={() => prevStep(4)}>
          <FaArrowLeft />
          Go Back
        </Button>
        <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => nextStep("continue")}
        >
          Next <FaArrowRight />
        </Button>
        {localStorage.getItem("opportunityfrom") && (
          <Button
            className="save-hosting-first-btn editsavebtn"
            type="button"
            onClick={() => nextStep("save")}
          >
            Save <FaSave />
          </Button>
        )}
        <Link to={`/user/hosting`}>
          {" "}
          <Button
            className="transparentbtn float-right mt-10 mr-2"
            type="button"
            // onClick={(e) => cancel(e)}
          >
            Cancel
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default StepSeven;
