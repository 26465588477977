import React from "react";
import Carousel, { Dots, slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import "./Workshop.css";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Dot,
  DotGroup,
} from "pure-react-carousel";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "pure-react-carousel/dist/react-carousel.es.css";
function Workshop() {
  const array = [
    {
      image: require("../../images/gallery/image1.jpeg").default,
      link: "https://dare2compete.com/o/ace-the-case-workshop-7-lakes-fest-iim-calcutta-indian-institute-of-management-iim-calcutta-139870#tab-infobox",
    },
    {
      image: require("../../images/gallery/closetheloop.jpeg").default,
      link: "https://dare2compete.com/o/conquest-consulting-club-of-iim-shillong-rajiv-gandhi-indian-institute-of-management-iim-shillong-4663?lb=21070317",
    },
    {
      image: require("../../images/gallery/marketing_club.jpeg").default,
      link: "https://dare2compete.com/o/excalibur-kritva-2021-international-management-institute-imi-new-delhi-144675?lb=21070317#tab-prize",
    },

    {
      image: require("../../images/gallery/image2.jpeg").default,
      link: "",
    },
    {
      image: require("../../images/gallery/acethecase.jpeg").default,
      link: "https://dare2compete.com/o/ace-the-case-workshop-conspectus-infusion21-indian-institute-of-management-iim-rohtak-141622?lb=21070317",
    },
    {
      image: require("../../images/gallery/image6.png").default,
      link: "https://dare2compete.com/o/concase-parivartan21-department-of-management-studies-dms-indian-institute-of-technology-iit-delhi-140917?lb=21070316",
    },
    {
      image: require("../../images/gallery/purvodaya.jpeg").default,
      link: "",
    },
  ];
  return (
    <div className="container">
      <div className="campus-wrapper px-lg-5">
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={40}
          slidesPerView={3}
          navigation={{
            nextEl: ".swiper-btn-next",
            prevEl: ".swiper-btn-prev",
          }}
          loop={true}
          pagination={{
            el: ".swiper-pagination",
            clickable: true,
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="campus  px-4 pb-4"
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 50,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1,
              spaceBetween: 50,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
        >
          <div className="swiper-wrapper mb-5">
            {/* <!-- Slides --> */}
            {array.map((item, index) => (
              <SwiperSlide>
                <div className="swiper-slide">
                  <img
                    className="img-fluid"
                    src={item.image}
                    alt="Flipkart Wired"
                  />
                </div>
              </SwiperSlide>
            ))}
          </div>
          <div className="swiper-buttons text-center mt-4">
            <div className="swiper-btn-prev">
              <FaArrowLeft />
            </div>
            <div className="swiper-pagination"></div>
            <div className="swiper-btn-next">
              <FaArrowRight />
            </div>
          </div>
        </Swiper>

        {/* <!-- Slider main container --> */}
      </div>
    </div>
  );
}

export default Workshop;
