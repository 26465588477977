import React from "react";
import UserRoutes from "./UserRoutes";

const UserRightSidebar = () => {
  return (
    <main className="col-md-12">
      <UserRoutes />
    </main>
  );
};

export default UserRightSidebar;
