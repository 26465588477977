import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'antd';
import { InputOTP } from 'antd-input-otp';
import './EmailVerification.css';
import { verifyOTPForEmailVerification, resendOTPForEmailVerification } from "../../services/emailVerificationService";
import logo from "../../images/main_logo.png";
import network_bg from "../../images/network_bg.jpg";


function EmailVerification(props) {
    const formRef = useRef(null);
    const [form] = Form.useForm();
    const [otpValues, setOtpValues] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [email, setEmail] = useState(null);

    useEffect(() => {
        // fetch email from session storage
        const email = sessionStorage.getItem("email");
        setEmail(email);

        if (!email) {
            localStorage.removeItem("token");
            localStorage.removeItem("alldata");
            localStorage.clear();
            sessionStorage.clear();
            props.history.push(`/`);
        }
    }, []);

    const handleFinish = (values) => {
        const payload = {
            userId: sessionStorage.getItem("userId"),
            email: sessionStorage.getItem("email"),
            otp: parseInt(otpValues.join(""), 10)
        }
        try {
            verifyOTPForEmailVerification(payload).then((response) => {
                if (response.status) {
                    form.resetFields();
                    setOtpValues([]);
                    const userdata = JSON.parse(localStorage.getItem("alldata"));
                    if (userdata) {
                        userdata.is_email_verified = true;
                        localStorage.setItem("alldata", JSON.stringify(userdata));
                    }
                    window.location.href = "/";
                } else {
                    form.resetFields();
                    setOtpValues([]);
                }
            })
        } catch (error) {
            if (formRef.current) {
                formRef.current.classList.add('shake');
                form.resetFields();
                setOtpValues([]);
            }
        }
    }

    const resendOTP = () => {
        const payload = {
            userId: sessionStorage.getItem("userId"),
            email: sessionStorage.getItem("email")
        }

        try {
            resendOTPForEmailVerification(payload).then((response) => {
                if (response.status) {
                    form.resetFields();
                    setOtpValues([]);
                    setDisableSubmit(true);
                }
            })
        } catch (error) {

        }
    }

    const signup = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("alldata");
        localStorage.clear();
        sessionStorage.clear();
        props.history.push(`/`);
    }

    useEffect(() => {
        const isOtpComplete = otpValues.length === 6 && otpValues.every(value => value !== undefined && value !== '');
        setDisableSubmit(!isOtpComplete);
    }, [otpValues]);

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <img src={logo} alt="Grad Partners" className="grad-logo" />
            </nav>
            <div className="email-verification-container" style={{ backgroundImage: `url(${network_bg})` }}>
                <Form onFinish={handleFinish} form={form} className="email-verification-form" ref={formRef}>
                    <h2 className='text-center text-white'>Please verify your email</h2>
                    <div className="email-verification-text">
                        <i className="fas fa-envelope" style={{ marginRight: '0.5rem', fontSize: '1.2rem' }} />
                        <span style={{ fontSize: '1rem' }}>We have shared the OTP on your email id (<a href="mailto:{email}">{email}</a>). Please enter that OTP below to verify your email.</span>
                    </div>
                    <Form.Item name="otp">
                        <InputOTP autoFocus inputType="numeric" onChange={setOtpValues} value={otpValues} />
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit" style={{ padding: '8px', height: 'auto' }} className="btn-submit" block disabled={disableSubmit}>Verify Now</Button>
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', alignItems: 'center' }}>
                        <span style={{ fontSize: '0.8rem' }}>Did not receive email ? </span>&nbsp;&nbsp;&nbsp;
                        <Button type="link" style={{ padding: 0, fontSize: '0.8rem' }} onClick={resendOTP}>Resend OTP </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <span style={{ fontSize: '0.8rem' }}>Incorrect email ? </span>&nbsp;&nbsp;&nbsp;
                        <Button type="link" style={{ padding: 0, fontSize: '0.8rem' }} onClick={signup}>Signup With Valid Email </Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default EmailVerification;
