import React, { useEffect, useState } from "react";
import LeftSideBar from "./UserLeftSideBar";
import RightSidebar from "./UserRightSidebar";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Toggle from "react-toggle";
import { Table } from "antd";
import "./Usercss.css";
import "react-toggle/style.css";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const columns = [
  {
    title: "Team Name",
    dataIndex: "team_name",
  },
  {
    title: "Member Name",
    dataIndex: "name",
  },
  {
    title: "Member Email",
    dataIndex: "email",
    // sorter: {
    //   compare: (a, b) => a.chinese - b.chinese,
    //   multiple: 3,
    // },
  },
  {
    title: "Member Rank",
    dataIndex: "is_teamlead",
    render: (text, record) => (record.is_teamlead === 1 ? "Leader" : "Member"),
  },
  {
    title: "Member phone",
    dataIndex: "mobile",
    // sorter: {
    //   compare: (a, b) => a.math - b.math,
    //   multiple: 2,
    // },
  },
  {
    title: "School",
    dataIndex: "school",
  },
  {
    title: "Gender",
    dataIndex: "gender",
  },
  {
    title: "Specialization",
    dataIndex: "specialization",
  },
  {
    title: "type",
    dataIndex: "type",
  },

  //   {
  //     title: 'Submission',
  //     dataIndex: 'button',
  //     render: (text, record) => (<button className="btn menubtn"><a href={record.submission_file} download>Download</a></button>)
  //   },
];

const Allregistereduser = (props) => {
  const [alldata, setAlldata] = useState();
  const [opportunity, setOpportunity] = useState();
  const [teamlist, setTeamlist] = useState();

  const [forfilter, setAForfilter] = useState();
  const [status, setStatus] = useState(1);
  const [attachmentstatus, setAttachmentstatus] = useState();

  useEffect(() => {
    // const tokencheck = localStorage.getItem("token")
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/all/registered/opportunities/" +
          props.match.params.id,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          // console.log("response", response)
          // const alldatas = response.data.data.rows;
          setTeamlist(response.data.data);
          // console.info("alldata", alldata);
          // console.info("alldata", opportunity);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  }, []);

  function filter(filterstatus, statuscheck) {
    console.log(filterstatus);
    const closedata = forfilter.filter(
      (workshop) => workshop.is_active === statuscheck,
    );
    if (filterstatus === 1) {
      setAlldata(forfilter);
    } else {
      setAlldata(closedata);
    }
    setStatus(statuscheck);

    console.log("close data", alldata);
  }

  async function exportproducts(params) {
    try {
      const result = await axios({
        method: "get",
        url:
          mainURL + "/registers/excel?opportunity_id=" + props.match.params.id,
        data: {
          opportunity_id: props.match.params.id,
        },
      });
      console.log("product re", result.data.data);
      let excelurl = mainURL + "/" + result.data.path;
      window.open(excelurl, "_blank");
    } catch (error) {
      console.log(error);
    }
  }

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <div className="container topmargin">
      <div className="  mb-4">
        <div className="row">
          <div className="col-md-9">
            <h5> Registered Teams</h5>
          </div>
          <div className="col-md-3">
            <button className="btn menubtn" onClick={() => exportproducts()}>
              {/* <img
                className=""
                src={require("../../assets/images/Export.png").default}
              /> */}
              Download Registration List
            </button>
          </div>
          <hr />
        </div>
      </div>
      {/* <div className="row">
        <div className="col-md-6">
          <input
            type="text"
            value=""
            name=""
            placeholder=""
            className="searchbox"
          ></input>
        </div>
      </div> */}

      <div id="user-wrapper" className="dashboard row registerbox tp20">
        <Table columns={columns} dataSource={teamlist} onChange={onChange} />
      </div>
    </div>
  );
};

export default Allregistereduser;
