import React, { useState, useEffect } from "react";
import "./ManageOpportunity.css";
import FormTab from "./FormTab";
import StepOne from "./FormSteps/StepOne";
import StepTwo from "./FormSteps/StepTwo";
import { StepFour } from "./FormSteps/StepFour";
import StepFive from "./FormSteps/StepFive";
import StepSix from "./FormSteps/StepSix";
import StepSeven from "./FormSteps/StepSeven";
import StepEight from "./FormSteps/StepEight";
import StepNine from "./FormSteps/StepNine";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import config from "../CommonFiles/config.json";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

function ManageOpportunity(props) {
  const [activeStep, setActiveStep] = useState();
  const [allColleges, setAllColleges] = useState([]);
  useEffect(() => {
    let activestatus = localStorage.getItem("activestep");
    console.log("props", props.location.state, activestatus);

    if (activestatus === null) {
      localStorage.setItem("activestep", 1);
      setActiveStep("1");
    } else {
      localStorage.setItem("activestep", activestatus);
      setActiveStep(activestatus);
    }
  }, [activeStep]);

  const activeSteps = (num) => {
    setActiveStep(num);
  };

  return (
    <React.Fragment>
      <Header
        onCollegeDataLoad={(allCollegesData) => setAllColleges(allCollegesData)}
      />

      <div>
        <FormTab />

        {activeStep === "1" && (
          <StepOne
            editStatus={props.location.state}
            newActiveStepValue={activeSteps}
          />
        )}

        {activeStep === "2" && (
          <StepTwo
            editStatus={props.location.state}
            newActiveStepValue={activeSteps}
          />
        )}

        {/* {activeStep === '3' && ( 
       <StepThree newActiveStepValue={activeSteps}  /> 
      )} */}
        {console.log("enter step 3", typeof activeStep)}
        {activeStep === "3" && (
          <>
            <StepFour
              editStatus={props.location.state}
              newActiveStepValue={activeSteps}
            />
          </>
        )}

        {activeStep === "4" && (
          <StepFive
            editStatus={props.location.state}
            newActiveStepValue={activeSteps}
          />
        )}

        {activeStep === "5" && (
          <StepSix
            editStatus={props.location.state}
            newActiveStepValue={activeSteps}
          />
        )}

        {activeStep === "6" && (
          <StepSeven
            editData={props.location.state}
            newActiveStepValue={activeSteps}
          />
        )}
        {activeStep === "7" && (
          <StepEight
            editStatus={props.location.state}
            newActiveStepValue={activeSteps}
          />
        )}
        {activeStep === "8" && allColleges.length > 0 && (
          <StepNine
            editStatus={props.location.state}
            newActiveStepValue={activeSteps}
            allColleges={allColleges}
            onAddOrg={(newOrg) =>
              setAllColleges([
                ...allColleges,
                { value: allColleges.length - 1, label: newOrg },
              ])
            }
          />
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ManageOpportunity;
