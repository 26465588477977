import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Tab, Tabs, TabContent } from "react-bootstrap";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import axios from "axios";
import config from "../CommonFiles/config.json";
import { Popconfirm } from "antd";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import $ from "jquery";
import Moment from "react-moment";
import Loader from "react-loader-spinner";
import Swal from "sweetalert2";

import moment from "moment-timezone";
import {
  FaArrowLeft,
  FaArrowRight,
  FaRegArrowAltCircleLeft,
} from "react-icons/fa";
import DynamicRegistrationForm from "../DynamicPages/DynamicRegistrationForm";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
var paytmconfig;
let userdata = JSON.parse(localStorage.getItem("alldata"));
const EditRegistrationform = (props) => {
  const [eventdata, setAlldata] = useState(null);
  const [removedId, setRemovedId] = useState([]);
  const [teamname, setTeamname] = useState();
  const [successmsg, setsuccessmsg] = useState();
  const [pronountype, setPronountype] = useState("");
  const [knowaboutus, setKnowaboutus] = useState();
  const [btnstatus, setBtnStatus] = useState(true);
  const [error, setError] = useState();
  const [smShow, setSmShow] = useState(false);
  const [showloader, setShowloader] = useState(false);
  const [selectedplan, setSelectedplan] = useState("");
  const [teamsize, setTeamsize] = useState();
  const [collages, setAllcollages] = useState();
  const tokencheck = localStorage.getItem("token");
  let [txntokenid, setTxntokenid] = useState();
  const [emailexist, setEmailexist] = useState();
  const [afterdiscount, setAfterdiscount] = useState("");
  const [planmsg, setPlanmsg] = useState();
  const [discountsuccess, setDiscountsuccess] = useState();
  const [Couponval, setCouponval] = useState();
  const [matched, setAllmatched] = useState();
  const [invalidemail, setInvalidemail] = useState([]);
  const [organisationtext, setOrganisationtext] = useState();
  const [logedinuser, setLoggedinuser] = useState(null);
  let [filterstatus, setFilterstatus] = useState(0);
  let [selectedindex, setSelectedindex] = useState(0);
  let [submitstatus, setSubmitstatus] = useState(false);
  const [findindex, setFindindex] = useState("");
  const [collagename, setCollageshow] = useState("");

  let tokenid;
  // alert(tokencheck)

  //   console.log("tokencheck", tokencheck);
  //   if (!tokencheck) {
  //     props.history.push(`/login`);
  //     // window.location.reload();
  //   } else {
  //     if (window.location.href.indexOf("reload") == -1) {
  //       window.location.href = window.location.href + "?reload";
  //     }
  //   }

  const cipla = [
    {
      value: "BMS College of Engineering, Bengaluru",
      label: "BMS College of Engineering, Bengaluru",
    },
    {
      value: "Cochin University of Science and Technology (CUSAT), Kochi",
      label: "Cochin University of Science and Technology (CUSAT), Kochi",
    },
    {
      value:
        "International Institute of Information Technology (IIIT), Bangalore",
      label:
        "International Institute of Information Technology (IIIT), Bangalore",
    },
    {
      value:
        "International Institute of Information Technology (IIIT), Hyderabad",
      label:
        "International Institute of Information Technology (IIIT), Hyderabad",
    },
    {
      value: "Karunya Institute of Technology and Sciences, Coimbatore",
      label: "Karunya Institute of Technology and Sciences, Coimbatore",
    },
    {
      value: "Kumaraguru College of Technology (KCT), Coimbatore",
      label: "Kumaraguru College of Technology (KCT), Coimbatore",
    },
    {
      value: "National Institute of Engineering (NIE), Mysore ",
      label: "National Institute of Engineering (NIE), Mysore ",
    },
    {
      value: "National Institute of Technology (NIT), Calicut",
      label: "National Institute of Technology (NIT), Calicut",
    },
    {
      value: "National Institute of Technology (NIT), Warangal",
      label: "National Institute of Technology (NIT), Warangal",
    },
    {
      value: "PES University, Bengaluru",
      label: "PES University, Bengaluru",
    },
    {
      value: "PSG College of Technology, Coimbatore",
      label: "PSG College of Technology, Coimbatore",
    },
    {
      value: "Punjab Engineering College (PEC), Chandigarh",
      label: "Punjab Engineering College (PEC), Chandigarh",
    },
    {
      value: "R. V. College of Engineering (RVCE), Bengaluru",
      label: "R. V. College of Engineering (RVCE), Bengaluru",
    },
    {
      value: "Ramaiah Institute of Technology, Bengaluru",
      label: "Ramaiah Institute of Technology, Bengaluru",
    },
    {
      value: "Sri Jayachamarajenda College of Engineering (SJCE), Mysore",
      label: "Sri Jayachamarajenda College of Engineering (SJCE), Mysore",
    },
    {
      value: "Thapar Institute of Engineering & Technology (TIET), Patiala",
      label: "Thapar Institute of Engineering & Technology (TIET), Patiala",
    },
    {
      value: "Thiagarajar College of Engineering (TCE), Madurai",
      label: "Thiagarajar College of Engineering (TCE), Madurai",
    },
    {
      value: "RV Institute of Technology and Management",
      label: "RV Institute of Technology and Management",
    },
  ];

  const USER_TOKEN = localStorage.getItem("token");
  console.log("user token", props);
  const AuthStr = "Bearer ".concat(USER_TOKEN);
  useEffect(async () => {
    try {
      const result = await axios({
        method: "get",
        url:
          mainURL +
          "/opportunity/" +
          props.history.location.state.slug +
          "?user_id=" +
          userdata.id,
      });

      setLoggedinuser(result.data.data);
      setTeamsize(result.data.data.max_team_size);
    } catch (error) {
      console.log(error);
    }
    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/opportunity/register/" + props.match.params.id,
        headers: { Authorization: AuthStr },
      });

      result.data.data[0] !== null
        ? setAlldata(result.data.data)
        : Swal.fire(
            "Error",
            "There is some issue with the data, please contact support team",
            "error"
          ).then(() => {
            props.history.push(`/user`);
          });

      for (let index = 0; index < result.data.data.length; index++) {
        const element = result.data.data[index];
        console.log("under team size", result.data.data[index].name);
        setTeamname(element.team_name);
        inputFields.push({
          id: element.id,
          leader_id: index > 0 ? userdata.id : null,
          is_teamlead: element.is_teamlead,
          name: element.name,
          email: element.email,
          secondary_mail: "",
          preferred_name: element.preferred_name,
          mobile: element.mobile,
          school: element.school,
          type: element.type,
          pronoun: element.pronoun,
          is_lgbtq: element.is_lgbtq,
          engg_score: element.engg_score,
          is_update: 1,
          is_open: false,
          gender: element.gender,
          instagram: element.instagram,
          high_school: element.high_school,
          is_backlog: element.is_backlog,
          is_internship: element.is_internship,
          current_location: element.current_location,
          hometown: element.hometown,
          intermediate: element.intermediate,
          is_accepted: element.is_accepted,
          linkedin: element.linkedin,
          dob: element.dob,
          domain: element.domain,
          college_registration: element.college_registration,
          course: element.course,
          specialization: element.specialization,
          course_duration: element.course_duration,
          year_of_study: element.year_of_study,
          graduation_year: element.graduation_year,
          know_about_us: element.know_about_us,
        });
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/allcollages",
      });
      setAllcollages(result.data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const [members, setMember] = useState([
    {
      is_teamlead: 1,
      name: userdata?.first_name,
      email: userdata?.email,
      mobile: "",
      pronoun: "",
      is_lgbtq: "",
      preferred_name: "",
      is_open: false,
    },
  ]);

  const [inputFields, setInputFields] = useState([]);
  const [inputshow, setInputshow] = useState([]);

  const onInputChangemember = (val, name) => {
    setPronountype(val);
    const rows = [...members];
    if (name == "name") {
      members.name = val;
    }
    if (name == "email") {
      members.email = val;
    }
    if (name === "mobile") {
      console.log("getting d", val);
      console.log("getting", name);
      members[0].mobile = val;
    }
    if (name === "pronoun") {
      if (val === "others") {
        members[0].is_open = true;
      } else {
        members[0].is_open = false;
      }
      members[0].pronoun = val;
    }

    setMember(rows);
    console.log("meme", members);
  };

  function ValidateEmail(mail, id) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      console.log("success");

      return true;
    }
    console.log("eror");
    if (!invalidemail.includes(id)) {
      setInvalidemail([...invalidemail, id]);
    }

    return false;
  }
  useEffect(() => {
    setInputFields(inputFields);
  }, [findindex]);
  // const onInputChange = (val, name, idx) => {
  //   const rows = [...inputFields];
  //   rows[idx].pronoun = val;

  // }
  const onPronounInputChange = (val, name, idx) => {
    const rows = [...inputFields];
    console.log("check rows", rows);
    rows[idx].pronoun = val;
    setInputFields(rows);
  };
  const onInputChange = (val, name, idx, e) => {
    // setInputValue({ ...inputFields, [name]: val });
    if (e) {
      const checkedid = "checkbox" + idx;

      const isChecked = document.getElementById(checkedid).checked;
      if (isChecked === false) {
        val = "";
      }
      if (isChecked === true) {
        setFindindex(0);
      }
      console.log("getting val", isChecked, val, findindex, idx);
    }

    const rows = [...inputFields];
    console.log("getting", val, name, idx, e);
    if (name == "name") {
      rows[idx].name = val;
    }
    if (name == "email") {
      if (ValidateEmail(val, idx) === true) {
        let remainingArr = invalidemail.filter((data) => data != idx);
        setInvalidemail(remainingArr);

        rows[idx].email = val;
      } else {
        console.log("invalid mail id's", invalidemail);
      }
    }
    if (name == "dob") {
      rows[idx].dob = val;
    }
    if (name == "gender") {
      rows[idx].gender = val;
    }
    if (name === "engg_score") {
      rows[idx].engg_score = val;
    }
    if (name == "preferred_name") {
      rows[idx].preferred_name = val;
    }
    if (name == "linkedin") {
      rows[idx].linkedin = val;
    }
    if (name == "instagram") {
      rows[idx].instagram = val;
    }
    if (name == "secondary_mail") {
      rows[idx].secondary_mail = val;
    }
    if (name == "mobile") {
      rows[idx].mobile = val;
    }

    if (name == "school") {
      // if (findindex === idx) {
      //   rows[idx].school = "";
      //   setFindindex("");
      // } else {
      //   setFindindex(idx);
      rows[idx].school = val;
      // }

      setFilterstatus(0);
    }
    if (name == "type") {
      rows[idx].type = val;
    }
    if (name == "pronoun") {
      console.log("pro val", val);
      if (val === "other") {
        rows[idx].is_open = true;
      } else {
        rows[idx].is_open = false;
      }

      rows[idx].pronoun = val;
    }
    if (name == "domain") {
      rows[idx].domain = val;
    }
    if (name == "is_lgbtq") {
      rows[idx].is_lgbtq = val;
    }
    if (name == "course") {
      rows[idx].course = val;
    }
    if (name == "specialization") {
      rows[idx].specialization = val;
    }
    if (name == "college_registration") {
      rows[idx].college_registration = val;
    }
    if (name == "year_of_study") {
      rows[idx].year_of_study = val;
    }
    if (name == "graduation_year") {
      rows[idx].graduation_year = val;
    }
    if (name == "course_duration") {
      rows[idx].course_duration = val;
    }
    if (name == "is_internship") {
      rows[idx].is_internship = val;
    }
    if (name == "is_backlog") {
      rows[idx].is_backlog = val;
    }
    if (name == "intermediate") {
      rows[idx].intermediate = val;
    }
    if (name == "high_school") {
      rows[idx].high_school = val;
    }
    if (name == "current_location") {
      rows[idx].current_location = val;
    }

    if (name == "hometown") {
      rows[idx].hometown = val;
    }

    setInputFields(rows);
    console.log("data", inputFields);
  };

  function cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }
  const handleAddFields = (index) => {
    // setTeamsize(eventdata.min_team_size + 1);
    console.log("alldata", userdata);
    const values = [...inputFields];
    values.push({
      is_teamlead: 0,
      name: "",
      email: "",
      mobile: "",
      school: "",
      type: "",
      leader_id: userdata.id,
      pronoun: "",
      is_lgbtq: "",
      is_open: false,
      domain: "",
      course: "",
      is_update: 0,
      specialization: "",
      year_of_study: "",
      graduation_year: "",
    });

    setInputFields(values);
    console.log("contact val", values);

    window.scrollTo({
      top: 1200 * (inputFields.length + 1),
      behavior: "smooth",
    });
  };

  const handleRemoveFields = (index, regId) => {
    const values = [...inputFields];
    const removelist = [...removedId];
    removelist.push(parseInt(regId));

    axios({
      method: "DELETE",
      url: mainURL + "/opportunity/register/" + regId,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {})
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
    setRemovedId(removelist);
    console.log("value check before", index, removelist);

    // values.splice(index, 1);
    console.log("value check", index, values);
    // setInputFields(values);
    const teamremove = `teamdiv${index}`;
    document.getElementById(teamremove).remove();
    // window.reload()
  };

  const submitform = (id) => {
    setBtnStatus(false);
    console.log(inputFields.length);

    var inputTag = document.querySelectorAll(".form-body input.required");
    var SelectedTag = document.getElementById("organisationname");
    var SelectedTag_course_duration = document.querySelectorAll(
      'select[name="course_duration"]'
    );

    for (var i = 0; i < SelectedTag_course_duration.length; i++) {
      console.log(
        "SelectedTag_course_duration",
        SelectedTag_course_duration[i].value
      );

      if (SelectedTag_course_duration[i].value === "") {
        SelectedTag_course_duration[i].setAttribute("id", "fill-the-field");
      } else {
        SelectedTag_course_duration[i].setAttribute("id", "");
      }
    }
    for (var i = 0; i < inputTag.length; i++) {
      if (
        inputTag[i].value === "" &&
        inputTag[i].getAttribute("name") != "college_registration"
      ) {
        console.log("input value", inputTag[i].value);
        inputTag[i].setAttribute("id", "fill-the-field");
      } else {
        inputTag[i].setAttribute("id", "");
      }

      if (!$("input[name='type" + i + "']:checked").val()) {
        $("input[name='type" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='type" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }

      if (!$("input[name='gender" + i + "']:checked").val()) {
        $("input[name='gender" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='gender" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }
      if (!$("input[name='is_lgbtq" + i + "']:checked").val()) {
        $("input[name='is_lgbtq" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='is_lgbtq" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }
      if (!$("input[name='pronoun" + i + "']:checked").val()) {
        $("input[name='pronoun" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='pronoun" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }

      if (!$("input[name='domain" + i + "']:checked").val()) {
        $("input[name='domain" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='domain" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }

      if (!$("input[name='year_of_study" + i + "']:checked").val()) {
        $("input[name='year_of_study" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='year_of_study" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }

      if (!$("input[name='graduation_year" + i + "']:checked").val()) {
        $("input[name='graduation_year" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='graduation_year" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }
    }
    if (!$("input[name='is_backlog" + i + "']:checked").val()) {
      $("input[name='is_backlog" + i + "']")
        .parent()
        .parent()
        .attr("id", "fill-the-field");
    } else {
      $("input[name='is_backlog" + i + "']")
        .parent()
        .parent()
        .attr("id", "");
    }
    if (!$("input[name='is_internship" + i + "']:checked").val()) {
      $("input[name='is_internship" + i + "']")
        .parent()
        .parent()
        .attr("id", "fill-the-field");
    } else {
      $("input[name='is_internship" + i + "']")
        .parent()
        .parent()
        .attr("id", "");
    }
    if (SelectedTag) {
      if (!SelectedTag.value) {
        SelectedTag.parentNode.setAttribute("id", "fill-the-field");
      } else {
        SelectedTag.parentNode.setAttribute("id", "");
      }
    }

    var errorexits = document.querySelectorAll("#fill-the-field");
    console.log("errorexits", errorexits.length);
    if (errorexits.length > 0) {
      setError("Please fill in all the details to register");
      console.log("error", error);
    }
    if (errorexits.length > 0) {
      return false;
    }

    for (let index = 0; index < inputFields.length; index++) {
      const element = inputFields[index];
      element.opportunity_id = props.match.params.id;
      element.team_name = teamname;
      element.know_about_us = knowaboutus;
      element.is_subscribe = 1;
    }
    // setInputFields({ ...inputFields, 'opportunity_id': id });

    // for (let index = 0; index < inputFields.length; index++) {

    //   const element = inputFields[index];

    // }

    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    setSubmitstatus(true);

    if (invalidemail.length > 0) {
      return false;
    }

    // if(removedId.length > 0)
    // {
    //   for (var i = 0; i < removedId.length; i++) {
    //   console.log("delete id" , removedId[i])

    //   }
    // }
    axios({
      method: "post",
      url: mainURL + "/opportunity/register",
      data: inputFields,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        localStorage.setItem("allteam", JSON.stringify(inputFields));
        console.log("consloe", response.data);
        if (response.data.status === 1) {
          // props.history.push(`/success/`);
          Swal.fire({
            title: "Good job!",
            text: "Team details have been updated!",
            type: "success",
          }).then(function () {
            // window.location = "redirectURL";
            props.history.push("/user");
          });
          // Swal.fire(
          //   "Good job!",
          //   "Team details have been updated ",
          //   "success"
          // );
          // setsuccessmsg("Team has beed updated")
        } else {
          setError(response.data.data);
        }
        // console.log("Error while calling api", response);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };
  $(document).on("keyup keypress", 'form input[type="text"]', function (e) {
    if (e.which == 13) {
      e.preventDefault();
      return false;
    }
  });

  function getorganization(val) {
    props.history.push("/user");
  }

  const submitonlymemberform = (id) => {
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    for (let index = 0; index < members.length; index++) {
      const element = members[index];
      element.opportunity_id = id;
      element.is_subscribe = 1;
    }
    axios({
      method: "post",
      url: mainURL + "/opportunity/register",
      data: members,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        if (response.data.status === 0) {
          setEmailexist(response.data.message);
        } else {
          localStorage.setItem("memberdata", JSON.stringify(members));
          props.history.push(`/register-successfull/`);
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  function planselect(e) {
    setSelectedplan(e.target.value);
  }
  function onScriptLoad(id) {
    let alldata;
    let orderid = Math.floor(Math.random() * 9999999999);
    for (let index = 0; index < inputFields.length; index++) {
      const element = inputFields[index];
      element.opportunity_id = id;
      element.team_name = teamname;
      element.know_about_us = knowaboutus;
      element.is_subscribe = 1;
      element.orderid = orderid;
    }
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: mainURL + "/opportunity/register",
      data: inputFields,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        // localStorage.setItem("allteam", JSON.stringify(inputFields));
        // props.history.push(`/success/`);
        console.log(response.status);
        if (response.data.status === 0) {
          setEmailexist(response.data.message);
          return true;
        } else {
          let planprice;

          if (afterdiscount) {
            planprice = afterdiscount;
          } else {
            planprice = selectedplan;
          }
          axios({
            method: "post",
            url: "https://staging.gradpartners.in/paynow",
            data: {
              orderid: orderid,
              amount: planprice,
              mobile: userdata.mobile,
              email: userdata.email,
            },
          })
            .then((response) => {
              alldata = response.data.body.txnToken; // i got the token from the paytm api
              setTxntokenid(alldata);
              tokenid = alldata;

              var MID = "GRADPA33600440875116";
              var URL =
                "https://securegw.paytm.in/merchantpgpui/checkoutjs/GRADPA33600440875116";
              var merchantCallback = null;
              var createDOMElements = function (input) {
                var scriptEle = document.createElement("script"),
                  cssEle = document.createElement("link"),
                  iframeEle = document.createElement("iframe");

                if (cssEle) {
                  cssEle.href = input.style;
                  cssEle.rel = "stylesheet";
                  cssEle.type = "text/css";
                  document.head.appendChild(cssEle);
                }
                if (scriptEle) {
                  scriptEle.async = true;
                  scriptEle.src = input.js;
                  scriptEle.type = "application/javascript";
                  scriptEle.crossOrigin = "anonymous";
                  scriptEle.onload = function () {
                    if (window.CheckoutJS) {
                      if (window.Paytm && window.Paytm.CheckoutJS) {
                        for (var key in window.CheckoutJS) {
                          if (window.CheckoutJS.hasOwnProperty(key)) {
                            window.Paytm.CheckoutJS[key] =
                              window.CheckoutJS[key];
                          }
                        }
                      }
                      try {
                        delete window.CheckoutJS; // remove CheckoutJS from window scope
                      } catch (e) {}
                      if (window.Paytm.CheckoutJS.initLib) {
                        window.Paytm.CheckoutJS.initLib(MID).then(function () {
                          if (merchantCallback) {
                            merchantCallback.call();
                          }
                        });
                      }
                    }
                  };
                  document.body.appendChild(scriptEle);
                }
              };

              var post = function () {
                var xhr = new XMLHttpRequest();
                xhr.onreadystatechange = function () {
                  if (this.readyState === 4) {
                    var data = JSON.parse(this.responseText);
                    console.log("response data", data);
                    createDOMElements(data);
                  }
                };
                xhr.open("GET", URL, true);
                xhr.setRequestHeader(
                  "Content-Type",
                  "application/javascript; charset=utf-8"
                );
                xhr.send(null);
              };

              post();

              if (!window.Paytm) {
                // check if window.Paytm exists or not
                window.Paytm = {};
              }

              window.Paytm.CheckoutJS = {
                onLoad: function (callback) {
                  if (!callback || callback.constructor !== Function) {
                    throw new Error(
                      "callback in onLoad function should be of function type"
                    );
                  }
                  merchantCallback = callback;
                },
              };

              paytmconfig = {
                root: "",
                flow: "DEFAULT",
                data: {
                  orderId: orderid /* update order id */,
                  token: tokenid /* update token value */,
                  tokenType: "TXN_TOKEN",
                  amount: "4.00" /* update amount */,
                },
                handler: {
                  notifyMerchant: function (eventName, data) {
                    console.log("notifyMerchant handler function called");
                    console.log("eventName => ", eventName);
                    console.log("data => ", data);
                  },
                },
              };
              console.log("config", paytmconfig);
              setShowloader(true);
              setSmShow(false);

              if (window.Paytm && window.Paytm.CheckoutJS) {
                console.log("config d", window.Paytm.CheckoutJS);
                console.log("config r", window.Paytm);

                window.Paytm.CheckoutJS.onLoad(
                  function excecuteAfterCompleteLoad() {
                    // initialze configuration using init method
                    window.Paytm.CheckoutJS.init(paytmconfig)
                      .then(function onSuccess() {
                        setShowloader(false);

                        // after successfully updating configuration, invoke JS Checkout
                        window.Paytm.CheckoutJS.invoke();
                      })
                      .catch(function onError(error) {
                        console.log("error => ", error);
                      });
                  }
                );
              }

              console.log("token", alldata);
            })
            .catch((err) => {
              console.log("jhghjghg", err);
            });
        }

        console.log("res[pm", response);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }

  function selectorganisation(e, index) {
    let filet = [];
    //   var filteredNames = allproject.filter(function(word) {
    //     return word.name.charAt(index) === e.target.value;
    //  });
    console.log("search data", props.match.params.id);
    setOrganisationtext(e);
    if (e === "") {
      setAllmatched([]);
      setFilterstatus(0);
      onInputChange(e, "school", index);
    } else {
      let college_list;
      if (props.match.params.id === "70") {
        college_list = cipla;
      } else {
        college_list = collages;
      }
      const matches = college_list.filter((s) =>
        s.label.toLowerCase().includes(e.toLowerCase())
      );
      // matches.selectdind = index
      setSelectedindex(index);
      setAllmatched(matches);
      console.log("allproject fdfd", collages, matches);
      setFilterstatus(1);
    }
  }

  function choosePlane(status) {
    var inputTag = document.querySelectorAll(".form-body .required");

    for (var i = 0; i < inputTag.length; i++) {
      if (inputTag[i].value === "") {
        inputTag[i].setAttribute("id", "fill-the-field");
      } else {
        inputTag[i].setAttribute("id", "");
      }

      if (!$("input[name='type" + i + "']:checked").val()) {
        $("input[name='type" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='type" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }

      if (!$("input[name='gender" + i + "']:checked").val()) {
        $("input[name='gender" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='gender" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }

      if (!$("input[name='domain" + i + "']:checked").val()) {
        $("input[name='domain" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='domain" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }

      if (!$("input[name='year_of_study" + i + "']:checked").val()) {
        $("input[name='year_of_study" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='year_of_study" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }

      if (!$("input[name='graduation_year" + i + "']:checked").val()) {
        $("input[name='graduation_year" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='graduation_year" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }
    }
    var errorexits = document.querySelectorAll("#fill-the-field");
    console.log("errorexits", errorexits.length);
    if (errorexits.length > 0) {
      setError("Please fill all the fields");
      console.log("error", error);
    }
    if (errorexits.length > 0) {
      return false;
    }

    setSmShow(status);
  }

  function discountapply(params) {
    if (!selectedplan) {
      setPlanmsg("Please select plan first");
    } else {
      const USER_TOKEN = localStorage.getItem("token");
      console.log("user token", USER_TOKEN);
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      let totalamount;
      axios({
        method: "post",
        url: mainURL + "/apply/coupons",
        data: {
          // user_id
          opportunity_id: eventdata.id,
          name: Couponval,
        },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          console.log(response.data.data);
          if (response.data.data === null) {
            setPlanmsg("Invalid coupon!");
          } else {
            if (response.data.data.coupon_type) {
              totalamount =
                selectedplan - parseInt(response.data.data.coupon_value);
              setDiscountsuccess(
                "Discount of Rs" +
                  parseInt(response.data.data.coupon_value) +
                  " successfully applied!"
              );
              console.log("totalamount1 ", totalamount);
              // setSelectedplan(totalamount)
              setAfterdiscount(totalamount);
            } else {
              totalamount =
                selectedplan -
                (selectedplan * parseInt(response.data.data.coupon_value)) /
                  100;
              // setSelectedplan(totalamount)
              setAfterdiscount(totalamount);

              console.log("totalamount2", totalamount);

              setDiscountsuccess(
                "Discount of Rs" +
                  (selectedplan * parseInt(response.data.data.coupon_value)) /
                    100 +
                  " successfully applied!"
              );
            }
          }
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  }
  function couponvalenter(e) {
    setCouponval(e.target.value);
  }
  function createorg(params) {
    Swal.fire({
      title: "Institution / Organization (Enter Full Name)",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      inputValue: organisationtext,
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: (login) => {
        console.log("login", login);
        if (login === "") {
          Swal.showValidationMessage(`Please Enter Organisation Name`);
        }
        setCollageshow(login);
        onInputChange(login, "school", params);
        setOrganisationtext(login);
        axios({
          method: "post",
          url: mainURL + "/collages",
          data: {
            name: login,
          },
        })
          .then((response) => {
            setFilterstatus(0);
          })
          .catch(function (response) {
            //handle error
            console.log("Error while calling api", response);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Your Institute has been listed",
          imageUrl: result.value.avatar_url,
        });
        setAllmatched([]);
      }
    });
  }

  const CancelRegistration = (oppId) => {
    console.log("click");

    Swal.fire({
      title: '<span className="maintitle">Confirm Cancellation?</span>',
      text: "This action can not be reverted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6e7d88",
      cancelButtonColor: "#d33",
      cancelButtonText: "No",
      confirmButtonText: "Yes, Cancel it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: mainURL + "/opportunity/register/" + oppId,
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            props.history.push("/user");

            // console.log("Error while calling api", response);
          })
          .catch(function (response) {
            //handle error
            console.log("Error while calling api", response);
          });
        Swal.fire("Deleted!", "Your Registration has been deleted.", "success");
      }
    });
  };
  return (
    <React.Fragment>
      <Header />
      {logedinuser && eventdata && (
        <div className="form teamaddform">
          <div className="registration-top">
            <h4
              className="mt-3"
              onClick={() => getorganization(props.history.location.state.slug)}
            >
              <FaRegArrowAltCircleLeft /> {props.history.location.state.title}
            </h4>
            <button
              className="btn menubtn"
              disabled={logedinuser?.submissions?.length > 0 && "disabled"}
              onClick={() => CancelRegistration(props.match.params.id)}
            >
              Cancel Registration
            </button>
          </div>

          <>
            {logedinuser.registration_form_settings && collages ? (
              // Update Registration in new dynamic form
              <DynamicRegistrationForm
                formSettings={JSON.parse(
                  logedinuser.registration_form_settings
                )}
                maxTeamSize={logedinuser.max_team_size}
                minTeamSize={logedinuser.min_team_size}
                opportunityId={logedinuser.id}
                updateRegistration={eventdata.map((member) => {
                  return { ...JSON.parse(member.form_data), id: member.id };
                })}
                allColleges={collages}
                onAddOrg={(newOrg) =>
                  setAllcollages([
                    ...collages,
                    { value: collages.length - 1, label: newOrg },
                  ])
                }
                onDeleteMember={(memberId) => {
                  setAlldata(
                    eventdata.filter((member) => member.id !== memberId)
                  );
                }}
                isSameOrg={
                  logedinuser.team_composition ===
                  "All members should be from same organization"
                }
                disabledMemberRemoval={
                  logedinuser?.submissions?.length > 0 && "disabled"
                }
                regEndDateTime={moment(logedinuser.register_end_date).set({
                  hour: parseInt(
                    logedinuser.register_end_time.split(":")[0],
                    10
                  ),
                  minute: parseInt(
                    logedinuser.register_end_time.split(":")[1],
                    10
                  ),
                })}
              />
            ) : (
              <div className="form-body">
                {successmsg && (
                  <p
                    className="align-center"
                    style={{ color: "green !impotant;", fontSize: "18px" }}
                  >
                    {successmsg}
                  </p>
                )}

                <div className="dnd-heading">
                  <h3>Register for Opportunity</h3>
                </div>

                <Form.Row>
                  <Col
                    className="form-group-col teamtitle"
                    md={12}
                    controlId="formGridOpportunityTitle"
                  >
                    <Form.Label className="input-titlle">
                      Team Name <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Your Team Name"
                      className="required"
                      name="team_name"
                      defaultValue={teamname}
                      onChange={(e) =>
                        onInputChange(setTeamname(e.target.value))
                      }
                    />
                  </Col>
                </Form.Row>
                {inputFields &&
                  inputFields.slice(0, teamsize).map((inputField, index) => (
                    <div
                      className={`registrationmainrow checkValue${index}`}
                      id={`teamdiv${index}`}
                    >
                      <p>
                        {index === 0 ? (
                          <h5 className="prizetittle leader">
                            Leader's Details
                          </h5>
                        ) : (
                          <h5 className="prizetittle memberdetail">
                            Member {index + 1} Details
                          </h5>
                        )}
                      </p>
                      <Form.Row className="twocolform">
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityStartDate"
                        >
                          <Form.Label className="input-titlle">
                            Member's Name{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="required"
                            placeholder="Your name"
                            name="name"
                            defaultValue={inputField.name}
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                          />
                        </Col>
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="formGridWebsite"
                        >
                          <Form.Label className="input-titlle">
                            {eventdata.slug == "Cipla-Ascend-Season-1-39"
                              ? "Member's Institute Email"
                              : "Member's Email"}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className={
                              index === 0 ||
                              logedinuser?.submissions?.length > 0
                                ? "disabled required"
                                : "required"
                            }
                            placeholder="Your email"
                            name="email"
                            defaultValue={inputField.email}
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                            disabled={
                              index === 0 ||
                              (logedinuser?.submissions?.length > 0 &&
                                "disabled")
                            }
                          />
                          {invalidemail.map(
                            (invalidmail) =>
                              invalidmail === index && (
                                <h4 style={{ color: "red" }}>
                                  Please enter valid email id
                                </h4>
                              )
                          )}
                        </Col>
                      </Form.Row>

                      <Form.Row>
                        {/* <Col
                        className="form-group-col"
                        md={12}
                        controlId="oppotunityStartDate"
                      >
                        <Form.Label className="input-titlle">
                          Preferred Name{" "}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Preferred Name"
                          defaultValue={inputField.preferred_name}
                          name="preferred_name"
                          onChange={(e) =>
                            onInputChange(e.target.value, e.target.name, index)
                          }
                        />
                      </Col> */}
                      </Form.Row>
                      {eventdata.slug == "Cipla-Ascend-Season-1-39" && (
                        <Form.Row className="twocolform">
                          <Col
                            className="form-group-col"
                            md={6}
                            controlId="formGridWebsite"
                          >
                            <Form.Label className="input-titlle">
                              Member's Personal Email{" "}
                              <span className="importantfield">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Your email"
                              name="secondary_mail"
                              defaultValue={inputField.secondary_mail}
                              onChange={(e) =>
                                onInputChange(
                                  e.target.value,
                                  e.target.name,
                                  index
                                )
                              }
                            />
                          </Col>
                          <Col
                            className="form-group-col"
                            md={6}
                            controlId="formGridWebsite"
                          >
                            <Form.Label className="input-titlle">
                              Member's Date of Birth{" "}
                              <span className="importantfield">*</span>
                            </Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="21-04-2021"
                              // placeholder="DD/MM/YYYY"
                              pattern="\d{4}-\d{2}-\d{2}"
                              name="dob"
                              defaultValue={inputField.dob}
                              onChange={(e) =>
                                onInputChange(
                                  e.target.value,
                                  e.target.name,
                                  index
                                )
                              }
                            />
                          </Col>
                        </Form.Row>
                      )}

                      <Form.Row className="twocolform">
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="formGridOrganization"
                        >
                          <Form.Label className="input-titlle">
                            Member's Mobile No.{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            placeholder="Your mobile no"
                            className="required"
                            name="mobile"
                            defaultValue={inputField.mobile}
                            maxLength="10"
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                          />
                        </Col>

                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityStartTime"
                        >
                          <Form.Label className="input-titlle">
                            Institution / Organization{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="school"
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                            required
                          >
                            <option>Select Institution / Organization</option>

                            {/* <option value="1">1</option> */}
                            {cipla.map((schoolname, index) => (
                              <option
                                value={schoolname.label}
                                selected={
                                  inputField.school === schoolname.label &&
                                  "selected"
                                }
                              >
                                {schoolname.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Col>

                        {/* <Col
                        className="form-group-col"
                        md={6}
                        controlId="oppotunityStartTime"
                      >
                        <Form.Label className="input-titlle">
                          Institution / Organization
                          <span className="importantfield">*</span>
                         
                        </Form.Label>
                        {index > 0 && inputFields[0].school != "" && (
                          <span className="sameasleader">
                            <input
                              name="organization"
                              type="checkbox"
                              id={"checkbox"+index}
                              className="required"
                              defaultValue={inputFields[0].school}
                              onClick={(e) =>
                                onInputChange(
                                  inputFields[0].school,
                                  "school",
                                  index,
                                  e
                                )
                              }
                            />{" "}
                            Same as Team Leader{" "}
                          </span>
                        )}
                        <input
                          type="text"
                          name="organization"
                          key={inputField.school}
                          defaultValue={inputField.school}
                          onChange={(e) =>
                            selectorganisation(e.target.value, index)
                          }
                          className="form-control required"
                          placeholder="Institution / Organization (Enter Full Name)"
                        />
                       
                        <div>
                          {filterstatus === 1 && selectedindex === index && (
                            <ul
                              className={
                                Object.keys(matched).length < 4
                                  ? "autoheight"
                                  : "uldatacollage"
                              }
                            >
                              {matched.map((item) => (
                                <li
                                  onClick={(e) =>
                                    onInputChange(item.label, "school", index)
                                  }
                                >
                                  {item.label}
                                </li>
                              ))}
                                
               {props.match.params.id != "70" && <li  >{organisationtext} ..  <span  className="createcolor" onClick={()=>createorg(index)}>List Institute</span></li> } 

                             
                            </ul>
                          )}
                        </div>
                      
                       
                      </Col> */}
                      </Form.Row>
                      <Form.Row className="twocolform">
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityEndTime"
                        >
                          <Form.Label className="input-titlle">
                            Gender <span className="importantfield">*</span>
                          </Form.Label>
                          <div
                            className="form-control radiocss oppfor"
                            name={`gender${index}`}
                            onChange={(e) =>
                              onInputChange(e.target.value, "gender", index)
                            }
                          >
                            <Form.Check
                              type="radio"
                              defaultValue="Male"
                              label="Male"
                              name={`gender${index}`}
                              checked={
                                inputField.gender === null
                                  ? null
                                  : inputField.gender === "Male"
                              }
                            />
                            <Form.Check
                              type="radio"
                              defaultValue="Female"
                              label="Female"
                              name={`gender${index}`}
                              checked={
                                inputField.gender === null
                                  ? null
                                  : inputField.gender === "Female"
                              }
                            />
                            <Form.Check
                              type="radio"
                              defaultValue="dontdisclose"
                              label="Do not wish to disclose"
                              name={`gender${index}`}
                              checked={
                                inputField.gender === null
                                  ? null
                                  : inputField.gender === "dontdisclose"
                              }
                            />
                          </div>
                        </Col>
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityEndTime"
                        >
                          <Form.Label className="input-titlle">
                            Domain <span className="importantfield">*</span>
                          </Form.Label>
                          <div
                            className="form-control radiocss oppfor"
                            name="type"
                            onChange={(e) =>
                              onInputChange(e.target.value, "domain", index)
                            }
                          >
                            <Form.Check
                              type="radio"
                              value="Engineering"
                              label="Engineering"
                              name={`domain${index}`}
                              checked={
                                inputField.domain === null
                                  ? null
                                  : inputField.domain === "Engineering"
                              }
                            />
                            {/* <Form.Check
                            type="radio"
                            value="Management"
                            label="Management"
                            name={`domain${index}`}
                            checked={
                                inputField.domain === null
                                  ? null
                                  : inputField.domain === "Management"
                              }
                          /> */}
                            {/* <Form.Check
                            type="radio"
                            value="Others"
                            label="Others"
                            name={`domain${index}`}
                            checked={
                                inputField.domain === null
                                  ? null
                                  : inputField.domain === "Others"
                              }
                          /> */}
                          </div>
                        </Col>
                      </Form.Row>

                      {/* <Form.Row className="twocolform">
                      <Col
                        className="form-group-col"
                        md={6}
                        controlId="oppotunityEndTime"
                      >
                        <Form.Label className="input-titlle">
                          Type <span className="importantfield">*</span>
                        </Form.Label>
                        <div
                          className="form-control radiocss oppfor"
                          name="type"
                          onChange={(e) =>
                            onInputChange(e.target.value, "type", index)
                          }
                        >
                          <div className="form-check">
                            <input
                              className={`form-check-input type${index}`}
                              type="radio"
                              name={`type${index}`}
                            //   defaultValue={inputField.type === ""}
                              checked={
                                inputField.type === null
                                  ? null
                                  : inputField.type === "College Student"
                              }
                              value="College Student"
                            />
                            <label
                              className="form-check-label"
                              for="exampleRadios1"
                            >
                              College Student
                            </label>
                          </div>

                         
                        </div>
                      </Col>
                    

                     
                    </Form.Row> */}

                      <Form.Row className="twocolform">
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityStartTime"
                        >
                          <Form.Label className="input-titlle">
                            Course <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="required"
                            placeholder="B.Tech / M.Tech / MBA / Others"
                            name="course"
                            defaultValue={inputField.course}
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                          />
                        </Col>
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityStartTime"
                        >
                          <Form.Label className="input-titlle">
                            Course Duration (in Years){" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            as="select"
                            name="course_duration"
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                            required
                          >
                            <option value="">Select Options</option>

                            {/* <option value="1">1</option> */}
                            <option
                              value="4"
                              selected={
                                inputField.course_duration
                                  ? inputField?.course_duration === "4"
                                    ? true
                                    : false
                                  : false
                              }
                            >
                              4
                            </option>
                            <option
                              value="5"
                              selected={
                                inputField.course_duration
                                  ? inputField?.course_duration === "5"
                                    ? true
                                    : false
                                  : false
                              }
                            >
                              5
                            </option>
                          </Form.Control>
                        </Col>
                      </Form.Row>

                      <Form.Row className="twocolform">
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityEndTime"
                        >
                          <Form.Label className="input-titlle">
                            Year of Study{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <div
                            className="form-control radiocss yearofstudy oppfor"
                            name="year_of_study"
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                "year_of_study",
                                index
                              )
                            }
                          >
                            <Form.Check
                              type="radio"
                              value="4th year"
                              label="4th year"
                              name={`year_of_study${index}`}
                              checked={
                                inputField.year_of_study === null
                                  ? null
                                  : inputField.year_of_study === "4th year"
                              }
                            />
                            <Form.Check
                              type="radio"
                              value="5th year"
                              label="5th year"
                              name={`year_of_study${index}`}
                              checked={
                                inputField.year_of_study === null
                                  ? null
                                  : inputField.year_of_study === "5th year"
                              }
                            />
                            {/* <Form.Check
                            type="radio"
                            value="2nd year"
                            label="2nd year"
                            name={`year_of_study${index}`}
                            checked={
                                inputField.year_of_study === null
                                  ? null
                                  : inputField.year_of_study === "2nd year"
                              }
                            
                            
                            
                          /> */}
                            {/* <Form.Check
                            type="radio"
                            value="3rd year"
                            label="3rd year"
                            name={`year_of_study${index}`}
                            checked={
                                inputField.year_of_study === null
                                  ? null
                                  : inputField.year_of_study === "3rd year"
                              }
                            
                            
                            
                          /> */}
                            {/* <Form.Check
                            type="radio"
                            value="4th year"
                            label="4th year"
                            name={`year_of_study${index}`}
                            checked={
                                inputField.year_of_study === null
                                  ? null
                                  : inputField.year_of_study === "4th year"
                              }
                            
                            
                            
                          /> */}
                            {/* <Form.Check
                            type="radio"
                            value="5th year"
                            label="5th year"
                            name={`year_of_study${index}`}
                            checked={
                                inputField.year_of_study === null
                                  ? null
                                  : inputField.year_of_study === "5th year"
                              }
                            
                            
                          /> */}
                          </div>
                        </Col>
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityEndTime"
                        >
                          <Form.Label className="input-titlle">
                            Graduation Year{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <div
                            className="form-control radiocss oppfor"
                            name="graduation_year"
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                "graduation_year",
                                index
                              )
                            }
                          >
                            {/* <Form.Check
                            type="radio"
                            value="2023"
                            label="2023"
                            name={`graduation_year${index}`}
                            checked={
                                inputField.graduation_year === null
                                  ? null
                                  : inputField.graduation_year === "2023"
                              }
                          />
                          <Form.Check
                            type="radio"
                            value="2024"
                            label="2024"
                            name={`graduation_year${index}`}
                            checked={
                                inputField.graduation_year === null
                                  ? null
                                  : inputField.graduation_year === "2024"
                              }
                          /> */}
                            <Form.Check
                              type="radio"
                              value="2024"
                              label="2024"
                              name={`graduation_year${index}`}
                              checked={
                                inputField.graduation_year === null
                                  ? null
                                  : inputField.graduation_year === "2024"
                              }
                            />

                            {/* <Form.Check
                            type="radio"
                            value="2026"
                            label="2026"
                            name={`graduation_year${index}`}
                            checked={
                                inputField.graduation_year === null
                                  ? null
                                  : inputField.graduation_year === "2026"
                              }
                          />
                          <Form.Check
                            type="radio"
                            value="2027"
                            label="2027"
                            name={`graduation_year${index}`}
                            checked={
                                inputField.graduation_year === null
                                  ? null
                                  : inputField.graduation_year === "2027"
                              }
                          />
                          <Form.Check
                            type="radio"
                            value="2028"
                            label="2028"
                            name={`graduation_year${index}`}
                            checked={
                                inputField.graduation_year === null
                                  ? null
                                  : inputField.graduation_year === "2028"
                              }
                          /> */}
                          </div>
                        </Col>
                      </Form.Row>
                      <Form.Row className="twocolform">
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityStartTime"
                        >
                          <Form.Label className="input-titlle">
                            Percentage Marks in 10th{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="required"
                            defaultValue={inputField.high_school}
                            placeholder="Percentage Marks in 10th"
                            name="high_school"
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                          />
                        </Col>
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityStartTime"
                        >
                          <Form.Label className="input-titlle">
                            Percentage Marks in 12th{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="required"
                            placeholder="Percentage Marks in 12th"
                            defaultValue={inputField.intermediate}
                            name="intermediate"
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                          />
                        </Col>
                      </Form.Row>
                      <Form.Row className="twocolform">
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityStartTime"
                        >
                          <Form.Label className="input-titlle">
                            Cumulative Engineering Score{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="required"
                            placeholder="Cumulative Engineering Score"
                            defaultValue={inputField.engg_score}
                            name="engg_score"
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                          />
                        </Col>
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityEndTime"
                        >
                          <Form.Label className="input-titlle">
                            Do you have backlog in any course through
                            Engineering?{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <div
                            className="form-control radiocss oppfor"
                            name="is_backlog"
                            onChange={(e) =>
                              onInputChange(e.target.value, "is_backlog", index)
                            }
                          >
                            {/* <Form.Check
                            type="radio"
                            value="2023"
                            label="2023"
                            name={`graduation_year${index}`}
                          /> */}
                            {/* <Form.Check
                            type="radio"
                            value="2024"
                            label="2024"
                            name={`graduation_year${index}`}
                          /> */}
                            <Form.Check
                              type="radio"
                              value="yes"
                              label="Yes"
                              name={`is_backlog${index}`}
                              checked={
                                inputField.is_backlog === null
                                  ? null
                                  : inputField.is_backlog === "yes"
                              }
                            />
                            <Form.Check
                              type="radio"
                              value="no"
                              label="No"
                              name={`is_backlog${index}`}
                              checked={
                                inputField.is_backlog === null
                                  ? null
                                  : inputField.is_backlog === "no"
                              }
                            />
                            {/* <Form.Check
                            type="radio"
                            value="2026"
                            label="2026"
                            name={`graduation_year${index}`}
                          />
                          <Form.Check
                            type="radio"
                            value="2027"
                            label="2027"
                            name={`graduation_year${index}`}
                          />
                          <Form.Check
                            type="radio"
                            value="2028"
                            label="2028"
                            name={`graduation_year${index}`}
                          /> */}
                          </div>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col
                          className="form-group-col"
                          md={12}
                          controlId="oppotunityEndTime"
                        >
                          <Form.Label className="input-titlle">
                            Are you available for 6 months on-site internship
                            starting Jan/Feb 2024?{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <div
                            className="form-control radiocss oppfor"
                            name="is_internship"
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                "is_internship",
                                index
                              )
                            }
                          >
                            <Form.Check
                              type="radio"
                              value="yes"
                              label="Yes"
                              name={`is_internship${index}`}
                              checked={
                                inputField.is_internship === null
                                  ? null
                                  : inputField.is_internship === "yes"
                              }
                            />
                            <Form.Check
                              type="radio"
                              value="no"
                              label="No"
                              name={`is_internship${index}`}
                              checked={
                                inputField.is_internship === null
                                  ? null
                                  : inputField.is_internship === "no"
                              }
                            />
                          </div>
                        </Col>
                      </Form.Row>
                      <Form.Row className="twocolform">
                        <Col
                          className="form-group-col"
                          md={6}
                          controlId="oppotunityStartTime"
                        >
                          <Form.Label className="input-titlle">
                            Current location{" "}
                            <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="required"
                            placeholder="Current location"
                            name="current_location"
                            defaultValue={inputField.current_location}
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                          />
                        </Col>
                        <Col
                          className="form-group-col"
                          md={12}
                          controlId="oppotunityStartTime"
                        >
                          <Form.Label className="input-titlle">
                            Home Town <span className="importantfield">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="required"
                            placeholder="Home Town "
                            defaultValue={inputField.hometown}
                            name="hometown"
                            onChange={(e) =>
                              onInputChange(
                                e.target.value,
                                e.target.name,
                                index
                              )
                            }
                          />
                        </Col>
                      </Form.Row>

                      {index > 0 && (
                        <Form.Row>
                          <Popconfirm
                            title="Are you sure to delete this member?"
                            onConfirm={() =>
                              handleRemoveFields(index, inputField.id)
                            }
                            onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                          >
                            <button
                              className={
                                logedinuser?.submissions?.length > 0
                                  ? "btn btn-link plus-btn ml-auto displaynone"
                                  : "btn btn-link plus-btn ml-auto "
                              }
                              type="button"
                            >
                              <h2 className="remove-round-member fr">
                                Remove <i className="fa fa-trash"></i>
                              </h2>
                            </button>
                          </Popconfirm>
                        </Form.Row>
                      )}
                    </div>
                  ))}

                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={12}
                    controlId="formGridCategory"
                  >
                    <Form.Label className="input-titlle">
                      Where did you got to know about this opportunity?{" "}
                      {/* <span className="importantfield">*</span> */}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="know_about_us"
                      onChange={(e) =>
                        onInputChange(setKnowaboutus(e.target.value))
                      }
                      required
                    >
                      <option>Select Options</option>
                      <option value="grad partners">
                        Grad Partners Platforms (Instagram, Linkedin, Others)
                      </option>
                      <option
                        value="facebook"
                        selected={
                          inputFields[0]?.know_about_us
                            ? inputFields[0]?.know_about_us === "facebook"
                              ? true
                              : false
                            : false
                        }
                      >
                        Facebook
                      </option>
                      <option
                        value="instagram"
                        selected={
                          inputFields[0]?.know_about_us
                            ? inputFields[0]?.know_about_us === "instagram"
                              ? true
                              : false
                            : false
                        }
                      >
                        Instagram
                      </option>
                      <option
                        value="linkedin"
                        selected={
                          inputFields[0]?.know_about_us
                            ? inputFields[0]?.know_about_us === "linkedin"
                              ? true
                              : false
                            : false
                        }
                      >
                        Linkedin
                      </option>
                      <option
                        value="college"
                        selected={
                          inputFields[0]?.know_about_us
                            ? inputFields[0]?.know_about_us === "college"
                              ? true
                              : false
                            : false
                        }
                      >
                        College
                      </option>
                      <option
                        value="students_referrals "
                        selected={
                          inputFields[0]?.know_about_us
                            ? inputFields[0]?.know_about_us ===
                              "students_referrals"
                              ? true
                              : false
                            : false
                        }
                      >
                        Students/ Referrals{" "}
                      </option>
                      <option value="others">Others</option>
                    </Form.Control>
                  </Col>
                </Form.Row>
                {teamsize > inputFields.length && (
                  <div className=" col-md-12 register_add od-nav">
                    <button
                      className="btn btn-link minus-btn ml-auto"
                      type="button"
                      onClick={() => handleAddFields()}
                    >
                      <h2 className="">
                        <i className="fa fa-plus"></i> Add member
                      </h2>
                    </button>
                  </div>
                )}
                <br />
                {/* <input type="checkbox"/> Subscribe to GradPartners Newsletter */}
                {error && <div className="notfilled">{error}</div>}
              </div>
            )}
          </>
          <Modal
            size="sm"
            show={smShow}
            onHide={() => setSmShow(false)}
            aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Workshop Plans
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <div className="row">
          <div className="col-md-6 button">
          <label><img src={require("../../images/plans/plan1.png").default}  /></label>

            </div>
            <div className="col-md-6 button">
          <label><img src={require("../../images/plans/plan2.png").default}  /></label>
          </div>
            </div> */}
              {eventdata.plans ? (
                eventdata.plans.map((plan, index) => (
                  <div className="planoptions">
                    <input
                      type="radio"
                      value={plan.price}
                      name="plan"
                      onChange={(e) => planselect(e)}
                    />{" "}
                    {plan.description} <br />
                  </div>
                ))
              ) : (
                <>
                  <div className="planoptions">
                    <input
                      type="radio"
                      value="999"
                      name="plan"
                      onChange={(e) => planselect(e)}
                    />{" "}
                    ₹ 999 (4 workshops) <br />
                  </div>
                  <div className="planoptions">
                    <input
                      type="radio"
                      value="599"
                      name="plan"
                      onChange={(e) => planselect(e)}
                    />{" "}
                    ₹ 599 (2 workshops) <br />
                  </div>
                  <div className="planoptions">
                    <input
                      type="radio"
                      value="799"
                      name="plan"
                      onChange={(e) => planselect(e)}
                    />{" "}
                    ₹ 799 (4 workshops, for existing AceTheCase subscribers){" "}
                    <br />
                  </div>
                  <div className="planoptions">
                    <input
                      type="radio"
                      value="499"
                      name="plan"
                      onChange={(e) => planselect(e)}
                    />{" "}
                    ₹ 499 (2 workshops, for existing AceTheCase subscribers)
                  </div>
                </>
              )}
              {emailexist && <p className="errormsg"> {emailexist} </p>}
              {planmsg ? (
                <p className="alert alert-danger">{planmsg}</p>
              ) : (
                discountsuccess && (
                  <p className="alert alert-success">{discountsuccess}</p>
                )
              )}
              <label>Coupon Code</label>
              <div className=" text-left">
                <input
                  type="text"
                  className="coupon-code"
                  name="plan"
                  onChange={(e) => couponvalenter(e)}
                />{" "}
                <button
                  className="btn applybtn"
                  onClick={() => discountapply()}
                >
                  Apply
                </button>
              </div>
              <div>
                {" "}
                <button
                  className="btn menubtn paynow"
                  onClick={() => onScriptLoad(eventdata.id)}
                >
                  Pay now
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}

      {(!logedinuser || !eventdata) && <div>Loading...</div>}

      {/* <div className="registerbtn">
                {eventdata.is_opportunity_paid === 1 ? (
                  <button className="" onClick={() => choosePlane(true)}>
                    {" "}
                    Enroll Now
                  </button>
                ) : (
                  <button className="save-hosting-btn" onClick={() => submitform(eventdata.id)}>
                    {" "}
                    Submit
                  </button>
                )}
              </div> */}
      {logedinuser && eventdata && !logedinuser.registration_form_settings && (
        <div className="hosting_bottom">
          {/* <Button className="help-btn" type="button" onClick={() => prevStep(4)}>
          <FaArrowLeft />Go Back
        </Button> */}
          <Button
            className="save-hosting-btn"
            type="button"
            onClick={() => submitform(eventdata.id)}
          >
            Update details
          </Button>
        </div>
      )}
      {/* <Footer /> */}
    </React.Fragment>
  );
};

export default EditRegistrationform;
