import React, { useState } from "react";
import { Col, Row, Button, Form, Tab, Tabs, TabContent } from "react-bootstrap";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import axios from "axios";
import config from "../CommonFiles/config.json";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const Successmsg = (props) => {
  const [teamname, setTeamname] = useState();
  const [successmsg, setMsg] = useState();
  const [inputFields, setInputFields] = useState([
    {
      is_teamlead: 1,
      name: "",
      email: "",
      mobile: "",
      school: "",
      type: "",
      gender: "",
      country: "",
    },
  ]);

  return (
    <React.Fragment>
      <Header />
      <div className="form">
        <Form className="form-body">
          <p>Successfully register</p>
        </Form>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Successmsg;
