import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LeftSideBar from "../../../components/Dashboard/LeftSideBar/LeftSideBar";
import { CaretRightOutlined } from "@ant-design/icons";
import "./notificationManagement.css";
import axios from "axios";
import { Select, Button, Modal, Table, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import Swal from "sweetalert2";
import EmailTemplateForm from "./emailTemplateForm";
import config from "../../CommonFiles/config.json";
import BroadcastEmailForm from "./broadcastEmailForm";

const { Panel } = Collapse;
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

export default function NotificationManagement(props) {
  const [domainlist, setDomainlist] = useState([]);
  const [yearList, setYearlist] = useState([]);
  const [roundList, setRoundlist] = useState([]);
  const [genderList, setGenderlist] = useState([]);
  const [allregistersList, setAllregisterlist] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const [collages, setAllcollages] = useState();
  const [mailSubject, setMailSubject] = useState("");
  const [mailSubjectBroadcast, setMailSubjectBroadcast] = useState("");
  const [mailertype, setSelectedtype] = useState("custom");
  const [domain, setSelectedDomain] = useState();
  const [school, setSelectedSchool] = useState();
  const [year_of_study, setSelectedyos] = useState();
  const [gender, setSelectedgender] = useState();
  const [round, setSelectedround] = useState();
  const [mailDec, setMailDec] = useState("");
  const [mailDecBrodcast, setMailDecBrodcast] = useState("");
  const params = useParams();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [broadCastData, setBroadCastData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      // pageSize:10,
      total: 0,
    },
  });
  const [loading, setLoading] = useState(false);
  const [broadcastMails, setbroadcastMails] = useState([]);
  const [open, setOpen] = useState(false);
  const [sesTemplates, setSESTemplates] = useState([]);
  let emailtype = [
    {
      value: "resultdeclared",
      label: "Result Declared",
    },
    {
      value: "deadlineextension",
      label: "Deadline Extension",
    },
    {
      value: "notselected",
      label: "Not Selected",
    },
    {
      value: "custom",
      label: "Custom",
    },
  ];
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "get",
      url: mainURL + "/allcollages",
    })
      .then((response) => {
        const alldatas = response.data.data;
        console.log("Error while calling api response", alldatas);
        let collagelist = [];
        response.data.data.map(function (obj) {
          collagelist.push({
            value: obj.label,
            label: obj.label,
          });
        });
        setAllcollages(collagelist);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
    axios({
      method: "get",
      url: mainURL + "/mails?opportunity_id=" + params.id,
    })
      .then((response) => {
        let domain = [];
        response.data.domains.map(function (obj) {
          if (obj.domain != "") {
            domain.push({
              value: obj.domain,
              label: obj.domain,
            });
          }

          // return obj.domain;
        });
        let yearOfStudies = [];
        response.data.yearOfStudies.map(function (obj) {
          if (obj.year_of_study != "") {
            yearOfStudies.push({
              value: obj.year_of_study,
              label: obj.year_of_study,
            });
          }
        });
        let rounds = [];
        response.data.rounds.map(function (obj) {
          if (obj.rounds != "") {
            rounds.push({
              value: obj.id,
              label: obj.title,
            });
          }
        });
        let genders = [];
        response.data.genders.map(function (obj) {
          if (obj.gender != "") {
            genders.push({
              value: obj.gender,
              label: obj.gender,
            });
          }
        });

        setDomainlist(domain);
        setYearlist(yearOfStudies);
        setRoundlist(rounds);
        setGenderlist(genders);
        axios({
          method: "get",
          url:
            mainURL +
            "/profile/all/registered/opportunities/" +
            props.match.params.id,
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            let allregisters = [];
            response.data.data.map(function (obj) {
              allregisters.push({
                key: obj.email,
                value: obj.email,
                label: obj.email,
                name: obj.name,
              });
            });
            setAllregisterlist(allregisters);
          })
          .catch(function (response) {
            //handle error
            console.log("Error while calling api", response);
          });
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });

    // fetch SES Email Template List
    axios({
      method: "GET",
      url: `${mainURL}/email/templates`,
    }).then((response) => {
      setSESTemplates(response.data.templates);
    }).catch(error => {
      console.log('unable to get SES Templates');
    })
  }, []);

  const genExtramessage = () => (
    <button className="btn  menubtn actionBtn"> + Add Message</button>
  );

  const handleEditClicked = (e, el) => {
    setSelectedRowKeys(e);
  };

  const sendEnail = (type) => {
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    let data;
    if (type === "broadcast") {
      data = {
        type: "custom",
        subject: mailSubjectBroadcast,
        body: mailDecBrodcast,
        emails: broadcastMails,
        opportunity_id: localStorage.getItem("hostingId"),
        broadcast: 1,
        allData: broadCastData,
      };
    } else {
      data = {
        opportunity_id: localStorage.getItem("hostingId"),
        type: mailertype,
        domain: domain,
        school: school,
        year_of_study: year_of_study,
        gender: gender,
        round: round,
        subject: mailertype === "custom" ? mailSubject : "",
        body: mailertype === "custom" ? mailDec : "",
        emails: selectedRowKeys,
        broadcast: 0,
      };
    }
    axios({
      method: "post",
      url: mainURL + "/mails",
      data: data,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        if (response.data.status === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Email has been sent",
            showConfirmButton: false,
            timer: 10000,
          });
        }
      })
      .catch(function (response) {
        console.log("Error while calling api", response);
      });
  };

  const columns = [
    {
      title: "Participants Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Participants Email",
      dataIndex: "label",
      key: "label",
      sorter: (a, b) => a.label.length - b.label.length,
    },
  ];

  const uploadresult = async () => {
    const { value: file } = await Swal.fire({
      title: "Upload emails",
      input: "file",
      inputAttributes: {
        accept: "*",
        "aria-label": "Upload your profile picture",
      },
    });
    const formData = new FormData();

    formData.append("excelfile", file, parseInt(props.match.params.id));
    // formData.append("round_id", parseInt(props.match.params.id));
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: mainURL + "/excel/",
      data: formData,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("Error  response wee", response.data.emails);
        setBroadCastData(response.data.allData);
        setbroadcastMails(response.data.emails);
      })
      .catch(function () {
        //handle error
      });
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        Swal.fire({
          title: "Email has been uploaded",
          imageAlt: "The uploaded picture",
        });
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="wrapper pt-5">
      {/* START LEFTSIDEBAR */}
      <LeftSideBar />
      {/* END LEFTSIDEBAR */}
      {/* START PAGE CONTENT START */}
      <div className="contents opportunityDashboard">
        <Modal
          open={open}
          title={`Add Participate (${allregistersList.length})`}
          onOk={handleOk}
          onCancel={handleCancel}
          className="participantlist"
          footer={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleOk}
            >
              Submit
            </Button>,
          ]}
        >
          <Input
            size="large"
            placeholder="large size"
            prefix={<UserOutlined />}
          />
          <Table
            bordered
            rowSelection={rowSelection}
            dataSource={allregistersList}
            columns={columns}
            // onChange={(e) => pageChnage(e)}
          />
        </Modal>
        <div className="page_content pt-5">
          <Collapse
            bordered={false}
            // defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel header="Send Email" key="1">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>Domain-Wise Breakdown </label>
                  <Select
                    placeholder="Please select"
                    onChange={(e) => setSelectedDomain(e)}
                    name="school"
                    style={{
                      width: "100%",
                    }}
                    options={domainlist}
                  />
                  <span>
                    <i className="fa fa-caret-down dropdownshow"></i>
                  </span>
                </div>
                <div className="col-md-6 mb-3">
                  <label>Select College</label>

                  <Select
                    size="middle"
                    placeholder="Please select"
                    // onChange={handleChange}
                    // onChange={handleChange}
                    onChange={(e) => setSelectedSchool(e)}
                    name="school"
                    style={{
                      width: "100%",
                    }}
                    options={collages}
                  />
                  <span>
                    <i className="fa fa-caret-down dropdownshow"></i>
                  </span>
                </div>
                <div className="col-md-6 mb-3">
                  <label>Select Year</label>

                  <Select
                    size="middle"
                    placeholder="Please select"
                    onChange={(e) => setSelectedyos(e)}
                    name="school"
                    style={{
                      width: "100%",
                    }}
                    options={yearList}
                  />
                  <span>
                    <i className="fa fa-caret-down dropdownshow"></i>
                  </span>
                </div>
                <div className="col-md-6 mb-3">
                  <label>Select Gender</label>

                  <Select
                    size="middle"
                    placeholder="Please select"
                    onChange={(e) => setSelectedgender(e)}
                    name="school"
                    style={{
                      width: "100%",
                    }}
                    options={genderList}
                  />
                  <span>
                    <i className="fa fa-caret-down dropdownshow"></i>
                  </span>
                </div>
                <div className="col-md-6 mb-3">
                  <label>
                    Select Round <span className="importantField">*</span>
                  </label>

                  <Select
                    size="middle"
                    placeholder="Please select"
                    onChange={(e) => setSelectedround(e)}
                    name="school"
                    style={{
                      width: "100%",
                    }}
                    options={roundList}
                  />
                  <span>
                    <i className="fa fa-caret-down dropdownshow"></i>
                  </span>
                </div>

                <div className="col-md-6 mb-3">
                  <label>Select Emailer Type</label>{" "}
                  <span className="importantField">*</span>
                  <Select
                    size="middle"
                    placeholder="Please select"
                    onChange={(e) => setSelectedtype(e)}
                    name="school"
                    defaultValue="custom"
                    style={{
                      width: "100%",
                    }}
                    options={emailtype}
                  />
                  <span>
                    <i className="fa fa-caret-down dropdownshow"></i>
                  </span>
                </div>
                <div className="col-md-12 mb-3">
                  <label>
                    Add Participants <span className="importantField">*</span>{" "}
                  </label>
                  <Select
                    mode="tags"
                    size="middle"
                    placeholder="Add a email or select from the participants list"
                    onChange={(e) => handleEditClicked(e)}
                    name="participent"
                    style={{
                      width: "100%",
                    }}
                    options={allregistersList}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label>Write Email: </label>
                  <div className="emailsec">
                    <input
                      type="text"
                      placeholder="Enter your subject"
                      onChange={(e) => setMailSubject(e.target.value)}
                      name="school"
                      className="form-control form-control-lg"
                      style={{
                        width: "100%",
                      }}
                    />
                    <br />
                    <textarea
                      cols={5}
                      rows={10}
                      onChange={(e) => setMailDec(e.target.value)}
                      className="form-control form-control-lg"
                    ></textarea>
                  </div>
                  <div className="float-right">
                    <button className="mr-3 mt-4  btn menubtn actionBtn transparentbtn">
                      {" "}
                      &nbsp;Send a Test Email
                    </button>
                    <button
                      className="btn mt-4  menubtn actionBtn"
                      onClick={() => sendEnail("mail")}
                      disabled={
                        selectedRowKeys.length > 0 && mailertype != "custom"
                          ? false
                          : (mailertype === "custom" && mailSubject === "") ||
                              mailDec === ""
                            ? true
                            : false
                      }
                    >
                      {" "}
                      Send Email
                    </button>
                  </div>
                </div>
              </div>
            </Panel>
            <Panel header="Broadcast Message" key="2" extra={genExtramessage()}>
              <>
                <div className="row">
                  <div className="col-md-8 mb-3">
                    <label>
                      Add Participants <span className="importantField">*</span>{" "}
                    </label>
                    {broadcastMails && broadcastMails.length > 0 && (
                      <div className="broadcastmailslist">
                        {broadcastMails.map((mails) => (
                          <div className="broadcastmails">{mails}</div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="col-md-4 mt-3 mb-4">
                    <button
                      className="btn menubtn resultuploadbtn"
                      onClick={() => uploadresult()}
                    >
                      {" "}
                      Upload Emails{" "}
                    </button>
                    <button className="btn menubtn resultuploadbtn">
                      {" "}
                      <a
                        href="https://gradimages.s3.ap-south-1.amazonaws.com/templates/BulkEmailTemplate.xlsx"
                        donwload
                      >
                        Email Template
                      </a>
                    </button>
                  </div>
                </div>
                <div className=" broadcatmsg">
                  <div className="emailsec">
                    <input
                      type="text"
                      placeholder="Enter your subject"
                      // onChange={handleChange}
                      // onChange={handleChange}
                      onChange={(e) => setMailSubjectBroadcast(e.target.value)}
                      name="school"
                      className="form-control form-control-lg"
                      style={{
                        width: "100%",
                      }}
                    />
                    <br />
                    <textarea
                      cols={5}
                      rows={10}
                      onChange={(e) => setMailDecBrodcast(e.target.value)}
                      className="form-control form-control-lg"
                    ></textarea>
                  </div>
                  <div className="float-right pb-5">
                    <button
                      className="btn mt-4  menubtn actionBtn"
                      onClick={() => sendEnail("broadcast")}
                      disabled={
                        (broadcastMails &&
                          broadcastMails.length > 0 &&
                          mailSubjectBroadcast === "") ||
                        mailDecBrodcast === ""
                          ? true
                          : false
                      }
                    >
                      {" "}
                      Send Email
                    </button>
                  </div>
                </div>
              </>
            </Panel>
            <Panel header="Broadcast Custom Email" key="3">
              <BroadcastEmailForm templates={sesTemplates}/>
            </Panel>
            <Panel header="Create Email Template" key="4">
              <EmailTemplateForm />
            </Panel>
          </Collapse>
        </div>
      </div>
    </div>
  );
}
