import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Main.css";
import "@brainhubeu/react-carousel/lib/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Cards from "../../CommonFiles/Card";
import Opportunities from "../../StaticPages/Opportunities.js";
import Testimonials from "../../../utils/Testimonials";
import Workshop from "../../DynamicPages/Workshop.js";
import Header from "../../CommonFiles/Header";
import Footer from "../../CommonFiles/Footer";
import axios from "axios";
import config from "../../CommonFiles/config.json";
import { Col, Row, Container } from "react-bootstrap";
import { Tabs } from "antd";
import { Navigation, Pagination, A11y, Autoplay, Keyboard } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import MetaTags from "react-meta-tags";
import "../../../components/StaticPages/swiperstyle/style.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ResetPasswordModal from "../../User/ResetPasswordModal.js";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const Main = () => {
  const { TabPane } = Tabs;
  const [opportunities, setOpportunities] = useState(null);
  const [workshops, setWorkshops] = useState(null);
  const [banners, setBanners] = useState([]);
  const [tabName, setTabName] = useState("Opportunities");
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const history = useHistory();

  // Check if URL is password reset URL, open the reset password modal
  useEffect(() => {
    if (window.location.href.includes("password-reset")) {
      setIsResetModalOpen(true);
    }
  }, []);

  useEffect(async () => {
    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/opportunity/featured",
      });
      console.log("result", result.data);
      setOpportunities(result.data.opportunity);
      setWorkshops(result.data.workshop);
    } catch (error) {
      console.log(error);
    }
    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/featured/banner",
      });
      console.log("featured/banner", result.data);
      setBanners(result.data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleResetModalClose = () => {
    setIsResetModalOpen(false);
    history.push("/");
  };

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: "linear",
    // speed: 500,

    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let [boxlist, setBoxlist] = useState([
    {
      title: "Network Members",
      image: require("../../../images/story_image/Icon-Network_Members.png")
        .default,
      subtitle: "100000+",
    },
    {
      title: "Mentees",
      image: require("../../../images/story_image/Icon-Mentees.png").default,
      subtitle: "25000+",
    },
    {
      title: "Subscribers",
      image: require("../../../images/story_image/Icon-Subscribers.png")
        .default,
      subtitle: "5000+",
    },
    {
      title: "Institutes",
      image: require("../../../images/story_image/Icon-Institutes.png").default,
      subtitle: "100+",
    },
    {
      title: "Workshops",
      image: require("../../../images/story_image/Icon-Workshops.png").default,
      subtitle: "100+",
    },
  ]);

  const array = [
    {
      image: require("../../../images/banner/bluekaktus.jpeg").default,
      link: "/e/BlueKaktus-StylAce-2021-14", //
    },

    {
      image: require("../../../images/banner/acethecase.png").default,
      link: "/e/B-School-Competitions-Symposium-7", //
    },
    {
      image: require("../../../images/banner/gradbanner.png").default,
      link: "https://dare2compete.com/o/ace-the-case-grad-partners-vista-2021-iim-bangalores-international-business-summit-indian-institute-of-management-iim--184623?refId=9vPzTb7&lb=21071122", //
    },
    {
      image: require("../../../images/banner/banner2.jpeg").default,
      link: "/e/HCCB-India-Case-Challenge-Season-1-6", //link to opprotunity
    },
    {
      image: require("../../../images/banner/banner-4.png").default,
      link: "https://dare2compete.com/competition/crown-jewel-international-case-study-competition-rubicon-indian-institute-of-management-iim-bangalore-185482?lb=JYpQ780", //
    },
  ];

  const companies = [
    {
      image: require("../../../images/company_images/cocacola.png").default,
      link: "/e/BlueKaktus-StylAce-2021-14", //
    },

    {
      image: require("../../../images/company_images/tatasteel.png").default,
      link: "/e/B-School-Competitions-Symposium-7", //
    },
    {
      image: require("../../../images/company_images/Cipla.png").default,
      link: "https://dare2compete.com/o/ace-the-case-grad-partners-vista-2021-iim-bangalores-international-business-summit-indian-institute-of-management-iim--184623?refId=9vPzTb7&lb=21071122", //
    },
    {
      image: require("../../../images/company_images/tvs.png").default,
      link: "/e/HCCB-India-Case-Challenge-Season-1-6", //link to opprotunity
    },
    {
      image: require("../../../images/company_images/bluekaktus.png").default,
      link: "https://dare2compete.com/competition/crown-jewel-international-case-study-competition-rubicon-indian-institute-of-management-iim-bangalore-185482?lb=JYpQ780", //
    },
    {
      image: require("../../../images/company_images/ultraTech.jpeg").default,
      link: "/e/UltraQuest-Season-1-90", //
    },
  ];

  return (
    <React.Fragment>
      <Header
        openlogin={openLogin}
        closeLoginModal={() => {
          setOpenLogin(false);
          history.push("/");
        }}
      />
      <MetaTags>
        <title> Grad Partners | Talent Engagement | Case Competitions</title>
        <meta name="description" content="Some description." />
        <meta
          property="og:title"
          content="Grad Partners | Talent Engagement | Case Competitions"
        />
        <meta
          property="og:image"
          content="https://www.gradpartners.in/static/media/main_logo.ed6347e1.png"
        />
      </MetaTags>
      <div className="main">
        <div className="upper-slide ">
          <Tabs className="pb-5" defaultActiveKey="1">
            <TabPane className="" tab={<span> Students</span>} key="1">
              <div>
                <img
                  src={require("../../../main_banner/mainbanner1.png").default}
                  className="img-fluid w-100"
                />
              </div>
            </TabPane>
            <TabPane className="" tab={<span> Corporates</span>} key="2">
              <div>
                <img
                  src={require("../../../main_banner/mainbanner2.png").default}
                  className="img-fluid w-100"
                />
              </div>
            </TabPane>
          </Tabs>
          {/* <SliderComponent banners={banners}/> */}
          {/* <Slider {...settings}>
            {banners.map((item, index) => (
              <div>
                <a
                  href={"https://www.gradpartners.in/e/" + item.slug}
                  target="_blank"
                >
                  <img
                    src={item.banner_image}
                    className="img-fluid w-100"
                  />
                </a>
              </div>
            ))}
          </Slider> */}
          <div
            className={
              banners.length > 1
                ? "homepage-banner"
                : "homepage-banner singlebanner"
            }
          >
            <Swiper
              modules={[Navigation, Pagination, A11y, Keyboard]}
              slidesPerView={1.3}
              cssMode={true}
              // pagination={true}
              mousewheel={true}
              keyboard={true}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 80 },
                414: { slidesPerView: 1, spaceBetween: 80 },
                420: { slidesPerView: 1, spaceBetween: 80 },
                480: { slidesPerView: 1, spaceBetween: 150 },
                720: { slidesPerView: 1.3 },
              }}
              centeredSlides={true}
              loop={(banners.length > 1)? 'true' : 'false'}
              spaceBetween={30}
              observer={true}
              observeParents={true}
              navigation={true}
              // virtual
            >
              {banners.map((item, index) => (
                <SwiperSlide>
                  <div className="swiper-slide">
                    <a
                      href={ item.is_active ? `/e/${item.slug}` : `javascript:void(0)`}
                      target={item.is_active ? `_blank` : ``}
                    >
                      <img
                        className={`img-fluid bannerslideimg`}
                        src={item.banner_image}
                        alt={item.slug}
                      />
                    </a>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <section className="cardSection">
          <div className="pt-5">
            <h1 className="text-center">
              Our <span>Story</span>
            </h1>
            <Cards boxlists={boxlist} />
          </div>
        </section>
        <section className="opportunitySection">
          {opportunities && workshops && (
            <div className="col-lg-10 offset-md-1 pt-5">
              <h1 className="text-center">
                {" "}
                Explore <span> {tabName}</span>
              </h1>
              <Opportunities
                onTabNameChange={(tabName) => setTabName(tabName)}
                opportunities={opportunities}
                workshops={workshops}
              />
            </div>
          )}
        </section>
        <section className="testimonialSection">
          <Testimonials />
        </section>
        <section className="workshopSection bg-color-white  ">
          <div className="col-lg-10 offset-md-1">
            <h1 className="text-center mb-5 pb-5">
              {" "}
              <span> Workshops </span> & Events{" "}
            </h1>
            <Workshop />
          </div>
        </section>
        <section className="subscribersection bg-color-white pt-5">
          <div className="  counter-boxes">
            <h1 className="text-center mb-5">
              Companies<span> associated </span>with us
            </h1>

            <Row>
              <Col md="12">
                <div className="row  formobilecardcls">
                  <Swiper
                    modules={[Navigation, Pagination, A11y, Autoplay]}
                    //     wrapperclassName= 'marquee-right__wrapper'
                    // slideclassName= 'marquee-right__slide'
                    // touchEventsTarget= 'marquee-right__wrapper'
                    direction="horizontal"
                    slidesPerView={4.5}
                    spaceBetween={30}
                    freeMode={true}
                    freeModeMomentum={false}
                    freeModeMomentumBounce={false}
                    speed={5000}
                    breakpoints={{
                      320: { slidesPerView: 1, spaceBetween: 80 },
                      480: { slidesPerView: 4.5 },
                    }}
                    autoplay={{
                      delay: 0,
                      disableOnInteraction: true,
                      //waitForTransition: false,
                      reverseDirection: false,
                    }}
                    loop={true}
                    grabCursor={true}
                    className="marquee-left__rev__container homepagemarque   pb-5"
                  >
                    {companies.map((item, index) => (
                      <SwiperSlide className="marquee-left__slide">
                        <img className="img-fluid px-4 py-2" src={item.image} />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  {/* {companies.map((item, index) => {
                    return (
                      <div className="cstm-boxes col-md-4 ">
                        <div className={`Opportunities companiesboxes `}>
                          <div>
                            <img
                              className="company-card-icon"
                              src={item.image}
                            />
                          </div>
                        </div>
                      </div>
                    ); */}
                  {/* })} */}
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="bg-color-white">
          <Container className=" my-md-5 pb-mb-4 counter-boxes">
            <Row>
              <Col></Col>
              <Col md="10" className="subscribersec">
                <div className="sub-leftside">
                  <h1>
                    Stay updated on our latest workshops & events with our
                    newsletter
                  </h1>
                  <input type="text" placeholder="Enter your email" />
                  <i className="fa fa-arrow-right gly-rotate-45"></i>
                </div>
                <div className="sub-rightside">
                  <img
                    className=""
                    src={require("../../../images/newsletter.png").default}
                  />
                </div>
              </Col>
              <Col></Col>
            </Row>
          </Container>
        </section>
        {isResetModalOpen && (
          <ResetPasswordModal
            isOpen={isResetModalOpen}
            onClose={handleResetModalClose}
            onLoginClick={() => setOpenLogin(true)}
          />
        )}
      </div>
      {/* <footer>
        <div className="top-ftr">
          <div className="container">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-7">
                  <h5 className="subscriberheading">
                    Stay updated on our latest workshops and events with our
                    newsletter
                  </h5>
                </div>

                <div className="col-md-5 newsletter-form">
                  <InputGroup className="mb-1 w-100 ml-0">
                    <FormControl
                      type="email"
                      placeholder="Enter your email"
                      aria-label="Enter youe Email ID"
                      aria-describedby="basic-addon2"
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-secondary"
                        className="subscribe-btn"
                      >
                        Subscribe
                        <FaTelegramPlane />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
      <span className="mainhomepage">
        <Footer />
      </span>
    </React.Fragment>
  );
};

export default Main;
