import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form, Tab, Tabs, TabContent } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight, FaSave } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import $ from "jquery";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {
  Button as Antbtn,
  Checkbox,
  Form as AntForm,
  Select,
  Input,
  Radio,
} from "antd";
import config from "../..//CommonFiles/config.json";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const USER_TOKEN = localStorage.getItem("token");
const AuthStr = "Bearer ".concat(USER_TOKEN);
const today = new Date().toISOString().split("T")[0];

const StepTwo = (props) => {
  const [planlists, setPlans] = useState([]);

  const [inputValue, setInputValue] = useState({
    // visibility: "",
    register_start_date: "",
    register_start_time: "",
    register_end_date: "",
    register_end_time: "",
    min_team_size: 1,
    max_team_size: 1,
    participant_type: "",
    team_composition: "",
    gender: "",
    opportunity_open_for: "",
    is_opportunity_paid: 0,
    years: "",
  });

  const [error, setError] = useState([]);
  const [teamoption, setTeam] = useState([]);
  const [studentYear, setStudentYear] = useState([]);
  const [steponedata, setSteponedata] = useState(
    JSON.parse(localStorage.getItem("FormOne"))
  );
  const [opportunityOpenFor, setOpportunityOpenFor] = useState("");

  useEffect(() => {
    console.log("step 2", planlists);

    if (localStorage.getItem("editopportunityid")) {
      axios({
        method: "get",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      })
        .then((response) => {
          const alldata = response.data.data;
          console.log(alldata, "get opprootunity step 2");
          if (alldata.plans != null) {
            setPlans(alldata.plans);
          }
          localStorage.setItem("opportunitydata", JSON.stringify(alldata));
          localStorage.setItem("FormTwo", JSON.stringify(alldata));
          var formdatssa = JSON.parse(localStorage.getItem("FormTwo"));
          if (formdatssa.plans) {
            setPlans(formdatssa.plans);
          } else {
            setPlans([]);
          }
          var oldData = {
            register_start_date: moment(formdatssa.register_start_date)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD"),
            register_start_time: formdatssa.register_start_time,
            register_end_date: moment(formdatssa.register_end_date)
              .tz("Asia/Kolkata")
              .format("YYYY-MM-DD"),
            register_end_time: formdatssa.register_end_time,
            min_team_size: formdatssa.min_team_size,
            max_team_size: formdatssa.max_team_size,
            participant_type:
              formdatssa.max_team_size > 1 ? "team" : "individual",
            plans: planlists.length > 0 ? JSON.stringify(formdatssa.plans) : [],
            is_opportunity_paid: formdatssa.is_opportunity_paid,
            gender: formdatssa.gender,
            team_composition: formdatssa.team_composition,
            opportunity_open_for: formdatssa.opportunity_open_for,
            years: formdatssa.years,
          };
          localStorage.setItem("FormTwo", JSON.stringify(oldData));

          // setStudentYear(JSON.parse("[" + formdatssa.years + "]"))
          console.log("api get", oldData);

          setInputValue(oldData);
        })
        .catch((err) => {
          console.log("jhghjghg", err);
        });
    }
    const formdata = JSON.parse(localStorage.getItem("FormTwo"));

    if (formdata) {
      var formdatssa = JSON.parse(localStorage.getItem("FormTwo"));
      console.log("api get local", formdatssa);

      var oldData = {
        register_start_date: formdatssa.register_start_date,
        register_start_time: formdatssa.register_start_time,
        register_end_date: formdatssa.register_end_date,
        register_end_time: formdatssa.register_end_time,
        min_team_size: formdatssa.min_team_size,
        is_opportunity_paid: formdatssa.is_opportunity_paid,
        max_team_size: formdatssa.max_team_size,
        participant_type: formdatssa.participant_type,
        gender: formdatssa.gender,
        team_composition: formdatssa.team_composition,
        plans:
          formdatssa.plans === null ? [] : JSON.stringify(formdatssa.plans),
        opportunity_open_for: formdatssa.opportunity_open_for,
        years: formdatssa.years,
      };
      if (formdatssa.years) {
        localStorage.setItem("years", formdatssa.years);
        // setStudentYear(JSON.parse(formdatssa.years));
      }
      localStorage.setItem("FormTwo", JSON.stringify(oldData));

      // setStudentYear(JSON.parse("[" + formdatssa.years + "]"))
      // setStudentYear(formdatssa.years)

      setInputValue(oldData);
      // if (formdata.plans != null || formdata.plans != "") {
      //   setPlans(formdatssa.plans);
      // }
    }
  }, []);

  const handleAddFields = () => {
    console.log("plan list view", planlists);
    const values = [...planlists];
    values.push({
      price: "",
      description: "",
    });
    setPlans(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...planlists];

    // document.getElementById(exitid).remove()
    values.splice(index, 1);
    setPlans(values);
    setInputValue({ ...inputValue, ["plans"]: JSON.stringify(values) });
    console.log("id check", inputValue);

    localStorage.setItem("FormTwo", JSON.stringify(inputValue));
    let exitid = "planid" + index;
    // var textRemove = document.getElementById(exitid);
    // textRemove.remove();
    planlistsCall(values);
  };

  const onInputChange = (val, name, teamtype) => {
    var items = JSON.parse(localStorage.getItem("FormTwo"));
    setTeam(val);
    console.log("plan list", items, teamtype);

    if (items && teamtype) {
      if (val === "individual") {
        console.log("items", items);
        items.max_team_size = 1;
        items.min_team_size = 1;
        localStorage.setItem("FormTwo", JSON.stringify(items));
      }
    }
    if (name === "is_opportunity_paid") {
      if (val === "0") {
        delete items.plans;
        setPlans([]);
        localStorage.setItem("FormTwo", JSON.stringify(items));
      }
    }

    const getlocal = localStorage.getItem("FormTwo");
    console.log("getlocal", getlocal);

    if (getlocal == null) {
      console.log("get", name, typeof val);

      setInputValue({ ...inputValue, [name]: val });
      localStorage.setItem(
        "FormTwo",
        JSON.stringify({ ...inputValue, [name]: val })
      );
    } else {
      var existing = JSON.parse(localStorage.getItem("FormTwo"));
      // If no existing data, create an array
      // Otherwise, convert the localStorage string to an array
      console.log("localstoragetyp", typeof existing);
      // Add new data to localStorage Array
      existing[name] = val;

      // Save back to localStorage
      localStorage.setItem("FormTwo", JSON.stringify(existing));

      setInputValue(existing);
    }
  };

  const nextStep = (e, status) => {
    const error = [];
    if (inputValue.register_start_date === "") {
      error["register_start_date"] = "Please select Start Date";
    }
    if (inputValue.register_start_time === "") {
      error["register_start_time"] = "Please select Start Time";
    }

    if (inputValue.register_end_date === "") {
      error["register_end_date"] = "Please select End Date";
    }

    if (inputValue.register_end_time === "") {
      error["register_end_time"] = "Please select End time";
    }

    if (teamoption === "team") {
      if (inputValue.min_team_size === "" && teamoption === 1) {
        error["min_team_size"] = "Please Enter Min team";
      }
      if (inputValue.max_team_size === "" && teamoption === 1) {
        error["max_team_size"] = "Please Enter Max Team";
      }
    }

    if (inputValue.gender === "") {
      error["gender"] = "Please select Gender";
    }

    if (inputValue.is_opportunity_paid === "1") {
      var inputTag = document.querySelectorAll(".requiredfield");

      for (var i = 0; i < inputTag.length; i++) {
        if (
          inputTag[i].value === "" &&
          inputTag[i].getAttribute("name") != "college_registration"
        ) {
          console.log("input value", inputTag[i].value);
          error["is_opportunity_paid"] = "Please Add plans";

          inputTag[i].setAttribute("id", "fill-the-field");
        } else {
          inputTag[i].setAttribute("id", "");
        }
      }
    }
    if (teamoption === "team") {
      if (inputValue.team_composition === "") {
        error["team_composition"] = "Please select team composition";
      }
    }

    if (inputValue.opportunity_open_for === "") {
      error["opportunity_open_for"] = "Please enter value for this field";
    }

    setError(error);
    console.log(error);
    if (Object.keys(error).length === 0) {
      // setInputValue({ ...inputValue, "years": JSON.stringify(studentYear) });

      const stepData = JSON.parse(localStorage.getItem("FormTwo"));
      const USER_TOKEN = localStorage.getItem("token");
      console.log("user stepData", stepData, planlists);
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      // stepData.push({"plan" : planlists})
      if (planlists.length > 0) {
        stepData.plans = JSON.stringify(planlists);
      } else {
        delete stepData.plans;
      }
      axios({
        method: "put",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
        // data: { ...stepData, years: studentYear },
        data: stepData,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldata = response.data.data;
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
      if (status === "continue") {
        localStorage.setItem("activestep", 3);
        props.newActiveStepValue(3);
      } else {
        Swal.fire(
          "Good job!",
          "Registration & payment details have been updated",
          "success"
        );
        // setSaveSucess("Basic Detail has been updated")
      }

      const getlocal = localStorage.getItem("FormTwo");
      // check if nothing update store previous locastorage value
      if (getlocal == null)
        localStorage.setItem("FormTwo", JSON.stringify(inputValue));
    }

    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  const prevStep = () => {
    localStorage.setItem("activestep", 1);
    props.newActiveStepValue(1);
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  const onInputChangeplan = (val, name, idx) => {
    // setInputValue({ ...inputValue, [name]: val });

    const rows = [...planlists];

    if (name == "price") {
      rows[idx].price = val;
    }
    if (name == "description") {
      rows[idx].description = val;
    }
    setPlans(rows);
    console.log("get val", rows);
    setInputValue({ ...inputValue, plans: JSON.stringify(rows) });

    localStorage.setItem("FormTwo", JSON.stringify(inputValue));

    // localStorage.setItem("plans", JSON.stringify(rows));
  };

  const cancel = () => {
    props.history.push("/user/hosting");
  };
  const yearsvalue = (e) => {
    console.log("check value", e.target.checked, studentYear);
    let val = e.target.value;
    let newvalue = studentYear;
    if (e.target.checked === true) {
      newvalue.push(val);
    } else {
      // newvalue.splice(e.target.value)
      for (var i = 0; i < newvalue.length; i++)
        if (newvalue[i] === e.target.value) {
          newvalue.splice(i, 1);
        }
    }
    setStudentYear(newvalue);

    // if(e.target.value === )
    // setInputValue({ ...inputValue, "years": JSON.stringify(studentYear) });
    // localStorage.setItem("FormTwo", JSON.stringify(inputValue));
    localStorage.setItem("years", newvalue);
    console.log("eval", e.target.value, e.target.name, typeof studentYear);
  };

  const onRemoveOpportunityForItem = (index) => {
    let opportunityForList = inputValue.opportunity_open_for.split(", ");
    opportunityForList.splice(index, 1);
    opportunityForList = opportunityForList.join(", ");
    onInputChange(opportunityForList, "opportunity_open_for");
  };

  const planlistsCall = (planlists) => {
    console.log("planlist", planlists);
    let mainplan = planlists?.map((planval, planindex) => (
      <div className={`mainrow ${planlists.length}`} id={`planid${planindex}`}>
        <div className="col-md-12 row inputboxcss">
          <div className="form-group col-sm-5 col-md-5">
            <label htmlFor="firstName">Registration fees</label>
            <input
              type="number"
              className="form-control requiredfield"
              value={planval.price}
              name="price"
              onChange={(e) =>
                onInputChangeplan(e.target.value, e.target.name, planindex)
              }
            />
          </div>
          <div className="form-group col-sm-5 col-md-5">
            <label htmlFor="firstName">Description</label>
            <input
              type="text"
              className="form-control requiredfield"
              value={planval.description}
              name="description"
              onChange={(e) =>
                onInputChangeplan(e.target.value, e.target.name, planindex)
              }
            />
          </div>
          <div className="col-md-1 pt-4 fr">
            <div className="form-group  addminus">
              <button
                className="btn btn-link minus-btn planremove"
                type="button"
                onClick={() => handleRemoveFields(planindex)}
              >
                <h2 className="remove-round fr">
                  Remove <i className="fa fa-trash"></i>
                </h2>
              </button>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    ));
    return mainplan;
  };

  return (
    <div>
      <div className="form">
        <p className="notifyshow">
          {" "}
          <i className="fa fa-exclamation-circle"></i> This field helps you to
          mention the format of the competition you are listing, along with
          various rounds and specific needs of the competition.
        </p>

        <Form className="form-body">
          <div className="step1head">
            {/* <Col className="form-group-col" md={6} id="formGridCheckbox" className="checkboxview">
                 <Form.Label className="input-titlle">Open Rregistration</Form.Label>
                 <Form.Check type="radio" label="Public" />
                 <Form.Check type="radio" label="Private" />
               </Col> */}
          </div>
          <Form.Row className="twocolform">
            <Col className="form-group-col" md={6} controlId="formGridCategory">
              <Form.Label className="input-titlle">
                Registration Start Date{" "}
                <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                placeholder="21-04-2021"
                min={steponedata.start_date}
                max={
                  inputValue.register_end_date && inputValue.register_end_date
                }
                name="register_start_date"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                value={inputValue.register_start_date}
              />
              <Form.Text className=" checkerror">
                {error.register_start_date}
              </Form.Text>
            </Col>

            <Col
              className="form-group-col"
              md={6}
              controlId="formGridOpportunityTitle"
            >
              <Form.Label className="input-titlle">
                Registration Start Time{" "}
                <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                placeholder="00:00:00"
                name="register_start_time"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                defaultValue={inputValue.register_start_time}
              />
              <Form.Text className=" checkerror">
                {error.register_start_time}
              </Form.Text>
            </Col>
          </Form.Row>
          <Form.Row className="twocolform">
            <Col className="form-group-col" md={6} controlId="formGridCategory">
              <Form.Label className="input-titlle">
                Registration End Date <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="date"
                min={inputValue.register_start_date}
                placeholder="21-04-2021"
                name="register_end_date"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                value={inputValue.register_end_date}
              />
              <Form.Text className=" checkerror">
                {error.register_end_date}
              </Form.Text>
            </Col>

            <Col
              className="form-group-col"
              md={6}
              controlId="formGridOpportunityTitle"
            >
              <Form.Label className="input-titlle">
                Registration End Time <span className="importantfield">*</span>
              </Form.Label>
              <Form.Control
                type="time"
                placeholder="00:00:00"
                name="register_end_time"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                defaultValue={inputValue.register_end_time}
              />
              <Form.Text className=" checkerror">
                {error.register_end_time}
              </Form.Text>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col
              className="form-group-col"
              md={12}
              controlId="formGridOpportunityTitle"
            >
              <Form.Label className="input-titlle">
                Participant Type <span className="importantfield">*</span>
              </Form.Label>

              <Select
                bordered={false}
                className="select-category"
                value={
                  inputValue.participant_type
                    ? inputValue.participant_type
                    : "individual"
                }
                onChange={(e) => onInputChange(e, "participant_type", "team")}
                dropdownMatchSelectWidth={true}
                options={[
                  {
                    value: "individual",
                    label: "Individual",
                  },
                  {
                    value: "team",
                    label: "Team",
                  },
                ]}
              />
            </Col>
          </Form.Row>
          {inputValue.participant_type === "team" && (
            <Form.Row className="twocolform">
              <Col
                className="form-group-col"
                md={6}
                controlId="formGridWebsite"
              >
                <Form.Label className="input-titlle">
                  Min. Team Size <span className="importantfield">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  placeholder="Min. Team Size"
                  name="min_team_size"
                  onChange={(e) => onInputChange(e.target.value, e.target.name)}
                  defaultValue={inputValue.min_team_size}
                />
                <Form.Text className=" checkerror">
                  {error.min_team_size}
                </Form.Text>
              </Col>

              <Col
                className="form-group-col"
                md={6}
                controlId="formGridOrganization"
              >
                <Form.Label className="input-titlle">
                  Max. Team Size <span className="importantfield">*</span>
                </Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  placeholder="Max. Team Size"
                  name="max_team_size"
                  onChange={(e) => onInputChange(e.target.value, e.target.name)}
                  defaultValue={inputValue.max_team_size}
                />
                <Form.Text className=" checkerror">
                  {error.max_team_size}
                </Form.Text>
              </Col>
            </Form.Row>
          )}
          {inputValue.participant_type === "team" && (
            <Form.Row>
              <Col
                className="form-group-col"
                md={12}
                controlId="oppotunityEndTime"
              >
                <Form.Label className="input-titlle">
                  Team Composition
                </Form.Label>
                <div
                  className="form-control radiocss"
                  name="team_composition"
                  onChange={(e) => onInputChange(e.target.value, e.target.name)}
                >
                  <Form.Check
                    type="radio"
                    value="All members should be from same organization"
                    label="All members should be from same organization"
                    name="team_composition"
                    checked={
                      inputValue.team_composition === null
                        ? null
                        : inputValue.team_composition ===
                          "All members should be from same organization"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="Members can be from different organization"
                    label="Members can be from different organization"
                    name="team_composition"
                    checked={
                      inputValue.team_composition === null
                        ? null
                        : inputValue.team_composition ===
                          "Members can be from different organization"
                    }
                  />
                </div>
                <Form.Text className=" checkerror">
                  {error.team_composition}
                </Form.Text>
              </Col>
            </Form.Row>
          )}

          <Form.Row>
            <Col
              className="form-group-col"
              md={12}
              controlId="oppotunityEndTime"
            >
              <Form.Label className="input-titlle">
                Gender <span className="importantfield">*</span>
              </Form.Label>
              <div
                className="form-control radiocss"
                name="gender"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
              >
                <Form.Check
                  type="radio"
                  value="all"
                  label="All"
                  name="gender"
                  checked={
                    inputValue.gender === null
                      ? null
                      : inputValue.gender === "all"
                  }
                />
                <Form.Check
                  type="radio"
                  value="male"
                  label="Only Male"
                  name="gender"
                  checked={
                    inputValue.gender === null
                      ? null
                      : inputValue.gender === "male"
                  }
                />
                <Form.Check
                  type="radio"
                  value="female"
                  label="Only Female"
                  name="gender"
                  checked={
                    inputValue.gender === null
                      ? null
                      : inputValue.gender === "female"
                  }
                />
              </div>
              <Form.Text className=" checkerror">{error.gender}</Form.Text>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col
              className="form-group-col"
              md={12}
              controlId="oppotunityEndTime"
            >
              <Form.Label className="input-titlle">
                Opportunity for <span className="importantfield">*</span>
              </Form.Label>
              {inputValue.opportunity_open_for &&
                inputValue.opportunity_open_for.length > 0 && (
                  <div className="opportunity-lists">
                    {inputValue.opportunity_open_for
                      .split(",")
                      .map((item, index) => {
                        return (
                          <div className="opportunity-item" key={index}>
                            {item}
                            <IoCloseOutline
                              style={{ marginLeft: "5px", cursor: "pointer" }}
                              onClick={() => onRemoveOpportunityForItem(index)}
                            />
                          </div>
                        );
                      })}
                  </div>
                )}
              <Input
                className="form-control"
                onChange={(e) => setOpportunityOpenFor(e.target.value)}
                onPressEnter={(e) => {
                  if (e.target.value.length > 0) {
                    onInputChange(
                      `${inputValue.opportunity_open_for ? inputValue.opportunity_open_for + ", " : ""}${e.target.value}`,
                      "opportunity_open_for"
                    );
                    setOpportunityOpenFor("");
                  }
                }}
                value={opportunityOpenFor}
                placeholder="Type your input then press enter to add"
              />
              <Form.Text className=" checkerror">
                {error.opportunity_open_for}
              </Form.Text>
            </Col>
          </Form.Row>

          {/* Years Selection Row */}
          {/* {inputValue.opportunity_open_for === "students" && (
            <Form.Row>
              <Col
                className="form-group-col"
                md={12}
                controlId="oppotunityEndTime"
              >
                <Form.Label className="input-titlle">
                  Years <span className="importantfield">*</span>
                </Form.Label>
                <div
                  className="form-control radiocss oppfor"
                  onChange={(e) => onInputChange(e.target.value, "years")}
                  name="years"
                >
                  <Form.Check
                    type="radio"
                    value="all"
                    label="All"
                    name="years"
                    onChange={(e) => yearsvalue(e)}
                    // checked={studentYear.includes("all") ? "checked" : null}
                    checked={
                      inputValue.years === null
                        ? null
                        : inputValue.years === "all"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="1st Year"
                    label="1st year"
                    name="years"
                    // checked={studentYear.includes("1st Year") ? "checked" : null}
                    checked={
                      inputValue.years === null
                        ? null
                        : inputValue.years === "1st Year"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="2nd Year"
                    label="2nd Year"
                    name="years"
                    checked={
                      inputValue.years === null
                        ? null
                        : inputValue.years === "2nd Year"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="3rd Year"
                    label="3rd Year"
                    name="years"
                    checked={
                      inputValue.years === null
                        ? null
                        : inputValue.years === "3rd Year"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="4th Year"
                    label="4th Year"
                    name="years"
                    checked={
                      inputValue.years === null
                        ? null
                        : inputValue.years === "4th Year"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="5th Year"
                    label="5th Year"
                    name="years"
                    checked={
                      inputValue.years === null
                        ? null
                        : inputValue.years === "5th Year"
                    }
                  />
                </div>
                <Form.Text className=" checkerror">{error.years}</Form.Text>
              </Col>
            </Form.Row>
          )} */}

          <Form.Row>
            <Col
              className="form-group-col"
              md={12}
              controlId="formGridOpportunityTitle"
            >
              <Form.Label className="input-titlle">
                Registration Type <span className="importantfield">*</span>
              </Form.Label>
              <Select
                bordered={false}
                className="select-category"
                value={
                  inputValue.is_opportunity_paid &&
                  (inputValue.is_opportunity_paid.toString() === "0" ||
                    inputValue.is_opportunity_paid.toString() === "1")
                    ? inputValue.is_opportunity_paid.toString()
                    : "0"
                }
                onChange={(e) => onInputChange(e, "is_opportunity_paid")}
                dropdownMatchSelectWidth={false}
                options={[
                  {
                    value: "0",
                    label: "Free",
                  },
                  {
                    value: "1",
                    label: "Paid",
                  },
                ]}
              />
              {/* <Form.Control
                as="select"
                name="is_opportunity_paid"
                onChange={(e) => onInputChange(e.target.value, e.target.name)}
                defaulValue={inputValue.is_opportunity_paid.toString()}
                required
              >
                <option value="0">Free</option>
                <option value="1">Paid</option>
              </Form.Control> */}
            </Col>
          </Form.Row>
          {inputValue.is_opportunity_paid === "1" && (
            <div>
              {planlistsCall(planlists)}
              <Form.Text className=" checkerror">
                {error.is_opportunity_paid}
              </Form.Text>
              <div className="form-group  addminus priceadd">
                <button
                  className="btn btn-link plus-btn"
                  type="button"
                  onClick={() => handleAddFields()}
                >
                  <h2 className="add-round fr">
                    <i className="fa fa-plus"></i>Add Plan{" "}
                  </h2>
                </button>
              </div>
            </div>
          )}

          {/* 
                 <Button className="help-btn" type="submit">
                 Whats this? Help!!
                 </Button> */}
        </Form>
      </div>
      <div className="hosting_bottom">
        <Button className="help-btn" type="button" onClick={() => prevStep(2)}>
          <FaArrowLeft />
          Go Back
        </Button>
        <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => nextStep(2, "continue")}
        >
          Next <FaArrowRight />
        </Button>
        {localStorage.getItem("opportunityfrom") && (
          <Button
            className="save-hosting-first-btn editsavebtn"
            type="button"
            onClick={() => nextStep(2, "save")}
          >
            save <FaSave />
          </Button>
        )}
        <Link to={`/user/hosting`}>
          {" "}
          <Button
            className="transparentbtn float-right mt-10 mr-2"
            type="button"
            // onClick={(e) => cancel(e)}
          >
            Cancel
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default StepTwo;
