import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Container from "react-bootstrap/Container";
import "./profile.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";

import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "./Components/Utils/Button";
import Tab from "./Components/Utils/tabs";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const Settings = (props) => {
  const [alldata, setAlldata] = useState();
  const [password, setPassword] = useState();
  const [confirmpass, setConfirmpass] = useState();
  const [msg, setMsg] = useState();
  const [tabStatus, setTabstatus] = useState("view");
  const [activeTab, setActiveTab] = useState("General");
  const [tabContent, setTabContent] = useState();
  let [popupstatus, setPopupstatus] = useState(false);

  const userdata = JSON.parse(localStorage.getItem("alldata"));
  const totalProgress = 60;

  const tabName = [
    { id: 1, title: "General", desciption: "General Data" },
    { id: 2, title: "Password", desciption: "Password Data" },
    {
      id: 3,
      title: "College Information",
      desciption: "College Information Data",
    },
    { id: 4, title: "Social Profiles", desciption: "Social Profiles Data" },
  ];

  const profileTabContent = (
    <div className="col-md-12 userdetail">
      <p className="settingcss">
        Name :{" "}
        <span>
          {userdata && userdata.first_name} {userdata && userdata.last_name}{" "}
        </span>
      </p>
      <p className="settingcss">
        Email ID : <span>{userdata && userdata.email} </span>
      </p>
      <p className="settingcss">
        Phone : <span>{userdata && userdata.mobile} </span>
      </p>
      <p className="settingcss">
        Gender : <span>{userdata && userdata.gender} </span>
      </p>
      <p className="settingcss">
        Organization : <span>{userdata && userdata.organization}</span>
      </p>
      {/* <p>Contact : {userdata.email}</p> */}
    </div>
  );
  const collageTabContent = (
    <div className="col-md-12 userdetail">
      <p className="settingcss">
        User Type: : <span>{userdata && userdata.user_type}</span>
      </p>
      <p className="settingcss">
        College/Organization: :{" "}
        <span>{userdata && userdata.organization} </span>
      </p>
      <p className="settingcss">
        Domain : <span>{userdata && userdata.domain} </span>
      </p>
      <p className="settingcss">
        Course : <span>{userdata && userdata.course} </span>
      </p>
      <p className="settingcss">
        Specialization : <span>{userdata && userdata.specialization}</span>
      </p>
      <p className="settingcss">
        College Registration no/Employee ID :{" "}
        <span>{userdata && userdata.college_id}</span>
      </p>
      <p className="settingcss">
        Year of Study : <span>{userdata && userdata.year_of_study}</span>
      </p>
      <p className="settingcss">
        Year of Graduation: :{" "}
        <span>{userdata && userdata.graduation_year}</span>
      </p>
      {/* <p>Contact : {userdata.email}</p> */}
    </div>
  );

  const passwordTabContent = (
    <div className="col-md-12 userdetail">
      <p className="settingcss">
        Password : <span>**********</span>
      </p>
    </div>
  );
  const socialTabContent = (
    <div className="col-md-12 userdetail">
      <p className="settingcss">
        LinkedIn Profile Link :{" "}
        {userdata && (
          <a href={userdata.linkedin_profile} target="_blank">
            {userdata.linkedin_profile}{" "}
          </a>
        )}
      </p>
      <p className="settingcss">
        Github Profile Link :{" "}
        {userdata && (
          <a href={userdata.github_profile} target="_blank">
            {userdata.github_profile}{" "}
          </a>
        )}
      </p>
    </div>
  );
  useEffect(async () => {
    // const tokencheck = localStorage.getItem("token")

    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      axios({
        method: "get",
        url: mainURL + "/profile",
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data[0];
          localStorage.setItem("alldata", JSON.stringify(alldatas));
          setAlldata(alldatas);
          console.info("all analytics", alldata);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }

    setTabContent(profileTabContent);
  }, []);

  function logout(params) {
    localStorage.removeItem("token");
    localStorage.removeItem("alldata");
    localStorage.clear();

    props.history.push(`/`);
  }

  const gotoedit = (params) => {
    props.history.push(`/user/profile/edit/` + params);
  };

  const setKey = (title) => {
    setActiveTab(title);
    console.log("new k", title);
    if (title === "General") {
      setTabContent(profileTabContent);
    }
    if (title === "Password") {
      setTabContent(passwordTabContent);
    }
    if (title === "Social Profiles") {
      setTabContent(socialTabContent);
    }
    if (title === "College Information") {
      setTabContent(collageTabContent);
    }
  };
  const handleShow = () => {
    setPopupstatus(true);
  };
  const handleClose = () => setPopupstatus(false);
  return (
    <Container className="pt-5 mt-5">
      <Row>
        <Col></Col>
        <Col md={7} className="profile__detail__section ">
          {/* Profile Detail Start */}
          <div className="profile__detail">
            <div className="profile__detail__sortname">
              {userdata.profileimage ? (
                <img
                  id="file-image"
                  src={userdata.profileimage}
                  onClick={handleShow}
                  alt="Preview"
                />
              ) : userdata.first_name ? (
                <h3>
                  {" "}
                  {userdata.first_name && userdata.first_name.charAt(0)}{" "}
                  {userdata.last_name && userdata.last_name.charAt(0)}{" "}
                </h3>
              ) : (
                <h3> {userdata.email && userdata.email.charAt(0)} </h3>
              )}
            </div>
            <Modal
              show={popupstatus}
              className="dashboardpopup"
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header className="profileimgpopup" closeButton />
              <div className="text-center">
                <img
                  id="file-image"
                  src={userdata.profileimage}
                  className="w-5"
                  alt="Preview"
                />
              </div>
            </Modal>
            <div className="profile__detail__name">
              <h3>
                {" "}
                {alldata && alldata.first_name} {alldata && alldata.last_name}{" "}
              </h3>
              <p>{alldata && alldata.organization} </p>
            </div>
            <div
              className="edit__profile"
              onClick={() => gotoedit(userdata.id)}
            >
              <Button clsName="pink__btn" name="Edit Profile" />
            </div>
            <div className="edit__profile" onClick={() => logout(userdata.id)}>
              <Button clsName="pink__btn" name="Logout" />
            </div>
          </div>

          {/* Profile Detail Start */}

          {/* Profile Complete Start */}
          <div className="progress__bar">
            <p>{alldata && alldata.profile_completed}% Profile Completed </p>
            <ProgressBar
              variant="success"
              now={alldata && alldata.profile_completed}
            />
          </div>
          <hr className="cstm__hrcolor" />
          {/* Profile Complete End */}

          {/* Tab Start */}

          <Tab
            tabData={tabName}
            userdata={tabContent}
            ActiveTab={activeTab}
            setKey={(k) => setKey(k)}
          />

          {/* Tab End */}
        </Col>
        <Col></Col>
      </Row>
    </Container>

    // <div id="user-wrapper" className="dashboard topmargin">
    //    <div className="row">
    //        <div className="col-md-12">
    //            <button className="logoutbtn" onClick={()=>logout()}> Logout</button>
    //        </div>
    //        <hr/>
    //        <div className="col-md-6 userdetail">
    //            <p className='settingcss'>Name : {alldata && alldata.first_name} {alldata && alldata.last_name} </p>
    //            <p>Email : {alldata && alldata.email}</p >
    //            <p>Phone : {alldata && alldata.mobile}</p >
    //            <p className='settingcss'>Gender : {alldata && alldata.gender}</p >
    //            <p className='settingcss'>Organization : {alldata && alldata.organization}</p >
    //            {/* <p>Contact : {userdata.email}</p> */}
    //        </div>
    //        <div className="col-md-12">
    //        <button className="floatright" onClick={() => gotoedit(userdata.id)}>Edit</button>

    //        </div>
    //    </div>
    //    <hr/>
    //    <div className="row">
    //    <div className="col-md-12">
    //        <h3>Change Password:</h3>
    //        </div>
    //        <hr/>
    //        <div className="col-md-12">

    //            <input type="password" name="password" className="form-control" onChange={(e) => setPassword(e.target.value)} placeholder="New Password"/>
    //            <input type="password" name="crmpassword" className="form-control" onChange={(e) => setConfirmpass(e.target.value)} placeholder="Confirm Password"/>
    //            <button className="floatright" onClick={()=> changepass()}>Update</button>

    //        </div>
    //        {setMsg && (
    //                <p className="errmsg">{msg}</p>
    //            )}
    //     </div>
    //     </div>
  );
};

export default Settings;
