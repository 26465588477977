import React, { useState, useEffect } from "react";
import { Col, Row, Form, Button, Tab, Tabs, TabContent } from "react-bootstrap";
import axios from "axios";
import config from "../../CommonFiles/config.json";
import { FaArrowLeft, FaArrowRight, FaSave } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { reqformdata } from "./StepFour";
const mainurl = Object.values(config["URL"]);
const URLs = mainurl.join("");
const mainURL = URLs;
const StepEight = ({ newActiveStepValue, props }) => {
  const [inputValue, setInputValue] = useState({});
  const [imageview, setPreviewlogo] = useState("");
  const [bannerview, setPreviewbanner] = useState("");
  const [rulebookPreview, setRulebookpreview] = useState("");
  const [multiBanner, setMultibanner] = useState([{ name: "" }]);
  const [success, setSuccess] = useState();
  const [errormsg, setError] = useState();
  const [logoerrromsg, setLogoerrormsg] = useState("");
  const [bannererrormsg, setBannererrormsg] = useState("");
  let history = useHistory();
  const formData = new FormData();

  const fileObj = [];
  const fileArray = [];
  console.log("attachement", reqformdata);

  useEffect(() => {
    if (localStorage.getItem("editopportunityid")) {
      axios({
        method: "get",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      })
        .then((response) => {
          const alldata = response.data.data;
          setPreviewlogo(alldata.logo_image);
          setPreviewbanner(alldata.banner_image);
          setRulebookpreview(alldata.rulebook);
          callBannerlist();
        })
        .catch((err) => {
          console.log("jhghjghg", err);
        });
    }
    // const formdata = JSON.parse(localStorage.getItem("FormSeven"));

    // if (formdata) {
    //   var formdatssa = JSON.parse(localStorage.getItem("FormSeven"));
    //   setInputFields(formdatssa);
    // }
  }, []);

  const removeImg = (e, imgid, index) => {
    e.preventDefault();
    console.log("image id", imgid);
    if (imgid) {
      axios({
        method: "delete",
        url: mainURL + "/opportunity/banner/" + imgid,
      })
        .then((bannerresponse) => {
          console.log("image id respo", bannerresponse);
          callBannerlist();
        })
        .catch((err) => {
          console.log("jhghjghg", err);
        });
      return false;
    } else {
      const values = [...multiBanner];
      values.splice(index, 1);
      setMultibanner(values);
    }
  };

  const handleAddFields = () => {
    const values = [...multiBanner];
    values.push({
      name: "",
    });
    setMultibanner(values);
  };

  const bannerUpload = (val, name, newval, index, bannerid) => {
    const file = newval;
    let imgRef = URL.createObjectURL(file);
    // setPreviewbanner(imgRef)
    const bannerData = new FormData();

    bannerData.append("banner_image", newval, name);
    bannerData.append(
      "opportunity_id",
      localStorage.getItem("editopportunityid")
    );
    if (bannerid) {
      bannerData.append("id", bannerid);
    }

    setPreviewbanner("true");

    let newid = "file-image" + index;
    // let oldref = document.getElementById(`${newid}`).src=imgRef ;
    var imageParent = document.getElementById(newid);
    console.log("image pre", imageParent);
    const USER_TOKEN = localStorage.getItem("token");
    const oppid = localStorage.getItem("oppid");

    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: mainURL + "/opportunity/banner",
      data: bannerData,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;
        setPreviewbanner("true");
        console.info("all analytics", alldata);
        callBannerlist();
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  const onInputChange = (val, name, newval, index) => {
    console.log("data", name, newval);
    const file = newval;
    let apidata;
    if (name === "logo_image") {
      let imgRef = URL.createObjectURL(file);
      setPreviewlogo(imgRef);
      formData.append("logo_image", newval, name);
      apidata = {
        logo_image: formData,
      };
    }

    if (name === "rulebook") {
      formData.append("rulebook", newval, name);

      apidata = {
        rulebook: formData,
      };
      setRulebookpreview(val + " has been uploaded");
    }

    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    const oppid = localStorage.getItem("oppid");
    axios({
      method: "post",
      url:
        mainURL +
        "/opportunity/images/" +
        localStorage.getItem("editopportunityid"),
      data: formData,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;

        console.info("all analytics", alldata);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });

    // if(name === "logo")
    // {
    //   const file = newval;

    //   let  imgRef = URL.createObjectURL(file)
    //   setPreviewlogo(imgRef)
    // }
    // if(name === "banner")
    // {
    //   const file = newval;

    //   let  imgRef = URL.createObjectURL(file)
    //   formData.append(
    //     "banner_image",
    //     newval,
    //     name
    //   );
    //   console.log("file data" , formData)
    //   setPreviewbanner(imgRef)
    // }

    // const getlocal = localStorage.getItem('FormOne')
    // if(getlocal == null)
    // {
    //   console.log("if null" , getlocal)
    //   if(name === "banner")
    //   {
    //   setInputValue({...inputValue, [name] : formData});

    //   }
    //   else
    //   {
    //   setInputValue({...inputValue, [name] : val});

    //   }

    // }
    // else
    // {
    //   var existing = localStorage.getItem('FormOne');
    //   existing = existing ? JSON.parse(existing) : {};
    //   existing[name] = val;
    //   localStorage.setItem('FormOne', JSON.stringify(existing));
    //   setInputValue(existing);
    //   }
  };

  // const step1 = (status) => {
  //   // formData.append(
  //   //   "attachetement",
  //   //   reqformdata,
  //   //   "attachetement.png"
  //   // );
  //   console.log("preview page", bannerview, multiBanner[0].name);

  //   if (imageview === null && bannerview === "" && multiBanner.length === 1) {
  //     // setError(" All options are required");
  //     setLogoerrormsg("Logo is required");
  //     if (multiBanner[0].name === "") {
  //       setBannererrormsg("Banner is required");
  //     }
  //     return false;
  //   }
  //   if (imageview === null) {
  //     // setError(" All options are required");
  //     setLogoerrormsg("Logo is required");
  //     setBannererrormsg("");
  //     return false;
  //   }
  //   if (bannerview === "" && multiBanner.length === 1) {
  //     // setError(" All options are required");
  //     setLogoerrormsg("");
  //     if (multiBanner[0].name === "") {
  //       setBannererrormsg("Banner is required");
  //     }
  //     return false;
  //   } else {
  //     const USER_TOKEN = localStorage.getItem("token");
  //     const oppid = localStorage.getItem("oppid");

  //     console.log("user token", USER_TOKEN);
  //     const AuthStr = "Bearer ".concat(USER_TOKEN);
  //     axios({
  //       method: "post",
  //       url:
  //         mainURL +
  //         "/opportunity/images/" +
  //         localStorage.getItem("editopportunityid"),
  //       data: reqformdata,
  //       headers: { Authorization: AuthStr },
  //     })
  //       .then((response) => {
  //         const alldata = response.data.data;

  //         console.info("all analytics", alldata);
  //       })
  //       .catch(function (response) {
  //         //handle error
  //         console.log("Error while calling api", response);
  //       });
  //     window.scrollTo({
  //       top: 300,
  //       behavior: "smooth",
  //     });
  //     localStorage.removeItem("FormFive");
  //     localStorage.removeItem("FormSix");
  //     localStorage.removeItem("FormFour");
  //     localStorage.removeItem("FormOne");
  //     localStorage.removeItem("FormTwo");
  //     localStorage.removeItem("FormThree");
  //     localStorage.removeItem("FormSeven");
  //     localStorage.removeItem("activestep");
  //     if (status === "save") {
  //       Swal.fire(
  //         "Good job!",
  //         "Media/Gallery details have been updated",
  //         "success"
  //       );
  //     } else {
  //       // history.push({
  //       //   pathname: "/manage/opportunity/success",
  //       // });
  //       Swal.fire(
  //         "Good job!",
  //         "Registration form details have been updated",
  //         "success"
  //       );
  //     }
  //     history.push({
  //       pathname: "/user/hosting",
  //     });
  //     // setSuccess("Opportunity has been successfully submitted")
  //   }
  // };

  const prevStep = () => {
    localStorage.setItem("activestep", 6);
    newActiveStepValue(6);
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  const nextStep = (status) => {
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });

    if (imageview === null && bannerview === "" && multiBanner.length === 1) {
      setLogoerrormsg("Logo is required");
      if (multiBanner[0].name === "") {
        setBannererrormsg("Banner is required");
      }
      return false;
    }

    if (imageview === null) {
      setLogoerrormsg("Logo is required");
      setBannererrormsg("");
      return false;
    }

    if (bannerview === "" && multiBanner.length === 1) {
      setLogoerrormsg("");
      if (multiBanner[0].name === "") {
        setBannererrormsg("Banner is required");
      }
      return false;
    } else {
      const USER_TOKEN = localStorage.getItem("token");
      const AuthStr = "Bearer ".concat(USER_TOKEN);

      axios({
        method: "post",
        url:
          mainURL +
          "/opportunity/images/" +
          localStorage.getItem("editopportunityid"),
        data: reqformdata,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          if (response.status === 200) {
            console.log(status);
            if (status === "save") {
              Swal.fire(
                "Good job!",
                "Media/Gallery details have been updated",
                "success"
              );
            } else {
              localStorage.setItem("activestep", 8);
              newActiveStepValue(8);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          Swal.fire(
            "Sorry!",
            "Some error occurred while updating your data",
            "error",
          );
        });
    }
  };

  const callBannerlist = () => {
    axios({
      method: "get",
      url:
        mainURL +
        "/opportunity/banner/" +
        localStorage.getItem("editopportunityid"),
    })
      .then((bannerresponse) => {
        let bannerlist = bannerresponse.data.data;
        if (bannerlist.length > 0) {
          setMultibanner(bannerresponse.data.data);
        }
        console.log("banner show", bannerresponse.data.data);

        if (bannerlist.length > 0) {
          for (let i = 0; i < bannerlist.length; i++) {
            let element = bannerlist[i].banner_url;
            let newid = "file-image" + i;
            // let oldref = document.getElementById(`${newid}`).src=imgRef ;
            var imageParent = document.getElementById(newid);
            console.log("image pre", imageParent);

            if (imageParent.hasChildNodes()) {
              // document.getElementById("my-element").remove();
              imageParent.removeChild(imageParent.firstChild);
              // imageParent.parentNode.removeChild(newid);
            }
            var image = document.createElement("img");

            // let oldref = document.getElementById(`${newid}`).src=imgRef ;
            var imageParent = document.getElementById(newid);
            var image = document.createElement("img");

            // imageParent.src = imgRef;
            image.id = "id";
            image.className = "save-hosting-first-btn bannerpreviewimg";
            image.src = element;
            image.width = "100%"; // image.src = "IMAGE URL/PATH"
            imageParent.appendChild(image);
            // imageParent.appendChild(removebtn);
          }
        }
      })
      .catch((err) => {
        console.log("jhghjghg", err);
      });
  };
  return (
    <div>
      <div className="form">
        {errormsg && (
          <p className="alert alert-danger color-red align-center">
            {errormsg}
          </p>
        )}

        <h3 className="successmsg">{success && success}</h3>
        <Form className="form-body">
          <Form.Row>
            <Col
              className="form-group-col "
              md={12}
              controlId="oppotunityEndTime"
            >
              <span className="uploader logosec">
                <input
                  type="file"
                  id="file-upload"
                  accept="image/*"
                  name="logo_image"
                  value=""
                  onChange={(e) =>
                    onInputChange(
                      e.target.files[0].name,
                      e.target.name,
                      e.target.files[0]
                    )
                  }
                />

                <label for="file-upload" id="file-drag">
                  <div id="start">
                    {imageview && (
                      <div>
                        <img id="file-image" src={imageview} alt="Preview" />
                      </div>
                    )}

                    <i className="fa fa-upload" aria-hidden="true"></i>
                    <div>
                      {imageview ? `Replace Your logo ` : "Upload Your logo"}{" "}
                      <span className="importantfield">*</span>
                    </div>
                    <div id="notimage" className="hidden">
                      Please select an image
                    </div>
                    {/* <span id="file-upload-btn" className="btn btn-primary">Select a file</span> */}
                  </div>
                </label>
              </span>
              {logoerrromsg && (
                <Form.Text className="finallogoerror checkerror">
                  {logoerrromsg}
                </Form.Text>
              )}
            </Col>
            {/* <img src={imageview} /> */}
          </Form.Row>

          {/* for banner image */}
          {/* <Form.Row>
              <Col className="form-group-col " md={12}  controlId="oppotunityEndTime">
                <span className="uploader logosec">
                <input type="file" id="file-uploads" accept="image/*" name="banner_image" value=""  onChange={(e) => onInputChange( e.target.files[0].name , e.target.name, e.target.files[0])} />

                <label for="file-uploads" id="file-drag">
                {bannerview && (
                <img id="file-image" src={bannerview} alt="Preview"  />

                  )}
                <div id="start">
                  <i className="fa fa-upload" aria-hidden="true"></i>
                  <div>Upload Your banner <span className="importantfield">*</span></div>
                  <div id="notimage" className="hidden">Please select an image</div>
                </div>
              </label>
              </span>
              
              </Col>
            </Form.Row> */}
          {/* multiple banner */}

          <Form.Row>
            {multiBanner.map((bannerval, bannerindex) => (
              <Col
                className="mb-0 multiplebanner"
                md={12}
                controlId="oppotunityEndTime"
              >
                <span className="uploader logosec">
                  <input
                    type="file"
                    id={`file-uploads${bannerindex}`}
                    accept="image/*"
                    name="banner_image"
                    value=""
                    onChange={(e) =>
                      bannerUpload(
                        e.target.files[0].name,
                        e.target.name,
                        e.target.files[0],
                        bannerval.id,
                        bannerval.id
                      )
                    }
                  />

                  <label for={`file-uploads${bannerindex}`} className="pt-0">
                    <div id="start">
                      <span id={`file-image${bannerindex}`}></span>

                      <p className="banner-title">Banner {bannerindex + 1}</p>

                      <i className="fa fa-upload" aria-hidden="true"></i>
                      <div>
                        {bannerval.banner_url
                          ? "Replace Your banner"
                          : "Upload Your banner"}
                        {bannerindex === 0 && (
                          <span className="importantfield"> *</span>
                        )}
                        <br />
                        <br />
                      </div>
                      <div id="notimage" className="hidden">
                        Please select an image
                      </div>
                      {/* <span id="file-upload-btn" className="btn btn-primary">Select a file</span> */}

                      {bannerindex > 0 && (
                        <button
                          className="menubtn removebanner"
                          type="button"
                          onClick={(e) =>
                            removeImg(e, bannerval.id, bannerindex)
                          }
                        >
                          Remove Banner
                        </button>
                      )}
                    </div>
                  </label>
                </span>
              </Col>
            ))}

            <div className="form-group  addminus priceadd">
              {bannererrormsg && (
                <Form.Text className="finallogoerror mb-3 checkerror">
                  {bannererrormsg}
                </Form.Text>
              )}
              <button
                className="btn btn-link plus-btn"
                type="button"
                onClick={() => handleAddFields()}
              >
                <h2 className="add-round fr">
                  <i className="fa fa-plus"></i>Add Banner{" "}
                </h2>
              </button>
            </div>
            {/* <img src={imageview} /> */}
          </Form.Row>
          {/* rule book upload */}
          <Form.Row>
            <Col
              className="form-group-col "
              md={12}
              controlId="oppotunityEndTime"
            >
              <span className="uploader logosec">
                <input
                  type="file"
                  id="file-uploads-rule"
                  accept="image/pdf"
                  name="rulebook"
                  value=""
                  onChange={(e) =>
                    onInputChange(
                      e.target.files[0].name,
                      e.target.name,
                      e.target.files[0]
                    )
                  }
                />

                <label for="file-uploads-rule" id="file-drag">
                  <div id="start">
                    {rulebookPreview && (
                      <p className="file-upload">
                        {" "}
                        <i className="fa fa-file"></i> {rulebookPreview}
                      </p>
                    )}
                    <i className="fa fa-upload" aria-hidden="true"></i>
                    <div>
                      {rulebookPreview ? "Replace" : "Upload"} Your Rule Book
                    </div>
                    <div id="notimage" className="hidden">
                      Please select an PDF
                    </div>
                    {/* <span id="file-upload-btn" className="btn btn-primary">Select a file</span> */}
                  </div>
                </label>
              </span>
            </Col>
            {/* <img src={imageview} /> */}
          </Form.Row>
          <Form.Row>
            {/* <form className="bannerview">
              <Col className="form-group-col " md={12}  controlId="oppotunityEndTime">
                <span className="uploader">
                <input type="file"  id="file-upload-banner" accept="image/*" name="banner" value="" onChange={(e)=>uploadMultipleFiles(e)} multiple />

                <label for="file-upload-banner" id="file-drag">
                  <Row>
                {(bannerview || []).map((url, index) => (

                  <Col md={3}>
                    <div className="img-wrap">
                      <span className="close" onClick={() => deleteFile(index)}>&times;</span>
                      <img src={url} alt="..." className="bannerview" />
                  </div>
                     
                        </Col>
                    ))}
                    </Row>
                <div id="start">
                  <i className="fa fa-download" aria-hidden="true"></i>
                  <div>Upload Your Gallery Images</div>
                  <div id="notimage" className="hidden">Please select an image</div>
                  <span id="file-upload-btn" className="btn btn-primary">Select a file</span>
                </div>
              </label>
              </span>
              
              </Col>
              </form> */}
            {/* <img src={imageview} /> */}
          </Form.Row>
        </Form>
      </div>
      {/* <Button className="save-btn" type="button"  onClick={() => step1(1)}>
Save and Finish
</Button> */}

      <div className="hosting_bottom">
        <Button className="help-btn" type="button" onClick={() => prevStep(4)}>
          <FaArrowLeft />
          Go Back
        </Button>
        {/* <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => step1("continue")}
        >
          Submit
          <FaArrowRight />
        </Button> */}
        <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => nextStep("continue")}
        >
          Next <FaArrowRight />
        </Button>
        {localStorage.getItem("opportunityfrom") && (
          <Button
            className="save-hosting-first-btn editsavebtn"
            type="button"
            onClick={(e) => nextStep("save")}
          >
            Save <FaSave />
          </Button>
        )}
        <Link to={`/user/hosting`}>
          {" "}
          <Button
            className="transparentbtn float-right mt-10 mr-2"
            type="button"
            // onClick={(e) => cancel(e)}
          >
            Cancel
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default StepEight;
