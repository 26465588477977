import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./LogIn.css";
import "./quiz.css";
import axios from "axios";
import moment from "moment";

import config from "../CommonFiles/config.json";
import Footer from "../CommonFiles/Footer";
import Header from "../CommonFiles/Header";
import Quiz from "./Quiz/Quiz";
import Stopwatch from "./stopWatch";
import Swal from "sweetalert2";
import questionlogo from "../../images/question.png";
import { Successmsg } from "./Register";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
// import "./paytm.js"
// const mainurl = Object.values(config["URL"]);
// const URL = mainurl.join("");
// // const mainURL = URL;
// const mainURL = "https://qareer.in/api";
let renderCount = 1;
var paytmconfig;

const Quize = (props) => {
  const [timestart, settimestart] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [notificationmsg, setNotificationmsg] = useState("watchtime");
  const [suffulestatus, setSufflestatus] = useState(true);
  const [editableAns, setEditableAns] = useState(true);
  const [suffuleans, setSuffuleans] = useState(true);
  const [quiz, setAllquizdata] = useState();
  const [quizSetting, setQuizSetting] = useState();
  const [suffleQuestions, setSufflequestion] = useState([]);
  const [showonly, setShowonly] = useState();
  const [quizDuration, setQuizDuration] = useState();
  const [quizBasic, setQuizBasic] = useState([]);
  var retrievedObject = JSON.parse(localStorage.getItem("userQA"));
  var opportunitydata = JSON.parse(localStorage.getItem("opportunitydata"));
  var userque = JSON.parse(localStorage.getItem("suffle"));
  let quizStartTime = moment();
  let quizStartTimesecond = moment();
  if (localStorage.getItem("quizStartTime")) {
    let date1 = moment();
    let date2 = moment(localStorage.getItem("quizStartTime"));
    quizStartTime = date1.diff(date2, "minutes");
    quizStartTimesecond = date1.diff(date2, "seconds");
  } else {
    localStorage.setItem("quizStartTime", quizStartTime);
  }
  // console.log("quizStartTime minutes", quizStartTime)
  let ssminutes = Math.floor(quizStartTimesecond / 60);
  let ssseconds = quizStartTimesecond - ssminutes * 60;
  console.log("quizStartTime minutes", ssminutes);
  console.log("quizStartTime seconds", ssseconds);
  // console.log("quizStartTime seconds", quizStartTimesecond)
  let qlogo = require("../../images/logo.png");
  // let quiz =

  function closedWin() {
    alert("close ?");
    return false; /* which will not allow to close the window */
  }
  if (window.addEventListener) {
    window.addEventListener("close", closedWin, false);
  }

  useEffect(() => {
    console.log("step 1");
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: mainURL + "/opportunity/round",
      data: { round_id: localStorage.getItem("selectedRound") },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("elemnt first", JSON.parse(response.data.data.quizes));
        const quizdata = JSON.parse(response.data.data.quizes);
        const quizseting = JSON.parse(response.data.data.quiz_setting);
        setQuizSetting(quizseting);
        const quiz_basic = JSON.parse(response.data.data.quiz_setting);
        setQuizBasic(quiz_basic);
        console.log("fdsfds", quizseting[0].shuffling_quetions);
        setSufflestatus(
          quizseting[0].shuffling_quetions === "yes" ? true : false,
        );
        setSuffuleans(quizseting[0].shuffling_options);
        setEditableAns(quizseting[0].iseditable);
        setShowonly(quizseting[0].showquestion);
        setQuizDuration(parseInt(quizseting[0].duration));
        for (let index = 0; index < quizdata.length; index++) {
          const element = quizdata[index];
          element.correctAnswer = element.answer;
          element.point = element.points;
          // element.type = "text";
          element.answerSelectionType = "single";
          element.checkradio = index + 1;
          element.messageForCorrectAnswer = "1";
          element.messageForIncorrectAnswer = "1";
          element.explanation = "1";
          console.log("elelemtb", element);
          const answers = [];
          if (element.answers.length > 0) {
            for (let i = 0; i < element.answers.length; i++) {
              console.log("element.answers[i]", element.answers[i]);
              const elementans = element.answers[i];
              answers.push(elementans);
            }
            console.log("quizdata sd", answers);

            element.answers = answers;
          }
        }
        console.log("quizdata", quizdata);

        setAllquizdata({
          quizTitle: "React Quiz Component Demo",
          quizSynopsis:
            "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim",
          questions: quizdata,
        });
        console.log("set all data", quizdata);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });

    if (localStorage.getItem("finish-quizbynet")) {
      localStorage.setItem("finish-quizbynet", true);

      const USER_TOKEN = localStorage.getItem("token");
      console.log("user token", USER_TOKEN);
      const AuthStr = "Bearer ".concat(USER_TOKEN);
      let date1 = moment();
      let date2 = moment(localStorage.getItem("quizStartTime"));
      let takeminuts = date1.diff(date2, "minutes");
      let takeseconds = date1.diff(date2, "seconds");
      let ssminutes = Math.floor(takeseconds / 60);
      let ssseconds = takeseconds - ssminutes * 60;
      if (ssseconds < 10) {
        ssseconds = "0" + ssseconds;
      }
      let finaldata;
      if (localStorage.getItem("userQA") === null) {
        finaldata = localStorage.getItem("suffle");
      } else {
        finaldata = localStorage.getItem("userQA");
      }
      axios({
        method: "post",
        url: mainURL + "/quize",
        data: {
          all_data: finaldata,
          questions_list: localStorage.getItem("suffle"),
          opportunity_id: localStorage.getItem("quiz_id"),
          is_completed: 1,
          start_time: moment(localStorage.getItem("quizStartTime")).format(
            "DD-MM-YYYY hh:mm:ss a",
          ),
          end_time: moment().format("DD-MM-YYYY hh:mm:ss a"),
          total_time: ssminutes + ":" + ssseconds,
          is_quiz: 1,
        },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          // setUploadmsg("Submission has been successfully uploaded");
          localStorage.removeItem("userQA");
          // localStorage.setItem("finish-quiz", true);
          localStorage.removeItem("quizStartTime");
          localStorage.removeItem("suffle");
          localStorage.removeItem("quiz_id");
          localStorage.setItem("finishbtn", true);

          window.location = `/quiz-finish`;
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }

    // if (localStorage.getItem("finish-quiz")) {
    //   // window.location = `/e/HCCB-GET-Electrical-45`;
    //   // return true;
    // } else {
    settimestart(true);

    renderCount += 1;
    console.log("steps 1", userque);
    console.log("step1dd page");
    setSufflestatus(false);
    console.log("myArrayFiltered");
    // if(renderCount === 2)
    // {
    //   quiz = JSON.parse(localStorage.getItem("suffle"))
    //   console.log("steps21" , userque);

    // }
    // }
    // document.oncontextmenu = document.body.oncontextmenu = function() {return false;}
  }, []);

  function quexits(questionname) {
    // quiz.questions.some(item => item.questions)
    for (let index = 0; index < retrievedObject.length; index++) {
      const element = retrievedObject[index];
      if (element.question == questionname) {
        return true;
      } else {
        return false;
      }
    }
  }

  const handleNotification = () => {
    // alert("chenage")
    setNotificationmsg("watchtimeend");
  };

  const handleLeaveMeeting = () => {
    setNotificationmsg("Time end");
    localStorage.setItem("finish-quizbynet", true);

    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    let date1 = moment();
    let date2 = moment(localStorage.getItem("quizStartTime"));
    let takeminuts = date1.diff(date2, "minutes");
    let takeseconds = date1.diff(date2, "seconds");
    let ssminutes = Math.floor(takeseconds / 60);
    let ssseconds = takeseconds - ssminutes * 60;
    if (ssseconds < 10) {
      ssseconds = "0" + ssseconds;
    }
    let finaldata;
    if (localStorage.getItem("userQA") === null) {
      finaldata = localStorage.getItem("suffle");
    } else {
      finaldata = localStorage.getItem("userQA");
    }
    axios({
      method: "post",
      url: mainURL + "/quize",
      data: {
        all_data: finaldata,
        questions_list: localStorage.getItem("suffle"),
        opportunity_id: localStorage.getItem("quiz_id"),
        is_completed: 1,
        start_time: moment(localStorage.getItem("quizStartTime")).format(
          "DD-MM-YYYY hh:mm:ss a",
        ),
        end_time: moment().format("DD-MM-YYYY hh:mm:ss a"),
        total_time: ssminutes + ":" + ssseconds,
        is_quiz: 1,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        // setUploadmsg("Submission has been successfully uploaded");
        localStorage.removeItem("userQA");
        // localStorage.setItem("finish-quiz", true);
        localStorage.removeItem("quizStartTime");
        localStorage.removeItem("suffle");
        localStorage.removeItem("quiz_id");
        localStorage.setItem("finishbtn", true);
        window.location = `/quiz-finish`;
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  function changeQuestion(item) {
    console.log("dd", item);
    // return false;
    setQuestionIndex(item);
    localStorage.setItem("finishbtn", false);
  }
  function finishquiz(params) {
    Swal.fire({
      title: "Do you want to submit your answers and finish quiz?",
      text: "Quiz will be submitted immediately. You can’t undo this action.",
      iconHtml: `<img src=${questionlogo}>`,
      customClass: {
        container: "your-container-class",
        popup: "finish-alert",
        header: "your-header-class",
        title: "your-title-class",
        closeButton: "your-close-button-class",
        icon: "your-icon-class",
        image: "your-image-class",
        content: "your-content-class",
        input: "your-input-class",
        actions: "your-actions-class",
        confirmButton: "your-confirm-button-class",
        cancelButton: "your-cancel-button-class",
        footer: "your-footer-class",
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const USER_TOKEN = localStorage.getItem("token");
        console.log("user token", USER_TOKEN);
        const AuthStr = "Bearer ".concat(USER_TOKEN);
        let date1 = moment();
        let date2 = moment(localStorage.getItem("quizStartTime"));
        let takeminuts = date1.diff(date2, "minutes");
        let takeseconds = date1.diff(date2, "seconds");
        let ssminutes = Math.floor(takeseconds / 60);
        let ssseconds = takeseconds - ssminutes * 60;
        if (ssseconds < 10) {
          ssseconds = "0" + ssseconds;
        }

        let finaldata;
        if (localStorage.getItem("userQA") === null) {
          finaldata = localStorage.getItem("suffle");
        } else {
          finaldata = localStorage.getItem("userQA");
        }
        axios({
          method: "post",
          url: mainURL + "/quize",
          data: {
            all_data: finaldata,
            questions_list: localStorage.getItem("suffle"),
            opportunity_id: localStorage.getItem("quiz_id"),
            round_id: localStorage.getItem("selectedRound"),
            is_completed: 1,
            start_time: moment(localStorage.getItem("quizStartTime")).format(
              "DD-MM-YYYY hh:mm:ss a",
            ),
            end_time: moment().format("DD-MM-YYYY hh:mm:ss a"),
            total_time: ssminutes + ":" + ssseconds,
            is_quiz: 1,
          },
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            // setUploadmsg("Submission has been successfully uploaded");
            localStorage.removeItem("userQA");
            // localStorage.setItem("finish-quiz", true);
            localStorage.removeItem("quizStartTime");
            localStorage.removeItem("suffle");
            localStorage.removeItem("quiz_id");
            localStorage.setItem("finishbtn", true);

            window.location = `/quiz-finish`;
          })
          .catch(function (response) {
            //handle error
            console.log("Error while calling api", response);
          });
      }
    });

    // setEndQuiz(true)
    // props.history.push(`/quiz-detail`)
  }
  const handleCallback = (childData) => {
    // Update the name in the component's state
    console.log("child data", childData);

    setSufflequestion(childData);
  };
  const updateQuestionIndex = (index) => {
    console.log("ttttt111", index);
    quiz.questions = JSON.parse(localStorage.getItem("suffle"));
    setQuestionIndex(index);
  };
  // console.log("check qu" , quiz.questions)

  return (
    <React.Fragment>
      <section className="quizebg">
        <div className="">
          <div className="mainquizdiv">
            <div className="logoquiz">
              <img src="https://www.gradpartners.in/static/media/main_logo.ed6347e1.png" />
            </div>
            <div className="eventtext">
              <h3>{opportunitydata.title}</h3>
            </div>
            <div className="logoquiz1">
              <div className="finishbtndiv text-center">
                <button className="finishquiz" onClick={() => finishquiz()}>
                  Finish Quiz
                </button>
              </div>
              {/* <img src="https://gradimages.s3.amazonaws.com/38/logo_image/d73eb01e-f092-4538-9b1b-8b0eb8e97676.jpeg" /> */}
            </div>
          </div>
          <div className="main-div">
            {notificationmsg && notificationmsg === "Time end" ? (
              <div className=" row">
                <div className="col-sm-12 main-div ">
                  <div className="react-quiz-container-finish">
                    <h1>
                      Your time is over. Thanks for participating in the
                      competition. <br />
                      <br /> All the best for results!
                    </h1>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" row">
                <div className="col-sm-9  right-div">
                  <div className=" bg-white-queiz ">
                    {quiz && (
                      <>
                        {/* {JSON.stringify(quiz)} */}

                        <Quiz
                          quiz={quiz && quiz}
                          quizseting={quizSetting && quizSetting}
                          editableAns={editableAns && editableAns}
                          shuffle={suffulestatus}
                          shuffleans={suffuleans}
                          questionIndex={questionIndex}
                          onUpdateQuestionIndex={updateQuestionIndex}
                          showonly={showonly}
                          timestart={timestart}
                          notificationmsg={notificationmsg}
                          handleNotification={handleNotification}
                          handleLeaveMeeting={handleLeaveMeeting}
                          parentCallback={handleCallback}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="col-sm-3  left-div">
                  <div className="  ">
                    {timestart == true && quizDuration && (
                      <React.Fragment>
                        <p className="watchtime">
                          <div id="timer">
                            <span>
                              <Stopwatch
                                hour="01"
                                min="00"
                                sec="30"
                                hour1="00"
                                min1="10"
                                sec1="00"
                                limits="00:02"
                                hourcheck={1}
                                minutscheck={parseInt(ssminutes)}
                                timeEnd={notificationmsg}
                                secondcheck={parseInt(ssseconds)}
                                // duration={props.history.location.state.id === 44 ? 20 : 15}
                                duration={quizDuration && quizDuration}
                                // message="Meeting will end in 5 minutes"
                                handleNotification={handleNotification}
                                handleLeaveMeeting={handleLeaveMeeting}
                              />
                            </span>
                          </div>
                        </p>
                      </React.Fragment>
                    )}

                    <div className="bg-white-queiz1">
                      {/* <div className="finishbtndiv text-center">
                      <button
                        className="finishquiz"
                        onClick={() => finishquiz()}
                      >
                        Finish Quiz
                      </button>
                    </div>
                    <hr /> */}

                      <h3 className="shownumberq">
                        {localStorage.getItem("suffle") &&
                          JSON.parse(localStorage.getItem("suffle"))
                            .length}{" "}
                        Questions
                      </h3>
                      <hr />
                      <div className="infobtnshow">
                        <span className=" currentquestion mr-3">
                          <i className="fa fa-circle"></i> Answered{" "}
                        </span>{" "}
                        <span className="answered mr-3">
                          <i className="fa fa-circle"></i> Current
                        </span>{" "}
                        <span className="pendingans">
                          <i className="fa fa-circle"></i> To be answered
                        </span>{" "}
                      </div>
                      <div className="text-center">
                        {suffleQuestions.length > 0 &&
                          suffleQuestions.map((item, index) => (
                            <button
                              className={
                                questionIndex === index
                                  ? "btnshowquiz activebtn"
                                  : retrievedObject &&
                                      retrievedObject.some(
                                        (elem) =>
                                          elem.question ===
                                            userque[index]?.question &&
                                          elem.userAnswer != "",
                                      ) === true
                                    ? "btnshowquiz  currentindex"
                                    : "btnshowquiz pendingans"
                              }
                              onClick={() => changeQuestion(index)}
                              value={index}
                            >
                              {index + 1}
                            </button>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Quize;
