import React from "react";
import "../CommonFiles/Header/Header.css";
import { NavLink, useLocation, Link } from "react-router-dom";
import logo from "../../images/main_logo.png";
import { FaUserAlt } from "react-icons/fa";

const UserHeader = (props) => {
  // let location = useLocation();
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  // localStorage.setItem('activestep' , 1)
  const userdata = JSON.parse(localStorage.getItem("alldata"));

  return (
    <div className="header">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/">
            <a href="#">
              <img src={logo} alt="logo" className="logo" />
            </a>
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto menu-right">
              <li
                className={
                  splitLocation[1] === "user" && splitLocation[2] === "hosting"
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="home nav-link" to="/user/hosting/">
                  My Hostings
                </Link>
              </li>
              <li
                className={
                  splitLocation[1] === "user" && splitLocation[2] === ""
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="home nav-link" to="/user/">
                  {" "} My Registrations
                </Link>
              </li>
            </ul>

            <div className="form-inline my-2 my-lg-0 nav-right-social">
              {userdata != null ? (
                <NavLink to="/user/setting">
                  <div className="header__sortname">
                    {userdata.profileimage ? (
                      <img
                        id="file-image"
                        src={userdata.profileimage}
                        alt="Preview"
                      />
                    ) : userdata.first_name ? (
                      <h3>
                        {" "}
                        {userdata.first_name &&
                          userdata.first_name.charAt(0)}{" "}
                        {userdata.last_name && userdata.last_name.charAt(0)}{" "}
                      </h3>
                    ) : (
                      <h3> {userdata.email && userdata.email.charAt(0)} </h3>
                    )}
                  </div>
                </NavLink>
              ) : (
                <NavLink to="/login">
                  <div className="centercls">
                    <FaUserAlt className="iconcls" />
                  </div>
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default UserHeader;
