import React, { useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import config from "../../CommonFiles/config.json";
import Header from "../../CommonFiles/Header";
import { FaArrowLeft, FaArrowRight, FaSave } from "react-icons/fa";
import { Link } from "react-router-dom";
import $ from "jquery";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Footer from "../../CommonFiles/Footer";
import { Tabs, Radio, Tooltip } from "antd";
import { Col, Row, Button, Form, TabContent } from "react-bootstrap";
// import { Editor } from "react-draft-wysiwyg";
import CKEditor from "react-ckeditor-component";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import { stateFromHTML } from "draft-js-import-html";
import "./quizsetting.css";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const { TabPane } = Tabs;
const QuizSettingPage = (props) => {
  const [alldata, setAlldata] = useState();
  const [addQuizstatus, setAddQuizstatus] = useState();
  const [detaillist, setdetail] = useState("no");
  let [tabkey, setTabkey] = useState("");
  let [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [errormsg, setErrormsg] = useState("");
  const [isSetting, setIssetting] = useState("false");
  const userdata = JSON.parse(localStorage.getItem("alldata"));
  const [inputFields, setInputFields] = useState([
    { question: "", answer: "", points: "", answers: [] },
  ]);
  const [basicdetail, setBasicdetail] = useState([
    {
      title: "",
      description: "",
      start_date: "",
      end_date: "",
      full_screen: "",
      end_time: "",
      duration: "",
      total_mark: "",
    },
  ]);
  const [contactFields, setContactFields] = useState([{ choice: "" }]);
  const [setting, setSetting] = useState("");
  const [answers, setAnswers] = useState([]);

  useEffect(async () => {
    try {
      const result = await axios({
        method: "get",
        url: mainURL + "/opportunity/" + props.match.params.id,
      });
      console.log("result", result.data.data);

      if (result.data.data.quiz_basic) {
        let quiz_basic = JSON.parse(result.data.data.quiz_basic);
        setBasicdetail(quiz_basic);
        console.log("quiz_basic.description", quiz_basic[0].description);
        let contentState = stateFromHTML(quiz_basic[0].description);
        console.log(contentState);

        setEditorState(EditorState.createWithContent(contentState));
      }
      if (result.data.data.quiz_setting) {
        let quizsetting = JSON.parse(result.data.data.quiz_setting);
        setSetting(quizsetting);
      }
      if (result.data.data.quizes) {
        let allqueiz = JSON.parse(result.data.data.quizes);
        setInputFields(allqueiz);
      }

      // console.log("rounds", result.data.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    let localroundData = JSON.parse(localStorage.getItem("FormFour"));
    if (localroundData[props.quizIndexval].quiz_setting) {
      setIssetting("true");
      setBasicdetail(
        JSON.parse(localroundData[props.quizIndexval].quiz_setting),
      );
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(basicdetail[0].description),
          ),
        ),
      );
      setdetail("yes");
    }
    console.log("quiz_basic.description 2");
  }, [localStorage.getItem("FormFour")]);
  const clearQuestions = () => {
    setBasicdetail([
      {
        title: "",
        description: "",
        start_date: "",
        end_date: "",
        full_screen: "",
        end_time: "",
        duration: "",
        total_mark: "",
      },
    ]);
  };

  const addbasicdetail = (val, name) => {
    console.log("fdsf", val, name);

    const value = [...basicdetail];

    if (name == "title") {
      value[0].title = val;
    }
    if (name == "duration") {
      value[0].duration = val;
    }
    if (name == "total_mark") {
      value[0].total_mark = val;
    }
    if (name == "quiztype") {
      value[0].question_type = val;
    }
    if (name == "who_can_play") {
      value[0].who_can_play = val;
    }
    if (name == "shuffleque") {
      value[0].shuffling_quetions = val;
    }
    if (name == "shufflequeoption") {
      value[0].shuffling_options = val;
    }
    if (name == "showquestion") {
      value[0].showquestion = val;
    }
    if (name === "total_question") {
      value[0].total_question = val;
    }

    if (name == "iseditable") {
      value[0].iseditable = val;
    }

    if (name == "full_screen") {
      value[0].full_screen = val;
    }
    if (name === "description") {
      value[0].description = val.editor.getData();
      setdetail("yes");
    }
    console.log("basic value", value[0]);
    setBasicdetail(value);
    console.log("basic value 2", basicdetail);
  };

  function saveQuestions(status) {
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    var inputTag = document.querySelectorAll(".quizrequiredfield");
    // var SelectedTag = document.querySelectorAll('.form-body select[className="organisationnam"]');
    if (status === "clear") {
      setBasicdetail([
        {
          title: "",
          description: "",
          start_date: "",
          end_date: "",
          full_screen: "",
          end_time: "",
          duration: "",
          total_mark: "",
        },
      ]);
      return false;
    } else {
      for (var i = 0; i < inputTag.length; i++) {
        console.log("validation check", basicdetail[0]);
        if (inputTag[i].value === "") {
          console.log("input value", inputTag[i].value);
          inputTag[i].setAttribute("id", "quizfill-the-field");
        } else {
          inputTag[i].setAttribute("id", "");
        }

        if (!$("input[name='shorlist']:checked").val()) {
          $("input[name='shorlist']")
            .parent()
            .parent()
            .attr("id", "quizfill-the-field");
        } else {
          $("input[name='shorlist']").parent().parent().attr("id", "");
        }
      }
      if (!$("input[name='full_screen']:checked").val()) {
        $("input[name='full_screen']")
          .parent()
          .parent()
          .attr("id", "quizfill-the-field");
      } else {
        $("input[name='full_screen']").parent().parent().attr("id", "");
      }

      if (!$("input[name='quiztype']:checked").val()) {
        $("input[name='quiztype']")
          .parent()
          .parent()
          .attr("id", "quizfill-the-field");
      } else {
        $("input[name='quiztype']").parent().parent().attr("id", "");
      }

      if (basicdetail[0].description === "") {
        var imageParent = document.getElementsByClassName("cke_reset")[0];
        console.log("user token s", imageParent);

        imageParent.classList.add("nodatafound");
      } else {
        var imageParent = document.getElementsByClassName("cke_reset")[0];
        console.log("user token s", imageParent);
        imageParent.classList.remove("nodatafound");
      }

      if (!$("input[name='who_can_play']:checked").val()) {
        $("input[name='who_can_play']")
          .parent()
          .parent()
          .attr("id", "quizfill-the-field");
      } else {
        $("input[name='who_can_play']").parent().parent().attr("id", "");
      }

      if (!$("input[name='shuffleque']:checked").val()) {
        $("input[name='shuffleque']")
          .parent()
          .parent()
          .attr("id", "quizfill-the-field");
      } else {
        $("input[name='shuffleque']").parent().parent().attr("id", "");
      }
      if (!$("input[name='shufflequeoption']:checked").val()) {
        $("input[name='shufflequeoption']")
          .parent()
          .parent()
          .attr("id", "quizfill-the-field");
      } else {
        $("input[name='shufflequeoption']").parent().parent().attr("id", "");
      }
      if (!$("input[name='iseditable']:checked").val()) {
        $("input[name='iseditable']")
          .parent()
          .parent()
          .attr("id", "quizfill-the-field");
      } else {
        $("input[name='iseditable']").parent().parent().attr("id", "");
      }

      if (
        parseInt(basicdetail[0].total_question) <
        parseInt(basicdetail[0].showquestion)
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Display questions should not be greater than total questions",
        });
        return false;
      }

      var errorexits = document.querySelectorAll("#quizfill-the-field");
      var errorexits2 = document.querySelectorAll(".nodatafound");
      console.log("errorexits", errorexits.length);
      if (errorexits.length > 0 || errorexits2.length > 0) {
        setErrormsg("Please fill in all the details");
        console.log("errorexits error", errormsg);
      } else {
        let roundVal = JSON.parse(localStorage.getItem("FormFour"));
        console.log("errormsg", errormsg);

        for (let i = 0; i < roundVal.length; i++) {
          if (i === props.quizIndexval) {
            roundVal[props.quizIndexval].quiz_setting =
              JSON.stringify(basicdetail);
          }
        }
        localStorage.setItem("FormFour", JSON.stringify(roundVal));
        axios({
          method: "post",
          url: mainURL + "/opportunity/round",
          data: {
            rounds: roundVal,
            opportunity_id: localStorage.getItem("editopportunityid"),
          },
          headers: { Authorization: AuthStr },
        })
          .then((response) => {
            // const alldata = response.data.data;
            // console.log("add new round" , alldata)
          })
          .catch(function (response) {
            //handle error
            console.log("Error while calling api", response);
          });
        if (status === "continue") {
          localStorage.setItem("quizStep", 2);
          props.newActiveStepValue(2);
        } else {
          Swal.fire("Good job!", "Quiz settings have been updated", "success");
        }
      }
    }
  }

  return (
    <section className="quizsettingsection">
      <div id="user-wrapper" className="dashboard form addquizsection topmargin">
        <div className="row form-body">
          <Form className="quizform">
            <Form.Row>
              <Col
                className="form-group-col"
                md={12}
                controlId="oppotunityStartDate"
              >
                <Form.Label className="input-titlle">
                  {tabkey}
                  Quiz Title <span className="importantfield">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ex. Quiz Name"
                  className="quizrequiredfield"
                  name="title"
                  defaultValue={basicdetail[0].title}
                  onChange={(e) =>
                    addbasicdetail(e.target.value, e.target.name)
                  }
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Form.Label className="input-titlle">
                Instructions for participants{" "}
                <span className="importantfield">*</span>
              </Form.Label>
              <Col
                className="form-group-col"
                md={12}
                controlId="oppotunityStartDate"
              >
                <CKEditor
                  activeClass="p10"
                  content={basicdetail[0].description}
                  id="cke_editor1"
                  // defaultValue={this.state.aboutOpportunity}
                  events={{
                    change: (e) => addbasicdetail(e, "description"),
                  }}
                />
                {/* <Editor
                  className="quizrequiredfield"
                  id="quizrequiredfielddata"
                  content
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={onEditorStateChange}
                /> */}
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                className="form-group-col"
                md={6}
                controlId="oppotunityStartDate"
              >
                <Form.Label className="input-titlle">
                  Total Questions <span className="importantfield">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="quizrequiredfield"
                  placeholder="Enter number of questions"
                  name="total_question"
                  // defaultValue={inputField.name}
                  defaultValue={basicdetail[0].total_question}
                  onChange={(e) =>
                    addbasicdetail(e.target.value, e.target.name)
                  }
                />
              </Col>
              <Col
                className="form-group-col"
                md={6}
                controlId="oppotunityStartDate"
              >
                <Form.Label className="input-titlle">
                  How many questions do you want to display?{" "}
                  <span className="importantfield">*</span>{" "}
                  <Tooltip
                    placement="rightTop"
                    title={
                      "Equal to or less than the total number of questions"
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter number of questions"
                  name="showquestion"
                  className="quizrequiredfield"
                  // defaultValue={inputField.name}
                  defaultValue={basicdetail[0].showquestion}
                  onChange={(e) =>
                    addbasicdetail(e.target.value, e.target.name)
                  }
                />
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                className="form-group-col"
                md={6}
                controlId="oppotunityStartDate"
              >
                <Form.Label className="input-titlle">
                  Quiz Duration <span className="importantfield">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter quiz duration"
                  name="duration"
                  className="quizrequiredfield"
                  // defaultValue={inputField.name}
                  defaultValue={basicdetail[0].duration}
                  onChange={(e) =>
                    addbasicdetail(e.target.value, e.target.name)
                  }
                />
              </Col>
              {/* <Col
                className="form-group-col"
                md={6}
                controlId="oppotunityStartDate"
              >
                <Form.Label className="input-titlle">
                  Total marks <span className="importantfield">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter marks"
                  name="total_mark"
                  className="quizrequiredfield"
                  // defaultValue={inputField.name}
                  defaultValue={basicdetail[0].total_mark}
                  onChange={(e) =>
                    addbasicdetail(e.target.value, e.target.name)
                  }
                />
              </Col> */}
              <Col
                className="form-group-col"
                md={6}
                controlId="oppotunityEndTime"
              >
                <Form.Label className="input-titlle">
                  Can participants edit their answers?{" "}
                  <span className="importantfield">*</span>
                </Form.Label>
                <div
                  className="form-control radiocss quizrequiredfield"
                  name="iseditable"
                  onChange={(e) =>
                    addbasicdetail(e.target.value, e.target.name)
                  }
                >
                  <Form.Check
                    type="radio"
                    value="yes"
                    label="Yes"
                    name="iseditable"
                    checked={
                      basicdetail[0].iseditable === null
                        ? null
                        : basicdetail[0].iseditable === "yes"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="no"
                    label="No"
                    name="iseditable"
                    checked={
                      basicdetail[0].iseditable === null
                        ? null
                        : basicdetail[0].iseditable === "no"
                    }
                  />
                </div>
                {/* <Form.Text className=" checkerror">{error.quiztype}</Form.Text>/ */}
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                className="form-group-col"
                md={6}
                controlId="oppotunityEndTime"
              >
                <Form.Label className="input-titlle">
                  Conduct Quiz in Full Screen mode?{" "}
                  <span className="importantfield">*</span>
                </Form.Label>
                <div
                  className="form-control radiocss quizrequiredfield"
                  name="full_screen"
                  onChange={(e) =>
                    addbasicdetail(e.target.value, e.target.name)
                  }
                >
                  <Form.Check
                    type="radio"
                    value="yes"
                    label="Yes"
                    name="full_screen"
                    checked={
                      basicdetail[0].full_screen &&
                      basicdetail[0].full_screen === "yes"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="no"
                    label="No"
                    name="full_screen"
                    checked={
                      basicdetail[0].full_screen &&
                      basicdetail[0].full_screen === "no"
                    }
                  />
                </div>
                {/* <Form.Text className=" checkerror">{error.quiztype}</Form.Text>/ */}
              </Col>

              <Col
                className="form-group-col"
                md={6}
                controlId="oppotunityEndTime"
              >
                <Form.Label className="input-titlle">
                  Who can play? <span className="importantfield">*</span>
                </Form.Label>
                <div
                  className="form-control radiocss quizrequiredfield"
                  name="who_can_play"
                  onChange={(e) =>
                    addbasicdetail(e.target.value, e.target.name)
                  }
                >
                  <Form.Check
                    type="radio"
                    value="Everyone"
                    label="Everyone"
                    name="who_can_play"
                    checked={
                      basicdetail[0].who_can_play === null
                        ? null
                        : basicdetail[0].who_can_play === "Everyone"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="Leader"
                    label="Leader"
                    name="who_can_play"
                    checked={
                      basicdetail[0].who_can_play === null
                        ? null
                        : basicdetail[0].who_can_play === "Leader"
                    }
                  />
                </div>
                {/* <Form.Text className=" checkerror">{error.quiztype}</Form.Text>/ */}
              </Col>
            </Form.Row>
            <Form.Row>
              <Col
                className="form-group-col"
                md={6}
                controlId="oppotunityEndTime"
              >
                <Form.Label className="input-titlle">
                  Shuffling of Questions{" "}
                  <span className="importantfield">*</span>
                </Form.Label>
                <div
                  className="form-control radiocss"
                  name="shuffleque"
                  onChange={(e) =>
                    addbasicdetail(e.target.value, e.target.name)
                  }
                >
                  <Form.Check
                    type="radio"
                    value="yes"
                    label="Yes"
                    name="shuffleque"
                    checked={
                      basicdetail[0].shuffling_quetions === null
                        ? null
                        : basicdetail[0].shuffling_quetions === "yes"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="no"
                    label="No"
                    name="shuffleque"
                    checked={
                      basicdetail[0].shuffling_quetions === null
                        ? null
                        : basicdetail[0].shuffling_quetions === "no"
                    }
                  />
                </div>
                {/* <Form.Text className=" checkerror">{error.quiztype}</Form.Text>/ */}
              </Col>

              <Col
                className="form-group-col"
                md={6}
                controlId="oppotunityEndTime"
              >
                <Form.Label className="input-titlle">
                  Shuffling of Options <span className="importantfield">*</span>
                </Form.Label>
                <div
                  className="form-control radiocss"
                  name="shufflequeoption"
                  onChange={(e) =>
                    addbasicdetail(e.target.value, e.target.name)
                  }
                >
                  <Form.Check
                    type="radio"
                    value="yes"
                    label="Yes"
                    name="shufflequeoption"
                    checked={
                      basicdetail[0].shuffling_options === null
                        ? null
                        : basicdetail[0].shuffling_options === "yes"
                    }
                  />
                  <Form.Check
                    type="radio"
                    value="no"
                    label="No"
                    name="shufflequeoption"
                    checked={
                      basicdetail[0].shuffling_options === null
                        ? null
                        : basicdetail[0].shuffling_options === "no"
                    }
                  />
                </div>
                {/* <Form.Text className=" checkerror">{error.quiztype}</Form.Text>/ */}
              </Col>
            </Form.Row>
            <Form.Row></Form.Row>
          </Form>
          {errormsg && <p className="errormsg">{errormsg}</p>}
        </div>
        <hr />
      </div>
      <div className="hosting_bottom">
        <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => saveQuestions("continue")}
        >
          Next <FaArrowRight />
        </Button>

        {isSetting === "true" && (
          <Button
            className="save-hosting-btn"
            type="button"
            onClick={() => saveQuestions("save")}
          >
            Save <FaSave />
          </Button>
        )}
        {/* <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => saveQuestions("clear")}
        >
          Clear <FaArrowRight />
        </Button> */}
      </div>
    </section>
  );
};

export default QuizSettingPage;
