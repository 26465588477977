export const CountryData = [
  {
    Country: "Afghanistan",
    CountryCode: 93,
  },
  {
    Country: "Albania",
    CountryCode: 355,
  },
  {
    Country: "Algeria",
    CountryCode: 213,
  },
  {
    Country: "American Samoa",
    CountryCode: "1-684",
  },
  {
    Country: "Andorra",
    CountryCode: 376,
  },
  {
    Country: "Angola",
    CountryCode: 244,
  },
  {
    Country: "Anguilla",
    CountryCode: "1-264",
  },
  {
    Country: "Antarctica",
    CountryCode: 672,
  },
  {
    Country: "Antigua and Barbuda",
    CountryCode: "1-268",
  },
  {
    Country: "Argentina",
    CountryCode: 54,
  },
  {
    Country: "Armenia",
    CountryCode: 374,
  },
  {
    Country: "Aruba",
    CountryCode: 297,
  },
  {
    Country: "Australia",
    CountryCode: 61,
  },
  {
    Country: "Austria",
    CountryCode: 43,
  },
  {
    Country: "Azerbaijan",
    CountryCode: 994,
  },
  {
    Country: "Bahamas",
    CountryCode: "1-242",
  },
  {
    Country: "Bahrain",
    CountryCode: 973,
  },
  {
    Country: "Bangladesh",
    CountryCode: 880,
  },
  {
    Country: "Barbados",
    CountryCode: "1-246",
  },
  {
    Country: "Belarus",
    CountryCode: 375,
  },
  {
    Country: "Belgium",
    CountryCode: 32,
  },
  {
    Country: "Belize",
    CountryCode: 501,
  },
  {
    Country: "Benin",
    CountryCode: 229,
  },
  {
    Country: "Bermuda",
    CountryCode: "1-441",
  },
  {
    Country: "Bhutan",
    CountryCode: 975,
  },
  {
    Country: "Bolivia",
    CountryCode: 591,
  },
  {
    Country: "Bosnia and Herzegovina",
    CountryCode: 387,
  },
  {
    Country: "Botswana",
    CountryCode: 267,
  },
  {
    Country: "Brazil",
    CountryCode: 55,
  },
  {
    Country: "British Indian Ocean Territory",
    CountryCode: 246,
  },
  {
    Country: "British Virgin Islands",
    CountryCode: "1-284",
  },
  {
    Country: "Brunei",
    CountryCode: 673,
  },
  {
    Country: "Bulgaria",
    CountryCode: 359,
  },
  {
    Country: "Burkina Faso",
    CountryCode: 226,
  },
  {
    Country: "Burundi",
    CountryCode: 257,
  },
  {
    Country: "Cambodia",
    CountryCode: 855,
  },
  {
    Country: "Cameroon",
    CountryCode: 237,
  },
  {
    Country: "Canada",
    CountryCode: 1,
  },
  {
    Country: "Cape Verde",
    CountryCode: 238,
  },
  {
    Country: "Cayman Islands",
    CountryCode: "1-345",
  },
  {
    Country: "Central African Republic",
    CountryCode: 236,
  },
  {
    Country: "Chad",
    CountryCode: 235,
  },
  {
    Country: "Chile",
    CountryCode: 56,
  },
  {
    Country: "China",
    CountryCode: 86,
  },
  {
    Country: "Christmas Island",
    CountryCode: 61,
  },
  {
    Country: "Cocos Islands",
    CountryCode: 61,
  },
  {
    Country: "Colombia",
    CountryCode: 57,
  },
  {
    Country: "Comoros",
    CountryCode: 269,
  },
  {
    Country: "Cook Islands",
    CountryCode: 682,
  },
  {
    Country: "Costa Rica",
    CountryCode: 506,
  },
  {
    Country: "Croatia",
    CountryCode: 385,
  },
  {
    Country: "Cuba",
    CountryCode: 53,
  },
  {
    Country: "Curacao",
    CountryCode: 599,
  },
  {
    Country: "Cyprus",
    CountryCode: 357,
  },
  {
    Country: "Czech Republic",
    CountryCode: 420,
  },
  {
    Country: "Democratic Republic of the Congo",
    CountryCode: 243,
  },
  {
    Country: "Denmark",
    CountryCode: 45,
  },
  {
    Country: "Djibouti",
    CountryCode: 253,
  },
  {
    Country: "Dominica",
    CountryCode: "1-767",
  },
  {
    Country: "Dominican Republic",
    CountryCode: "1-809",
  },
  {
    Country: "Dominican Republic",
    CountryCode: "1-829",
  },
  {
    Country: "Dominican Republic",
    CountryCode: "1-849",
  },
  {
    Country: "East Timor",
    CountryCode: 670,
  },
  {
    Country: "Ecuador",
    CountryCode: 593,
  },
  {
    Country: "Egypt",
    CountryCode: 20,
  },
  {
    Country: "El Salvador",
    CountryCode: 503,
  },
  {
    Country: "Equatorial Guinea",
    CountryCode: 240,
  },
  {
    Country: "Eritrea",
    CountryCode: 291,
  },
  {
    Country: "Estonia",
    CountryCode: 372,
  },
  {
    Country: "Ethiopia",
    CountryCode: 251,
  },
  {
    Country: "Falkland Islands",
    CountryCode: 500,
  },
  {
    Country: "Faroe Islands",
    CountryCode: 298,
  },
  {
    Country: "Fiji",
    CountryCode: 679,
  },
  {
    Country: "Finland",
    CountryCode: 358,
  },
  {
    Country: "France",
    CountryCode: 33,
  },
  {
    Country: "French Polynesia",
    CountryCode: 689,
  },
  {
    Country: "Gabon",
    CountryCode: 241,
  },
  {
    Country: "Gambia",
    CountryCode: 220,
  },
  {
    Country: "Georgia",
    CountryCode: 995,
  },
  {
    Country: "Germany",
    CountryCode: 49,
  },
  {
    Country: "Ghana",
    CountryCode: 233,
  },
  {
    Country: "Gibraltar",
    CountryCode: 350,
  },
  {
    Country: "Greece",
    CountryCode: 30,
  },
  {
    Country: "Greenland",
    CountryCode: 299,
  },
  {
    Country: "Grenada",
    CountryCode: "1-473",
  },
  {
    Country: "Guam",
    CountryCode: "1-671",
  },
  {
    Country: "Guatemala",
    CountryCode: 502,
  },
  {
    Country: "Guernsey",
    CountryCode: "44-1481",
  },
  {
    Country: "Guinea",
    CountryCode: 224,
  },
  {
    Country: "Guinea-Bissau",
    CountryCode: 245,
  },
  {
    Country: "Guyana",
    CountryCode: 592,
  },
  {
    Country: "Haiti",
    CountryCode: 509,
  },
  {
    Country: "Honduras",
    CountryCode: 504,
  },
  {
    Country: "Hong Kong",
    CountryCode: 852,
  },
  {
    Country: "Hungary",
    CountryCode: 36,
  },
  {
    Country: "Iceland",
    CountryCode: 354,
  },
  {
    Country: "India",
    CountryCode: 91,
  },
  {
    Country: "Indonesia",
    CountryCode: 62,
  },
  {
    Country: "Iran",
    CountryCode: 98,
  },
  {
    Country: "Iraq",
    CountryCode: 964,
  },
  {
    Country: "Ireland",
    CountryCode: 353,
  },
  {
    Country: "Isle of Man",
    CountryCode: "44-1624",
  },
  {
    Country: "Israel",
    CountryCode: 972,
  },
  {
    Country: "Italy",
    CountryCode: 39,
  },
  {
    Country: "Ivory Coast",
    CountryCode: 225,
  },
  {
    Country: "Jamaica",
    CountryCode: "1-876",
  },
  {
    Country: "Japan",
    CountryCode: 81,
  },
  {
    Country: "Jersey",
    CountryCode: "44-1534",
  },
  {
    Country: "Jordan",
    CountryCode: 962,
  },
  {
    Country: "Kazakhstan",
    CountryCode: 7,
  },
  {
    Country: "Kenya",
    CountryCode: 254,
  },
  {
    Country: "Kiribati",
    CountryCode: 686,
  },
  {
    Country: "Kosovo",
    CountryCode: 383,
  },
  {
    Country: "Kuwait",
    CountryCode: 965,
  },
  {
    Country: "Kyrgyzstan",
    CountryCode: 996,
  },
  {
    Country: "Laos",
    CountryCode: 856,
  },
  {
    Country: "Latvia",
    CountryCode: 371,
  },
  {
    Country: "Lebanon",
    CountryCode: 961,
  },
  {
    Country: "Lesotho",
    CountryCode: 266,
  },
  {
    Country: "Liberia",
    CountryCode: 231,
  },
  {
    Country: "Libya",
    CountryCode: 218,
  },
  {
    Country: "Liechtenstein",
    CountryCode: 423,
  },
  {
    Country: "Lithuania",
    CountryCode: 370,
  },
  {
    Country: "Luxembourg",
    CountryCode: 352,
  },
  {
    Country: "Macau",
    CountryCode: 853,
  },
  {
    Country: "Macedonia",
    CountryCode: 389,
  },
  {
    Country: "Madagascar",
    CountryCode: 261,
  },
  {
    Country: "Malawi",
    CountryCode: 265,
  },
  {
    Country: "Malaysia",
    CountryCode: 60,
  },
  {
    Country: "Maldives",
    CountryCode: 960,
  },
  {
    Country: "Mali",
    CountryCode: 223,
  },
  {
    Country: "Malta",
    CountryCode: 356,
  },
  {
    Country: "Marshall Islands",
    CountryCode: 692,
  },
  {
    Country: "Mauritania",
    CountryCode: 222,
  },
  {
    Country: "Mauritius",
    CountryCode: 230,
  },
  {
    Country: "Mayotte",
    CountryCode: 262,
  },
  {
    Country: "Mexico",
    CountryCode: 52,
  },
  {
    Country: "Micronesia",
    CountryCode: 691,
  },
  {
    Country: "Moldova",
    CountryCode: 373,
  },
  {
    Country: "Monaco",
    CountryCode: 377,
  },
  {
    Country: "Mongolia",
    CountryCode: 976,
  },
  {
    Country: "Montenegro",
    CountryCode: 382,
  },
  {
    Country: "Montserrat",
    CountryCode: "1-664",
  },
  {
    Country: "Morocco",
    CountryCode: 212,
  },
  {
    Country: "Mozambique",
    CountryCode: 258,
  },
  {
    Country: "Myanmar",
    CountryCode: 95,
  },
  {
    Country: "Namibia",
    CountryCode: 264,
  },
  {
    Country: "Nauru",
    CountryCode: 674,
  },
  {
    Country: "Nepal",
    CountryCode: 977,
  },
  {
    Country: "Netherlands",
    CountryCode: 31,
  },
  {
    Country: "Netherlands Antilles",
    CountryCode: 599,
  },
  {
    Country: "New Caledonia",
    CountryCode: 687,
  },
  {
    Country: "New Zealand",
    CountryCode: 64,
  },
  {
    Country: "Nicaragua",
    CountryCode: 505,
  },
  {
    Country: "Niger",
    CountryCode: 227,
  },
  {
    Country: "Nigeria",
    CountryCode: 234,
  },
  {
    Country: "Niue",
    CountryCode: 683,
  },
  {
    Country: "North Korea",
    CountryCode: 850,
  },
  {
    Country: "Northern Mariana Islands",
    CountryCode: "1-670",
  },
  {
    Country: "Norway",
    CountryCode: 47,
  },
  {
    Country: "Oman",
    CountryCode: 968,
  },
  {
    Country: "Pakistan",
    CountryCode: 92,
  },
  {
    Country: "Palau",
    CountryCode: 680,
  },
  {
    Country: "Palestine",
    CountryCode: 970,
  },
  {
    Country: "Panama",
    CountryCode: 507,
  },
  {
    Country: "Papua New Guinea",
    CountryCode: 675,
  },
  {
    Country: "Paraguay",
    CountryCode: 595,
  },
  {
    Country: "Peru",
    CountryCode: 51,
  },
  {
    Country: "Philippines",
    CountryCode: 63,
  },
  {
    Country: "Pitcairn",
    CountryCode: 64,
  },
  {
    Country: "Poland",
    CountryCode: 48,
  },
  {
    Country: "Portugal",
    CountryCode: 351,
  },
  {
    Country: "Puerto Rico",
    CountryCode: "1-787",
  },
  {
    Country: "Puerto Rico",
    CountryCode: "1-939",
  },
  {
    Country: "Qatar",
    CountryCode: 974,
  },
  {
    Country: "Republic of the Congo",
    CountryCode: 242,
  },
  {
    Country: "Reunion",
    CountryCode: 262,
  },
  {
    Country: "Romania",
    CountryCode: 40,
  },
  {
    Country: "Russia",
    CountryCode: 7,
  },
  {
    Country: "Rwanda",
    CountryCode: 250,
  },
  {
    Country: "Saint Barthelemy",
    CountryCode: 590,
  },
  {
    Country: "Saint Helena",
    CountryCode: 290,
  },
  {
    Country: "Saint Kitts and Nevis",
    CountryCode: "1-869",
  },
  {
    Country: "Saint Lucia",
    CountryCode: "1-758",
  },
  {
    Country: "Saint Martin",
    CountryCode: 590,
  },
  {
    Country: "Saint Pierre and Miquelon",
    CountryCode: 508,
  },
  {
    Country: "Saint Vincent and the Grenadines",
    CountryCode: "1-784",
  },
  {
    Country: "Samoa",
    CountryCode: 685,
  },
  {
    Country: "San Marino",
    CountryCode: 378,
  },
  {
    Country: "Sao Tome and Principe",
    CountryCode: 239,
  },
  {
    Country: "Saudi Arabia",
    CountryCode: 966,
  },
  {
    Country: "Senegal",
    CountryCode: 221,
  },
  {
    Country: "Serbia",
    CountryCode: 381,
  },
  {
    Country: "Seychelles",
    CountryCode: 248,
  },
  {
    Country: "Sierra Leone",
    CountryCode: 232,
  },
  {
    Country: "Singapore",
    CountryCode: 65,
  },
  {
    Country: "Sint Maarten",
    CountryCode: "1-721",
  },
  {
    Country: "Slovakia",
    CountryCode: 421,
  },
  {
    Country: "Slovenia",
    CountryCode: 386,
  },
  {
    Country: "Solomon Islands",
    CountryCode: 677,
  },
  {
    Country: "Somalia",
    CountryCode: 252,
  },
  {
    Country: "South Africa",
    CountryCode: 27,
  },
  {
    Country: "South Korea",
    CountryCode: 82,
  },
  {
    Country: "South Sudan",
    CountryCode: 211,
  },
  {
    Country: "Spain",
    CountryCode: 34,
  },
  {
    Country: "Sri Lanka",
    CountryCode: 94,
  },
  {
    Country: "Sudan",
    CountryCode: 249,
  },
  {
    Country: "Suriname",
    CountryCode: 597,
  },
  {
    Country: "Svalbard and Jan Mayen",
    CountryCode: 47,
  },
  {
    Country: "Swaziland",
    CountryCode: 268,
  },
  {
    Country: "Sweden",
    CountryCode: 46,
  },
  {
    Country: "Switzerland",
    CountryCode: 41,
  },
  {
    Country: "Syria",
    CountryCode: 963,
  },
  {
    Country: "Taiwan",
    CountryCode: 886,
  },
  {
    Country: "Tajikistan",
    CountryCode: 992,
  },
  {
    Country: "Tanzania",
    CountryCode: 255,
  },
  {
    Country: "Thailand",
    CountryCode: 66,
  },
  {
    Country: "Togo",
    CountryCode: 228,
  },
  {
    Country: "Tokelau",
    CountryCode: 690,
  },
  {
    Country: "Tonga",
    CountryCode: 676,
  },
  {
    Country: "Trinidad and Tobago",
    CountryCode: "1-868",
  },
  {
    Country: "Tunisia",
    CountryCode: 216,
  },
  {
    Country: "Turkey",
    CountryCode: 90,
  },
  {
    Country: "Turkmenistan",
    CountryCode: 993,
  },
  {
    Country: "Turks and Caicos Islands",
    CountryCode: "1-649",
  },
  {
    Country: "Tuvalu",
    CountryCode: 688,
  },
  {
    Country: "U.S. Virgin Islands",
    CountryCode: "1-340",
  },
  {
    Country: "Uganda",
    CountryCode: 256,
  },
  {
    Country: "Ukraine",
    CountryCode: 380,
  },
  {
    Country: "United Arab Emirates",
    CountryCode: 971,
  },
  {
    Country: "United Kingdom",
    CountryCode: 44,
  },
  {
    Country: "United States",
    CountryCode: 1,
  },
  {
    Country: "Uruguay",
    CountryCode: 598,
  },
  {
    Country: "Uzbekistan",
    CountryCode: 998,
  },
  {
    Country: "Vanuatu",
    CountryCode: 678,
  },
  {
    Country: "Vatican",
    CountryCode: 379,
  },
  {
    Country: "Venezuela",
    CountryCode: 58,
  },
  {
    Country: "Vietnam",
    CountryCode: 84,
  },
  {
    Country: "Wallis and Futuna",
    CountryCode: 681,
  },
  {
    Country: "Western Sahara",
    CountryCode: 212,
  },
  {
    Country: "Yemen",
    CountryCode: 967,
  },
  {
    Country: "Zambia",
    CountryCode: 260,
  },
  {
    Country: "Zimbabwe",
    CountryCode: 263,
  },
];
