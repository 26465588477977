import React, { useEffect, useState } from "react";
import LeftSideBar from "./UserLeftSideBar";
import RightSidebar from "./UserRightSidebar";
import axios from "axios";
import config from "../CommonFiles/config.json";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import Moment from "react-moment";
import moment from "moment-timezone";
import logo from "../../images/Logo-Karpediem (1).png";
import { Link } from "react-router-dom";
import $ from "jquery";
import Swal from "sweetalert2";

import { NavLink } from "react-router-dom";

import { Popconfirm, message, Radio, Tabs, Dropdown, Space } from "antd";
import Select from "react-select";
import { FaRegCalendarAlt } from "react-icons/fa";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const Userhosting = (props) => {
  const { TabPane } = Tabs;
  const [alldata, setAlldata] = useState([]);
  const [tempData, setTempData] = useState();
  const [sortData, setSortByData] = useState([]);
  const [filterdata, setFilterData] = useState("all");
  const [sortbyvalue, setSortByValue] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [registrationDeadline, setRegistrationDeadline] = useState();
  const [teamdetail, setTeamdetail] = useState();
  const [selectedtab, setSelctedtab] = useState(1);
  const [userdata, setUserdata] = useState(
    JSON.parse(localStorage.getItem("alldata"))
  );

  const USER_TOKEN = localStorage.getItem("token");
  console.log("user token", USER_TOKEN);
  const AuthStr = "Bearer ".concat(USER_TOKEN);
  const data = [
    {
      value: 0,
      text: "All",
      icon: <i className="fa fa-bars"></i>,
    },
    {
      value: 1,
      text: "Live",
      icon: <i className="fa fa-circle"></i>,
    },
    {
      value: 2,
      text: "Closed",
      icon: <i className="fa fa-lock"></i>,
    },
  ];

  const sortby = [
    {
      value: 0,
      text: "Alphabetically",
      icon: <i className="fa fa-bars"></i>,
    },
    {
      value: 1,
      text: "Opportunity Deadline",
      icon: <i className="fa fa-circle"></i>,
    },
  ];
  useEffect(async () => {
    // const USER_TOKEN = localStorage.getItem("token");
    // console.log("user token", USER_TOKEN);
    // const AuthStr = "Bearer ".concat(USER_TOKEN);
    // const tokencheck = localStorage.getItem("token")
    localStorage.removeItem("editopportunityid");
    localStorage.removeItem("activestep");
    console.log(
      "moment(''2020-01-02'').diff(moment('2020-01-03' , 'days'))",
      moment("2021-07-16T00:00:00.000Z").diff(moment(new Date(), "days"))
    );

    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      getOpportunity();
    }
  }, []);

  const getOpportunity = () => {
    axios({
      method: "get",
      url: mainURL + "/profile/opportunities",
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldatas = response.data.data.rows;
        setTeamdetail(response.data.teamlead);
        setAlldata(alldatas);
        setTempData(alldatas);
        console.info("all analytics", alldatas);
      })
      .catch(function (error) {
        //handle error
        if (error?.response?.data?.auth === false) {
          Swal.fire({
            title: "Your session has expired, please login again!",
            icon: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            allowOutsideClick: false,
            cancelButtonColor: "#d33",
            confirmButtonText: "Continue",
          }).then((result) => {
            if (result.isConfirmed) {
              let slugName = localStorage.getItem("eventslug");
              localStorage.clear();
              window.location.href = "/";
            }
          });
        }
        console.log("Error while calling api", error.response);
      });
  };

  // function confirm(id) {
  //   console.log(id);
  //   // message.success('Click on Yes');
  //   const USER_TOKEN = localStorage.getItem("token");
  //   console.log("user token", USER_TOKEN);
  //   const AuthStr = "Bearer ".concat(USER_TOKEN);
  //   axios({
  //     method: "post",
  //     url: mainURL + "/profile/cancel/registered/opportunities",
  //     data: {
  //       user_id: userdata.id,
  //       opportunity_id: id,
  //     },
  //   })
  //     .then((response) => {
  //       axios({
  //         method: "get",
  //         url: mainURL + "/profile/registered/opportunities",
  //         headers: { Authorization: AuthStr },
  //       })
  //         .then((response) => {
  //           const alldatas = response.data.data;
  //           setTeamdetail(response.data.teamlead);
  //           setAlldata(alldatas);
  //           console.info("all analytics", alldata);
  //         })
  //         .catch(function (response) {
  //           //handle error
  //           console.log("Error while calling api", response);
  //         });
  //     })
  //     .catch(function (response) {
  //       //handle error
  //       console.log("Error while calling api", response);
  //     });
  // }

  function cancel(e) {
    console.log(e);
    // message.error('Click on No');
  }
  function selectetab(status) {
    setSelctedtab(status);
    setSearchValue("");
    console.log("status show", status, registrationDeadline);
    if (registrationDeadline === 0) {
      setAlldata(tempData);
    }
  }

  const finalfilterdata = (e, status) => {
    console.log("filster data ", e, status);
    let statusoflisting;
    let sortof;
    let searchExist;
    if (status === "searchData") {
      setSearchValue(e);
      statusoflisting =
        typeof registrationDeadline != "undefined"
          ? "&status=" + registrationDeadline
          : "";
      sortof =
        typeof sortbyvalue !== "undefined" ? "&sortby=" + sortbyvalue : "";
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/opportunities?search=" +
          e +
          statusoflisting +
          sortof,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data.rows;
          setTeamdetail(response.data.teamlead);
          setAlldata(alldatas);
          setTempData(alldatas);
          console.info("all analytics", alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }

    if (status === "statusoflisting") {
      searchExist = searchValue != "" ? "&search=" + searchValue : "";
      sortof =
        typeof sortbyvalue !== "undefined" ? "&sortby=" + sortbyvalue : "";
      setRegistrationDeadline(e);
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/opportunities?" +
          searchExist +
          "&status=" +
          e +
          sortof,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data.rows;
          setTeamdetail(response.data.teamlead);
          setAlldata(alldatas);
          setTempData(alldatas);
          console.info("all analytics", alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
    if (status === "sortby") {
      searchExist = searchValue != "" ? "&search=" + searchValue : "";
      statusoflisting =
        typeof registrationDeadline != "undefined"
          ? "&status=" + registrationDeadline
          : "";

      setSortByValue(e);
      axios({
        method: "get",
        url:
          mainURL +
          "/profile/opportunities?" +
          searchExist +
          statusoflisting +
          "&sortby=" +
          e,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldatas = response.data.data.rows;
          setTeamdetail(response.data.teamlead);
          setAlldata(alldatas);
          setTempData(alldatas);
          console.info("all analytics", alldatas);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  };
  const searchRegistrations = (e) => {
    setSearchValue(e);
    if (registrationDeadline != 0) {
      handleRegistrationDeadline(registrationDeadline);
    }
    if (sortbyvalue != 0) {
      sortbyChange(sortbyvalue);
    }
    // setsor

    const searchList = alldata.filter((item) => {
      return item.title.toLowerCase().indexOf(e.toLowerCase()) !== -1;
    });

    // searchList =

    setAlldata(searchList);

    console.log("step 1", alldata, tempData, searchList);
  };

  // const finalfilterdata = (searchFilter , sortkey) =>{

  //   alldata.filter(function(v, i) {
  //     return (v["term_id"] == 5  && v.type == "car");
  //   })

  //   const finalsort  = alldata.filter(searchFilter)
  //   if(sortkey)
  //   {
  //     finalsort.sort(sortkey)

  //   }

  //   return finalSort

  // }
  const handleRegistrationDeadline = (e) => {
    console.log("change opetion", e, searchValue, alldata);

    setRegistrationDeadline(e);
    const filterdata = [];
    let searchArray;
    if (searchValue != "") {
      searchRegistrations(searchValue);
    }
    if (sortbyvalue != 0) {
      sortbyChange(sortbyvalue);
    }
    for (let index = 0; index < alldata.length; index++) {
      const element = alldata[index];
      console.log(
        moment(element.register_end_date).diff(moment(new Date()), "days"),
        "date chek",
        e.value
      );
      if (e === 2) {
        if (
          moment(element.register_end_date).diff(moment(new Date()), "days") <=
          0
        ) {
          filterdata.push(element);
        }
      } else if (e === 1) {
        if (
          moment(element.register_end_date).diff(moment(new Date()), "days") > 0
        ) {
          filterdata.push(element);
        }
      }
      // else if (e === 0) {
      //   filterdata.push(element);
      // }
    }

    setAlldata(filterdata);

    console.log("step 2", alldata);
  };

  const gotoEdithosting = (hosting_id) => {
    props.history.push({
      pathname: "/manage/opportunity",
      state: hosting_id,
    });
    localStorage.setItem("editopportunityid", hosting_id);
    localStorage.setItem("opportunityfrom", "editopp");
  };

  const gotohosting = () => {
    localStorage.removeItem("FormFive");
    localStorage.removeItem("FormSix");
    localStorage.removeItem("FormFour");
    localStorage.removeItem("FormOne");
    localStorage.removeItem("FormTwo");
    localStorage.removeItem("FormThree");
    localStorage.removeItem("FormSeven");
    localStorage.removeItem("opportunityfrom");
    localStorage.removeItem("editopportunityid");
    localStorage.removeItem("activestep");
    props.history.push({
      pathname: "/manage/opportunity",
    });
  };
  const gotodashboard = (hosting_id) => {
    localStorage.setItem("hostingId", hosting_id);

    props.history.push({
      pathname: "/user/opportunity/dashboard/" + hosting_id,
      state: hosting_id,
    });
  };

  const sortbyChange = (e) => {
    console.log("select valu", e.value);
    setSortByValue(e.value);
    if (e.value === 0) {
      setFilterData("title");
      setSortByData(
        alldata.sort(function (a, b) {
          if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
          if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
          //  return 0;
        })
      );
    }
    if (e.value === 1) {
      setFilterData("deadline");
      setSortByData(
        alldata.sort((a, b) =>
          a.end_date
            .split("/")
            .reverse()
            .join()
            .localeCompare(b.end_date.split("/").reverse().join())
        )
      );
    }

    console.log("select sort by", sortData, tempData, alldata);
  };

  // useEffect(() => {
  //   setAlldata(alldata);
  // }, [alldata]);

  const duplicate = (id) => {
    console.log("opp id", id);
    axios({
      method: "post",
      url: mainURL + "/opportunity/duplicate",
      data: {
        opportunity_id: id,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        getOpportunity();
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  // delete opportunity

  const deleteOpp = (id) => {
    console.log("opp id", id);
    axios({
      method: "delete",
      url: mainURL + "/opportunity/" + id,
      data: {
        opportunity_id: id,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        let docid = "#opportunity" + id;
        $(docid).hide();
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  return (
    <>
      <div className="dashboard-BG">
        <div className="row searchandDropdown">
          <div className="col-md-9">
            <span className="searchIcon">
              <i className="fa fa-search"></i>
            </span>
            <input
              type="text"
              defaultValue={searchValue != "" ? searchValue : ""}
              name=""
              placeholder="Search for hosting"
              className="searchbox"
              onChange={(e) => finalfilterdata(e.target.value, "searchData")}
            ></input>
          </div>
          <div className="col-md-3">
            <div className="dropdownContainer">
              <span className="right-dropdown">Status of Listing:</span>
              <Select
                placeholder="Select"
                isSearchable={false}
                options={data}
                onChange={(e) => finalfilterdata(e.value, "statusoflisting")}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {e.icon}
                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <div id="user-wrapper" className="dashboard  content hostingpage">
          <div className="position_absolute">
            <button
              className="btn menubtn actionBtn"
              onClick={() => gotohosting()}
            >
              + Host Opportunity
            </button>
            <Select
              placeholder="Sort By"
              isSearchable={false}
              // value={selectedOption}
              options={sortby}
              onChange={(e) => finalfilterdata(e.value, "sortby")}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginLeft: 5 }}>{e.text}</span>
                </div>
              )}
            />
          </div>
          <h3>
            {tempData && tempData.length}{" "}
            {tempData && tempData.length > 1 ? "Hostings" : "Hosting"}
          </h3>
          <Tabs
            style={{
              marginBottom: 32,
            }}
            className="insideTab"
            onChange={(e) => selectetab(e)}
          >
            <TabPane
              tab={
                <span>
                  <img
                    className="list-icon-img"
                    src={require("../../images/all.png").default}
                    alt="od-banner"
                  />{" "}
                  All ({alldata && alldata?.length}){" "}
                </span>
              }
              key="1"
            >
              {alldata?.length > 0 ? (
                alldata?.map((item, index) => (
                  <div className="row registerbox" id={`opportunity${item.id}`}>
                    <div className="col-md-2">
                      <div>
                        <img src={item.logo_image} className="imgsize" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p className="myhosting-titiles">
                        <Link to={`/e/${item.slug}`}>{item.title}</Link>
                        {moment(item.end_date).diff(
                          moment(new Date()),
                          "days"
                        ) <= 0 ? (
                          <button className="btn menubtn stautusBtn closed">
                            <i className="fa fa-lock"></i> Closed
                          </button>
                        ) : (
                          <button className="btn menubtn stautusBtn ">
                            <i className="fa fa-circle"></i> Live
                          </button>
                        )}
                        <i className="fa fa-share-alt shareicon"></i>
                        <a
                          href={"/e/" + item.slug}
                          target="_blank"
                          className="previewlink"
                        >
                          Preview
                        </a>
                        {/* <WhatsappShareButton url="https://www.gradpartners.in/e/HCCB-campus-2023-60">
<WhatsappIcon size={32} round={true} />
</WhatsappShareButton> */}
                      </p>
                      {/* <p>
                    {item.organization}, {item.location}
                  </p> */}

                      {item.rounds[0]?.start_date &&
                      moment(item.rounds[0].start_date).diff(
                        moment(new Date()),
                        "days"
                      ) >= 0 ? (
                        <div className="displayflex1">
                          <div>
                            <p>
                              {" "}
                              Ongoing Round:{" "}
                              <span className="team_name">Registration</span>
                            </p>
                          </div>
                          <div>
                            <p>
                              {" "}
                              Ongoing Round Deadline:{" "}
                              <span className="team_name">
                                <Moment
                                  format="Do MMM YYYY "
                                  date={item.register_end_date}
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="displayflex1">
                          <div>
                            <p>
                              {" "}
                              Ongoing Round:{" "}
                              <span className="team_name">
                                {item.rounds.find(
                                  (round) =>
                                    moment(round.start_date) <=
                                      moment(new Date()) &&
                                    moment(round.end_date) >= moment(new Date())
                                )?.title ?? "NA"}
                              </span>
                            </p>
                          </div>
                          {item.rounds.find(
                            (round) =>
                              moment(round.start_date) <= moment(new Date()) &&
                              moment(round.end_date) >= moment(new Date())
                          )?.end_date && (
                            <div>
                              <p>
                                {" "}
                                Ongoing Round Deadline:{" "}
                                <span className="team_name">
                                  <Moment
                                    format="Do MMM YYYY "
                                    date={
                                      item.rounds.find(
                                        (round) =>
                                          moment(round.start_date) <=
                                            moment(new Date()) &&
                                          moment(round.end_date) >=
                                            moment(new Date())
                                      )?.end_date
                                    }
                                  />
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      )}

                      <p className="mt-10">
                        <FaRegCalendarAlt className="roundcal" />
                        <Moment
                          format="DD MMM YYYY "
                          date={item.start_date}
                        />{" "}
                        - <Moment format="DD MMM YYYY " date={item.end_date} />{" "}
                      </p>
                    </div>
                    <div className="col-md-4 hostingcls talignright">
                      <div className="d-flex justify-content-end">
                        <Popconfirm
                          title="Are you sure to delete this Opportunity?"
                          onConfirm={() => deleteOpp(item.id)}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <p className="mt-10">Delete</p>
                        </Popconfirm>

                        <button
                          className="btn menubtn actionBtn transparentbtn"
                          onClick={() => gotoEdithosting(item.id)}
                        >
                          <i
                            className="fa fa-pencil-square-o"
                            aria-hidden="true"
                          ></i>{" "}
                          &nbsp;Edit Opportunity
                        </button>
                      </div>
                      <div className="d-flex justify-content-end">
                        <p className="mt-10" onClick={() => duplicate(item.id)}>
                          Duplicate
                        </p>
                        <button
                          className="btn menubtn actionBtn transparentbtn"
                          // disabled
                          onClick={() => gotodashboard(item.id)}
                        >
                          <img
                            src={
                              require("../../images/view_dashboard.png").default
                            }
                            alt="od-banner"
                            className="dashboardicon"
                          />{" "}
                          &nbsp; View Dashboard
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  <img class="img-fluid" src="https://gradimages.s3.ap-south-1.amazonaws.com/not-found.png" />
                </div>
              )}
            </TabPane>
            <TabPane
              tab={
                <span>
                  {" "}
                  <img
                    className="list-icon-img"
                    src={require("../../images/Icon-Competition.png").default}
                    alt="od-banner"
                  />{" "}
                  Competitions (
                  {alldata &&
                    alldata.reduce(function (n, person) {
                      return n + (person.category == "opportunity");
                    }, 0)}
                  ){" "}
                </span>
              }
              key="2"
            >
              {alldata.length > 0 ? (
                alldata.map(
                  (item, index) =>
                    item.category === "opportunity" && (
                      <div className="row registerbox">
                        <div className="col-md-2">
                          <div>
                            <img src={item.logo_image} className="imgsize" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="myhosting-titiles">
                            <Link to={`/e/${item.slug}`}>{item.title}</Link>
                            {moment(item.end_date).diff(
                              moment(new Date()),
                              "days"
                            ) <= 0 ? (
                              <button className="btn menubtn stautusBtn closed">
                                <i className="fa fa-lock"></i> Closed
                              </button>
                            ) : (
                              <button className="btn menubtn stautusBtn ">
                                <i className="fa fa-circle"></i> Live
                              </button>
                            )}
                            <i className="fa fa-share-alt shareicon"></i>
                            {/* <WhatsappShareButton url="https://www.gradpartners.in/e/HCCB-campus-2023-60">
  <WhatsappIcon size={32} round={true} />
  </WhatsappShareButton> */}
                          </p>
                          {/* <p>
                      {item.organization}, {item.location}
                    </p> */}

                          {moment(item.rounds[0]?.start_date).diff(
                            moment(new Date()),
                            "days"
                          ) >= 0 ? (
                            <div className="displayflex1">
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round:{" "}
                                  <span className="team_name">
                                    Registration
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round Deadline:{" "}
                                  <span className="team_name">
                                    <Moment
                                      format="Do MMM YYYY "
                                      date={item.register_end_date}
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="displayflex1">
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round:{" "}
                                  <span className="team_name">
                                    {item.rounds[0]?.title}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round Deadline:{" "}
                                  <span className="team_name">
                                    <Moment
                                      format="Do MMM YYYY "
                                      date={item.register_end_date}
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                          )}

                          <p className="mt-10">
                            <FaRegCalendarAlt className="roundcal" />
                            <Moment
                              format="DD MMM YYYY "
                              date={item.start_date}
                            />{" "}
                            -{" "}
                            <Moment
                              format="DD MMM YYYY "
                              date={item.end_date}
                            />{" "}
                          </p>
                        </div>
                        <div className="col-md-4 hostingcls talignright">
                          <div className="d-flex justify-content-end">
                            <p className="mt-10">Preview</p>
                            <button
                              className="btn menubtn actionBtn transparentbtn"
                              onClick={() => gotoEdithosting(item.id)}
                            >
                              <i
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>{" "}
                              &nbsp;Edit Opportunity
                            </button>
                          </div>
                          <div className="d-flex justify-content-end">
                            <p className="mt-10">Duplicate</p>
                            <button
                              className="btn menubtn actionBtn transparentbtn"
                              // disabled
                              onClick={() => gotodashboard(item.id)}
                            >
                              <img
                                src={
                                  require("../../images/view_dashboard.png")
                                    .default
                                }
                                alt="od-banner"
                                className="dashboardicon"
                              />{" "}
                              &nbsp; View Dashboard
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="text-center">
                  <img class="img-fluid" src="https://gradimages.s3.ap-south-1.amazonaws.com/not-found.png" />
                </div>
              )}
            </TabPane>
            <TabPane
              tab={
                <span>
                  {" "}
                  <img
                    className="list-icon-img"
                    src={require("../../images/Icon-Workshop.png").default}
                    alt="od-banner"
                  />{" "}
                  Workshops (
                  {alldata &&
                    alldata.reduce(function (n, person) {
                      return n + (person.category == "workshop");
                    }, 0)}
                  ){" "}
                </span>
              }
              key="3"
            >
              {alldata.length > 0 ? (
                alldata.map(
                  (item, index) =>
                    item.category === "workshop" && (
                      <div className="row registerbox">
                        <div className="col-md-2">
                          <div>
                            <img src={item.logo_image} className="imgsize" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <p className="myhosting-titiles">
                            <Link to={`/e/${item.slug}`}>{item.title}</Link>
                            {moment(item.end_date).diff(
                              moment(new Date()),
                              "days"
                            ) <= 0 ? (
                              <button className="btn menubtn stautusBtn closed">
                                <i className="fa fa-lock"></i> Closed
                              </button>
                            ) : (
                              <button className="btn menubtn stautusBtn ">
                                <i className="fa fa-circle"></i> Live
                              </button>
                            )}
                            <i className="fa fa-share-alt shareicon"></i>
                            {/* <WhatsappShareButton url="https://www.gradpartners.in/e/HCCB-campus-2023-60">
  <WhatsappIcon size={32} round={true} />
  </WhatsappShareButton> */}
                          </p>
                          {/* <p>
                      {item.organization}, {item.location}
                    </p> */}

                          {moment(item.rounds[0]?.start_date).diff(
                            moment(new Date()),
                            "days"
                          ) >= 0 ? (
                            <div className="displayflex1">
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round:{" "}
                                  <span className="team_name">
                                    Registration
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round Deadline:{" "}
                                  <span className="team_name">
                                    <Moment
                                      format="Do MMM YYYY "
                                      date={item.register_end_date}
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div className="displayflex1">
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round:{" "}
                                  <span className="team_name">
                                    {item.rounds[0]?.title}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p>
                                  {" "}
                                  Ongoing Round Deadline:{" "}
                                  <span className="team_name">
                                    <Moment
                                      format="Do MMM YYYY "
                                      date={item.register_end_date}
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                          )}

                          <p className="mt-10">
                            <FaRegCalendarAlt className="roundcal" />
                            <Moment
                              format="DD MMM YYYY "
                              date={item.start_date}
                            />{" "}
                            -{" "}
                            <Moment
                              format="DD MMM YYYY "
                              date={item.end_date}
                            />{" "}
                          </p>
                        </div>
                        <div className="col-md-4 hostingcls talignright">
                          <div className="d-flex justify-content-end">
                            <p className="mt-10">
                              <a href={"/e/" + item.slug} target="_blank">
                                Preview
                              </a>
                            </p>
                            <button
                              className="btn menubtn actionBtn transparentbtn"
                              onClick={() => gotoEdithosting(item.id)}
                            >
                              <i
                                className="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>{" "}
                              &nbsp;Edit Opportunity
                            </button>
                          </div>
                          <div className="d-flex justify-content-end">
                            <p className="mt-10">Duplicate</p>
                            <button
                              className="btn menubtn actionBtn transparentbtn"
                              // disabled
                              onClick={() => gotodashboard(item.id)}
                            >
                              <img
                                src={
                                  require("../../images/view_dashboard.png")
                                    .default
                                }
                                alt="od-banner"
                                className="dashboardicon"
                              />{" "}
                              &nbsp; View Dashboard
                            </button>
                          </div>

                          {/* {item.register_end_date} ---  */}
                          {/* {moment(item.register_end_date).diff(moment(new Date()))} */}
                          {/* {item.leader_id === null &&
                          item.category === "opportunity" &&
                          moment(item.register_end_date).diff(
                            moment(new Date())
                          ) > 0 && (
                            <Popconfirm
                              title="Are you sure to delete this registration?"
                              onConfirm={() => confirm(item.id)}
                              onCancel={cancel}
                              okText="Yes"
                              cancelText="No"
                            >
                              <button
                                className="btn menubtn cancelbtn"
                                onClick=""
                              >
                                Cancel Registration
                              </button>
                            </Popconfirm>
                          )} */}
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="text-center">
                  <img class="img-fluid" src="https://gradimages.s3.ap-south-1.amazonaws.com/not-found.png" />
                </div>
              )}
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Userhosting;
