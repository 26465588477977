import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form, Tab, Tabs, TabContent } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight, FaSave } from "react-icons/fa";
import $ from "jquery";
import axios from "axios";
import { Link } from "react-router-dom";
import config from "../..//CommonFiles/config.json";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { Drawer, Switch } from "antd";

import {
  Button as Antbtn,
  Checkbox,
  Form as AntForm,
  Select,
  Input,
  Radio,
} from "antd";
import QuizSettingPage from "../../User/quizManagement/QuizSettingPage";
import Managequiz from "../../User/quizManagement/ManageQuiz";
import SubmissionSettings from "../../User/Forms/submissionSettings";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const USER_TOKEN = localStorage.getItem("token");
const AuthStr = "Bearer ".concat(USER_TOKEN);
const today = new Date().toISOString().split("T")[0];
var reqformdata;

const StepFour = (props) => {
  const [inputValue, setInputValue] = useState({});
  const formdata = JSON.parse(localStorage.getItem("FormFour"));
  const stepone = JSON.parse(localStorage.getItem("FormOne"));
  const [error, setError] = useState();
  const [imageview, setPreviewlogo] = useState("");
  const [entireData, setEntireData] = useState([]);
  const [smShow, setSmShow] = useState(false);
  const [quizIndex, setQuizIndex] = useState(0);
  const [subSettingsOpenedFor, setSubSettingsOpenedFor] = useState(null);

  const [inputFields, setInputFields] = useState([
    {
      type: "",
      title: "",
      description: "",
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      registerandaccess: "",
      is_eleminator: "",
      submission_settings: null,
    },
  ]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("editopportunityid")) {
      axios({
        method: "get",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      })
        .then((response) => {
          const alldata = response.data.rounds;
          setEntireData(response.data.data);
          console.log(alldata, "get opprootunity step 2");
          if (alldata) {
            localStorage.setItem("FormFour", JSON.stringify(alldata));
            var formdatssa = JSON.parse(localStorage.getItem("FormFour"));
            setInputFields(formdatssa);
          }
        })
        .catch((err) => {
          console.log("jhghjghg", err);
        });
    }
    const formdata = JSON.parse(localStorage.getItem("FormFour"));

    if (formdata) {
      var formdatssa = JSON.parse(localStorage.getItem("FormFour"));
      setInputValue(formdatssa);
    }
  }, []);

  const onClose = () => {
    setOpen(false);
    setSubSettingsOpenedFor(null);
  };

  const openSubmissionSettings = (roundIndex) => {
    setSubSettingsOpenedFor(roundIndex);
    setOpen(true);
  };

  const handleAddFields = () => {
    var formdatssa = JSON.parse(localStorage.getItem("FormFour"));

    const values = [...formdatssa];
    values.push({
      type: "",
      title: "",
      description: "",
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
      registerandaccess: "",
      is_eleminator: "",
      submission_settings: null,
    });
    setInputFields(values);
    localStorage.setItem("FormFour", JSON.stringify(values));

    console.log("window.screen.height", window.screen.height);

    window.scrollTo({
      top: window.screen.height * inputFields.length,
      behavior: "smooth",
    });
  };

  const handleRemoveFields = (id, index) => {
    axios({
      method: "post",
      url: mainURL + "/opportunity/round",
      data: { type: "delete", id: id },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const updatedInputFields = inputFields.splice(index, 1);
        setInputFields(updatedInputFields);
        localStorage.setItem("FormFour", JSON.stringify(updatedInputFields));
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  const onInputChange = (val, name, idx) => {
    // setInputValue({ ...inputValue, [name]: val });
    console.log("edit itme", val, name, idx);

    const rows = [...inputFields];
    const getlocal = localStorage.getItem("FormFour");
    if (getlocal == null) {
      console.log(rows);

      if (name == "type") {
        rows[idx].type = val;
      }
      if (name == "title") {
        rows[idx].title = val;
      }
      if (name == "description") {
        rows[idx].description = val;
      }
      if (name == "start_time") {
        rows[idx].start_time = val;
      }
      if (name == "end_time") {
        rows[idx].end_time = val;
      }
      if (name == "start_date") {
        rows[idx].start_date = val;
      }
      if (name == "end_date") {
        rows[idx].end_date = val;
      }
      if (name == "registerandaccess") {
        rows[idx].registerandaccess = val;
      }
      if (name == "is_eleminator") {
        rows[idx].is_eleminator = val;
      }
      if (name == "submission_settings") {
        rows[idx].submission_settings = val;
      }
      setInputFields(rows);
      console.log("get val", rows);
      localStorage.setItem("FormFour", JSON.stringify(rows));
    } else {
      var existingValue = JSON.parse(localStorage.getItem("FormFour"));
      // console.log("onchange val rows" , rows)
      // console.log("onchange val getlocal" , getlocal )
      // console.log("onchange val idx" , idx)
      console.log("onchange val existingValue", existingValue, idx);

      if (name == "type") {
        existingValue[idx].type = val;
      }
      if (name == "title") {
        existingValue[idx].title = val;
      }
      if (name == "description") {
        existingValue[idx].description = val;
      }
      if (name == "start_time") {
        existingValue[idx].start_time = val;
      }
      if (name == "end_time") {
        existingValue[idx].end_time = val;
      }
      if (name == "start_date") {
        existingValue[idx].start_date = val;
      }
      if (name == "end_date") {
        existingValue[idx].end_date = val;
      }
      if (name == "registerandaccess") {
        existingValue[idx].registerandaccess = val;
      }
      if (name == "is_eleminator") {
        existingValue[idx].is_eleminator = val;
      }
      if (name == "submission_settings") {
        existingValue[idx].submission_settings = val;
      }
      setInputFields(existingValue);
      console.log("get val 2", inputValue);
      localStorage.setItem("FormFour", JSON.stringify(existingValue));
    }
  };

  const uploadattachment = (val, name, newval) => {
    console.log("newval", val, name, newval);
    const formData = new FormData();
    setPreviewlogo(val);

    formData.append("attachments", newval, name);
    reqformdata = formData;
    axios({
      method: "post",
      url:
        mainURL +
        "/opportunity/images/" +
        localStorage.getItem("editopportunityid"),
      data: reqformdata,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;

        console.info("all analytics", alldata);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
    // localStorage.setItem('attachement' , formData)
  };

  const nextStep = (status) => {
    var inputTag = document.querySelectorAll(".requiredfield");
    // var SelectedTag = document.querySelectorAll('.form-body select[className="organisationnam"]');
    for (var i = 0; i < inputTag.length; i++) {
      if (
        inputTag[i].value === "" &&
        inputTag[i].getAttribute("name") != "college_registration"
      ) {
        console.log("input value", inputTag[i].value);
        inputTag[i].setAttribute("id", "fill-the-field");
      } else {
        inputTag[i].setAttribute("id", "");
      }

      if (!$("input[name='shorlist" + i + "']:checked").val()) {
        $("input[name='shorlist" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='shorlist" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }
    }

    var errorexits = document.querySelectorAll("#fill-the-field");
    console.log("errorexits step round", errorexits.length);
    if (errorexits.length > 0) {
      setError("Please fill in all the details to register");
      console.log("error", error);
    }
    if (errorexits.length > 0) {
      return false;
    }

    const stepData = JSON.parse(localStorage.getItem("FormFour"));

    axios({
      method: "post",
      url: mainURL + "/opportunity/round",
      data: {
        rounds: stepData,
        opportunity_id: localStorage.getItem("editopportunityid"),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        const alldata = response.data.data;
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
    if (status === "continue") {
      localStorage.setItem("activestep", 4);

      props.newActiveStepValue(4);
    } else {
      Swal.fire("Good job!", "Round details have been updated", "success");
    }

    const getlocal = localStorage.getItem("FormFour");
    // check if nothing update store previous locastorage value
    if (getlocal == null)
      localStorage.setItem("FormFour", JSON.stringify(inputValue));

    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  const prevStep = () => {
    localStorage.setItem("activestep", 2);
    props.newActiveStepValue(2);
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };
  const cancel = () => {
    props.history.push("/");
  };
  const addQuiz = (index, round_id, settingdata, singelRound) => {
    console.log("round id", round_id, settingdata);
    var inputTag = document.querySelectorAll(".requiredfield");
    // var SelectedTag = document.querySelectorAll('.form-body select[className="organisationnam"]');
    for (var i = 0; i < inputTag.length; i++) {
      if (
        inputTag[i].value === "" &&
        inputTag[i].getAttribute("name") != "college_registration"
      ) {
        console.log("input value", inputTag[i].value);
        inputTag[i].setAttribute("id", "fill-the-field");
      } else {
        inputTag[i].setAttribute("id", "");
      }

      if (!$("input[name='shorlist" + i + "']:checked").val()) {
        $("input[name='shorlist" + i + "']")
          .parent()
          .parent()
          .attr("id", "fill-the-field");
      } else {
        $("input[name='shorlist" + i + "']")
          .parent()
          .parent()
          .attr("id", "");
      }
    }

    var errorexits = document.querySelectorAll("#fill-the-field");
    console.log("errorexits", errorexits.length);
    if (errorexits.length > 0) {
      setError("Please fill in all the details to register");
      console.log("error", error);
    }
    if (errorexits.length > 0) {
      return false;
    }

    localStorage.setItem("quizStep", 1);
    setQuizIndex(index);
    const stepData = JSON.parse(localStorage.getItem("FormFour"));
    // let dataMethod;
    //   if(round_id)
    //   {
    //    dataMethod = "put"
    //   }
    //   else{
    //    dataMethod = "post"

    //   }

    if (settingdata === undefined) {
      axios({
        method: "post",
        url: mainURL + "/opportunity/round",
        data: {
          rounds: [singelRound],
          opportunity_id: localStorage.getItem("editopportunityid"),
        },
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          const alldata = response.data.data;
          localStorage.setItem("FormFour", JSON.stringify(alldata));
          console.log("round check", alldata);
          localStorage.setItem("round_id", alldata[alldata.length - 1].id);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    } else {
      localStorage.setItem("round_id", round_id);
    }

    setSmShow(true);
  };
  // this is new add for edit button
  const closebtn = () => {
    setSmShow(false);
    // window.location.reload();
    const newdata = JSON.parse(localStorage.getItem("FormFour"));
    setInputFields(newdata);
  };

  const onSubSettingSave = (settingsData) => {
    onInputChange(settingsData, "submission_settings", subSettingsOpenedFor);
    onClose();
  };

  return (
    <div>
      <Modal
        size="sm"
        className="quizaddmodal"
        show={smShow}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body>
          <button
            type="button"
            className="close"
            onClick={() => setSmShow(false)}
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="row">
            <Managequiz
              quizIndexval={quizIndex}
              closeBtn={(e) => closebtn(e)}
            />
            {/* <Managequiz/> */}
          </div>
        </Modal.Body>
      </Modal>
      <div className="form">
        {inputFields &&
          inputFields.map((inputField, index) => (
            <>
              <Form
                className="form-body "
                key={`${inputField}~${index}`}
                id={`roundid_${index}`}
              >
                <h5 className="round-tittle">ROUND {index + 1}</h5>
                <Form.Row className="twocolform">
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="formGridCategory"
                  >
                    <Form.Label className="input-titlle">
                      Category <span className="importantfield">*</span>
                    </Form.Label>

                    <Select
                      bordered={false}
                      className="select-category"
                      dropdownMatchSelectWidth={true}
                      onChange={(e) => onInputChange(e, "type", index)}
                      options={[
                        {
                          label: "Select category",
                        },
                        {
                          value: "quiz",
                          label: "Quiz",
                        },
                        {
                          value: "submission",
                          label: "Submission",
                        },
                        {
                          value: "workshop",
                          label: "Workshop",
                        },
                        {
                          value: "others",
                          label: "Others",
                        },
                      ]}
                      value={
                        inputField.type ? inputField.type : "Select category"
                      }
                    />
                  </Col>

                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="formGridOpportunityTitle"
                  >
                    <Form.Label className="input-titlle">
                      Round Title <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="requiredfield"
                      placeholder="Round Title"
                      name="title"
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                      defaultValue={inputField.title}
                    />
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={12}
                    controlId="formGridWebsite"
                  >
                    <Form.Label className="input-titlle">
                      Description <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="webiste"
                      className="requiredfield"
                      placeholder="Description"
                      name="description"
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                      defaultValue={inputField.description}
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="twocolform">
                  <Col className="form-group-col" md={6} controlId="start_date">
                    <Form.Label className="input-titlle">
                      Round Start Date <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      className="requiredfield"
                      placeholder="21-04-2021"
                      name="start_date"
                      min={stepone.start_date}
                      max={inputField.end_date}
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                      value={inputField.start_date}
                    />
                  </Col>
                  <Col className="form-group-col" md={6} controlId="start_date">
                    <Form.Label className="input-titlle">
                      Round Start Time <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="time"
                      className="requiredfield"
                      name="start_time"
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                      defaultValue={inputField.start_time}
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="twocolform">
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="oppotunityEndTime"
                  >
                    <Form.Label className="input-titlle">
                      Round End Date <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="21-04-2021"
                      min={inputField.start_date}
                      className="requiredfield"
                      name="end_date"
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                      value={inputField.end_date}
                    />
                  </Col>
                  <Col className="form-group-col" md={6} controlId="end_time">
                    <Form.Label className="input-titlle">
                      Round End Time <span className="importantfield">*</span>
                    </Form.Label>
                    <Form.Control
                      type="time"
                      name="end_time"
                      className="requiredfield"
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                      defaultValue={inputField.end_time}
                    />
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Col
                    className="form-group-col"
                    md={6}
                    controlId="oppotunityEndTime"
                  >
                    <Form.Label className="input-titlle">
                      Eliminator Round{" "}
                      {/* <span className="importantfield">*</span> */}
                    </Form.Label>

                    <div
                      className="form-control radiocss"
                      onChange={(e) =>
                        onInputChange(e.target.value, e.target.name, index)
                      }
                    >
                      <Form.Check
                        type="radio"
                        value="yes"
                        name="is_eleminator"
                        label="Yes"
                        checked={inputField.is_eleminator === "yes"}
                      />
                      <Form.Check
                        type="radio"
                        value="no"
                        name="is_eleminator"
                        label="No"
                        checked={inputField.is_eleminator === "no"}
                      />
                    </div>
                  </Col>
                  {inputField.type === "quiz" && (
                    <Col
                      className="form-group-col"
                      md={6}
                      controlId="oppotunityEndTime"
                    >
                      <Button
                        className="transparentbtn addquizbtn float-right mt-10 mr-2"
                        type="button"
                        onClick={(e) =>
                          addQuiz(
                            index,
                            inputField.id,
                            inputField.quiz_setting,
                            inputField
                          )
                        }
                      >
                        {inputField.quiz_setting
                          ? "+ Edit Quiz Settings"
                          : "+ Add Quiz Settings"}
                      </Button>
                    </Col>
                  )}
                </Form.Row>

                {index === 0 ? (
                  inputField.type == "submission" && (
                    <Form.Row>
                      <div className="offset-lg-8 col-lg-4 col-md-6 offset-md-6 fr">
                        <button
                          className="btn btn-link minus-btn mr-3"
                          type="button"
                          onClick={() => openSubmissionSettings(index)}
                        >
                          <h2 className="remove-round fr">
                            Submission Settings <i className="fa fa-gear"></i>
                          </h2>
                        </button>
                      </div>
                    </Form.Row>
                  )
                ) : (
                  <Form.Row>
                    <div className="offset-lg-8 col-lg-4 col-md-6 offset-md-6 fr">
                      <div className="form-group addminus flex justify-content-md-between justify-content-lg-end">
                        {inputField.type == "submission" && (
                          <button
                            className="btn btn-link minus-btn mr-3"
                            type="button"
                            onClick={() => openSubmissionSettings(index)}
                          >
                            <h2 className="remove-round fr">
                              Submission Settings <i className="fa fa-gear"></i>
                            </h2>
                          </button>
                        )}
                        <button
                          className="btn btn-link minus-btn"
                          type="button"
                          onClick={() =>
                            handleRemoveFields(inputField.id, index)
                          }
                        >
                          <h2 className="remove-round fr">
                            Remove <i className="fa fa-trash"></i>
                          </h2>
                        </button>
                      </div>
                    </div>
                  </Form.Row>
                )}
              </Form>
            </>
          ))}
        <div className="form-group  addminus priceadd">
          <button
            className="btn btn-link plus-btn"
            type="button"
            onClick={() => handleAddFields()}
          >
            <h2 className="add-round fr">
              <i className="fa fa-plus"></i>Add Round{" "}
            </h2>
          </button>
        </div>
      </div>
      <div className="hosting_bottom">
        <Button className="help-btn" type="button" onClick={() => prevStep()}>
          <FaArrowLeft />
          Go Back
        </Button>
        <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => nextStep("continue")}
        >
          Next <FaArrowRight />
        </Button>
        {localStorage.getItem("opportunityfrom") && (
          <Button
            className="save-hosting-first-btn editsavebtn"
            type="button"
            onClick={() => nextStep("save")}
          >
            save <FaSave />
          </Button>
        )}
        <Link to={`/user/hosting`}>
          {" "}
          <Button
            className="transparentbtn float-right mt-10 mr-2"
            type="button"
            // onClick={(e) => cancel(e)}
          >
            Cancel
          </Button>
        </Link>
      </div>
      <Drawer
        className="round-settings"
        title={`Submission Settings`}
        placement="right"
        onClose={onClose}
        open={open}
        size={"large"}
      >
        <SubmissionSettings
          onSubSettingSave={onSubSettingSave}
          submissionSettings={
            subSettingsOpenedFor !== null
              ? JSON.parse(inputFields[subSettingsOpenedFor].submission_settings)
              : null
          }
        />
      </Drawer>
    </div>
  );
};

export { StepFour, reqformdata };
