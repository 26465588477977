import React from "react";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import "./static.css";
let allteam = JSON.parse(localStorage.getItem("memberdata"));
function Membersuccess() {
  window.scrollTo({
    top: 20,
    behavior: "smooth",
  });

  console.log("allteam", allteam);
  if (window.location.href.indexOf("reload") == -1) {
    window.location.href = window.location.href + "?reload";
  }

  return (
    <React.Fragment>
      <Header />
      <div className="success-page">
        <div className="">
          <span className="successpagemsg">
            You have registered successfully for the opportunity!{" "}
          </span>
          <table id="customers">
            <thead>
              <th>Member Name</th>
              {/* <th>Member Type</th> */}
              <th>Email</th>
              <th>Number</th>
              <th>Gender</th>
            </thead>
            {allteam.map((item, index) => (
              <tr>
                <td>{item.name}</td>
                {/* <td>{item.is_teamlead === 1 ? "Leader" : "Member"}</td> */}
                <td>{item.email}</td>
                <td>{item.mobile}</td>

                <td>{item.gender}</td>
              </tr>
            ))}
          </table>
          {/* <button className="btn menubtn fr">Homepage</button> */}
        </div>
      </div>

      <Footer />
    </React.Fragment>
  );
}

export default Membersuccess;
