import React, { useState } from "react";
import "./Opportunities.css";
import Opportunitiescard from "../CommonFiles/Opportunitiescard";
import Workshopcard from "../CommonFiles/Workshopcard";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import config from "../CommonFiles/config.json";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const Keys = {
  Opportunities: 1,
  Workshops: 2,
};

const TabNames = {
  1: "Opportunities",
  2: "Workshops",
};

function Opportunities(props) {
  const { TabPane } = Tabs;
  const [allopportunities, setAllopportunities] = useState(
    props.opportunities.rows
  );
  const [allworkshops, setWorkshops] = useState(props.workshops.rows);

  return (
    <div className="opportunities">
      <div className="opportunities-content mb-4">
        <Tabs
          className=""
          defaultActiveKey={Keys.Opportunities}
          onChange={(tab) => {
            props.onTabNameChange(TabNames[tab]);
          }}
        >
          <TabPane
            className=""
            tab={<span>Opportunities</span>}
            key={Keys.Opportunities}
          >
            <Opportunitiescard allopportunities={allopportunities} />
          </TabPane>
          <TabPane
            className=""
            tab={<span>Workshops</span>}
            key={Keys.Workshops}
          >
            <Workshopcard allworkshops={allworkshops} />
          </TabPane>
        </Tabs>

      </div>
    </div>
  );
}

export default Opportunities;
