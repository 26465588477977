import { Button, Form, Input, notification } from "antd";
import React, { useState } from "react";
import axios from "axios";
import config from "../../CommonFiles/config.json";

const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

const buttonLayout = {
  wrapperCol: {
    span: 12,
    offset: 12,
  },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
/* eslint-enable no-template-curly-in-string */

const EmailTemplateForm = () => {
  const [formLayout, setFormLayout] = useState("vertical");
  const [form] = Form.useForm();

  const onFinish = (values) => {
    createSESTemplate(values);
  };

  const createSESTemplate = (values) => {
    const USER_TOKEN = localStorage.getItem("token");
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "POST",
      url: `${mainURL}/template`,
      data: {
        ...values,
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        form.resetFields();
        openNotificationWithIcon(
          "success",
          "Template Created",
          "You now can use the created template to send personalised emails."
        );
      })
      .catch((err) => {
        openNotificationWithIcon(
          "error",
          "There was an issue while creating template",
          "Try Again or Reach to support team. "
        );
      });
  };

  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description,
      placement: "bottomRight",
    });
  };

  return (
    <Form
      form={form}
      layout={formLayout}
      name="nest-messages"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Form.Item
        name={"templateName"}
        label="Template Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"subject"}
        label="Email Subject Line"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={"text"}
        rules={[
          {
            required: true,
          },
        ]}
        label="Template Text"
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name={"html"}
        rules={[
          {
            required: true,
          },
        ]}
        label="Template HTML"
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item {...buttonLayout} style={{ textAlign: "right" }}>
        <Button type="primary" size={"large"} danger htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
export default EmailTemplateForm;
