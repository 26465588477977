import React from "react";
import LeftSideBar from "./LeftSideBar";
import RightSidebar from "./RightSidebar";
import Nav from "./Nav";

const Dashboard = () => {
  return (
    <div>
      <div style={{ display: "flex" }} className="dashboard">
        <LeftSideBar />
        <div>
          <Nav />
          <RightSidebar />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
