import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  getSignedUrls,
  uploadFileWithProgress,
} from "../../../services/uploadService";
import "./submissionSettings.css";

// const options = [
//   { label: "PDF Document", value: "application/pdf" },
//   {
//     label: "Presentation Files",
//     value:
//       "application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation",
//   },
//   {
//     label: "Video Files",
//     value:
//       "video/mp4,video/webm,video/ogg,video/avi,video/mov,video/mkv,video/x-msvideo",
//   },
//   { label: "Audio Files", value: "audio/mp3,audio/wav" },
// ];

function SubmissionSettingsCaseStudy({
  caseStudy,
  index,
  onCaseStudyDataChange,
  onDeleteCaseStudy,
}) {
  const [uploadCaseStudyForm] = Form.useForm();
  const [uploading, setUploading] = useState(false);
  const [defaultFileList, setDefaultFileList] = useState([]);

  useEffect(() => {
    if (caseStudy) {
      const fileList = caseStudy.fileUrl
        ? [
            {
              name: caseStudy.fileName,
              url: caseStudy.fileUrl,
              fileName: caseStudy.fileName,
              fileURL: caseStudy.fileUrl,
              status: "done",
            },
          ]
        : [];
      setDefaultFileList(fileList);

      uploadCaseStudyForm.setFieldsValue({
        caseStudyFiles: fileList,
        multipleSubmissions: caseStudy.multipleSubmissions,
        allowedFiles: caseStudy.allowedFiles,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseStudy]);

  const uploadCaseStudy = async (options) => {
    const { file, onSuccess } = options;
    setUploading(true);

    // Change the file name
    const newFileName = `${moment().valueOf()}-${file.name}`;
    const newFile = new File([file], newFileName, { type: file.type });

    try {
      // Use the new file name to get the signed URL
      const signedUrl = await getSignedUrls([{ fileName: newFile.name }]);

      // Upload the file with the new name
      await uploadFileWithProgress(signedUrl[0].signedUploadURL, newFile);

      const updatedFileList = [
        {
          fileName: newFile.name,
          fileURL: signedUrl.find((s) => s.fileName === newFile.name).fileURL,
          name: newFile.name,
          url: signedUrl.find((s) => s.fileName === newFile.name).fileURL,
          status: "done",
        },
      ];

      setDefaultFileList(updatedFileList);
      uploadCaseStudyForm.setFieldsValue({
        caseStudyFiles: updatedFileList,
      });
      onCaseStudyDataChange({
        data: {
          fileName: updatedFileList[0].fileName,
          fileUrl: updatedFileList[0].fileURL,
          multipleSubmissions: uploadCaseStudyForm.getFieldValue(
            "multipleSubmissions"
          ),
          allowedFiles: uploadCaseStudyForm.getFieldValue("allowedFiles"),
        },
        index: index,
      });

      onSuccess("ok");
    } catch (error) {
      console.error("Upload failed:", error);
      setDefaultFileList(
        defaultFileList.length > 0
          ? defaultFileList.filter((f) => f.name !== file.name)
          : []
      );
    } finally {
      setUploading(false);
    }
  };

  return (
    <Form form={uploadCaseStudyForm} name="uploadCaseStudyForm">
      {/* File Dropper */}
      <Form.Item
        label="Add the case study file for this round"
        name="caseStudyFiles"
        valuePropName="fileList"
        getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
      >
        <Upload
          accept="application/pdf"
          customRequest={uploadCaseStudy}
          listType="picture"
          defaultFileList={defaultFileList}
          maxCount={1}
          multiple={false}
          openFileDialogOnClick={defaultFileList.length < 1}
          onRemove={() => {
            setDefaultFileList([]);
            onCaseStudyDataChange({
              data: {
                fileName: null,
                fileUrl: null,
                multipleSubmissions: uploadCaseStudyForm.getFieldValue(
                  "multipleSubmissions"
                ),
                allowedFiles: uploadCaseStudyForm.getFieldValue("allowedFiles"),
              },
              index: index,
            });
          }}
        >
          {defaultFileList.length >= 1 || uploading ? (
            <p style={{ cursor: "text" }}>
              To Update, Delete and Upload Again.
            </p>
          ) : (
            <Button icon={<UploadOutlined style={{ fill: "#000" }} />}>
              Upload Case Study
            </Button>
          )}
        </Upload>
      </Form.Item>

      {/* Checkbox Group for Allowed File Types */}
      {/* <Form.Item
            label="Allowed Submission File Types"
            name="allowedFiles"
            initialValue={[]}
          >
            <Checkbox.Group
              className="flex flex-column"
              onChange={() =>
                onCaseStudyDataChange({
                  data: {
                    fileName:
                      defaultFileList.length > 0
                        ? defaultFileList[0].fileName
                        : null,
                    fileUrl:
                      defaultFileList.length > 0
                        ? defaultFileList[0].fileURL
                        : null,
                    multipleSubmissions: uploadCaseStudyForm.getFieldValue(
                      "multipleSubmissions"
                    ),
                    allowedFiles:
                      uploadCaseStudyForm.getFieldValue("allowedFiles"),
                  },
                  index: index,
                })
              }
            >
              {options.map((option) => (
                <Checkbox key={option.value} value={option.value}>
                  {option.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item> */}

      {/* Switch Toggle */}
      {/* <Form.Item
            label="Allow Multiple Submissions"
            name="multipleSubmissions"
            valuePropName="checked"
            initialValue={false}
          >
            <Switch
              onChange={() =>
                onCaseStudyDataChange({
                  data: {
                    fileName:
                      defaultFileList.length > 0
                        ? defaultFileList[0].fileName
                        : null,
                    fileUrl:
                      defaultFileList.length > 0
                        ? defaultFileList[0].fileURL
                        : null,
                    multipleSubmissions: uploadCaseStudyForm.getFieldValue(
                      "multipleSubmissions"
                    ),
                    allowedFiles:
                      uploadCaseStudyForm.getFieldValue("allowedFiles"),
                  },
                  index: index,
                })
              }
            />
          </Form.Item> */}
    </Form>
  );
}

export default SubmissionSettingsCaseStudy;
