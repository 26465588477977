import React, { useEffect, useState } from "react";
import Opportunities from "../StaticPages/Opportunities";
import axios from "axios";
import config from "../CommonFiles/config.json";
import OpportunitiesRightSide from "../CommonFiles/OpportunitiesRightSide";
import { Col, Row, Container, Card, Button } from "react-bootstrap";
import OpportunitiesRightSideCard from "../CommonFiles/OpportunitiesRightSideCard.js";
import Routerss from "./Routes";

const RightSidebar = () => {
  const onMenuClick = () => {
    var leftSidebar = document.querySelector(".left-sidebar");
    leftSidebar.classList.toggle("collapsed-in");
  };
  return (
    <div id="main">
      <nav className="navbar navbar-expand-lg navbar-light bg-light dashboard-top-header">
        <a className="navbar-brand" onClick={() => onMenuClick()}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </a>
        <form className="d-none d-sm-inline-block"></form>

        <div className="collapse navbar-collapse" id="navbarText">
          <div className="navbar-text ml-auto">
            <div className="header-right-icon">
              <a href="">
                <i className="fa fa-bell-o" aria-hidden="true"></i>
              </a>
              <a href="">
                <i className="fa fa-user" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </nav>

      <Routerss />

      <div className="copyright dashboard-copyright w-100">
        <p>© Copyright 2021 All Rights Reserved by Grad Partners</p>
      </div>
    </div>
  );
};

export default RightSidebar;
