import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../CommonFiles/config.json";
import { Table } from "antd";
import "./Usercss.css";
import "react-toggle/style.css";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const columns = [
  {
    title: "Team Name",
    dataIndex: "team_name",
  },
  {
    title: "Leader Name",
    dataIndex: "name",
    // sorter: {
    //   compare: (a, b) => a.name - b.name,
    //   multiple: 3,
    // },
  },
  {
    title: "Leader Email",
    dataIndex: "email",
    // sorter: {
    //   compare: (a, b) => a.email - b.email,
    //   multiple: 3,
    // },
  },
  {
    title: "Leader phone",
    dataIndex: "mobile",
    // sorter: {
    //   compare: (a, b) => a.mobile - b.mobile,
    //   multiple: 2,
    // },
  },
  {
    title: "Domain",
    dataIndex: "domain",
    // sorter: {
    //   compare: (a, b) => a.domain - b.domain,
    //   multiple: 2,
    // },
  },
  {
    title: "Course",
    dataIndex: "course",
    // sorter: {
    //   compare: (a, b) => a.course - b.course,
    //   multiple: 2,
    // },
  },
  {
    title: "Gender",
    dataIndex: "gender",
    // sorter: {
    //   compare: (a, b) => a.gender - b.gender,
    //   multiple: 2,
    // },
  },
  {
    title: "Submission",
    dataIndex: "button",
    render: (text, record) => (
      <button className="btn menubtn">
        <a href={record.submission_file} download>
          Download
        </a>
      </button>
    ),
  },
];

// const data = [
//   {
//     key: '1',
//     team_name: 'John Brown',
//     leader_name: 98,
//     leader_email: 'hemant@gmail.com',
//     leader_phone: 60,
//   },
//   {
//     key: '1',
//     team_name: 'John Brown',
//     leader_name: 'abhishek gautam',
//     leader_email: 'abhishek.gautam@gmail.com',
//     leader_phone: 60,
//   },
//   {
//     key: '1',
//     team_name: 'John Brown',
//     leader_name: 98,
//     leader_phone: 60,
//   },
//   {
//     key: '1',
//     team_name: 'John Brown',
//     leader_name: 98,
//     leader_phone: 60,
//   },
//   {
//     key: '1',
//     team_name: 'John Brown',
//     leader_name: 98,
//     leader_phone: 60,
//   },

// ];

const Submitionlist = (props) => {
  const [alldata, setAlldata] = useState();
  const [opportunity, setOpportunity] = useState();

  const [forfilter, setAForfilter] = useState();
  const [status, setStatus] = useState(1);
  const [attachmentstatus, setAttachmentstatus] = useState();
  const [submissionurl, setSubmissionurl] = useState();

  useEffect(() => {
    // const tokencheck = localStorage.getItem("token")
    const USER_TOKEN = localStorage.getItem("token");
    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    if (USER_TOKEN === null) {
      props.history.push(`/login`);
    } else {
      axios({
        method: "get",
        url: mainURL + "/opportunity/submissions/" + props.match.params.id,
        headers: { Authorization: AuthStr },
      })
        .then((response) => {
          // console.log("response", response)
          // const alldatas = response.data.data.rows;
          setAlldata(response.data.userSubmissions);
          setOpportunity(response.data.data);
          // console.info("alldata", alldata);
          // console.info("alldata", opportunity);
        })
        .catch(function (response) {
          //handle error
          console.log("Error while calling api", response);
        });
    }
  }, []);

  function filter(filterstatus, statuscheck) {
    console.log(filterstatus);
    const closedata = forfilter.filter(
      (workshop) => workshop.is_active === statuscheck,
    );
    if (filterstatus === 1) {
      setAlldata(forfilter);
    } else {
      setAlldata(closedata);
    }
    setStatus(statuscheck);

    console.log("close data", alldata);
  }

  function changestatus(event) {}

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const submissionResult = async () => {
    try {
      const result = await axios({
        method: "get",
        url:
          mainURL +
          "/submissions/result/excel?opportunity_id=" +
          props.match.params.id,
      });
      console.log("result", result.data);
      setSubmissionurl(result.data.path);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container topmargin">
      <div className="  mb-4">
        <div className="row">
          <div className="col-md-8 mt-15">
            <h5> Submissions for {opportunity && opportunity.title}</h5>
          </div>
          <div className="col-md-4 text-right">
            <button className="btn menubtn" onClick={() => submissionResult()}>
              <a
                href={
                  submissionurl && "http://13.232.79.236:7000/" + submissionurl
                }
                download
              >
                Download all submissions
              </a>
            </button>
          </div>
          <hr />
        </div>
      </div>
      {/* <hr/> */}

      <div className="row">
        {/* <div className="col-md-6">
          <input
            type="text"
            value=""
            name=""
            placeholder=""
            className="searchbox"
          ></input>
        </div> */}
      </div>
      {/* <div id="user-wrapper" className="dashboard row registerbox tp20"> */}
      <Table
        columns={columns}
        dataSource={alldata}
        bordered
        onChange={onChange}
      />

      {/* </div> */}
    </div>
  );
};

export default Submitionlist;
