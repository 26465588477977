import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import config from "../CommonFiles/config.json";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const USER_TOKEN = localStorage.getItem("token");
console.log("user token", USER_TOKEN);
const AuthStr = "Bearer ".concat(USER_TOKEN);
const Discountlist = (props) => {
  const [alldiscount, setalldiscount] = useState();

  useEffect(() => {
    axios({
      method: "get",
      url: mainURL + "/coupons?opportunity_id=" + props.match.params.id,

      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("fdsf", response.data);
        setalldiscount(response.data.data);
        // localStorage.setItem("allteam", JSON.stringify(inputFields));
        // props.history.push(`/success/`);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  }, []);
  function gotodiscount(id) {
    props.history.push(`/user/profile/event/dicount/` + id);
  }
  return (
    <div className="container topmargin">
      <div className="  mb-4">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-10">
              <h5> Coupon List</h5>
            </div>
            <div className="col-md-2">
              <button
                className="btn menubtn"
                onClick={() => gotodiscount(props.match.params.id)}
              >
                Add Coupon
              </button>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th>Coupon Name</th>
            <th>Discount</th>
            <th>Coupon type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {alldiscount &&
            alldiscount.map((discount) => (
              <tr>
                <td>{discount.name}</td>
                <td>{discount.coupon_value}</td>
                <td>{discount.coupon_type === true ? "Flat" : "Percentage"}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Discountlist;
