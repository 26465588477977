import React, { useEffect } from "react";
import Header from "../CommonFiles/Header";
import Footer from "../CommonFiles/Footer";
import "./static.css";

function Termandcondition() {
  window.scrollTo(0, 0);
  useEffect(() => {
    var element = document.getElementById("footershow");
    element.classList.add("footershow");
  }, []);

  return (
    <React.Fragment>
      <Header />
      <div className="topimagetnc"></div>
      <div className="container termandcondition">
        <p>
          Welcome to the Terms and Conditions (integral part of any
          collaboration) section of Grad Partners which govern the use of our
          service by you and will be applicable between Grad Partners and you
          (User(s)). By accessing our website and social media pages, (together
          shall be referred to as “Grad Partners Platform”), you agree to be
          bound by the provisions of these Terms and Conditions.
        </p>

        <p>
          Please read these Terms and Conditions, along with the Privacy Policy
          and all other rules and regulations made available or published on
          Grad Partners Platform as they shall govern your use of our services.
          By using or visiting the Grad Partners website or any Grad Partners
          service provided to you on, from, or through the Grad Partners
          platforms, you signify your agreement to these “Terms and Conditions”,
          Grad Partners’ Privacy Policy and any other terms that are updated
          from time to time. If you do not agree to any of these terms, please
          refrain yourself from using the Grad Partners Platform and our
          services.
        </p>

        <h4>1. About Grad Partners</h4>

        <p>
          The domain name and website are owned, registered and operated by Grad
          Partners, and having its registered office at Q5/20, DLF Phase II,
          Gurugram-122008, Haryana, India (hereinafter referred to as “Grad
          Partners” or “us” or “we” or “our” or “Company”).
        </p>

        <h4>2. Grad Partners Platforms</h4>

        <p>
          These Terms and Conditions apply to all users of the Grad Partners
          platforms. The Grad Partners Platform includes all aspects of the
          Website and social media pages which includes but is not limited to
          products and service offered via their Platforms.
        </p>

        <p>
          The Grad Partners Platform may include or contain links to any
          third-party websites that may or may not be owned or controlled by
          Grad Partners. Grad Partners has no control over, and assumes no
          responsibility for, the content, privacy policies, or practices of any
          third-party websites. In addition, Grad Partners will not and cannot
          censor or edit the content of any third-party site. However, we
          recommend that before you use any third-party services or website you
          read and understand the terms and conditions and privacy policy of
          each such website that you visit.
        </p>

        <p>
          Subject to these Terms and Conditions, Privacy Policy and all other
          rules and policies made available or published elsewhere, Grad
          Partners hereby grants the you a non-exclusive, non-transferable,
          non-sub licensable, limited license to use the Grad Partners Platform
          and intellectual properties in accordance with these Terms and
          Conditions.
        </p>

        <p>
          You agree and acknowledge that Grad Partners shall have the right at
          any time to change or discontinue any aspect or feature of the Grad
          Partners Platform, including, but not limited to, the User Content,
          hours of availability and devices required for access or use. Grad
          Partners reserves the right to refuse access to the Grad Partners
          Platform, terminate accounts, remove or edit contents without any
          notice to you.
        </p>

        <h4>3. Access, Permissions and Restrictions</h4>

        <p>
          Grad Partners hereby grants you permission to access and use the Grad
          Partners Platform as set forth in these Terms and Conditions, provided
          that:
        </p>

        <p>
          You agree not to distribute in any medium or any part of the Grad
          Partners Platform or the content without Grad Partners’ prior written
          authorization.
        </p>

        <p>
          You agree not to alter or modify any part of the Grad Partners
          Platform. You agree not to access content of any other User through
          any technology or means other than the video playback pages of the
          Grad Partners Platform itself.
        </p>

        <p>
          You agree not to use the Grad Partners Platform for any of the
          following commercial uses unless you obtain Grad Partners’ prior
          written approval: the sale of access to the Grad Partners Platform;
          the sale of advertising, sponsorships, or promotions placed on or
          within the Grad Partners Platform or content; or the sale of
          advertising, sponsorships, or promotions on any page or website that
          provide similar Grad Partners Platform as that of Grad Partners.
        </p>

        <p>
          You agree not to use or launch any automated system, including without
          limitation, “robots,” “spiders,” or “offline readers,” that accesses
          the Grad Partners Platform in a manner that sends more request
          messages to Grad Partners’ servers in a given period of time than a
          human can reasonably produce in the same period by using a
          conventional on-line web browser. Notwithstanding the foregoing, Grad
          Partners grants the operators of public search engines permission to
          use spiders to copy materials from the site for the sole purpose of
          and solely to the extent necessary for creating publicly available
          searchable indices of the materials, but not caches or archives of
          such materials. Grad Partners reserves the right to revoke these
          exceptions either generally or in specific cases at any time with or
          without providing any notice in this regard. You agree not to collect
          or harvest any personally identifiable information, including account
          names, from the Grad Partners Platform, nor to use the communication
          systems provided by the Grad Partners Platform (e.g., comments, email)
          for any commercial solicitation purposes. You agree not to solicit,
          for commercial purposes, any Users of the Grad Partners Platform with
          respect to User Content.
        </p>

        <p>
          You may post reviews, comments and other content; send other
          communications; and submit suggestions, ideas, comments, questions or
          other information as long as the content is not illegal, obscene,
          threatening, defamatory, invasive of privacy, infringement of
          intellectual property rights, or otherwise injurious to third parties
          or objectionable and does not consist of or contain software viruses,
          political campaigning, commercial solicitation, chain letters, mass
          mailings or any other form of spam. Further, you give Grad Partners
          limited, royalty free, worldwide, non-exclusive license to use the
          User Content and communication in developing its Grad Partners
          Platform and in any of its marketing or promotional activities.
        </p>

        <p>
          In your use of the Grad Partners Platform, you will at all times
          comply with all applicable laws and regulations. Grad Partners
          reserves the right to discontinue any aspect of the Grad Partners
          Platform at any time with or without notice at its sole discretion.
        </p>

        <h4>4. Content Use</h4>

        <p>
          In addition to the general restrictions mentioned above, the following
          limitation and conditions shall apply to your use of the Content.
        </p>

        <p>
          Grad Partners content utilized on the Grad Partners Platform which
          shall include but not be limited to trademarks, service marks &amp;
          logos, process, images, graphics are owned by or licensed to Grad
          Partners and subject to copyright and other intellectual property
          rights under the law.
        </p>

        <p>
          User Content is provided to you on an AS IS basis. You may access
          Content for your information and personal use solely as intended
          through the provided functionality on the Grad Partners Platform and
          as permitted under these Terms and Conditions. You shall not download
          any User Content unless you see a “download” or similar link displayed
          by Grad Partners on our Platform for that user content. You shall not
          copy, reproduce, make available online or electronically transmit,
          publish, adapt, distribute, transmit, broadcast, display, sell,
          license, or otherwise exploit any user content for any other purposes
          other than as provided herein without the prior written consent of
          Grad Partners or the respective licensors of the user content.{" "}
        </p>

        <p>
          You agree not to circumvent, disable or otherwise interfere with
          security-related features of the Grad Partners Platform or features
          that prevent or restrict use or copying of any User Content or enforce
          limitations on use of the Grad Partners Platform or the User Content
          therein.
        </p>

        <p>
          You understand that when using the Grad Partners Platform, you will be
          exposed to User Content from variety of sources and by different
          Users, and that Grad Partners is not responsible for the accuracy,
          usefulness, safety, or intellectual property rights of or relating to
          such User Content. You further understand and acknowledge that you may
          be exposed to User Content that is inaccurate, offensive, indecent, or
          objectionable, and you agree to waive, and hereby do waive, any legal
          or equitable rights or remedies you have or may have against Grad
          Partners with respect thereto, and, to the extent permitted by
          applicable law, agree to indemnify and hold harmless Grad Partners,
          its owners, operators, affiliates, licensors, and licensees to the
          fullest extent allowed by law regarding all matters related to your
          use of the Grad Partners Platform.
        </p>

        <h4>5. Content and Conduct</h4>

        <p>
          As Grad Partners User, you may submit User Content on the Grad
          Partners Platform, including but not limited to presentations, videos,
          other documents and user comments. You understand that Grad Partners
          will such submission against any competitive thing confidential and
          will be shared only with the intended users.
        </p>

        <p>
          You shall be solely responsible for your own user Content and the
          consequences of submitting and publishing such User Content on the
          Grad Partners Platform. You affirm, represent, and warrant that you
          own or have the necessary licenses, rights, consents, and permissions
          to publish the User Content that you submit; and you grant limited
          license to Grad Partners to all patent, trademark, trade secret,
          copyright or other proprietary rights in and to such User Content for
          publication on the Grad Partners Platform pursuant to these Terms and
          Conditions for the duration the said User Content is available on Grad
          Partners Platform.
        </p>

        <p>
          For clarity, you retain your ownership rights in your User Content.
          However, by submitting User Content to Grad Partners, you hereby grant
          Grad Partners a limited, worldwide, non-exclusive, royalty-free,
          sub-licenseable and transferable license to use, reproduce,
          distribute, display, publish, make available online or electronically
          transmit, the User Content in connection with the Grad Partners
          Platform and Grad Partners’ (and its successors’ and affiliates’)
          business, including without limitation for promoting and
          redistributing part or all of the Grad Partners Platform in any media
          formats and through any media channels. You also hereby grant each
          User of the Grad Partners Platform a limited, non-exclusive license to
          access your User Content through the Grad Partners Platform. The above
          licenses granted by you in the User Content you submit to the Grad
          Partners Platform terminate within a commercially reasonable time
          after you request Grad Partners to remove or delete your videos from
          the Grad Partners Platform provided you pay the mutually agreed amount
          that Grad Partners incurred in providing the Grad Partners Platform to
          you. You understand and agree, however, that Grad Partners may retain,
          but not display, distribute, or perform, server copies of your videos
          that have been removed or deleted. The above licenses granted by you
          in User comments and testimonial you submit are perpetual and
          irrevocable.
        </p>

        <p>
          You further agree that User Content you submit on the Grad Partners
          Platform will not contain third party copyrighted material, or
          material that is subject to other third party proprietary rights,
          unless you have permission from the rightful owner of the material or
          you are otherwise legally entitled to post the material and to grant
          Grad Partners all of the license rights granted herein.
        </p>

        <p>
          You understand and confirm that you shall not during your use of the
          Grad Partners Platforms at any time post or publish any content,
          comments or act in any way which will lead to harassment of any other
          User, whether a learner or an educator. If at any given point it comes
          to the notice of Grad Partners that you are engaged in any kind of
          harassment of other Users, then in such a case you agree that Grad
          Partners shall have the sole right to suspend/terminate your account
          with immediate effect and without any notice of such suspension or
          termination and we also reserve the right in our sole discretion to
          initiate any legal proceedings against you in such cases.
        </p>

        <p>
          Grad Partners at its sole discretion may process any audio or
          audio-visual content uploaded by you to the Grad Partners Platform to
          confirm if the same is in accordance with the Grad Partners internal
          quality requirements and is compatible with the Grad Partners
          Platform.
        </p>

        <p>
          Grad Partners does not endorse any User Content submitted on the Grad
          Partners Platform by any User or other licensor, or any opinion,
          recommendation, or advice expressed therein, and Grad Partners
          expressly disclaims any and all liability in connection with User
          Content. Grad Partners does not permit copyright infringing activities
          and infringement of intellectual property rights on the Grad Partners
          Platform, and Grad Partners will remove all User Content if properly
          notified that such Content infringes on another’s intellectual
          property rights. Grad Partners reserves the right to remove User
          Content without prior notice if it has reason to believe that the User
          Content infringes any copyright.
        </p>

        <h4>6. Electronic Communication</h4>

        <p>
          When you visit Grad Partners Platform or send email to us, you are
          communicating with us electronically. By communicating with us, you
          consent to receive communication from us electronically. We will
          communicate with you by email or posting notices on Grad Partners
          Platform. You agree that all agreements, notices, disclosures,
          disclaimers, offers and other communications that are provided to you
          electronically satisfy any legal requirement that such communication
          should be in writing.
        </p>

        <p>
          All our services are online. No physical delivery will be carried out
          for any of the services. Users will get an email once a service is
          purchased with instructions on how to go about availing them on the
          website. Our team of experts will assist you in providing a hassle
          free user experience.
        </p>

        <h4>7. Termination of Account and Access Restriction</h4>

        <p>
          Grad Partners reserves the right, in its sole discretion,
          terminate/suspend a User's access to the Grad Partners Platform, if
          the Users breaches any terms of these Terms and Conditions; Violation
          of any applicable laws; your use of the Grad Partners Platform
          disrupts our business operations or affects any other party/ User; or
          you have behaved in a way, which objectively could be regarded as
          inappropriate or unlawful or illegal or which would bring any claims
          against Grad Partners.
        </p>

        <p>
          Grad Partners reserves the right to decide whether User Content
          violates these Terms and Conditions for reasons other than copyright
          infringement, such as, but not limited to, pornography, obscenity, or
          excessive length or any other parameter that Grad Partners deems fit
          from time to time. Grad Partners may at any time, without prior notice
          and in its sole discretion, remove such User Content and/or terminate
          a User's account for submitting such material in violation of these
          Terms and Conditions.
        </p>

        <p>
          We may suspend access to the Grad Partners Platforms or require user
          to change their password if we reasonably believe that the Grad
          Partners Platforms have been or are likely to be misused, and we will
          notify users accordingly.{" "}
        </p>

        <h4>8. Copyright Issue:</h4>

        <p>
          Grad Partners operates a clear copyright policy in relation to any
          User Content alleged to infringe the copyright of a third party. As
          part of Grad Partner’s copyright policy, we will terminate user access
          to the Grad Partners Platform if a User has been determined to be a
          repeat infringer. A repeat infringer is a User who has been notified
          of infringing activity more than twice.
        </p>

        <p>
          If you believe that your work has been copied in a way that
          constitutes copyright infringement, please refer to the instructions
          given in the copyright policy on how to make a claim of copyright
          infringement.
        </p>

        <h4>9. Confidentiality</h4>

        <p>
          You will not without obtaining prior written consent of Grad Partners,
          disclose to third party any Confidential Information (as defined
          below) that is disclosed to you during the term of your use of the
          Grad Partners Platform.
        </p>

        <p>
          For the purpose of this clause Confidential Information shall include
          but shall not be limited to employee details, User list, business
          model, processes, ideas, concepts etc. relating to Grad Partners or
          Grad Partners Platform which are not available in the public domain.
          You acknowledge and agree that the Confidential Information so
          provided to you shall at all-time be the property of Grad Partners and
          any breach of the same shall cause irreparable damage to us.
        </p>

        <h4>10. Limitation of Liability</h4>

        <p>
          Grad Partners will endeavor to ensure that all the information on the
          Website is correct, but we neither warrants nor makes any
          representations regarding the quality, accuracy or completeness of any
          data, information, product or Service. In no event shall Grad Partners
          be liable for any direct, indirect, punitive, incidental, special,
          consequential damages or any other damages resulting from
        </p>

        <p>
          the use or the inability to use the Services, unauthorized access to
          or alteration of the user's transmissions or data etc.
        </p>

        <h4>
          11. Eligibility to use and Acceptance of the Terms and Conditions
        </h4>

        <p>
          You affirm that you are either more than 18 years of age, or possess
          legal parental or guardian consent, and are fully able and competent
          to enter into the terms, conditions, obligations, affirmations,
          representations, and warranties set forth in these Terms and
          Conditions, and to abide by and comply with these Terms and
          Conditions. If you are under 18 years of age, then please talk to your
          parents or guardian before using the Grad Partners Platform.
        </p>

        <p>
          Grad Partners reserves the right to refuse access to use the Grad
          Partners Platforms to any Users or to suspend and/or terminate access
          granted to existing registered Users at any time without according any
          reasons for doing so.
        </p>

        <p>
          We provide these Terms and Conditions with our Grad Partners Platform
          so that you know what terms apply to your use. You acknowledge that we
          have given you a reasonable opportunity to review these Terms and
          Conditions and that you have agreed to them. You agree and acknowledge
          that your use of the Grad Partners Platform is subject to the most
          current version of the Terms and Conditions made available on the Grad
          Partners Platform at the time of such use.
        </p>

        <h4>12. Force Majeure</h4>

        <p>
          Grad Partners shall not be liable for failure to perform, or the delay
          in performance of, any of its obligations if, and to the extent that,
          such failure or delay is caused by events substantially beyond the its
          control, including but not limited to acts of God, acts of the public
          enemy or governmental body in its sovereign or contractual capacity,
          war, terrorism, floods, fire, strikes, epidemics, civil unrest or
          riots, power outage, and/or unusually severe weather.
        </p>

        <h4>13. Information Technology Act</h4>

        <p>
          These Terms and Conditions are published in accordance with the
          provisions of Rule 3 (1) of the Information Technology (Intermediaries
          guidelines) Rules, 2011 that require publishing the rules and
          regulations, privacy policy and terms and conditions for access or
          usage of the Grad Partners Platform.
        </p>

        <p>
          You are advised not to post any information or messages that are, or
          that may be construed, as being malicious, defamatory, inappropriate,
          slanderous, pornographic or otherwise sexually oriented or that makes
          attacks on or the otherwise opines or comments on any individuals or
          groups of individuals, educational institutions or any other entities
          whatsoever (whether companies, firms, or any other institutions). You
          also agree not to post any information to which you do not have
          copyrights or other appropriate permissions to post in public forum.
          Your failure to comply with these terms may result in removal of your
          postings without prior notice to User. The IP address of all posts is
          recorded to aid in enforcing these conditions.
        </p>

        <h4>14. Governing Law and Jurisdiction</h4>

        <p>
          The Terms and Conditions are governed by and constructed in accordance
          with the laws of India, without reference to conflict of laws
          principles and disputes arising in relation hereto shall be subject to
          the exclusive jurisdiction of the courts at Delhi.
        </p>

        <h5>15. Modification, Amendment or Termination</h5>

        <p>
          Grad Partners may, in its sole discretion, amend their Terms and
          Conditions and policies at any time, and you agree to be bound by such
          modifications or revisions. User shall be responsible for checking
          them to remain in compliance with these terms. Your continued use of
          our Platform post any modification of the Terms &amp; Conditions shall
          constitute your consent &amp; acceptance to such modifications and
          users also agree to be bound by any such amendments. Nothing in these
          Terms and Conditions shall be deemed to confer any third-party rights
          or benefits.
        </p>

        <h4>16. Refund Policy / Cancellation</h4>

        <p>
          Please read the terms and conditions carefully before paying for any
          of products or services of Grad Partners as once you make the required
          payment, it shall be final and there cannot be any changes or
          modifications to the same, neither will there be any refund nor
          exchange permitted. However, if in a transaction performed by you on
          the platform, money has been charged to your card or bank account
          without the delivery of the services, then you may inform us by
          sending an email to{" "}
          <a href="mailto:support@gradpartners.in">support@gradpartners.in</a>{" "}
          or through Contact Us page. Grad Partners shall investigate the
          incident and if it is found that money was indeed charged to your card
          or bank account without delivery of the service, then you will be
          refunded the money within 21 working days from the date of receipt of
          your email. All refunds will be credited back to source of the
          payment. It will take 3-21 days for the money to show in your bank
          account depending on your bank’s policy.
        </p>

        <h4>17. General</h4>

        <p>
          If any part of these Terms and Conditions is unlawful, void or
          unenforceable, that part of will be deemed severable and will not
          affect the validity and enforceability of any remaining provisions.
          Any notice required to be given in connection with the Grad Partners
          Platform shall be in writing and sent to the registered office of Grad
          Partners. We do not guarantee continuous, uninterrupted or secure
          access to the Grad Partners Platform, and operation of the Grad
          Partners Platform may be interfered by numerous factors outside our
          control. Headings are for reference purpose only an on no way define,
          limit, construe or describe the scope or extent of such section. Out
          failure to act with respect to any breach by you or others does not
          waive our right to act with respect to subsequent or similar breaches.
        </p>

        <br />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Termandcondition;
