import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../CommonFiles/config.json";
import Header from "../../CommonFiles/Header";
import { FaArrowLeft, FaArrowRight, FaDownload, FaSave } from "react-icons/fa";
import { Link } from "react-router-dom";
import Footer from "../../CommonFiles/Footer";
import { Tabs, Radio, Select, Collapse } from "antd";
import { Col, Row, Button, Form, TabContent } from "react-bootstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from "sweetalert2";
import CKEditor from "react-ckeditor-component";

import { stateFromHTML } from "draft-js-import-html";
import $ from "jquery";
import uploadfileimg from "../../../images/uploadfile.png";
import "./quizsetting.css";
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;
const { TabPane } = Tabs;
const AddQuestions = (props) => {
  const [errormsg, setErrormsg] = useState("");
  const [addQuizstatus, setAddQuizstatus] = useState("auto");
  const [questionFile, setQuestionFile] = useState("");
  const [tabkey, setTabkey] = useState([
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
  ]);
  let [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [autouploadmsg, setAutoUploadErrormsg] = useState("");
  const userdata = JSON.parse(localStorage.getItem("alldata"));
  const [inputFields, setInputFields] = useState([
    {
      question: "",
      answer: "",
      points: "",
      negativeMark: "",
      answers: [
        { choice: "", type: "text" },
        { choice: "", type: "text" },
      ],
    },
  ]);
  const [basicdetail, setBasicdetail] = useState([
    {
      title: "",
      discription: "",
      start_date: "",
      end_date: "",
      start_time: "",
      end_time: "",
      duration: "",
      total_mark: "",
    },
  ]);
  const [contactFields, setContactFields] = useState([{ choice: "" }]);
  const [setting, setSetting] = useState([
    {
      question_type: "yes",
      who_can_play: "yes",
      shuffling_quetions: "",
      shuffling_options: "",
      showquestion: "",
    },
  ]);
  const [answers, setAnswers] = useState([]);
  const { Panel } = Collapse;

  useEffect(async () => {
    try {
      const result = await axios({
        method: "get",
        url:
          mainURL + "/opportunity/" + localStorage.getItem("editopportunityid"),
      });
      console.log("result", result.data.data);

      if (result.data.data.quiz_basic) {
        let quiz_basic = JSON.parse(result.data.data.quiz_basic);
        setBasicdetail(quiz_basic);
        console.log("quiz_basic.discription", quiz_basic[0].discription);
        let contentState = stateFromHTML(quiz_basic[0].discription);
        console.log(contentState);

        setEditorState(EditorState.createWithContent(contentState));
      }
      if (result.data.data.quiz_setting) {
        let quizsetting = JSON.parse(result.data.data.quiz_setting);
        setSetting(quizsetting);
      }
      if (result.data.data.quizes) {
        alert("alert");
        let allqueiz = JSON.parse(result.data.data.quizes);

        setInputFields(allqueiz);
      }

      // console.log("rounds", result.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [addQuizstatus]);

  // get from localhost
  useEffect(() => {
    let localroundData = JSON.parse(localStorage.getItem("FormFour"));
    if (localroundData[props.quizIndexval].quizes) {
      setInputFields(JSON.parse(localroundData[props.quizIndexval].quizes));
    }
    for (let index = 0; index < inputFields.length; index++) {
      tabkey.push(JSON.stringify(index));
      console.log("all keys data", index);
    }
    setTabkey(tabkey);
    if (localroundData[props.quizIndexval].quizes) {
      setAddQuizstatus("mannual");
    }
    console.log("all keys", tabkey, inputFields);
  }, [localStorage.getItem("FormFour")]);

  useEffect(() => {
    setTabkey(tabkey);
  }, [tabkey]);

  const handleAddFields = (index) => {
    const values = [...inputFields];
    values.push({
      question: "",
      answer: "",
      points: "",
      negativeMark: "",
      answers: [
        { choice: "", type: "text" },
        { choice: "", type: "text" },
      ],
    });
    setInputFields(values);
    setTabkey([]);

    console.log("all key val inside before", tabkey, "len", inputFields.length);

    tabkey.push(JSON.stringify(inputFields.length));
    console.log("all key val inside after", tabkey, "len", inputFields.length);

    console.log("all key val", tabkey);
    setTabkey(tabkey);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  function onEditorStateChange(editorState) {
    const value = basicdetail;
    value[0].discription = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
    );
    setEditorState(editorState);
  }

  const handleAddcontactFields = (index) => {
    const values = [...contactFields];
    const fild = [...inputFields];
    console.log("add choice", fild[index]);
    console.log("add 2nd  choice", index);
    console.log(inputFields);

    if (fild[index].answers.length > 3) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Can not add more than 4 options",
      });
    } else {
      console.log("under if");
      fild[index].answers.push({
        choice: "",
        type: "text",
      });
      // setContactFields(values);

      setInputFields(fild);
    }

    // setAnswers(ans)
  };

  const handleRemovecontactFields = (index) => {
    const values = [...contactFields];
    values.splice(index, 1);
    setContactFields(values);
  };

  // input entered
  const onInputChangecontact = (val, name, idx) => {
    // setInputValue({ ...inputValue, [name]: val });

    const rows = [...inputFields];
    if (name === "typeofquestion") {
      rows[idx].typeofquestion = val;
    }
    if (name == "question") {
      rows[idx].question = val.editor.getData();
    }
    if (name == "currectans") {
      rows[idx].answer = val;
    }
    if (name == "points") {
      rows[idx].points = val;
    }
    if (name == "negativeMark") {
      rows[idx].negativeMark = val;
    }

    console.log("ljghgjk", val, name, idx);
    setInputFields(rows);
  };
  const onSelectedAns = (val, name, idx) => {
    // setInputValue({ ...inputValue, [name]: val });

    const rows = [...inputFields];

    rows[idx].answer = val;
    console.log("onSelectedAns", val, name, idx);
    setInputFields(rows);
  };
  function onInputsetans(val, name, idx, qidx) {
    console.log("ans choice", val, name, idx, qidx);
    const rows = [...inputFields];

    rows[qidx].answers[idx].choice = val;
    rows[qidx].answers[idx].type = "text";

    console.log("enter row", rows);
    setInputFields(rows);
  }

  function onInputremoveans(index, qidx) {
    const values = [...inputFields];

    // values[qidx].answers.splice(index, 1);
    // delete values[qidx].answers[index];
    // values[qidx].answers[index] = ''
    // values[qidx].answers[index] = undefined
    let newarr = values[qidx].answers;

    let sectionid = "choiceid_" + qidx + "-" + index;

    // var gfg_down = document.getElementById(sectionid);
    // gfg_down.remove();
    const filteredPeople = values[qidx].answers.filter(
      (item, itemi) => itemi != index,
    );

    //   delete filteredPeople[index];
    // values[qidx].answers.splice(index, 1);

    console.log("remove row splice od", filteredPeople);

    values[qidx].answers = filteredPeople;
    setInputFields(values);
    console.log("remove row splice", values);

    // setContactFields(values);
  }

  const prevStep = () => {
    localStorage.setItem("quizStep", 1);
    props.newActiveStepValue(1);
  };
  const nextStep = () => {
    if (addQuizstatus === "mannual") {
      var inputTag = document.querySelectorAll(".questionrequiredfield");
      // var SelectedTag = document.querySelectorAll('.form-body select[className="organisationnam"]');
      for (var i = 0; i < inputTag.length; i++) {
        if (inputTag[i].value === "") {
          console.log("input value", inputTag[i].value);
          inputTag[i].setAttribute("id", "quizfill-the-field");
        } else {
          inputTag[i].setAttribute("id", "");
        }

        if (!$("input[name='shorlist']:checked").val()) {
          $("input[name='shorlist']")
            .parent()
            .parent()
            .attr("id", "quizfill-the-field");
        } else {
          $("input[name='shorlist']").parent().parent().attr("id", "");
        }
      }
      let roundVal = JSON.parse(localStorage.getItem("FormFour"));
      console.log("submit", inputFields);
      for (let index = 0; index < inputFields.length; index++) {
        const element = inputFields[index].answers;
        for (let choiceindex = 0; choiceindex < element.length; choiceindex++) {
          const questionChoice = element[choiceindex].choice;

          if (questionChoice === "") {
            const choiceID = `choiceid_${index}-${choiceindex}`;
            var imageParent = document.getElementById(choiceID);
            $(imageParent).addClass("redborder");

            console.log(
              "index",
              index,
              "choice index",
              choiceindex,
              imageParent,
            );
          }
        }
      }

      for (let i = 0; i < roundVal.length; i++) {
        if (i === props.quizIndexval) {
          roundVal[props.quizIndexval].quizes = JSON.stringify(inputFields);
        }
      }
      localStorage.setItem("FormFour", JSON.stringify(roundVal));
      var errorexits = document.querySelectorAll("#quizfill-the-field");
      console.log("errorexits", errorexits.length);

      if (errorexits.length > 0) {
        setErrormsg("Please enter mandatory details");
        return false;
      } else {
        console.log("inside error", inputFields);
        for (let index = 0; index < inputFields.length; index++) {
          const element = inputFields[index].answer;

          if (element === "") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Please select correct answer for each question",
            });
            return false;
          }
        }
        props.closeBtn(false);
      }
    } else {
      if (questionFile === "") {
        setAutoUploadErrormsg("Please upload file");
      } else {
        props.closeBtn(false);
      }
    }
  };

  const questionimg = (val, name, newval, index) => {
    let newid = "file-image" + index;
    // let imgRef = URL.createObjectURL(newval);
    const file = newval;
    // let oldref = document.getElementById(`${newid}`).src=imgRef ;
    var imageParent = document.getElementById(newid);
    console.log("image pre", imageParent);

    if (imageParent.hasChildNodes()) {
      // document.getElementById("my-element").remove();
      imageParent.removeChild(imageParent.firstChild);
      // imageParent.parentNode.removeChild(newid);
    }
    var image = document.createElement("img");

    // let oldref = document.getElementById(`${newid}`).src=imgRef ;
    var imageParent = document.getElementById(newid);
    var image = document.createElement("img");

    // imageParent.src = imgRef;
    image.id = "id";
    image.className = "save-hosting-first-btn questionpreviewimg";
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        image.src = e.target.result;
      };

      reader.readAsDataURL(file);
    }
    image.width = "100%"; // image.src = "IMAGE URL/PATH"
    imageParent.appendChild(image);
  };

  // choice image
  const choicequestionimg = (val, name, newval, qindex, choiceindex) => {
    let newid = "choice-image" + qindex + "-" + choiceindex;
    console.log("image pre", newid);
    let inputsection = "choice-input-uploads" + qindex + "-" + choiceindex;
    // let imgRef = URL.createObjectURL(newval);
    const file = newval;
    // let oldref = document.getElementById(`${newid}`).src=imgRef ;
    var imageParent = document.getElementById(newid);
    var inputsectionid = document.getElementById(inputsection);
    inputsectionid.className = "hideinput";

    if (imageParent.hasChildNodes()) {
      // document.getElementById("my-element").remove();
      imageParent.removeChild(imageParent.firstChild);
      // imageParent.parentNode.removeChild(newid);
    }
    var image = document.createElement("img");

    // let oldref = document.getElementById(`${newid}`).src=imgRef ;
    var imageParent = document.getElementById(newid);
    var image = document.createElement("img");
    var span = document.createElement("span");
    span.innerHTML = "X";
    span.className = "removechoiceimg";
    span.id = "removechoiceimg" + qindex + "-" + choiceindex;
    var myFancyFunction = function () {
      // document.getElementById("id" + qindex + "-" + choiceindex).remove();
      console.log(
        "remove image",
        "removechoiceimg" + qindex + "-" + choiceindex,
      );

      var removeimage = document.getElementById(
        "removechoiceimg" + qindex + "-" + choiceindex,
      );
      removeimage.remove();
      const rows = [...inputFields];

      rows[qindex].answers[choiceindex].type = "text";
      rows[qindex].answers[choiceindex].choice = "";

      console.log("enter row", rows);
      setInputFields(rows);
      inputsectionid.className = "questioninput questionrequiredfield";
      inputsectionid.value = "";
    };
    span.addEventListener("click", myFancyFunction);
    // imageParent.src = imgRef;
    image.id = "id" + qindex + "-" + choiceindex;
    image.className = "save-hosting-first-btn choicepreviewimg";
    if (file) {
      const reader = new FileReader();

      reader.onload = function (e) {
        image.src = e.target.result;
      };

      reader.readAsDataURL(file);
    }
    image.width = "100%"; // image.src = "IMAGE URL/PATH"
    // imageParent.appendChild(image);
    imageParent.appendChild(span);

    const USER_TOKEN = localStorage.getItem("token");
    const oppid = localStorage.getItem("oppid");

    console.log("user token", USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    const bannerData = new FormData();
    bannerData.append("image", newval, name);
    axios({
      method: "post",
      url: mainURL + "/quiz/image",
      data: bannerData,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        let imgurl = response.data.image_url;
        console.log("all analytics", response.data);
        const rows = [...inputFields];

        rows[qindex].answers[choiceindex].choice = imgurl;
        rows[qindex].answers[choiceindex].type = "image";

        console.log("enter row", rows);
        setInputFields(rows);
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };
  function callbackaccordian(key) {
    console.log(key);
  }

  function questionFileAction(val, name) {
    setQuestionFile(name);
  }

  const clearQuestion = () => {
    setInputFields([]);
  };

  const uploadQuestions = (name, newval, val) => {
    const USER_TOKEN = localStorage.getItem("token");
    const oppid = localStorage.getItem("oppid");

    const AuthStr = "Bearer ".concat(USER_TOKEN);
    let roundVal = localStorage.getItem("round_id");
    console.log("user val", name, newval, roundVal);

    const bannerData = new FormData();
    bannerData.append("quizefile", val, name);
    bannerData.append("round_id", roundVal);

    axios({
      method: "post",
      url: mainURL + "/upload/quiz/excel",
      data: bannerData,
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        let imgurl = response.data.image_url;
        if (response.data.status === 0) {
          // alert(response.data.message)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.message,
          });
          return false;
        }
        setQuestionFile(name);

        console.log("all analytics", response.data);
        axios({
          method: "get",
          url:
            mainURL +
            "/opportunity/" +
            localStorage.getItem("editopportunityid"),
        })
          .then((response) => {
            const alldata = response.data.rounds;

            localStorage.setItem("FormFour", JSON.stringify(alldata));
            for (let index = 0; index < alldata.length; index++) {
              const element = alldata[index];
              if (element.id == roundVal) {
                setInputFields(JSON.parse(element.quizes));
              }
            }
          })
          .catch((err) => {
            console.log("jhghjghg", err);
          });

        setAddQuizstatus("mannual");
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  return (
    <section className="quizsettingsection">
      <div id="user-wrapper" className="dashboard form addquizsection topmargin">
        <div className="row question-form-body">
          <Form className="quizform">
            <div className="">
              <h6>
                Upload Questions <span className="importantfield">*</span>{" "}
              </h6>
              <br></br>
              <span className="alert mr-md-5 mt-md-5">
                <input
                  type="radio"
                  name="addQuizstatus"
                  onChange={() => {
                    setAddQuizstatus("auto");
                  }}
                  checked={
                    addQuizstatus === null ? null : addQuizstatus === "auto"
                  }
                />{" "}
                Do you want to upload the excel
              </span>
              <span className="alert mr-md-5 mt-md-5">
                <input
                  type="radio"
                  name="addQuizstatus"
                  onChange={() => {
                    setAddQuizstatus("mannual");
                  }}
                  checked={
                    addQuizstatus === null ? null : addQuizstatus === "mannual"
                  }
                />{" "}
                Do you want to add manually add the questions
              </span>
            </div>
            {addQuizstatus === "mannual" && (
              <Collapse defaultActiveKey={tabkey} onChange={callbackaccordian}>
                {inputFields.map((inputField, qindex) => (
                  <>
                    {qindex > 0 && (
                      <div className="text-right removeicon removeiconq">
                        <button
                          className="btn btn-link pt-2 minus-btn"
                          type="button"
                          onClick={() => handleRemoveFields(qindex)}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                      </div>
                    )}
                    {}
                    <Panel header={`Question ${qindex + 1}`} key={qindex}>
                      <div className="col-md-12 pt-3">
                        <div className="row">
                          <div className="col-md-12  inputboxcss quizform  pb-2">
                            <div className="form-group float-left col-md-6">
                              <Form.Label className="input-titlle">
                                Type Of Question{" "}
                                <span className="importantfield">*</span>
                              </Form.Label>

                              <Select
                                className="questionrequiredfield"
                                defaultValue={
                                  inputField.type
                                    ? inputField.type
                                    : "Select category"
                                }
                                onChange={(e) =>
                                  onInputChangecontact(
                                    e,
                                    "typeofquestion",
                                    qindex,
                                  )
                                }
                                size={"large"}
                                style={{
                                  width: "100%",
                                  height: 40,
                                }}
                                dropdownMatchSelectWidth={false}
                                // placement=""
                                options={[
                                  {
                                    label: "Select Type Of Question",
                                  },
                                  {
                                    value: "MCQ",
                                    label: "Multiple Choice Questions (MCQ)",
                                  },
                                  {
                                    value: "submission",
                                    label: "Submission",
                                  },
                                ]}
                              />
                            </div>
                            <div className="form-group float-left col-md-12">
                              <label
                                className="input-titlle form-label"
                                htmlFor="firstName"
                              >
                                Questions{" "}
                                <span className="importantfield">*</span>
                              </label>
                              <div>
                                <CKEditor
                                  activeClass="p10 mt-4"
                                  content={inputField.question}
                                  id="cke_editor1"
                                  // defaultValue={this.state.aboutOpportunity}
                                  events={{
                                    change: (e) =>
                                      onInputChangecontact(
                                        e,
                                        "question",
                                        qindex,
                                      ),
                                  }}
                                />
                              </div>

                              {/* <input
                                type="text"
                                className="form-control questionrequiredfield"
                                name="question"
                                defaultValue={inputField.question}
                                onChange={(e) =>
                                  onInputChangecontact(
                                    e.target.value,
                                    e.target.name,
                                    qindex
                                  )
                                }
                              /> */}

                              {/* <span className="uploader logosec">
                                <input
                                  type="file"
                                  id={`file-uploads${qindex}`}
                                  accept="image/*"
                                  name="banner_image"
                                  value=""
                                  onChange={(e) =>
                                    questionimg(
                                      e.target.files[0].name,
                                      e.target.name,
                                      e.target.files[0],
                                      qindex
                                    )
                                  }
                                />

                                <label
                                  for={`file-uploads${qindex}`}
                                  className="pt-0"
                                >
                                  <div id="questionimg">
                                    <i
                                      className="fa fa-image"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                </label>
                              </span> */}
                            </div>
                            <span id={`file-image${qindex}`}></span>
                            {/* {qindex > 0  && <button
                    className="menubtn removebanner"
                    type="button"
                    // onClick={(e) => removeImg(e , bannerval.id ,qindex)}
                  >
                    Remove image
                  </button>
} */}

                            {/* <div className="form-group float-left col-md-12">
                            <label
                              className="input-titlle form-label"
                              htmlFor="firstName"
                            >
                              Correct Answer{" "}
                              <span className="importantfield">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control questionrequiredfield"
                              name="correct_ans"
                              defaultValue={inputField.answer}
                              onChange={(e) =>
                                onInputChangecontact(
                                  e.target.value,
                                  e.target.name,
                                  qindex
                                )
                              }
                            />
                          </div> */}
                          </div>
                          {/* <div className="col-md-10">
                      <h5>Add Options</h5>
                      </div> */}
                          {inputField && (
                            <label htmlFor="firstName">
                              Options <span className="importantfield">*</span>
                            </label>
                          )}
                          <div className="inputboxcss choicecss">
                            {inputField.answers.map(
                              (choiceinputField, choiceindex) => (
                                <>
                                  <div
                                    key={choiceindex}
                                    className=" col-md-12 quizform choicesec   pb-2 mb-3"
                                  >
                                    <div className="">
                                      <div className="form-group float-left col-md-12">
                                        {/* {inputField.answer} - {choiceinputField.choice} */}
                                        <div
                                          className="form-control"
                                          id={`choiceid_${qindex}-${choiceindex}`}
                                        >
                                          <span
                                            id={`choice-image${qindex}-${choiceindex}`}
                                          ></span>
                                          <input
                                            type="radio"
                                            name={"currectans" + inputField.id}
                                            onChange={(e) =>
                                              onSelectedAns(
                                                choiceinputField.choice,
                                                e.target.name,
                                                qindex,
                                              )
                                            }
                                            // checked={
                                            //   inputField.answer ===
                                            //   choiceinputField.choice && "checked"
                                            // }
                                            checked={
                                              inputField.answer === null
                                                ? null
                                                : inputField.answer ===
                                                  choiceinputField.choice
                                            }
                                          />
                                          {choiceinputField.type === "text" ? (
                                            <input
                                              type="text"
                                              className="questioninput questionrequiredfield"
                                              id={`choice-input-uploads${qindex}-${choiceindex}`}
                                              placeholder="Type your option here or upload image"
                                              name="name"
                                              value={choiceinputField.choice}
                                              onChange={(e) =>
                                                onInputsetans(
                                                  e.target.value,
                                                  e.target.name,
                                                  choiceindex,
                                                  qindex,
                                                )
                                              }
                                            />
                                          ) : (
                                            <img
                                              id={`removechoiceimgoption${qindex}-${choiceindex}`}
                                              src={choiceinputField.choice}
                                              className="editchoiceimg"
                                            />
                                          )}

                                          <span className="uploader logosec">
                                            <input
                                              type="file"
                                              id={`choice-uploads${qindex}-${choiceindex}`}
                                              accept="image/*"
                                              name="image_choise"
                                              value=""
                                              onChange={(e) =>
                                                choicequestionimg(
                                                  e.target.files[0].name,
                                                  e.target.name,
                                                  e.target.files[0],
                                                  qindex,
                                                  choiceindex,
                                                )
                                              }
                                            />

                                            <label
                                              for={`choice-uploads${qindex}-${choiceindex}`}
                                              className="pt-0"
                                            >
                                              <div id="choicequestionimg">
                                                <i
                                                  className="fa fa-image"
                                                  aria-hidden="true"
                                                ></i>
                                              </div>
                                            </label>
                                          </span>
                                          {/* <div className="removeicon"> */}
                                          {choiceindex > 1 && (
                                            <button
                                              className="btn btn-link removequestion minus-btn "
                                              type="button"
                                              onClick={() =>
                                                onInputremoveans(
                                                  choiceindex,
                                                  qindex,
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-trash"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          )}
                                          {/* </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ),
                            )}
                          </div>
                          <div className="col-md-12">
                            <p
                              className="chooseoption"
                              onClick={() => handleAddcontactFields(qindex)}
                            >
                              + Add Options
                            </p>
                          </div>
                          <div className="form-group float-left col-md-6">
                            <label
                              className="input-titlle form-label"
                              htmlFor="firstName"
                            >
                              Marks <span className="importantfield">*</span>
                            </label>
                            <input
                              type="numbers"
                              className="form-control questionrequiredfield"
                              name="points"
                              defaultValue={inputField.points}
                              onChange={(e) =>
                                onInputChangecontact(
                                  e.target.value,
                                  e.target.name,
                                  qindex,
                                )
                              }
                            />
                          </div>
                          <div className="form-group float-left pl-4 col-md-6">
                            <label
                              className="input-titlle form-label"
                              htmlFor="firstName"
                            >
                              Negative Marks
                            </label>
                            <input
                              type="numbers"
                              className="form-control"
                              name="negativeMark"
                              defaultValue={inputField.negativeMark}
                              onChange={(e) =>
                                onInputChangecontact(
                                  e.target.value,
                                  e.target.name,
                                  qindex,
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </>
                ))}
              </Collapse>
            )}
            {addQuizstatus === "auto" && (
              <span className="uploader questionupload">
                <input
                  type="file"
                  id="file-uploads"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  name="quizfile"
                  value=""
                  onChange={(e) =>
                    uploadQuestions(
                      e.target.files[0].name,
                      e.target.name,
                      e.target.files[0],
                    )
                  }
                />

                <label for="file-uploads" className="quizlable" id="file-drag">
                  {/* {bannerview && (
                <img id="file-image" src={bannerview} alt="Preview"  />

                  )} */}
                  <div id="start">
                    <img src={uploadfileimg} />
                    <br />
                    <br />
                    <div>
                      <span className="nonecolor">Upload File or</span> Select a
                      file
                      <span className="importantfield"> *</span>
                    </div>
                    {/* <div id="notimage" className="hidden">Please select an image</div> */}
                  </div>
                </label>

                <div className="quizdetailmsg">
                  <span>Follow these steps to upload the .csv file-</span>
                  <ul>
                    <li>
                      Download the Sample sheet and fill Questions and Solutions
                      in respective columns.
                    </li>
                    <li>
                      Use 'Subjective' for Subjective Questions and 'Objective'
                      for Multiple Choice Questions in 'Type' column.
                    </li>
                    <li>
                      Please do no fill alphabets in 'Marks','Answer Keywords
                      marks' and 'Negative Marks' column.
                    </li>
                    <li>
                      Use comma-separated keywords in 'Answer Keywords' column.
                    </li>
                    <li>
                      Do not modify or rearrange the sheet headers (i.e. column
                      names) to avoid confusion.
                    </li>
                    <li>
                      Only .csv file format can be uploaded, so make sure prior
                      to saving.
                    </li>
                    <li>
                      Having followed the above steps correctly, click on the
                      file button to finish with the process.
                    </li>
                    <li>
                      In case you want to modify or make any changes to the
                      already uploaded score sheet, you can easily do so by
                      accessing the panel.
                    </li>
                  </ul>
                </div>
                <div className="text-left mainrow">
                  <a href="https://gradimages.s3.ap-south-1.amazonaws.com/quizes/Questions_Template.xlsx">
                    <button className="p-3 transparentbtn " type="button">
                      Download Sample File <FaDownload />
                    </button>
                  </a>
                </div>
                {autouploadmsg && <p className="errormsg">{autouploadmsg}</p>}
              </span>
            )}
            {errormsg && <p className="errormsg">{errormsg}</p>}

            {addQuizstatus === "mannual" && (
              <>
                <div className="text-right mainrow">
                  <button
                    className="btn add-round fr w-100"
                    type="button"
                    onClick={() => handleAddFields()}
                  >
                    + Add Question
                  </button>
                </div>
              </>
            )}
          </Form>
        </div>
        <hr />
      </div>
      <div className="hosting_bottom">
        <Button className="help-btn" type="button" onClick={() => prevStep(2)}>
          <FaArrowLeft />
          Go Back
        </Button>
        <Button
          className="save-hosting-btn"
          type="button"
          onClick={() => nextStep(2, "continue")}
        >
          Save and Submit <FaArrowRight />
        </Button>
        {/* {" "}
          <Button
            className="transparentbtn float-right mt-10 mr-2"
            type="button"
            onClick={(e) => clearQuestion(e)}
          >
            Clear
          </Button> */}
      </div>
    </section>
  );
};

export default AddQuestions;
