import axios from "axios";
import config from '../components/CommonFiles/config.json'
const mainurl = Object.values(config["URL"]);
const URL = mainurl.join("");
const mainURL = URL;

export const sendOTPForEmailVerification = async ({ userId, email }) => {
    try {
        const response = await axios.post(`${mainURL}/generateOTP`, { userId, email });
        return response.data;
    } catch (error) {
        return { status: 0, message: error.message || "Failed to send OTP", data: null }
    }
};

export const verifyOTPForEmailVerification = async ({ userId, email, otp }) => {
    try {
        const response = await axios.post(`${mainURL}/verifyOTP`, { userId, email, otp });
        return response.data;
    } catch (error) {
        return { status: 0, message: error.message || "Failed to verify OTP", data: null }
    }
}

export const resendOTPForEmailVerification = async ({ userId, email }) => {
    try {
        const response = await axios.post(`${mainURL}/resendOTP`, { userId, email });
        return response.data;
    } catch (error) {
        return { status: 0, message: error.message || "Failed to resend OTP", data: null }
    }
}
