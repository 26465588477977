import React, { useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
const QuizTab = (props) => {
  const [pbar, setPbar] = useState(10);
  window.scrollTo({
    top: 20,
    behavior: "smooth",
  });

  return (
    <div className="tab-names box arrow-bottommain">
      <div className="container ">
        <div className="row tab-align-center">
          <div
            className={
              1 === parseInt(localStorage.getItem("quizStep"))
                ? " quiz_tab activebtn"
                : "quiz_tab"
            }
          >
            <span>1</span>Quiz Settings
          </div>

          <div
            className={
              2 === parseInt(localStorage.getItem("quizStep"))
                ? " quiz_tab activebtn"
                : "quiz_tab"
            }
          >
            <span>2</span> Questions
          </div>
        </div>
      </div>
      <span className="hostingProgress">
        <ProgressBar
          now={parseInt(localStorage.getItem("quizStep")) === 1 ? 50 : 100}
          visuallyHidden
        />
      </span>
    </div>
  );
};

export default QuizTab;
